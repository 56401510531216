import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import {
  CoupeOperation,
  PieceBuilder,
  RadioButton,
  RotationHelper,
  SectionTitle,
  VectorHelper,
} from '../../../internal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { INNER, OUTER } from '../../../constants/Angles';
import { DEGREES } from '../../../constants/Values';
import {
  INNER_COUPE,
  LEFT_BACK_UP,
  LEFT_DOWN,
  LEFT_FRONT_DOWN,
  LEFT_HORIZONTAL,
  LEFT_UP,
  NORMAL,
  OUTER_COUPE,
  RIGHT_BACK_UP,
  RIGHT_DOWN,
  RIGHT_FRONT_DOWN,
  RIGHT_HORIZONTAL,
  RIGHT_UP,
} from '../../../constants/ConnectObjectStyles';
import _ from 'lodash';
import { animateScroll } from 'react-scroll';
import { PieceService } from '../../../classes/services/PieceService';
import { addAlertMessage, setShouldUpdatePrice, setWindowIsLoading } from '../../../actions/GeneralActions';
import { COUPE, HEIGHT_COUPE } from '../../../constants/OperationTypes';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import ConfigurableReducerHelper from '../../../classes/helpers/ConfigurableReducerHelper';
import { ConnectionTypeSelect } from './ConnectionTypeSelect';
import { ConnectionSideSelect } from './ConnectionSideSelect';
import { NormalConnectionStyleSelect } from './NormalConnectionStyleSelect';
import { WidthCoupeInput } from './WidthCoupeInput';
import { HeightCoupeInput } from './HeightCoupeInput';
import { DANGER } from '../../../constants/Variants';
import { ValidatedInput } from '../../forms/inputs/ValidatedInput';
import { useForm } from 'react-hook-form';
import { parseNumber, parseToCommaSeparated } from '../../../classes/helpers/StringHelper';
import { BASEBOARDS, WALL_SLABS } from '../../../constants/Presets';
import { ModalHolder } from '../ModalHolder';
import { BACK, FRONT, OTHER } from '../../../constants/ObjectSides';
import { PieceTypeSelect } from '../../forms/selects/PieceTypeSelect';
import { MASSIVE_TYPES } from '../../../constants/ObjectTypes';

export function ConnectPieceModal(props) {
  const prefix = 'modals.connectPieceModal.';
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const [connectionType, setConnectionType] = useState(null);
  const [connectionSide, setConnectionSide] = useState(null);
  const [selectedCoupe, setSelectedCoupe] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [widthCoupeValue, setWidthCoupeValue] = useState(0);
  const [widthCoupeInputType, setWidthCoupeInputType] = useState(DEGREES);
  const [name, setName] = useState('');
  const [selectedAlignment, setSelectedAlignment] = useState(FRONT);
  const [showAlignmentForm, setShowAlignmentForm] = useState();
  const [selectedPieceType, setSelectedPieceType] = useState();

  const objectBuilder = new PieceBuilder();

  const pieceService = new PieceService();
  const configurableReducerHelper = new ConfigurableReducerHelper();

  const { currentConfiguration, currentPiece } = useSelector(state => state.offerReducer);
  const { canEdit } = useSelector(state => state.generalReducer);

  const dispatch = useDispatch();

  const { register, errors, handleSubmit, getValues, watch } = useForm();

  useEffect(() => {
    animateScroll.scrollToBottom({
      containerId: 'connectPieceModalScrollContainer',
      smooth: true,
      duration: 500,
    });
  }, [
    connectionType,
    connectionSide,
    selectedCoupe,
    selectedStyle,
    widthCoupeValue,
    widthCoupeValue,
    name,
    getValues('width'),
  ]);

  useEffect(() => {
    if (props.isActive) {
      setName(generateDefaultName());

      if ([BASEBOARDS, WALL_SLABS].includes(currentConfiguration.options.preset)) {
        setConnectionType(COUPE);
      }
    } else {
      resetForm();
    }
  }, [props.isActive]);

  useEffect(() => {
    if (
      [LEFT_HORIZONTAL, RIGHT_HORIZONTAL].includes(selectedStyle) &&
      currentPiece?.dimensions.width !== parseNumber(watch('width'))
    ) {
      if (!showAlignmentForm) setShowAlignmentForm(true);
    } else {
      if (showAlignmentForm) setShowAlignmentForm(false);
    }
  }, [watch('width')]);

  const getWidthInputValue = () => {
    if (!currentPiece || !selectedCoupe) return;

    return parseToCommaSeparated(currentPiece.dimensions.width - selectedCoupe.dimensions.width);
  };

  const createOrUpdatePiece = newPiece => {
    dispatch(setWindowIsLoading(true));

    pieceService
      .connectNewPiece(
        currentConfiguration,
        currentPiece.id,
        newPiece,
        selectedAlignment,
        getValues('alignmentDistance'),
      )
      .then(response => {
        if (response.success) {
          let tempConfiguration = currentConfiguration;

          tempConfiguration.pieces = currentConfiguration.pieces.concat([response.data.newPiece]);
          tempConfiguration.pieces = tempConfiguration.pieces.map(piece => {
            if (piece.id === response.data.updatedPiece.id) {
              piece = response.data.updatedPiece;
            }

            return piece;
          });

          configurableReducerHelper.updateConfiguration(tempConfiguration);
          dispatch(setShouldUpdatePrice(true));

          props.onClose(true);
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, t(prefix + 'createPieceFailed')));
        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const getOppositeConnectionStyle = connectionStyle => {
    let oppositeStyle;

    switch (connectionStyle) {
      case LEFT_UP:
        oppositeStyle = RIGHT_BACK_UP;
        break;
      case LEFT_DOWN:
        oppositeStyle = RIGHT_FRONT_DOWN;
        break;
      case LEFT_FRONT_DOWN:
        oppositeStyle = RIGHT_DOWN;
        break;
      case LEFT_BACK_UP:
        oppositeStyle = RIGHT_UP;
        break;
      case LEFT_HORIZONTAL:
        oppositeStyle = RIGHT_HORIZONTAL;
        break;
      case RIGHT_UP:
        oppositeStyle = LEFT_BACK_UP;
        break;
      case RIGHT_DOWN:
        oppositeStyle = LEFT_FRONT_DOWN;
        break;
      case RIGHT_FRONT_DOWN:
        oppositeStyle = LEFT_DOWN;
        break;
      case RIGHT_BACK_UP:
        oppositeStyle = LEFT_UP;
        break;
      case RIGHT_HORIZONTAL:
        oppositeStyle = LEFT_HORIZONTAL;
        break;
      default:
        oppositeStyle = null;
        break;
    }

    return oppositeStyle;
  };

  const selectConnectedSide = side => {
    setSelectedCoupe(null);
    setConnectionSide(side);
  };

  const selectCoupe = coupe => {
    coupe = Object.assign(new CoupeOperation(), coupe);

    if (coupe.type === HEIGHT_COUPE) {
      if (coupe.angle === INNER) coupe.angle = OUTER;
      else coupe.angle = INNER;
    }

    setSelectedCoupe(coupe);
    setConnectionSide(null);
  };

  const selectConnectionType = connectionTypeToSet => {
    setConnectionType(connectionTypeToSet);
    setConnectionSide(null);
    setSelectedCoupe(null);
    setSelectedStyle(null);
    setWidthCoupeValue(0);
  };

  const onCoupeInputChange = value => {
    value = parseNumber(value);

    setWidthCoupeValue(value);
  };

  const addNewPieceWithCoupe = (coupe, length, width, height, newCoupeWidth) => {
    let pieceDimensions = _.cloneDeep(currentPiece.dimensions);
    pieceDimensions.length = length;
    pieceDimensions.width = width;
    pieceDimensions.height = height;

    let additionalDimension = { type: widthCoupeInputType, value: widthCoupeValue };

    const newPiece = objectBuilder.buildWithCoupe(
      pieceDimensions,
      currentPiece,
      coupe,
      additionalDimension,
      currentConfiguration.type,
      [BASEBOARDS, WALL_SLABS].includes(currentConfiguration.options.preset) ? HEIGHT_COUPE : COUPE,
      parseNumber(newCoupeWidth),
    );

    if (newPiece == null) return;

    // Set the name of the object
    newPiece.name = name;

    // Set the object as connected to the main object
    const coupeConnectionStyle = coupe.angle === INNER ? INNER_COUPE : OUTER_COUPE;
    newPiece.addConnectedObject(currentPiece.id, coupeConnectionStyle);
    coupe.addConnectedCoupe(newPiece.getOperationsByType(coupe.type)[0]);

    // Put the updated selected coupe in place of the old coupe
    for (let i = 0; i < currentPiece.operations.length; i++) {
      if (currentPiece.operations[i].type === COUPE && currentPiece.operations[i].id === coupe.id) {
        currentPiece.operations[i] = coupe;
      }
    }

    newPiece.updateObjectRotationAndPosition();

    return newPiece;
  };

  const generateDefaultName = () => {
    const numberOfPieces = currentConfiguration ? currentConfiguration.pieces.length + 1 : '';

    return t(prefix + 'defaultName') + ' ' + numberOfPieces;
  };

  const resetForm = () => {
    setConnectionType(null);
    setConnectionSide(null);
    setSelectedCoupe(null);
    setSelectedStyle(null);
    setWidthCoupeValue(0);
    setWidthCoupeInputType(DEGREES);
    setName('');
    setSelectedAlignment(FRONT);
    setSelectedPieceType();
  };

  const submit = fields => {
    let newPiece;

    if (selectedCoupe) {
      const length = parseNumber(fields.length);
      const width = parseNumber(fields.width);
      const coupeWidth = width - parseNumber(fields.coupeWidth);

      newPiece = addNewPieceWithCoupe(selectedCoupe, length, width, getNewPieceHeight(), coupeWidth);
      newPiece.type = selectedPieceType;
    } else if (connectionType === NORMAL && selectedStyle != null) {
      // Create new object
      let dimensions = _.cloneDeep(currentPiece.dimensions);
      dimensions.length = parseNumber(fields.length);
      dimensions.width = parseNumber(fields.width);

      newPiece = objectBuilder.build(name, dimensions, currentConfiguration.type);
      newPiece.type = selectedPieceType;
      newPiece.dimensions.height = getNewPieceHeight();

      // Set rotation
      newPiece.rotateY(RotationHelper.getRotationByConnectionStyle(currentPiece.rotation, selectedStyle));

      // Set position
      newPiece.position = VectorHelper.getVectorForNewObject(
        currentPiece,
        newPiece,
        selectedStyle,
        selectedAlignment,
        getValues('alignmentDistance'),
      );
      newPiece.addConnectedObject(currentPiece.id, getOppositeConnectionStyle(selectedStyle));
    }

    if (newPiece) {
      createOrUpdatePiece(newPiece);
    }
  };

  const getNewPieceHeight = () => {
    // When the existing piece is one that is made from one slab of bluestone, the height should be 1 cm lower
    // so that the front sides of all pieces will be aligned
    if (MASSIVE_TYPES.includes(currentPiece.type) && !MASSIVE_TYPES.includes(selectedPieceType)) {
      return currentConfiguration.options.height - 1;
    }

    // Here it is the opposite, with an existing piece made from one slab of bluestone, the height should be 1 cm higher
    if (MASSIVE_TYPES.includes(selectedPieceType) && !MASSIVE_TYPES.includes(currentPiece.type)) {
      return currentConfiguration.options.height + 1;
    }

    return currentPiece.dimensions.height;
  };

  const renderNameInput = () => {
    return (
      <div className="form-group">
        <label>{t(prefix + 'nameLabel')}</label>
        <input
          data-cy="connectPieceModal-nameInput"
          value={name}
          type="text"
          placeholder={t(prefix + 'namePlaceholder')}
          onChange={event => setName(event.target.value)}
          disabled={!canEdit}
        />
      </div>
    );
  };

  const renderConnectionForm = () => {
    if (connectionType != null && (selectedCoupe != null || connectionSide != null)) {
      if (connectionType === NORMAL) {
        return (
          <NormalConnectionStyleSelect
            selectedStyle={selectedStyle}
            selectedSide={connectionSide}
            setSelectedStyle={style => setSelectedStyle(style)}
            disabled={!canEdit}
          />
        );
      } else if (connectionType === COUPE && selectedCoupe != null) {
        return (
          <WidthCoupeInput
            onInputChange={value => onCoupeInputChange(value)}
            onInputTypeChange={type => setWidthCoupeInputType(type)}
            disabled={!canEdit}
          />
        );
      } else {
        // connectionType === HEIGHT_COUPE
        return <HeightCoupeInput onInputChange={value => onCoupeInputChange(value)} disabled={!canEdit} />;
      }
    }
  };

  const renderDimensionForm = () => {
    if (widthCoupeValue < 1 && !selectedStyle) return;

    const widthLabel =
      currentConfiguration.options.preset === BASEBOARDS ? t(prefix + 'heightLabel') : t(prefix + 'widthLabel');

    return (
      <>
        <SectionTitle content={t(prefix + 'dimensionTitle')} />

        <div className="row">
          <div className="col-6">
            <ValidatedInput
              dataCy="connectPieceModal-lengthInput"
              register={register}
              name="length"
              error={errors.length}
              placeholder={t(prefix + 'lengthPlaceholder')}
              label={t(prefix + 'lengthLabel')}
              required={true}
              min={1}
              max={5000}
              disabled={!canEdit}
            />
          </div>
          <div className="col-6">
            <ValidatedInput
              dataCy="connectPieceModal-widthInput"
              register={register}
              name="width"
              error={errors.width}
              placeholder={t(prefix + 'widthPlaceholder')}
              label={widthLabel}
              required={true}
              min={1}
              max={5000}
              disabled={!canEdit}
            />
          </div>
        </div>
      </>
    );
  };

  const renderAlignmentForm = () => {
    if (!showAlignmentForm) {
      return;
    }

    return (
      <>
        <SectionTitle content={t(prefix + 'alignmentTitle')} />

        <RadioButton
          onChange={() => setSelectedAlignment(FRONT)}
          name="alignmentRadioButton"
          disabled={!canEdit}
          content={t(prefix + 'alignedToFrontOption')}
          checked={selectedAlignment === FRONT}
        />
        <RadioButton
          onChange={() => setSelectedAlignment(BACK)}
          name="alignmentRadioButton"
          disabled={!canEdit}
          content={t(prefix + 'alignedToBackOption')}
          checked={selectedAlignment === BACK}
        />
        <RadioButton
          onChange={() => setSelectedAlignment(OTHER)}
          name="alignmentRadioButton"
          disabled={!canEdit}
          content={t(prefix + 'alignedSpecificDistanceOption')}
          checked={selectedAlignment === OTHER}
        />

        <ValidatedInput
          register={register}
          name="alignmentDistance"
          error={errors.alignmentDistance}
          placeholder={t(prefix + 'alignmentDistancePlaceholder')}
          label={t(prefix + 'alignmentDistanceLabel')}
          required={selectedAlignment === OTHER}
          min={selectedAlignment === OTHER ? 0.1 : 0}
          max={Math.abs(currentPiece.dimensions.width - 1)}
          disabled={!canEdit || selectedAlignment !== OTHER}
        />
      </>
    );
  };

  const renderFooterButtons = () => {
    const close = () => {
      resetForm();
      props.onClose();
    };

    const submitButton = (
      <button
        data-cy="connectPieceModal-submitButton"
        key={'connectPieceModalSubmitButton'}
        className="button button--primary"
        form="connectPieceForm"
        type="submit"
      >
        {t(prefix + 'submitButton')}
      </button>
    );

    let buttons = [
      <div
        key={'connectPieceModalCancelButton'}
        data-cy="connectPieceModal-cancelButton"
        className="button button button--outline"
        onClick={() => close()}
      >
        {t(prefix + 'cancelButton')}
      </div>,
    ];

    if (canEdit) {
      buttons.unshift(submitButton);
    }

    return buttons;
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={props.onClose} animation={true} className="modal--connection">
        <Modal.Header>
          <Modal.Title>{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="connectPieceModalScrollContainer">
          <form id="connectPieceForm" onSubmit={handleSubmit(submit)}>
            {renderNameInput()}

            <ConnectionTypeSelect
              currentConnectionType={connectionType}
              setSelectionType={style => selectConnectionType(style)}
              variant={[BASEBOARDS, WALL_SLABS].includes(currentConfiguration?.options.preset) ? HEIGHT_COUPE : COUPE}
            />

            <ConnectionSideSelect
              selectedType={connectionType}
              selectedSide={connectionSide}
              selectedCoupe={selectedCoupe}
              selectCoupe={coupe => selectCoupe(coupe)}
              selectSide={side => selectConnectedSide(side)}
            />

            {!!(
              selectedCoupe &&
              selectedCoupe.type === COUPE &&
              selectedCoupe.dimensions.width !== currentPiece?.dimensions.width
            ) && (
              <>
                <SectionTitle content={t(prefix + 'newCoupeWidthTitle')} />

                <ValidatedInput
                  register={register}
                  name="coupeWidth"
                  error={errors.coupeWidth}
                  placeholder={t(prefix + 'coupeWidthPlaceholder')}
                  label={t(prefix + 'coupeWidthLabel')}
                  required={true}
                  max={currentPiece?.dimensions.width}
                  value={getWidthInputValue()}
                  disabled={!canEdit}
                />
              </>
            )}

            {renderConnectionForm()}
            {renderDimensionForm()}
            {renderAlignmentForm()}

            {
              // Two parts of the if statement, one for connection without coupe and one for connection with coupe
              !!(
                (selectedStyle && getValues('length') && getValues('width')) ||
                (selectedCoupe && widthCoupeValue)
              ) && (
                <>
                  <SectionTitle content={t(prefix + 'pieceTypeSelectTitle')} />

                  <PieceTypeSelect
                    onChange={pieceType => setSelectedPieceType(pieceType)}
                    selectedPieceType={selectedPieceType}
                    configuration={currentConfiguration}
                  />
                </>
              )
            }
          </form>
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
}

ConnectPieceModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
