import { VectorHelper } from './VectorHelper';
import * as ObjectSides from '../../constants/ObjectSides';
import { ArrowHelper, Vector3 } from 'three';
import * as ThreeConstants from '../../constants/ThreeConstants';

const standardArrowSize = 3;
const minimumArrowSize = 2;

export function createArrowsForFinishedSides(piece, sides, arrowSize = null) {
  let arrowList = [];

  piece.getFinishedSides.forEach(finishedSide => {
    const side = finishedSide.name;
    const type = finishedSide.type;
    let arrowPosition = VectorHelper.getVectorForFinishedSideArrow(piece, finishedSide);
    let direction = getArrowDirectionBySide(side);

    if (direction) {
      let isStandard = sides.find(f => f.name === side && f.type === type);

      if (!arrowSize) arrowSize = calculateArrowSize(piece.dimensions.length);
      if (arrowSize < minimumArrowSize) arrowSize = minimumArrowSize;
      let arrow = createArrow(arrowPosition, direction, isStandard, arrowSize);

      arrowList.push(arrow);
    }
  });

  return arrowList;
}

export function calculateArrowSize(length) {
  return standardArrowSize * (length / 120);
}

function createArrow(position, direction, isStandard, size) {
  // Length has to be bigger than the headLength and headWidth
  const color = isStandard ? ThreeConstants.STANDARD_ARROW_COLOR : ThreeConstants.EXTRA_ARROW_COLOR;

  const arrow = new ArrowHelper(direction, direction, size + 0.01, color, size, size);
  arrow.position.add(position);

  return arrow;
}

function getArrowDirectionBySide(side) {
  let direction;

  switch (side) {
    case ObjectSides.FRONT:
      direction = new Vector3(0, 0, -1);
      break;
    case ObjectSides.LEFT:
      direction = new Vector3(1, 0, 0);
      break;
    case ObjectSides.BACK:
      direction = new Vector3(0, 0, 1);
      break;
    case ObjectSides.RIGHT:
      direction = new Vector3(-1, 0, 0);
      break;
    case ObjectSides.TOP:
      // No arrow needed
      break;
    case ObjectSides.BOTTOM:
      // No arrow needed
      break;
    default:
      break;
  }

  return direction;
}
