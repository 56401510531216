import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_PICKER } from '../../../constants/Variants';

export const DatePicker = props => {
  const [pickedDate, setPickedDate] = useState();

  const formatDateForInput = date => {
    let format;

    if (props.variant === DATE_PICKER.DATE) {
      format = 'YYYY-MM-DD';
    } else {
      format = 'YYYY-MM-DDTHH:mm';
    }

    return date ? moment(date).format(format) : '';
  };

  const updatePickedDate = dateString => {
    const newDate = moment(dateString);
    setPickedDate(newDate);
    props.onChange(newDate);
  };

  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      <input
        data-cy={props.dataCy}
        type={props.variant === DATE_PICKER.DATE ? 'date' : 'datetime-local'}
        className="form-group__date"
        value={formatDateForInput(pickedDate ?? props.date)}
        onChange={event => updatePickedDate(event.target.value)}
        disabled={props.disabled}
      />
    </div>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  variant: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
};

DatePicker.defaultProps = {
  variant: DATE_PICKER.DATE,
  date: new Date(),
  disabled: false,
};
