import { BoxGeometry, ExtrudeGeometry, Mesh, Shape, Vector3, Math, MeshBasicMaterial } from 'three';
import { DEFAULT_OBJECT_COLOR } from '../../constants/ThreeConstants';
import CSG from '../../threeJsPlugins/csg';
import { OperationBuilder, VectorHelper } from '../../internal';
import { LEFT, RIGHT } from '../../constants/ObjectSides';

export class TypeMeshCreator {
  static createType1Mesh(dimensions) {
    const material = new MeshBasicMaterial({ color: DEFAULT_OBJECT_COLOR });
    const geometry = new BoxGeometry(dimensions.length, dimensions.height, dimensions.width);

    return new Mesh(geometry, material);
  }

  static createType3Mesh(dimensions) {
    const barWidth = dimensions.barWidth;
    const barHeight = dimensions.height - 1 + dimensions.extrusion;

    const material = new MeshBasicMaterial({ color: DEFAULT_OBJECT_COLOR });
    const mainGeometry = new BoxGeometry(dimensions.length, dimensions.height, dimensions.width - barWidth);
    const mainMesh = new Mesh(mainGeometry, material);
    mainMesh.position.add(new Vector3(0, 0, barWidth / 2));
    mainMesh.updateMatrix();

    const barGeometry = new BoxGeometry(dimensions.length, barHeight, barWidth);
    let barMesh = new Mesh(barGeometry, material);
    // Subtract a small margin so a line will appear between the bar and the mesh
    barMesh.position.add(
      new Vector3(0, (barHeight - dimensions.height) / 2 + 1, -(dimensions.width / 2) + barWidth / 2),
    );
    barMesh.updateMatrix();

    let main = CSG.fromMesh(mainMesh);
    let bar = CSG.fromMesh(barMesh);

    main = main.union(bar);

    let resultMesh = CSG.toMesh(main, mainMesh.matrix);
    resultMesh.material = material;

    return resultMesh;
  }

  static createType5Mesh(dimensions) {
    // Dimensions = frontHeight, height, extrusion, width, length
    const material = new MeshBasicMaterial({ color: DEFAULT_OBJECT_COLOR });

    let mainMesh = new Mesh(new BoxGeometry(dimensions.length, dimensions.height, dimensions.width), material);

    const height = dimensions.height - dimensions.frontHeight;

    let shape = new Shape();
    shape.moveTo(0, 0);
    shape.lineTo(0, height);
    shape.lineTo(dimensions.width - dimensions.barWidth, height);
    shape.lineTo(dimensions.width - dimensions.barWidth, height - dimensions.extrusion);

    // Add some settings for the shape, I added 0.1 so it would extrude from the main object a bit.
    // That way the coupe will cut through the whole object
    let extrudeSettings = {
      steps: 2,
      depth: dimensions.length,
      bevelEnabled: false,
    };

    let triangle = new Mesh(new ExtrudeGeometry(shape, extrudeSettings), new MeshBasicMaterial({ color: 0xff00ff }));
    triangle.rotateY(Math.degToRad(90));
    const yPos = -(dimensions.height / 2) + dimensions.frontHeight;
    triangle.position.add(new Vector3(-(dimensions.length / 2), yPos, dimensions.width / 2));
    triangle.updateMatrix();

    const triangleBSP = CSG.fromMesh(triangle);

    let mainBspTree = CSG.fromMesh(mainMesh);

    if (dimensions.frontHeight !== dimensions.height) {
      mainBspTree = mainBspTree.subtract(triangleBSP);
    }

    let resultMesh = CSG.toMesh(mainBspTree, mainMesh.matrix);
    resultMesh.material = material;

    return resultMesh;
  }

  static createType7Mesh(dimensions) {
    if (dimensions.extrusion === 0 && dimensions.frontHeight === dimensions.height)
      return this.createType1Mesh(dimensions);

    const operationBuilder = new OperationBuilder();
    let mainMesh = this.createType5Mesh(dimensions);

    let height = dimensions.height - dimensions.frontHeight - dimensions.extrusion;
    height = height < 0 ? 0 : height;

    //region Create two cushions, one for left one for right
    let leftCushion = operationBuilder.buildCushionForSlopingSide(dimensions, height);
    leftCushion.position.add(VectorHelper.getVectorForSlopingSideCushions(dimensions, LEFT));
    leftCushion.updateMatrix();

    let rightCushion = operationBuilder.buildCushionForSlopingSide(dimensions, height);
    rightCushion.position.add(VectorHelper.getVectorForSlopingSideCushions(dimensions, RIGHT));
    rightCushion.updateMatrix();
    //endregion

    let mainBsp = CSG.fromMesh(mainMesh);
    let leftCushionBsp = CSG.fromMesh(leftCushion);
    let rightCushionBsp = CSG.fromMesh(rightCushion);

    mainBsp = mainBsp.union(leftCushionBsp);
    mainBsp = mainBsp.union(rightCushionBsp);

    let result = CSG.toMesh(mainBsp, mainMesh.matrix);
    result.material = mainMesh.material;

    return result;
  }
}
