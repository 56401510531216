import * as React from 'react';
import PropTypes from 'prop-types';

export const SidebarTextItem = props => {
  return (
    <div data-cy={props.dataCy} className="list__description">
      {props.description && <span>{props.description}</span>}
      {props.value ? <span>{props.value}</span> : null}
    </div>
  );
};

SidebarTextItem.propTypes = {
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataCy: PropTypes.string,
};
