import * as React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DANGER } from '../../../constants/Variants';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { ValidationMessage } from '../../shared/ValidationMessage';

export const ChangePasswordForm = props => {
  const { watch, errors, register, handleSubmit } = useForm();
  const validationPrefix = 'validation.';
  const formPrefix = 'pages.settings.changePasswordForm.';
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const changePassword = fields => {
    let data = {
      currentPassword: fields.oldPassword.trim(),
      newPassword: fields.newPassword.trim(),
    };
    props.handlePasswordChange(data);
  };
  return (
    <div className="p-l-4 p-r-4">
      <form id="changePasswordForm" onSubmit={handleSubmit(changePassword)}>
        <div className="m-b-2">
          <div className="form-group">
            <label>{t(formPrefix + 'currentPassword')}</label>
            <input
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 100,
              })}
              name="oldPassword"
              type="password"
            />
            {errors.oldPassword?.type === 'required' ? (
              <ValidationMessage content={t(validationPrefix + 'required')} variant={DANGER} />
            ) : null}
            {errors.oldPassword?.type === 'minLength' ? (
              <ValidationMessage content={t(validationPrefix + 'minLength', { min: 8 })} variant={DANGER} />
            ) : null}
            {errors.oldPassword?.type === 'maxLength' ? (
              <ValidationMessage content={t(validationPrefix + 'maxLength', { max: 100 })} variant={DANGER} />
            ) : null}
          </div>

          <div className="form-group">
            <label>{t(formPrefix + 'newPassword')}</label>
            <input
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 100,
              })}
              name="newPassword"
              type="password"
            />
            {errors.newPassword?.type === 'required' ? (
              <ValidationMessage content={t(validationPrefix + 'required')} variant={DANGER} />
            ) : null}
            {errors.newPassword?.type === 'minLength' ? (
              <ValidationMessage content={t(validationPrefix + 'minLength', { min: 8 })} variant={DANGER} />
            ) : null}
            {errors.newPassword?.type === 'maxLength' ? (
              <ValidationMessage content={t(validationPrefix + 'maxLength', { max: 100 })} variant={DANGER} />
            ) : null}
          </div>

          <div className="form-group">
            <label>{t(formPrefix + 'repeatPassword')}</label>
            <input
              ref={register({
                required: true,
                minLength: 8,
                maxLength: 100,
                validate: value => value === watch('newPassword'),
              })}
              name="newPasswordCheck"
              type="password"
            />
            {errors.newPasswordCheck?.type === 'validate' ? (
              <ValidationMessage content={t(validationPrefix + 'notMatch')} variant={DANGER} />
            ) : null}
          </div>
        </div>

        <button type="submit" form="changePasswordForm" className="button button--primary">
          {t(formPrefix + 'changePassword')}
        </button>
      </form>
    </div>
  );
};

ChangePasswordForm.propTypes = {
  handlePasswordChange: PropTypes.func.isRequired,
};
