export const PMO_1 = 'PMO_1';
export const PMO_2 = 'PMO_2';
export const MONDAY_DELIVERY = 'MONDAY_DELIVERY';
export const WEDNESDAY_DELIVERY_WEEKLY = 'WEDNESDAY_DELIVERY_WEEKLY';
export const WEDNESDAY_DELIVERY_BI_WEEKLY = 'WEDNESDAY_DELIVERY_BI_WEEKLY';
export const FRIDAY_COLLECT = 'FRIDAY_COLLECT';
export const OTHER = 'OTHER';

export const TYPES_FOR_DELIVERY = [
  PMO_1,
  PMO_2,
  MONDAY_DELIVERY,
  WEDNESDAY_DELIVERY_WEEKLY,
  WEDNESDAY_DELIVERY_BI_WEEKLY,
];

export const TYPES_FOR_COLLECT = [FRIDAY_COLLECT];
