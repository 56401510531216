import produce from 'immer';
import * as ActionTypes from '../constants/ActionTypes';
import { DANGER } from '../constants/Variants';
import { store } from '../store/index';
import { ADMIN_CAN_EDIT_OFFERS } from '../constants/AdminSettings';
import { cloneDeep } from 'lodash';
import { hasOneOfRoles } from '../classes/helpers/UserHelper';
import { UserSettings } from '../classes/services/UserSettings';
import { USER } from '../constants/RoleNames';

const defaultConfirmationModalObject = {
  isActive: false,
  content: '',
  onAccept: () => {},
  onClose: () => {},
};

const initialState = {
  domain: '',
  currentUser: null,
  isAuthenticated: false,
  currentOfferId: 0,
  currentConfigurationId: 0,
  currentObjectId: 0,
  alertMessages: [],
  canvasIsLoading: false,
  formsAreLoading: false,
  listIsLoading: false,
  screenIsLoading: false,
  windowIsLoading: false,
  priceIsLoading: false,
  activeOperationModal: { type: null, additionalType: '' },
  activeInfoModal: { isActive: false, content: '' },
  shouldUpdatePrice: false,
  shouldUpdateLogo: false,
  aspect: '',
  view: '',
  searchKeyword: null,
  currentPriceVersion: null,
  refreshModalIsActive: false,

  canEdit: true,
  confirmationModal: defaultConfirmationModalObject,

  configuratorUnsavedDimension: null,

  settings: {
    [ADMIN_CAN_EDIT_OFFERS]: false,
  },

  userSettings: new UserSettings(0, null),
};

function generalReducer(state = initialState, action) {
  return produce(state, nextState => {
    switch (action.type) {
      case ActionTypes.SET_DOMAIN:
        nextState.domain = action.domain;
        break;
      case ActionTypes.SET_CURRENT_USER:
        nextState.currentUser = action.payload;
        break;
      case ActionTypes.ADD_ALERT_MESSAGE:
        const alert = {
          variant: action.payload.variant,
          content: action.payload.content,
        };

        let newAlertMessages = state.alertMessages.concat(alert);

        // Only show max 4 messages
        if (newAlertMessages.length > 4) {
          newAlertMessages.splice(0, 1);
        }

        nextState.alertMessages = [...newAlertMessages];

        // Automatically remove after x seconds
        if (alert.variant !== DANGER) {
          setTimeout(() => {
            const s = store.getState();
            let alerts = s.generalReducer.alertMessages;
            const index = alerts.findIndex(alertToRemove => alertToRemove.content === alert.content);

            store.dispatch({
              type: ActionTypes.REMOVE_ALERT_MESSAGE,
              payload: index,
            });
          }, 4000);
        }
        break;
      case ActionTypes.REMOVE_ALERT_MESSAGE:
        const messages = state.alertMessages.map(message => message);
        messages.splice(action.payload.index, 1);

        nextState.alertMessages = [...messages];
        break;
      case ActionTypes.SET_CANVAS_IS_LOADING:
        nextState.canvasIsLoading = action.payload;
        break;
      case ActionTypes.SET_FORMS_ARE_LOADING:
        nextState.formsAreLoading = action.payload;
        break;
      case ActionTypes.SET_LIST_IS_LOADING:
        nextState.listIsLoading = action.payload;
        break;
      case ActionTypes.SET_SCREEN_IS_LOADING:
        nextState.screenIsLoading = action.payload;
        break;
      case ActionTypes.SET_WINDOW_IS_LOADING:
        nextState.windowIsLoading = action.payload;
        break;
      case ActionTypes.SET_PRICE_IS_LOADING:
        nextState.priceIsLoading = action.payload;
        break;
      case ActionTypes.SET_ACTIVE_OPERATION_MODAL:
        nextState.activeOperationModal = action.payload;
        break;
      case ActionTypes.SET_ACTIVE_INFO_MODAL:
        nextState.activeInfoModal = action.payload;
        break;
      case ActionTypes.SET_SHOULD_UPDATE_PRICE:
        nextState.shouldUpdatePrice = action.payload;
        break;
      case ActionTypes.SET_SHOULD_UPDATE_LOGO:
        nextState.shouldUpdateLogo = action.payload;
        break;
      case ActionTypes.SET_PRICE_TYPE:
        nextState.priceType = action.priceType;
        nextState.shouldUpdatePrice = true;
        break;
      case ActionTypes.SET_CAN_EDIT:
        nextState.canEdit = action.canEdit;
        break;
      case ActionTypes.SET_CONFIGURATOR_UNSAVED_DIMENSION:
        nextState.configuratorUnsavedDimension = action.dimensionName;
        break;
      case ActionTypes.SET_CONFIRMATION_MODAL:
        if (action.data) {
          nextState.confirmationModal = action.data;
        } else {
          nextState.confirmationModal = defaultConfirmationModalObject;
        }
        break;
      case ActionTypes.SET_SEARCH_KEYWORD:
        nextState.searchKeyword = action.searchKeyword;

        break;
      case ActionTypes.SET_SETTINGS:
        nextState.settings = cloneDeep(action.settings);

        if (!hasOneOfRoles(USER)) {
          nextState.canEdit = action.settings[ADMIN_CAN_EDIT_OFFERS];
        }
        break;
      case ActionTypes.SET_IS_AUTHENTICATED:
        nextState.isAuthenticated = action.isAuthenticated;
        break;
      case ActionTypes.SET_PRICE_VERSION:
        nextState.currentPriceVersion = action.currentPriceVersion;
        break;
      case ActionTypes.SET_REFRESH_MODAL_IS_ACTIVE:
        nextState.refreshModalIsActive = action.refreshModalIsActive;
        break;
      case ActionTypes.SET_USER_SETTINGS:
        nextState.userSettings = cloneDeep(action.userSettings);
        break;
      default:
        break;
    }
  });
}

export default generalReducer;
