import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from '../../headers/SectionTitle';
import { MaterialColorList } from '../../lists/MaterialColorList';
import {
  BLUE,
  EXTERIOR_BLUESTONE_COLORS,
  EXTERIOR_BLUESTONE_STOCK_COLORS,
  EXTERIOR_GRANITE_COLORS,
  GRANITE_COLORS_HEIGHT,
  GRAY,
  INTERIOR_BLUESTONE_COLORS,
  MARBLE_COLORS_HEIGHT,
  PETIT_GRANIT_POLI_SHINY,
} from '../../../constants/MaterialColors';
import { Message } from '../../messages/Message';
import { WARNING } from '../../../constants/Variants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import * as Materials from '../../../constants/Materials';
import { EXTERIOR, INTERIOR } from '../../../constants/Values';
import { SILLS_STOCK, STAIR_RISERS, STAIR_STEPS } from '../../../constants/Presets';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { PALM, THOMASPIRON } from '../../../constants/ArticleSubstitutionGroupNames';

export const MaterialColorSelect = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.configurationModal.';

  const [colors, setColors] = useState([]);

  const { company, currentCompanyBranch } = useSelector(state => state.companyReducer);
  const articleSubstitutionGroup = company?.articleSubstitutionGroup;

  useEffect(() => {
    setAvailableColors();
  }, [articleSubstitutionGroup, props.selectedOptions.material, props.selectedOptions.height]);

  const hasAnyArticleSubstitutionGroup = groups => {
    let entityWithGroup = null;

    if (currentCompanyBranch) entityWithGroup = currentCompanyBranch;
    else if (company) entityWithGroup = company;
    else return false;

    return groups.includes(entityWithGroup.articleSubstitutionGroup?.name);
  };

  const renderWarningMessage = () => {
    if ([BLUE, GRAY].includes(props.selectedOptions.materialColor)) {
      return <Message content={t('messages.extraCostsColor')} variant={WARNING} />;
    }
  };

  const getExteriorBluestoneColors = () => {
    const colors = cloneDeep(EXTERIOR_BLUESTONE_COLORS);

    // Set the color GRAY as default for the given ArticleSubstitutionGroups
    if (hasAnyArticleSubstitutionGroup([PALM, THOMASPIRON])) {
      const index = colors.findIndex(c => c === GRAY);

      if (index >= 0) {
        colors.splice(index, 1);

        colors.unshift(GRAY);
      }
    }

    return colors;
  };

  const setAvailableColors = () => {
    if (props.selectedOptions.material == null || props.selectedOptions.decor == null) {
      setColors([]);
      return;
    }

    let availableColors = [];

    if (props.selectedOptions.material === Materials.BLUESTONE) {
      if (props.selectedOptions.decor === EXTERIOR) {
        if (props.selectedOptions.subPreset === SILLS_STOCK) {
          availableColors = EXTERIOR_BLUESTONE_STOCK_COLORS;
        } else {
          availableColors = getExteriorBluestoneColors();
        }
      } else {
        if (props.selectedOptions.height === 1.1) {
          availableColors = INTERIOR_BLUESTONE_COLORS.CM1;
        }
        if (props.selectedOptions.height === 2) {
          availableColors = INTERIOR_BLUESTONE_COLORS.CM2;
        }
        if (props.selectedOptions.height === 3) {
          availableColors = INTERIOR_BLUESTONE_COLORS.CM3;
        }
      }
    } else if (props.selectedOptions.material === Materials.MARBLE) {
      if (props.selectedOptions.decor === INTERIOR) {
        if (props.selectedOptions.height === 2) {
          availableColors = MARBLE_COLORS_HEIGHT.CM2;
        }
        if (props.selectedOptions.height === 3) {
          availableColors = MARBLE_COLORS_HEIGHT.CM3;
        }
      }
    } else if (props.selectedOptions.material === Materials.GRANITE) {
      if (props.selectedOptions.decor === EXTERIOR) {
        availableColors = EXTERIOR_GRANITE_COLORS;
      } else {
        if (props.selectedOptions.height === 2) {
          availableColors = GRANITE_COLORS_HEIGHT.CM2;
        }
        if (props.selectedOptions.height === 3) {
          availableColors = GRANITE_COLORS_HEIGHT.CM3;
        }
      }
    }

    if ([STAIR_STEPS, STAIR_RISERS].includes(props.selectedOptions.preset)) {
      availableColors = availableColors.filter(color => color !== PETIT_GRANIT_POLI_SHINY);
    }

    setColors(availableColors);
  };

  return (
    <>
      {!!props.isVisible && (
        <>
          <SectionTitle content={t(prefix + 'materialColorTitle')} />
          <MaterialColorList
            colors={colors}
            selectedColor={props.selectedOptions.materialColor}
            onItemSelect={color => props.setColor(color)}
          />
          {renderWarningMessage()}
        </>
      )}
    </>
  );
};

MaterialColorSelect.propTypes = {
  isVisible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  selectedOptions: PropTypes.object,
  setColor: PropTypes.func.isRequired,
};
