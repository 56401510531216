import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { setActiveInfoModal } from '../../../actions/GeneralActions';

export const NumberInput = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const dispatch = useDispatch();
  const prefix = 'validation.';

  let editableSpan = useRef();

  const onEnterPress = event => {
    if (event.keyCode === 13) {
      window.getSelection().removeAllRanges();
      debouncedOnChange(event.target.innerText);
    }
  };

  const onChange = newValue => {
    newValue = parseInt(newValue);
    if (newValue > props.max) {
      dispatch(setActiveInfoModal(true, t(prefix + 'max', { max: props.max })));
    } else if (newValue < props.min) {
      dispatch(setActiveInfoModal(true, t(prefix + 'min', { min: props.min })));
    } else {
      props.update(newValue);
    }
  };

  const debouncedOnChange = debounce(onChange, 650);

  return (
    <div className="configuration__objects__specs">
      <div className="configuration__objects__spec configuration__objects__spec__w-100 m-r-1">
        <label>{t(props.label)}</label>
        <span
          suppressContentEditableWarning={true}
          ref={span => (editableSpan = span)}
          onInput={event => debouncedOnChange(event.target.innerText)}
          contentEditable={!props.disabled}
          onKeyDown={event => onEnterPress(event)}
        >
          {props.value}
        </span>
      </div>
    </div>
  );
};

NumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
};

NumberInput.defaultProps = {
  value: 1,
};
