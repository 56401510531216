import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import * as ObjectCorners from '../../constants/ObjectCorners';
import * as OperationTypes from '../../constants/OperationTypes';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../headers/SectionTitle';
import * as ObjectSides from '../../constants/ObjectSides';
import { RadioButton } from '../forms/inputs/RadioButton';
import { ConfirmationModal } from './ConfirmationModal';
import { InfoModal } from './InfoModal';
import { useForm } from 'react-hook-form';
import { Operation } from '../../classes/models/Operation';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { BACK, FRONT, LEFT, RIGHT } from '../../constants/ObjectSides';
import { getRoundedCornerIcon } from '../../classes/helpers/OperationHelper';
import { PROHIBITED_OPERATION_TYPES } from '../../constants/OperationTypes';
import { createOperation, deleteOperation, updateOperation } from './index';
import { ModalHolder } from './ModalHolder';

function RoundedCornerModal(props) {
  const prefix = 'modals.roundedCornerModal.';

  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const { errors, register, handleSubmit } = useForm();

  const [selectedSide, setSelectedSide] = useState('');
  const [selectedCorner, setSelectedCorner] = useState('');
  const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
  const [infoModalIsActive, setInfoModalIsActive] = useState(false);
  const infoModalContent = t(prefix + 'infoModalContent');
  const [availableCorners, setAvailableCorners] = useState([]);

  const currentOperation = useSelector(state => state.offerReducer.currentOperation);
  const currentPiece = useSelector(state => state.offerReducer.currentPiece);
  const canEdit = useSelector(state => state.generalReducer.canEdit);

  useEffect(() => {
    if (currentOperation == null) {
      let corners = [];

      if (currentPiece) {
        corners = currentPiece.getAvailableCorners(PROHIBITED_OPERATION_TYPES.ROUNDED_CORNER);
        setAvailableCorners(corners);
      }

      if (corners.includes(ObjectCorners.FRONT_LEFT)) {
        setSelectedCorner(ObjectCorners.FRONT_LEFT);
        setSelectedSide(ObjectSides.LEFT);
      } else if (corners.includes(ObjectCorners.FRONT_RIGHT)) {
        setSelectedCorner(ObjectCorners.FRONT_RIGHT);
        setSelectedSide(ObjectSides.RIGHT);
      } else if (corners.includes(ObjectCorners.BACK_LEFT)) {
        setSelectedCorner(ObjectCorners.BACK_LEFT);
        setSelectedSide(ObjectSides.LEFT);
      } else if (corners.includes(ObjectCorners.BACK_RIGHT)) {
        setSelectedCorner(ObjectCorners.BACK_RIGHT);
        setSelectedSide(ObjectSides.RIGHT);
      }
    } else {
      let tempAvailableCorners = currentPiece?.getAvailableCorners(PROHIBITED_OPERATION_TYPES.ROUNDED_CORNER);

      if (
        currentOperation.additionalDimension.type === ObjectSides.FRONT &&
        currentOperation.side === ObjectSides.LEFT
      ) {
        setSelectedCorner(ObjectCorners.FRONT_LEFT);
        tempAvailableCorners.push(ObjectCorners.FRONT_LEFT);
      } else if (
        currentOperation.additionalDimension.type === ObjectSides.FRONT &&
        currentOperation.side === ObjectSides.RIGHT
      ) {
        setSelectedCorner(ObjectCorners.FRONT_RIGHT);
        tempAvailableCorners.push(ObjectCorners.FRONT_RIGHT);
      } else if (
        currentOperation.additionalDimension.type === ObjectSides.BACK &&
        currentOperation.side === ObjectSides.LEFT
      ) {
        setSelectedCorner(ObjectCorners.BACK_LEFT);
        tempAvailableCorners.push(ObjectCorners.BACK_LEFT);
      } else {
        setSelectedCorner(ObjectCorners.BACK_RIGHT);
        tempAvailableCorners.push(ObjectCorners.BACK_RIGHT);
      }

      setAvailableCorners(tempAvailableCorners);
      setSelectedSide(currentOperation.side);
    }
  }, [currentOperation, currentPiece]);

  const createRoundedCorner = fields => {
    let roundedCorner = new Operation(0, OperationTypes.ROUNDED_CORNER);

    roundedCorner.dimensions.length = parseNumber(fields.radius);
    roundedCorner.side = selectedSide;

    if (selectedCorner === ObjectCorners.FRONT_LEFT || selectedCorner === ObjectCorners.FRONT_RIGHT) {
      roundedCorner.additionalDimension.type = ObjectSides.FRONT;
    } else {
      roundedCorner.additionalDimension.type = ObjectSides.BACK;
    }

    createOperation(roundedCorner, () => props.onClose(true), t(prefix + 'roundedCornerCreateFailed'));
  };

  const updateRoundedCorner = fields => {
    currentOperation.dimensions = { length: parseNumber(fields.radius) };
    currentOperation.side = selectedSide;

    if (selectedCorner === ObjectCorners.FRONT_LEFT || selectedCorner === ObjectCorners.FRONT_RIGHT) {
      currentOperation.additionalDimension.type = ObjectSides.FRONT;
    } else {
      currentOperation.additionalDimension.type = ObjectSides.BACK;
    }

    updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'roundedCornerUpdateFailed'));
  };

  const deleteRoundedCorner = () => {
    deleteOperation(currentOperation, () => props.onClose(true), t(prefix + 'roundedCornerDeleteSuccess'));
  };

  const closeModal = fields => {
    if (!fields) {
      props.onClose();
      return;
    }

    if (currentOperation == null) {
      if (availableCorners.length) {
        createRoundedCorner(fields);
      } else {
        setInfoModalIsActive(true);
      }
    } else {
      updateRoundedCorner(fields);
    }
  };

  const renderCornerSelect = () => {
    let frontLeftIsSelected = selectedCorner === ObjectCorners.FRONT_LEFT;
    let frontRightIsSelected = selectedCorner === ObjectCorners.FRONT_RIGHT;
    let backLeftIsSelected = selectedCorner === ObjectCorners.BACK_LEFT;
    let backRightIsSelected = selectedCorner === ObjectCorners.BACK_RIGHT;

    return (
      <div>
        <SectionTitle content={t(prefix + 'cornerTitle')} />

        <RadioButton
          name="roundedCornerSide"
          content={t('constants.objectCorners.' + ObjectCorners.FRONT_LEFT)}
          image={getRoundedCornerIcon(LEFT, FRONT)}
          checked={frontLeftIsSelected}
          onChange={() => onCornerChange(ObjectCorners.FRONT_LEFT)}
          disabled={!availableCorners.includes(ObjectCorners.FRONT_LEFT) || !canEdit}
        />

        <RadioButton
          name="roundedCornerSide"
          content={t('constants.objectCorners.' + ObjectCorners.FRONT_RIGHT)}
          image={getRoundedCornerIcon(RIGHT, FRONT)}
          checked={frontRightIsSelected}
          onChange={() => onCornerChange(ObjectCorners.FRONT_RIGHT)}
          disabled={!availableCorners.includes(ObjectCorners.FRONT_RIGHT) || !canEdit}
        />

        <RadioButton
          name="roundedCornerSide"
          content={t('constants.objectCorners.' + ObjectCorners.BACK_LEFT)}
          image={getRoundedCornerIcon(LEFT, BACK)}
          checked={backLeftIsSelected}
          onChange={() => onCornerChange(ObjectCorners.BACK_LEFT)}
          disabled={!availableCorners.includes(ObjectCorners.BACK_LEFT) || !canEdit}
        />

        <RadioButton
          name="roundedCornerSide"
          content={t('constants.objectCorners.' + ObjectCorners.BACK_RIGHT)}
          image={getRoundedCornerIcon(RIGHT, BACK)}
          checked={backRightIsSelected}
          onChange={() => onCornerChange(ObjectCorners.BACK_RIGHT)}
          disabled={!availableCorners.includes(ObjectCorners.BACK_RIGHT) || !canEdit}
        />
      </div>
    );
  };

  const onCornerChange = value => {
    if (
      value !== ObjectCorners.BACK_RIGHT &&
      value !== ObjectCorners.BACK_LEFT &&
      value !== ObjectCorners.FRONT_LEFT &&
      value !== ObjectCorners.FRONT_RIGHT
    )
      return;

    // Set the new value for the radio buttons, so the correct one is shown as selected
    setSelectedCorner(value);
    if (value === ObjectCorners.BACK_LEFT || value === ObjectCorners.FRONT_LEFT) {
      setSelectedSide(ObjectSides.LEFT);
    } else {
      setSelectedSide(ObjectSides.RIGHT);
    }
  };

  const toggleConfirmationModal = userHasAccepted => {
    if (userHasAccepted) {
      deleteRoundedCorner();
    }

    setConfirmationModalIsActive(!confirmationModalIsActive);
  };

  const closeInfoModal = () => {
    setInfoModalIsActive(false);
    props.onClose();
  };

  const renderFooterButtons = () => {
    let secondaryButtonStyle = 'button button--outline';
    let secondaryButtonContent = t(prefix + 'cancelButton');
    let secondaryButtonOnClick = () => closeModal();

    if (currentOperation != null && canEdit) {
      secondaryButtonStyle = 'button button--danger';
      secondaryButtonContent = t(prefix + 'deleteButton');
      secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
    }

    const submitButton = (
      <button
        key={'roundedCornerModalSubmitButton'}
        data-cy={'roundedCornerModal-submitButton'}
        className="button button--primary"
        form="roundedCornerForm"
        type="submit"
      >
        {t(prefix + 'doneButton')}
      </button>
    );

    let buttons = [
      <button
        key={'roundedCornerModalCancelButton'}
        type="button"
        data-cy={'roundedCornerModal-deleteButton'}
        className={secondaryButtonStyle}
        onClick={secondaryButtonOnClick}
      >
        {secondaryButtonContent}
      </button>,
    ];

    if (canEdit) {
      buttons.unshift(submitButton);
    }

    return buttons;
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <ConfirmationModal
        isActive={confirmationModalIsActive}
        onClose={userHasAccepted => toggleConfirmationModal(userHasAccepted)}
        content={t('modals.roundedCornerModal.deleteRoundedCorner')}
      />

      <InfoModal isActive={infoModalIsActive} onClose={() => closeInfoModal()} content={infoModalContent} />

      <Modal show={props.isActive} onHide={() => closeModal()} animation={true}>
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="roundedCornerForm" onSubmit={handleSubmit(closeModal)}>
            <p>{t(prefix + 'description')}</p>

            {renderCornerSelect()}

            <SectionTitle content={t(prefix + 'radiusTitle')} />

            <div className="row">
              <div className="col-12">
                <ValidatedInput
                  register={register}
                  name="radius"
                  dataCy={'radius'}
                  placeholder={t(prefix + 'roundedCornerRadiusPlaceholder')}
                  label={t(prefix + 'roundedCornerRadiusLabel')}
                  value={currentOperation?.dimensions.length}
                  disabled={!canEdit}
                  error={errors.radius}
                  required={true}
                  min={1}
                  max={10}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
}

RoundedCornerModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { RoundedCornerModal };
