import React, { useState } from 'react';
import logo from '../../../assets/img/logo.svg';
import { AuthService } from '../../../internal';
import { useTranslation } from 'react-i18next';
import history from '../../../config/history';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useForm } from 'react-hook-form';
import { ValidationMessage } from '../../shared/ValidationMessage';
import { DANGER } from '../../../constants/Variants';

export function ResetPassword(props) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.forgot.';
  const validationPrefix = 'validation.';

  const [displayAlert, setDisplayAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { watch, errors, register, handleSubmit } = useForm();

  const authService = new AuthService();

  const renderSuccess = () => {
    return (
      <div className="sessionpage">
        <div className="sessionpage__login">
          <div className="sessionpage__login__inner">
            <h1 data-cy={'successReset-title'}>
              <img alt="logo" src={logo} />
              {t(prefix + 'title')}
            </h1>

            <p data-cy={'successReset-text'}>{t(prefix + 'successReset')}</p>

            <div className="sessionpage__login__footer">
              <span data-cy={'reset-back-login-button'} className="link" onClick={() => history.push('/login')}>
                {t(prefix + 'loginButton')}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="sessionpage">
        <div className="sessionpage__login">
          <div className="sessionpage__login__inner">
            <h1>
              <img alt="logo" src={logo} />
              {t(prefix + 'title')}
            </h1>
            <form id="forgotPasswordForm" onSubmit={handleSubmit(onSubmitForgot)}>
              <div className="form-group">
                <label data-cy={'reset-passwordEmail-label'} htmlFor="email">
                  {t(prefix + 'emailAddress')}
                </label>
                <input
                  name={'email'}
                  type="text"
                  data-cy={'reset-passwordEmail-input'}
                  placeholder={t(prefix + 'emailAddressPlaceholder')}
                  ref={register({
                    required: true,
                    maxLength: 100,
                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}\s*$/i,
                  })}
                />
                {errors.email?.type === 'required' ? (
                  <ValidationMessage
                    dataCy={'reset-passwordEmail-error'}
                    content={t(validationPrefix + 'required')}
                    variant={DANGER}
                  />
                ) : null}
                {errors.email?.type === 'maxLength' ? (
                  <ValidationMessage
                    dataCy={'reset-passwordEmail-error'}
                    content={t(validationPrefix + 'maxLength', { max: 100 })}
                    variant={DANGER}
                  />
                ) : null}
                {errors.email?.type === 'pattern' ? (
                  <ValidationMessage
                    dataCy={'reset-passwordEmail-error'}
                    content={t(validationPrefix + 'email', { max: 100 })}
                    variant={DANGER}
                  />
                ) : null}
              </div>

              <div className="form-group">
                <label data-cy={'reset-passwordNewPassword-label'}>{t(prefix + 'newPassword')}</label>
                <input
                  ref={register({
                    required: true,
                    minLength: 8,
                    maxLength: 100,
                  })}
                  data-cy={'reset-passwordNewPassword-input'}
                  name="newPassword"
                  type="password"
                />
                {errors.newPassword?.type === 'required' ? (
                  <ValidationMessage
                    dataCy={'reset-passwordNewPassword-error'}
                    content={t(validationPrefix + 'required')}
                    variant={DANGER}
                  />
                ) : null}
                {errors.newPassword?.type === 'minLength' ? (
                  <ValidationMessage
                    dataCy={'reset-passwordNewPassword-error'}
                    content={t(validationPrefix + 'minLength', { min: 8 })}
                    variant={DANGER}
                  />
                ) : null}
                {errors.newPassword?.type === 'maxLength' ? (
                  <ValidationMessage
                    dataCy={'reset-passwordNewPassword-error'}
                    content={t(validationPrefix + 'maxLength', { max: 100 })}
                    variant={DANGER}
                  />
                ) : null}
              </div>

              <div className="form-group">
                <label data-cy={'reset-passwordRepeatPassword-label'}>{t(prefix + 'repeatPassword')}</label>
                <input
                  ref={register({
                    required: true,
                    minLength: 8,
                    maxLength: 100,
                    validate: value => value === watch('newPassword'),
                  })}
                  data-cy={'reset-passwordRepeatPassword-input'}
                  name="newPasswordCheck"
                  type="password"
                />
                {errors.newPasswordCheck?.type === 'validate' ? (
                  <ValidationMessage
                    dataCy={'reset-passwordRepeatPassword-error'}
                    content={t(validationPrefix + 'notMatch')}
                    variant={DANGER}
                  />
                ) : null}
              </div>

              <div className="form-group">
                {displayAlert ? (
                  <ValidationMessage
                    dataCy={'reset-passwordRepeatPassword-error'}
                    content={t(prefix + 'wrongReset')}
                    variant={DANGER}
                  />
                ) : null}
              </div>

              <div className="form-group">{renderButtons()}</div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const onSubmitForgot = fields => {
    let formData = new FormData();

    formData.append('email', fields.email);
    formData.append('token', props.match.params.token);
    formData.append('password', fields.newPassword);
    formData.append('password_confirmation', fields.newPasswordCheck);

    setSuccess(false);
    setIsLoading(true);
    setDisplayAlert(false);

    authService
      .resetPassword(formData)
      .then(data => {
        if (data && data.success) {
          setSuccess(true);
        } else {
          setDisplayAlert(true);
        }
      })
      .catch(error => {
        throw error;
      })
      .finally(() => setIsLoading(false));
  };

  const renderButtons = () => {
    return (
      <div className="m-t-2">
        <button
          data-cy={'reset-password-submit'}
          type="submit"
          className="button button--primary w-100"
          disabled={isLoading}
        >
          {isLoading ? t(prefix + 'resetLoading') : t(prefix + 'resetButton')}
        </button>

        <div data-cy={'reset-password-login'} className="sessionpage__login__footer">
          <span className="link" onClick={() => history.push('/login')}>
            {t(prefix + 'loginButton')}
          </span>
        </div>
      </div>
    );
  };

  return <div>{success ? renderSuccess() : renderForm()}</div>;
}
