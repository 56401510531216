import { BACK, FRONT, LEFT, RIGHT, TOP } from './ObjectSides';
import { COMPLETELY, PARTIALLY } from './FinishedSideStates';
import {
  CORNER_CUTOUT,
  COUPE,
  COUPE_OVER_LENGTH,
  DEBASING_ROUGH,
  DRILL_HOLE,
  EXTRA_SANDING_5CM_BOTTOM,
  FINISHED_SIDE,
  GLUED_CUSHION,
  GROOVES,
  HEIGHT_COUPE,
  NOTCH,
  PROFILE,
  RABAT,
  RECTANGULAR_CUT_OUT,
  ROUNDED_CORNER,
  WATERLIST,
} from './OperationTypes';

export const WINDOW_SLABS = 'WINDOW_SLABS';
export const ENTREPORTS = 'ENTREPORTS';
export const WALL_SLABS = 'WALL_SLABS';
export const STAIR_STEPS = 'STAIR_STEPS';
export const STAIR_RISERS = 'STAIR_RISERS';
export const CUSTOM_TILE = 'CUSTOM_TILE';

export const SILLS = 'SILLS';
export const WALL_COVERS = 'WALL_COVERS';
export const CHIMNEY_COVERS = 'CHIMNEY_COVERS';
export const BASEBOARDS = 'BASEBOARDS';
export const LINTELS = 'LINTELS';
export const PILLARS = 'PILLARS';

export const REAR_HEELS = 'REAR_HEELS';
export const CUSHIONS = 'CUSHIONS';

export const SILLS_CUSTOM = 'SILLS_CUSTOM';
export const SILLS_STOCK = 'SILLS_STOCK';

export const ALL_PRESETS = [
  WINDOW_SLABS,
  ENTREPORTS,
  WALL_SLABS,
  STAIR_STEPS,
  STAIR_RISERS,
  CUSTOM_TILE,
  SILLS,
  WALL_COVERS,
  CHIMNEY_COVERS,
  BASEBOARDS,
  LINTELS,
  PILLARS,
  REAR_HEELS,
  CUSHIONS,
];

export const SILLS_TYPES = [SILLS_CUSTOM, SILLS_STOCK];

export const INTERIOR_PRESETS = [WINDOW_SLABS, ENTREPORTS, WALL_COVERS, STAIR_STEPS, STAIR_RISERS, CUSTOM_TILE];

export const EXTERIOR_PRESETS = [
  SILLS,
  WALL_COVERS,
  CHIMNEY_COVERS,
  WALL_SLABS,
  BASEBOARDS,
  STAIR_STEPS,
  STAIR_RISERS,
  LINTELS,
  PILLARS,
  REAR_HEELS,
  CUSHIONS,
  CUSTOM_TILE,
];

export const FINISHED_SIDES_INCLUDED_IN_PRICE = {
  DEFAULT: [
    { name: TOP, type: COMPLETELY },
    { name: LEFT, type: PARTIALLY },
    {
      name: RIGHT,
      type: PARTIALLY,
    },
    { name: FRONT, type: COMPLETELY },
  ],
  ENTREPORTS: [
    { name: TOP, type: COMPLETELY },
    { name: LEFT, type: PARTIALLY },
    {
      name: RIGHT,
      type: PARTIALLY,
    },
    { name: FRONT, type: COMPLETELY },
    { name: BACK, type: COMPLETELY },
  ],
  BASEBOARDS: [
    { name: TOP, type: COMPLETELY },
    { name: LEFT, type: PARTIALLY },
    {
      name: RIGHT,
      type: PARTIALLY,
    },
    { name: BACK, type: COMPLETELY },
  ],
  CUSHIONS: [
    { name: TOP, type: COMPLETELY },
    { name: LEFT, type: COMPLETELY },
    {
      name: RIGHT,
      type: COMPLETELY,
    },
    { name: FRONT, type: COMPLETELY },
    { name: BACK, type: COMPLETELY },
  ],
  STAIR_RISERS: [
    { name: TOP, type: COMPLETELY },
    { name: BACK, type: COMPLETELY },
  ],
  WALL_SLABS: [
    { name: TOP, type: COMPLETELY },
    { name: BACK, type: COMPLETELY },
  ],
};

export const INVERTED_DIMENSION_PRESETS = [BASEBOARDS, PILLARS];

export const VERTICAL_DISPLAYED_PRESETS = [PILLARS];

export const UPRIGHT_PRESETS = [BASEBOARDS, STAIR_RISERS, WALL_SLABS, LINTELS];

export const PRESETS_WITH_SHORTENED_WATERLISTS = [CHIMNEY_COVERS, WALL_COVERS];

export const PRESETS_WITH_STANDARD_WIDTHS = [SILLS, STAIR_STEPS];

export const WINDOW_SLAB_SIDES = [
  { name: TOP, type: COMPLETELY },
  { name: LEFT, type: PARTIALLY },
  {
    name: RIGHT,
    type: PARTIALLY,
  },
  { name: FRONT, type: COMPLETELY },
];

export const ENTREPORT_SIDES = [
  { name: TOP, type: COMPLETELY }, // Both with chanfrein
  { name: FRONT, type: COMPLETELY },
  { name: BACK, type: COMPLETELY },
];

export const SILL_SIDES = [
  { name: TOP, type: COMPLETELY },
  { name: LEFT, type: PARTIALLY },
  {
    name: RIGHT,
    type: PARTIALLY,
  },
  { name: FRONT, type: COMPLETELY },
];

export const WALL_COVER_SIDES = [
  { name: TOP, type: COMPLETELY },
  { name: LEFT, type: PARTIALLY },
  {
    name: RIGHT,
    type: PARTIALLY,
  },
  { name: FRONT, type: COMPLETELY },
];

export const CHIMNEY_COVER_SIDES = [
  { name: TOP, type: COMPLETELY },
  { name: FRONT, type: COMPLETELY },
  {
    name: BACK,
    type: COMPLETELY,
  },
  { name: LEFT, type: COMPLETELY },
  { name: RIGHT, type: COMPLETELY },
];

export const WALL_SLAB_SIDES = [{ name: TOP, type: COMPLETELY }];

export const BASEBOARD_SIDES = [
  { name: TOP, type: COMPLETELY },
  { name: BACK, type: COMPLETELY },
];

export const STAIR_STEP_SIDES = [
  { name: FRONT, type: COMPLETELY },
  { name: TOP, type: COMPLETELY },
];

export const STAIR_RISER_SIDES = [{ name: TOP, type: COMPLETELY }];

export const LINTEL_SIDES = [
  { name: FRONT, type: COMPLETELY },
  { name: TOP, type: COMPLETELY },
];

export const PILLAR_SIDES = [
  { name: FRONT, type: COMPLETELY },
  { name: TOP, type: COMPLETELY },
];

export const CUSTOM_TILE_SIDES = [
  { name: FRONT, type: COMPLETELY },
  { name: TOP, type: COMPLETELY },
];

export const REAR_HEEL_SIDES = [
  { name: TOP, type: COMPLETELY },
  { name: FRONT, type: COMPLETELY },
];

export const CUSHION_SIDES = [
  { name: TOP, type: COMPLETELY },
  { name: FRONT, type: COMPLETELY },
  {
    name: LEFT,
    type: COMPLETELY,
  },
  { name: BACK, type: COMPLETELY },
  { name: RIGHT, type: COMPLETELY },
];

export const EXTERIOR_PRESETS_WITHOUT_WATERLISTS = [LINTELS, PILLARS, BASEBOARDS, REAR_HEELS, CUSHIONS, CUSTOM_TILE];

export const CONFIGURABLE_OPERATIONS = {
  ALL: [
    FINISHED_SIDE,
    RECTANGULAR_CUT_OUT, // NOTCH_OVER_LENGTH,
  ],
  INTERIOR: {
    WINDOW_SLABS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH,
      ROUNDED_CORNER,
      DRILL_HOLE,
      HEIGHT_COUPE,
      PROFILE,
      DEBASING_ROUGH,
      RABAT,
      EXTRA_SANDING_5CM_BOTTOM,
    ],
    ENTREPORTS: [NOTCH, CORNER_CUTOUT, COUPE, COUPE_OVER_LENGTH, HEIGHT_COUPE, PROFILE, DEBASING_ROUGH, RABAT],
    WALL_COVERS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH,
      ROUNDED_CORNER,
      DRILL_HOLE,
      HEIGHT_COUPE,
      PROFILE,
      DEBASING_ROUGH,
      RABAT,
      EXTRA_SANDING_5CM_BOTTOM,
    ],
    STAIR_STEPS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH,
      ROUNDED_CORNER,
      HEIGHT_COUPE,
      PROFILE,
      DEBASING_ROUGH,
      RABAT,
      EXTRA_SANDING_5CM_BOTTOM,
    ],
    STAIR_RISERS: [NOTCH, CORNER_CUTOUT, COUPE, COUPE_OVER_LENGTH, HEIGHT_COUPE, DEBASING_ROUGH, RABAT],
    CUSTOM_TILE: [
      NOTCH,
      CORNER_CUTOUT,
      GROOVES,
      ROUNDED_CORNER,
      COUPE,
      COUPE_OVER_LENGTH,
      ROUNDED_CORNER,
      DRILL_HOLE,
      HEIGHT_COUPE,
      WATERLIST,
      GLUED_CUSHION,
      DEBASING_ROUGH,
      RABAT, // ANCHOR_HOLE
    ],
  },
  EXTERIOR: {
    SILLS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH,
      ROUNDED_CORNER,
      DRILL_HOLE,
      HEIGHT_COUPE,
      PROFILE,
      WATERLIST,
      GLUED_CUSHION,
      DEBASING_ROUGH,
      GROOVES,
      RABAT,
      EXTRA_SANDING_5CM_BOTTOM,
    ],
    WALL_COVERS: [
      NOTCH,
      CORNER_CUTOUT,
      ROUNDED_CORNER,
      COUPE,
      COUPE_OVER_LENGTH,
      ROUNDED_CORNER,
      DRILL_HOLE,
      HEIGHT_COUPE,
      PROFILE,
      WATERLIST,
      GLUED_CUSHION,
      DEBASING_ROUGH,
      RABAT,
      EXTRA_SANDING_5CM_BOTTOM,
    ],
    CHIMNEY_COVERS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH,
      DRILL_HOLE,
      DEBASING_ROUGH,
      RABAT,
      EXTRA_SANDING_5CM_BOTTOM,
    ],
    WALL_SLABS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH, //ANCHOR_HOLE,
      DRILL_HOLE,
      HEIGHT_COUPE,
      DEBASING_ROUGH,
      RABAT,
      EXTRA_SANDING_5CM_BOTTOM,
    ],
    BASEBOARDS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH, //ANCHOR_HOLE,
      DRILL_HOLE,
      HEIGHT_COUPE,
      DEBASING_ROUGH,
      RABAT,
    ],
    STAIR_STEPS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH,
      ROUNDED_CORNER,
      DRILL_HOLE,
      HEIGHT_COUPE,
      PROFILE,
      GROOVES,
      DEBASING_ROUGH,
      RABAT,
      EXTRA_SANDING_5CM_BOTTOM,
    ],
    STAIR_RISERS: [NOTCH, CORNER_CUTOUT, COUPE, COUPE_OVER_LENGTH, HEIGHT_COUPE, DEBASING_ROUGH, RABAT],
    LINTELS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH, //ANCHOR_HOLE,
      DRILL_HOLE,
      HEIGHT_COUPE,
      PROFILE,
      DEBASING_ROUGH,
      RABAT,
    ],
    PILLARS: [
      NOTCH,
      CORNER_CUTOUT,
      COUPE,
      COUPE_OVER_LENGTH, //ANCHOR_HOLE,
      DRILL_HOLE,
      HEIGHT_COUPE,
      PROFILE,
      DEBASING_ROUGH,
      RABAT,
    ],
    CUSTOM_TILE: [
      NOTCH,
      CORNER_CUTOUT,
      GROOVES,
      ROUNDED_CORNER,
      COUPE,
      COUPE_OVER_LENGTH,
      ROUNDED_CORNER,
      DRILL_HOLE,
      HEIGHT_COUPE,
      WATERLIST,
      GLUED_CUSHION,
      DEBASING_ROUGH,
      RABAT, // ANCHOR_HOLE
    ],
    REAR_HEELS: [
      // NONE
    ],
    CUSHIONS: [PROFILE],
  },
};
