import * as React from 'react';
import PropTypes from 'prop-types';
import { SelectableImage } from '../forms/inputs/SelectableImage';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import icon100x100 from '../../assets/icons/100x100.png';

export const MaterialQualityList = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'constants.materialQualities.';

  const images = require.context('../../assets/img/configuration', true);

  const getImage = quality => {
    try {
      return images('./' + quality.toLowerCase() + '.jpg');
    } catch (e) {
      return icon100x100;
    }
  };

  return (
    <div className="form-group--selectable__holder">
      {props.qualities.map((quality, index) => {
        return (
          <SelectableImage
            dataCy={`materialQualitySelect-${quality.toLowerCase()}`}
            key={index}
            image={getImage(quality)}
            onClick={() => props.onSelect(quality)}
            name="presetSelect"
            content={t(prefix + quality)}
            selected={props.selectedQuality === quality}
            disabled={props.disabledQualities.includes(quality)}
          />
        );
      })}
    </div>
  );
};

MaterialQualityList.propTypes = {
  qualities: PropTypes.array.isRequired,
  selectedQuality: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabledQualities: PropTypes.array.isRequired,
};

MaterialQualityList.defaultProps = {
  selectedQuality: '',
};
