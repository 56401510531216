import moment from 'moment';

export function isToday(date) {
  const today = moment().startOf('day');

  if (!(date instanceof moment)) {
    date = moment(date);
  }

  const difference = date.startOf('day').diff(today, 'seconds');

  return Math.abs(difference) === 0;
}

export function dateIsBetween(date, start, end) {
  date = date.startOf('day');

  const dateIsAfterStart = moment(start).startOf('day').diff(date, 'days') <= 0;

  const dateIsAfterEnd = moment(end).startOf('day').diff(date, 'days') >= 0;

  return dateIsAfterStart && dateIsAfterEnd;
}
