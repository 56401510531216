import { store } from '../../store/index';
import { setCurrentManufacturer } from '../../actions/ManufacturerActions';

class ManufacturerReducerHelper {
  static updateCatalogName(catalogId, catalogName) {
    const state = store.getState();

    let currentManufacturer = state.manufacturerReducer.currentManufacturer;

    currentManufacturer.catalogs = currentManufacturer.catalogs.map(catalog => {
      if (catalog.id === catalogId) {
        catalog.name = catalogName;

        return catalog;
      }

      return catalog;
    });

    store.dispatch(setCurrentManufacturer(currentManufacturer));
  }

  static updateCatalog(catalogId, catalogToUpdate) {
    const state = store.getState();

    let currentManufacturer = state.manufacturerReducer.currentManufacturer;

    currentManufacturer.catalogs = currentManufacturer.catalogs.map(catalog => {
      if (catalog.id === catalogId) {
        return catalogToUpdate;
      }

      return catalog;
    });

    store.dispatch(setCurrentManufacturer(currentManufacturer));
  }

  static deleteCatalog(catalogId) {
    const state = store.getState();

    let currentManufacturer = state.manufacturerReducer.currentManufacturer;

    currentManufacturer.catalogs = currentManufacturer.catalogs.filter(catalog => catalog.id !== catalogId);

    store.dispatch(setCurrentManufacturer(currentManufacturer));
  }
}

export default ManufacturerReducerHelper;
