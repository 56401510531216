import React, { useEffect, useState } from 'react';
import { AppHolder } from '../../layout/AppHolder';
import { NavigationBar } from '../../navigation/NavigationBar';
import { SideBar } from '../../navigation/SideBar';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { SidebarListItem } from '../../lists/items/SidebarListItem';
import { AppContent } from '../../layout/AppContent';
import { NewUserList } from './NewUserList';
import { MessageHolder } from '../../messages/MessageHolder';
import { addAlertMessage, removeAlertMessage, setWindowIsLoading } from '../../../actions/GeneralActions';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingMessage } from '../../messages/LoadingMessage';
import { UserService } from '../../../classes/services/UserService';
import { DANGER, SUCCESS } from '../../../constants/Variants';
import { EmptyPage } from '../../empty/EmptyPage';
import { ManufacturerManagement } from './ManufacturerManagement';
import { usePrevious } from '../../../classes/hooks/usePrevious';
import { setCurrentManufacturer } from '../../../actions/ManufacturerActions';
import { GalleryImageManagement } from './GalleryImageManagement';
import Cookies from 'js-cookie';
import { ADMIN } from '../../../constants/RoleNames';
import { CompanyList } from './companies/CompanyList';
import packageJson from '../../../../package.json';
import {
  NEW_USERS,
  COMPANIES,
  GALLERY_IMAGES,
  PRODUCTS,
  CATALOGS,
  ADMIN_PAGES,
  NEW_USERS_URL_NAME,
  DELIVERIES,
  SETTINGS,
  ADMIN_PAGE_NAME_LOOKUP,
  ADMIN_PAGE_URL_LOOKUP,
} from '../../../constants/AdminPages';
import { useHistory, useParams } from 'react-router-dom';
import { Deliveries } from './deliveries/Deliveries';
import { Settings } from './Settings';

export const Admin = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.admin.';
  const constantsPrefix = 'constants.adminPages.';

  const dispatch = useDispatch();

  const { alertMessages, windowIsLoading } = useSelector(state => state.generalReducer);

  const [activeSidebarItem, setActiveSidebarItem] = useState(NEW_USERS);
  const [pendingUsers, setPendingUsers] = useState([]);
  const previousSidebarItem = usePrevious(activeSidebarItem);

  const userService = new UserService();
  const { page: pageUrl } = useParams();
  const history = useHistory();

  useEffect(() => {
    const role = Cookies.get('role');
    if (role !== ADMIN) history.push('/offers');

    getNewUsers();
  }, []);

  useEffect(() => {
    setActiveSidebarItem(getPageNameByUrl(pageUrl));
  }, [pageUrl]);

  useEffect(() => {
    if (previousSidebarItem === CATALOGS) {
      dispatch(setCurrentManufacturer(null));
    }

    if (!activeSidebarItem) history.push('/admin/newusers');
  }, [activeSidebarItem]);

  const getNewUsers = () => {
    dispatch(setWindowIsLoading(true));

    userService
      .getUsersWithPendingRegistrations()
      .then(response => {
        if (response.success) {
          setPendingUsers(response.data);
        } else {
          throw Error(t(prefix + 'getUsersFailed'));
        }
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, error.message));
        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const handleRegistration = (userId, accepted) => {
    dispatch(setWindowIsLoading(true));

    userService
      .closePendingRegistration(userId, accepted)
      .then(response => {
        if (response.success) {
          dispatch(addAlertMessage(SUCCESS, t(prefix + 'successfullyHandledRegistration')));

          getNewUsers();
        } else {
          throw Error(t(prefix + 'handleRegistrationFailed'));
        }
      })
      .catch(error => {
        dispatch(setWindowIsLoading(false));
        dispatch(addAlertMessage(DANGER, error.message));
        throw error;
      });
  };

  const getPageNameByUrl = url => {
    const sidebarItemName = ADMIN_PAGE_NAME_LOOKUP[url];

    return sidebarItemName ?? NEW_USERS;
  };

  const getUrlByPageName = name => {
    const url = ADMIN_PAGE_URL_LOOKUP[name];

    return url ?? NEW_USERS_URL_NAME;
  };

  const changeActiveSidebarItem = page => {
    // When the user clicks on the catalogs sidebaritem the view should return to its original state
    if (page === CATALOGS && activeSidebarItem === CATALOGS) {
      dispatch(setCurrentManufacturer(null));
    } else {
      history.push('/admin/' + getUrlByPageName(page));
    }
  };

  const renderContent = () => {
    let content;

    switch (activeSidebarItem) {
      case COMPANIES:
        content = <CompanyList />;
        break;
      case DELIVERIES:
        content = <Deliveries />;
        break;
      case CATALOGS:
        content = <ManufacturerManagement />;
        break;
      case GALLERY_IMAGES:
        content = <GalleryImageManagement />;
        break;
      case SETTINGS:
        content = <Settings />;
        break;
      case PRODUCTS:
        content = null;
        break;
      default:
        if (pendingUsers.length > 0) {
          content = (
            <NewUserList
              users={pendingUsers}
              handleRegistration={(userId, accepted) => handleRegistration(userId, accepted)}
            />
          );
        } else {
          if (!windowIsLoading) {
            content = <EmptyPage title={t(prefix + 'emptyPageTitle')} content={t(prefix + 'emptyPageContent')} />;
          }
        }
        break;
    }

    return content;
  };

  return (
    <AppHolder>
      {windowIsLoading ? <LoadingMessage variant="overlay" /> : null}

      <NavigationBar />

      <SideBar title={t(prefix + 'sidebarTitle') + ` (v${packageJson.version})`}>
        {ADMIN_PAGES.map(page => {
          return (
            <SidebarListItem
              key={`adminPageSidebarItem${page}`}
              onClick={() => changeActiveSidebarItem(page)}
              content={t(constantsPrefix + page)}
              active={activeSidebarItem === page}
            />
          );
        })}
      </SideBar>

      <AppContent>{renderContent()}</AppContent>

      <MessageHolder messages={alertMessages} onClose={index => dispatch(removeAlertMessage(index))} />
    </AppHolder>
  );
};
