import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../headers/SectionTitle';
import { RadioButton } from '../forms/inputs/RadioButton';
import { ConfirmationModal } from './ConfirmationModal';
import { useForm } from 'react-hook-form';
import { Operation } from '../../classes/models/Operation';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { COUPE_OVER_LENGTH, PROHIBITED_OPERATION_TYPES } from '../../constants/OperationTypes';
import { createOperation, deleteOperation, updateOperation } from './index';
import { LEFT, RIGHT, FRONT, BACK } from '../../constants/ObjectSides';
import { getSideNameByPreset } from '../../classes/helpers/ObjectHelper';
import { ModalHolder } from './ModalHolder';

function CoupeOverLengthModal(props) {
  const prefix = 'modals.coupeOverLengthModal.';

  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const { errors, register, handleSubmit } = useForm();

  const [selectedSide, setSelectedSide] = useState('');
  const [selectedShortSide, setSelectedShortSide] = useState('');
  const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
  const [availableSides, setAvailableSides] = useState([]);

  const { currentConfiguration, currentOperation, currentPiece } = useSelector(state => state.offerReducer);
  const canEdit = useSelector(state => state.generalReducer.canEdit);

  useEffect(() => {
    if (currentOperation == null) {
      let sides = [];

      if (currentPiece) {
        sides = currentPiece.getAvailableSides(PROHIBITED_OPERATION_TYPES.COUPE_OVER_LENGTH);
        setAvailableSides(sides);
      }

      if (sides.includes(FRONT)) {
        setSelectedSide(FRONT);
      } else if (sides.includes(BACK)) {
        setSelectedSide(BACK);
      } else {
        setSelectedSide('');
      }

      setSelectedShortSide(LEFT);
    } else {
      let sides = [];
      if (currentPiece) {
        sides = currentPiece.getAvailableSides(PROHIBITED_OPERATION_TYPES.COUPE_OVER_LENGTH);
        sides.push(currentOperation.side);
        setAvailableSides(sides);
      }
      setSelectedShortSide(currentOperation.additionalDimension.type);
      setSelectedSide(currentOperation.side);
    }
  }, [currentOperation, currentPiece]);

  const createCoupeOverLength = fields => {
    let coupeOverLength = new Operation(0, COUPE_OVER_LENGTH);

    coupeOverLength.dimensions.width = parseNumber(fields.width);
    coupeOverLength.side = selectedSide;
    coupeOverLength.additionalDimension.type = selectedShortSide;

    createOperation(coupeOverLength, () => props.onClose(true), t(prefix + 'coupeOverLengthCreateFailed'));
  };

  const updateCoupeOverLength = fields => {
    currentOperation.dimensions = { width: parseNumber(fields.width) };
    currentOperation.side = selectedSide;
    currentOperation.additionalDimension.type = selectedShortSide;

    updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'coupeOverLengthUpdateFailed'));
  };

  const deleteCoupeOverLength = () => {
    deleteOperation(
      currentOperation,
      () => props.onClose(true),
      t(prefix + 'coupeOverLengthDeleteSuccess'),
      t(prefix + 'coupeOverLengthDeleteFailed'),
    );
  };

  const closeModal = fields => {
    if (!fields) {
      props.onClose();
      return;
    }

    if (currentOperation) {
      updateCoupeOverLength(fields);
    } else {
      createCoupeOverLength(fields);
    }
  };

  const toggleConfirmationModal = userHasAccepted => {
    if (userHasAccepted) {
      deleteCoupeOverLength();
    }

    setConfirmationModalIsActive(!confirmationModalIsActive);
  };

  const renderTiltedSideSelect = () => {
    return (
      <>
        <SectionTitle content={t(prefix + 'tiltedSideTitle')} />

        {[FRONT, BACK].map((side, index) => {
          const sideName = getSideNameByPreset(currentConfiguration?.options.preset, side);

          return (
            <RadioButton
              dataCy={`coupeOverLengthModal-tiltedSide-${side.toLowerCase()}`}
              key={`coupeOverLengthSideRadioButton${index}`}
              name="coupeOverLengthSide"
              content={t('constants.objectSides.' + sideName)}
              checked={selectedSide === side}
              onChange={() => setSelectedSide(side)}
              disabled={!availableSides.includes(side) || !canEdit}
            />
          );
        })}
      </>
    );
  };

  const renderFooterButtons = () => {
    let secondaryButtonStyle = 'button button--outline';
    let secondaryButtonContent = t(prefix + 'cancelButton');
    let secondaryButtonOnClick = () => closeModal();

    if (currentOperation != null) {
      secondaryButtonStyle = 'button button--danger';
      secondaryButtonContent = t(prefix + 'deleteButton');
      secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
    }

    const submitButton = (
      <button
        data-cy="coupeOverLengthModal-submitButton"
        key={'coupeOverLengthModalSubmitButton'}
        className="button button--primary"
        form="coupeOverLengthForm"
        type="submit"
        disabled={selectedSide === ''}
      >
        {t(prefix + 'doneButton')}
      </button>
    );

    let buttons = [
      <button
        data-cy="coupeOverLengthModal-deleteButton"
        key={'coupeOverLengthModalCancelButton'}
        type="button"
        className={secondaryButtonStyle}
        onClick={secondaryButtonOnClick}
      >
        {secondaryButtonContent}
      </button>,
    ];

    if (canEdit) {
      buttons.unshift(submitButton);
    }

    return buttons;
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <ConfirmationModal
        isActive={confirmationModalIsActive}
        onClose={userHasAccepted => toggleConfirmationModal(userHasAccepted)}
        content={t('modals.coupeOverLengthModal.deleteCoupeOverLength')}
      />

      <Modal show={props.isActive} onHide={() => closeModal()} animation={true}>
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="coupeOverLengthForm" onSubmit={handleSubmit(closeModal)}>
            <p>{t(prefix + 'description')}</p>

            {renderTiltedSideSelect()}

            <SectionTitle content={t(prefix + 'widthTitle')} />

            <div className="row m-0">
              <div className="col-6 p-0 p-r-1">
                <div className="form-group">
                  <label>{t(prefix + 'shortSideLabel')}</label>
                  <select
                    data-cy="coupeOverLengthModal-shortSide"
                    onChange={event => setSelectedShortSide(event.target.value)}
                    value={selectedShortSide}
                    disabled={!canEdit}
                  >
                    <option value={LEFT}>
                      {t('constants.objectSides.' + getSideNameByPreset(currentConfiguration?.options.preset, LEFT))}
                    </option>
                    <option value={RIGHT}>
                      {t('constants.objectSides.' + getSideNameByPreset(currentConfiguration?.options.preset, RIGHT))}
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-6 p-0">
                <ValidatedInput
                  dataCy="coupeOverLengthModal-widthInput"
                  register={register}
                  name="width"
                  label={t(prefix + 'widthLabel')}
                  placeholder={t(prefix + 'widthPlaceholder')}
                  value={currentOperation?.dimensions.width}
                  required={true}
                  min={1}
                  max={currentPiece?.dimensions.width}
                  error={errors.width}
                  disabled={!canEdit}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
}

CoupeOverLengthModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { CoupeOverLengthModal };
