import { Box3, Vector3 } from 'three';
import {
  CollectionHelper,
  getTypeOfPiece,
  MeshCreator,
  OperationHelper,
  recalculateCoupePosition,
  recalculateRectangularCutOut,
  VectorHelper,
} from '../../internal';

import { BACK, FRONT, LEFT, RIGHT } from '../../constants/ObjectSides';
import * as ObjectCorners from '../../constants/ObjectCorners';
import * as Quality from '../../constants/Quality';
import * as Angles from '../../constants/Angles';
import * as ConnectionStyles from '../../constants/ConnectObjectStyles';
import {
  CHISELED_SIDE,
  CORNER_CUTOUT,
  COUPE,
  COUPE_OVER_LENGTH,
  FINISHED_SIDE,
  HEIGHT_COUPE,
  NOTCH,
  RECTANGULAR_CUT_OUT,
  ROUNDED_CORNER,
  WATERLIST,
} from '../../constants/OperationTypes';
import { dividePieceInParts } from '../helpers/PieceDivisionHelper';
import { parseNumber } from '../helpers/StringHelper';
import { DEFAULT_DIMENSION_RATIO } from '../../constants/Values';

class Piece {
  constructor(id, dimensions = { length: 0, height: 0, width: 0 }, operations = [], position = new Vector3()) {
    this.id = id;
    this.name = '';
    this.type = '';
    this.dimensions = dimensions;
    this.operations = operations;
    this.positionVector = position;
    this.rotation = { x: 0, y: 0, z: 0 };
    this.sideVectors = null;
    this.boundingBox = { size: new Vector3(), center: new Vector3(), min: new Vector3(), max: new Vector3() };
    this.isAnchor = false;
    this.connectedObjects = [];
    this.quality = Quality.DEFAULT;
    this.parts = [{ id: 0, length: dimensions.length }];
    this.dimensionRatio = { length: DEFAULT_DIMENSION_RATIO, width: DEFAULT_DIMENSION_RATIO };
    this.originalDimensions = { length: 0, height: 0, width: 0 };
    this.isManuallyDivided = false;

    this.calculateSideVectors(dimensions);
  }

  set length(length) {
    // Set the new length of the piece
    const previousDimensions = this.dimensions;
    this.dimensions.length = length;

    // Calculate the default vectors of the sides again
    this.calculateSideVectors();
    this.recalculateOperations(previousDimensions);

    // Update the position of the piece after resizing
    this.updatePosition();

    this.parts = dividePieceInParts(
      this.dimensions.length,
      this.dimensions.width,
      this.dimensions.length,
      getTypeOfPiece(this),
    );
  }

  set height(height) {
    const previousDimensions = this.dimensions;
    this.dimensions.height = height;

    // Calculate the default vectors of the sides again
    this.calculateSideVectors();
    this.recalculateOperations(previousDimensions);

    // Update the position of the piece after resizing
    this.updatePosition();
  }

  set width(width) {
    const previousDimensions = this.dimensions;
    this.dimensions.width = parseFloat(width);

    // Calculate the default vectors of the sides again
    this.calculateSideVectors();
    this.recalculateOperations(previousDimensions);

    // Update the position of the piece after resizing
    this.updatePosition();
  }

  get position() {
    return this.positionVector;
  }

  set position(position) {
    this.positionVector = position;
    this.calculateSideVectors();
  }

  set setFinishedSides(finishedSides) {
    const coupeList = OperationHelper.getCoupesOfPiece(this).map(coupe => coupe.side);

    this.finishedSides = finishedSides.filter(val => !coupeList.includes(val));
  }

  get getFinishedSides() {
    return this.operations
      .filter(operation => [FINISHED_SIDE, CHISELED_SIDE].includes(operation.type))
      .map(operation => {
        return { name: operation.side, type: operation.additionalDimension.type };
      });
  }

  get waterlists() {
    return this.operations.filter(operation => operation.type === WATERLIST);
  }

  get materialPrice() {
    let price = parseNumber(this.price);

    this.operations.forEach(operation => {
      price -= parseNumber(operation.price);
    });

    return price.toFixed(2);
  }

  // These are the vectors of the piece as it originally is, operations are not taken into consideration
  calculateSideVectors() {
    const meshCreator = new MeshCreator();
    let mesh = meshCreator.createType1ForCalculating(this, null, false);

    let updatedVertices = [];

    mesh.geometry.vertices.forEach(vertex => {
      let updatedVertex = vertex.clone();
      mesh.localToWorld(updatedVertex);

      updatedVertices.push(updatedVertex);
    });

    this.sideVectors = {
      FRONT: [updatedVertices[5], updatedVertices[0], updatedVertices[2], updatedVertices[7]],
      LEFT: [updatedVertices[4], updatedVertices[5], updatedVertices[6], updatedVertices[7]],
      BACK: [updatedVertices[1], updatedVertices[4], updatedVertices[3], updatedVertices[6]],
      RIGHT: [updatedVertices[0], updatedVertices[1], updatedVertices[2], updatedVertices[3]],
      TOP: updatedVertices[4],
      BOTTOM: updatedVertices[7],
    };
  }

  rotateX(angle) {
    // Reset the position of the piece otherwise the boundingbox will be calculated on the previous position
    this.positionVector = new Vector3();
    this.rotation.x = angle;
    this.boundingBox = this.generateBoundingBox();
  }

  rotateY(angle) {
    // Reset the position of the piece otherwise the boundingbox will be calculated on the previous position
    this.positionVector = new Vector3();
    this.rotation.y = angle;
    this.boundingBox = this.generateBoundingBox();
  }

  rotateZ(angle) {
    // Reset the position of the piece otherwise the boundingbox will be calculated on the previous position
    this.positionVector = new Vector3();
    this.rotation.z = angle;
    this.boundingBox = this.generateBoundingBox();
  }

  addConnectedObject(pieceId, connectionStyle) {
    this.connectedObjects.push({ id: pieceId, style: connectionStyle });
  }

  addCoupe(coupe) {
    if (coupe == null) return;

    this.setFinishedSides = this.getFinishedSides?.filter(side => coupe.side !== side);

    this.operations.push(coupe);
  }

  generateBoundingBox() {
    // Only execute this when the piece is rotated
    if (this.rotation.x !== 0 || this.rotation.y !== 0 || this.rotation.z !== 0) {
      const meshCreator = new MeshCreator();

      // Create a mesh for this piece to serve as a dummy
      // That way we can calculate a bounding box in this class, and we don't need to write code like this
      // In the class that creates the meshes for displaying on the canvas
      let piece = meshCreator.createType1ForCalculating(this);

      // Calculate the bounding box for this piece
      let boundingBox = new Box3().setFromObject(piece);

      // Find the size of the bounding box
      let boundingBoxSize = new Vector3();
      boundingBox.getSize(boundingBoxSize);

      // Find the center of the bounding box
      let boundingBoxCenter = new Vector3();
      boundingBox.getCenter(boundingBoxCenter);
      boundingBoxCenter.sub(this.positionVector);

      return { size: boundingBoxSize, center: boundingBoxCenter, min: boundingBox.min, max: boundingBox.max };
    }

    return { size: new Vector3(), center: new Vector3(), min: new Vector3(), max: new Vector3() };
  }

  recalculateOperations(previousDimensions = null) {
    if (!previousDimensions) previousDimensions = this.dimensions;

    this.operations = this.operations.map(operation => {
      if (operation.type === NOTCH) {
        operation.position = VectorHelper.getVectorForNotch(this.dimensions, operation);
      } else if (operation.type === COUPE) {
        operation = recalculateCoupePosition(this.dimensions, previousDimensions, operation);
      } else if (operation.type === RECTANGULAR_CUT_OUT) {
        operation = recalculateRectangularCutOut(this, previousDimensions, operation);
      }

      return operation;
    });
  }

  updateObjectRotationAndPosition() {
    // If the piece is not an anchor the position and rotation can be changed
    if (!this.isAnchor) {
      // Get the coupe that is connected to the main coupe
      let mainCoupe = this.operations.find(
        operation => [COUPE, HEIGHT_COUPE].includes(operation.type) && operation.connectedCoupe,
      );
      if (!mainCoupe) return null;

      let connectedCoupe = CollectionHelper.getOperationById(mainCoupe.connectedCoupe);
      if (!connectedCoupe) return null;

      // Get the piece that is connected to this main piece by the coupe
      let connectedObject = CollectionHelper.getPieceById(connectedCoupe.mainObject);

      if (mainCoupe.type === COUPE) {
        this.updateRotationAndPositionWidthCoupe(mainCoupe, connectedCoupe, connectedObject);
      } else if (mainCoupe.type === HEIGHT_COUPE) {
        this.updateRotationAndPositionHeightCoupe(mainCoupe, connectedCoupe, connectedObject);
      }
    }
  }

  updateRotationAndPositionWidthCoupe(mainCoupe, connectedCoupe, connectedObject) {
    // Calculate the degrees that the connected piece should be rotated to fit correctly together with the
    // main piece
    let angle = VectorHelper.calculateAngleDegrees(mainCoupe, connectedCoupe);

    if (mainCoupe.angle === Angles.OUTER) {
      angle += connectedObject.rotation.y;
    } else {
      angle -= connectedObject.rotation.y;
    }

    // Negate the angle degrees when it is an inner angle type
    // This code makes sure that there is no 'gap' between two coupes/pieces in terms of rotation
    if (angle !== 0) {
      if (mainCoupe.angle === Angles.INNER) {
        angle = -angle;
      }
    }

    if (Math.abs(angle) > 360) {
      if (angle < 0) {
        angle += 360;
      } else {
        angle -= 360;
      }
    }

    this.rotateY(angle);

    // Change the position so the piece connects perfectly with the other piece
    this.position = VectorHelper.getVectorForObjectConnectedByCoupe(connectedObject, connectedCoupe, this);
  }

  updateRotationAndPositionHeightCoupe(mainCoupe, connectedCoupe, connectedObject) {
    // Calculate the degrees that the connected piece should be rotated to fit correctly together with the
    // main piece
    let zRotation = VectorHelper.calculateAngleDegreesHeightCoupe(mainCoupe, connectedCoupe);
    let xRotation = 0;

    if ([LEFT, RIGHT].includes(mainCoupe.side)) {
      if (mainCoupe.angle === Angles.OUTER) {
        zRotation += connectedObject.rotation.z;
      } else {
        zRotation -= connectedObject.rotation.z;
      }
    } else {
      // FRONT || BACK
      zRotation = 0;
      xRotation = VectorHelper.calculateAngleDegreesHeightCoupe(mainCoupe, connectedCoupe);

      if (connectedCoupe.side === FRONT) {
        if (mainCoupe.angle === Angles.INNER) {
          xRotation = -xRotation;
        }
      } else {
        if (mainCoupe.angle === Angles.OUTER) {
          xRotation = -xRotation;
        }
      }
    }

    // Negate the angle degrees when it is an inner angle type
    // This code makes sure that there is no 'gap' between two coupes/pieces in terms of rotation
    if (zRotation !== 0) {
      if (mainCoupe.angle === Angles.INNER) {
        zRotation = -zRotation;
      }
    }

    if (Math.abs(zRotation) > 360) {
      if (zRotation < 0) {
        zRotation += 360;
      } else {
        zRotation -= 360;
      }
    }

    this.rotateX(xRotation);
    this.rotateZ(zRotation);

    // Change the position so the piece connects perfectly with the other piece
    this.position = VectorHelper.getVectorForObjectConnectedByCoupe(connectedObject, connectedCoupe, this);
  }

  updatePosition() {
    if (this.connectedObjects.length === 1) {
      const connectionStyleIsCoupe =
        this.connectedObjects[0].style === ConnectionStyles.INNER_COUPE ||
        this.connectedObjects[0].style === ConnectionStyles.OUTER_COUPE;

      if (connectionStyleIsCoupe) {
        this.updateObjectRotationAndPosition();
      } else {
        let mainObject = CollectionHelper.getPieceById(this.connectedObjects[0].id);
        const connectionStyle = mainObject.connectedObjects.filter(piece => piece.id === this.id)[0].style;
        this.position = VectorHelper.getVectorForNewObject(mainObject, this, connectionStyle);
      }
    }
  }

  updateOperation(operation) {
    this.operations = this.operations.map(existingOperation => {
      if (existingOperation.id === operation.id) {
        return operation;
      }

      return existingOperation;
    });
  }

  removeFinishedSideAndWaterlistFromSide(side) {
    this.operations = this.operations.filter(operation => {
      if ([FINISHED_SIDE, CHISELED_SIDE, WATERLIST].includes(operation.type)) {
        if (operation.side === side) {
          return false;
        }
      }

      return true;
    });
  }

  getOperationsByType(operationType) {
    return this.operations.filter(operation => operation.type === operationType);
  }

  getSummary() {
    return this.dimensions.length + 'x' + this.dimensions.width + 'x' + this.dimensions.height;
  }

  getAvailableSidesToConnectPieces() {
    let availableSides = [];

    let leftIsAvailable = true;
    let rightIsAvailable = true;

    let leftStyles = ConnectionStyles.LEFT_STYLES;
    let rightStyles = ConnectionStyles.RIGHT_STYLES;

    this.connectedObjects.forEach(connectedPiece => {
      if (leftStyles.includes(connectedPiece.style)) leftIsAvailable = false;
      if (rightStyles.includes(connectedPiece.style)) rightIsAvailable = false;
    });

    this.getOperationsByType(COUPE).forEach(coupe => {
      if (coupe.side === LEFT && coupe.connectedCoupe != null) leftIsAvailable = false;
      if (coupe.side === RIGHT && coupe.connectedCoupe != null) rightIsAvailable = false;
    });

    this.getOperationsByType(HEIGHT_COUPE).forEach(heightCoupe => {
      if (heightCoupe.side === LEFT && heightCoupe.connectedCoupe != null) leftIsAvailable = false;
      if (heightCoupe.side === RIGHT && heightCoupe.connectedCoupe != null) rightIsAvailable = false;
    });

    if (leftIsAvailable) availableSides.push(LEFT);
    if (rightIsAvailable) availableSides.push(RIGHT);

    return availableSides;
  }

  getAvailableCorners(prohibitedOperationTypes) {
    let availableCorners = [];
    const availableSides = this.getAvailableSides(prohibitedOperationTypes);

    let leftFrontIsAvailable = true;
    let leftBackIsAvailable = true;
    let rightFrontIsAvailable = true;
    let rightBackIsAvailable = true;

    const checkCorners = operation => {
      if (availableSides.includes(LEFT)) {
        if (operation.additionalDimension.type === FRONT && operation.side === LEFT) leftFrontIsAvailable = false;
        if (operation.additionalDimension.type === BACK && operation.side === LEFT) leftBackIsAvailable = false;
      } else {
        leftFrontIsAvailable = false;
        leftBackIsAvailable = false;
      }

      if (availableSides.includes(RIGHT)) {
        if (operation.additionalDimension.type === FRONT && operation.side === RIGHT) rightFrontIsAvailable = false;
        if (operation.additionalDimension.type === BACK && operation.side === RIGHT) rightBackIsAvailable = false;
      } else {
        rightFrontIsAvailable = false;
        rightBackIsAvailable = false;
      }
    };

    this.operations.forEach(operation => {
      if (prohibitedOperationTypes.includes(operation.type)) checkCorners(operation);
    });

    this.getOperationsByType(CORNER_CUTOUT).forEach(checkCorners);
    this.getOperationsByType(ROUNDED_CORNER).forEach(checkCorners);

    if (leftFrontIsAvailable) availableCorners.push(ObjectCorners.FRONT_LEFT);
    if (leftBackIsAvailable) availableCorners.push(ObjectCorners.BACK_LEFT);
    if (rightFrontIsAvailable) availableCorners.push(ObjectCorners.FRONT_RIGHT);
    if (rightBackIsAvailable) availableCorners.push(ObjectCorners.BACK_RIGHT);

    return availableCorners;
  }

  getAvailableSides(prohibitedOperationTypes) {
    let availableSides = [];

    let leftIsAvailable = true;
    let rightIsAvailable = true;
    let frontIsAvailable = true;
    let backIsAvailable = true;

    prohibitedOperationTypes.forEach(type => {
      this.getOperationsByType(type).forEach(operation => {
        if (operation.side === LEFT) leftIsAvailable = false;
        if (operation.side === RIGHT) rightIsAvailable = false;
        if (operation.side === BACK) backIsAvailable = false;
        if (operation.side === FRONT) frontIsAvailable = false;
      });
    });

    if (frontIsAvailable) availableSides.push(FRONT);
    if (leftIsAvailable) availableSides.push(LEFT);
    if (backIsAvailable) availableSides.push(BACK);
    if (rightIsAvailable) availableSides.push(RIGHT);

    return availableSides;
  }

  getAvailableSidesForCoupeOverLength() {
    let availableSides = [];
    let frontIsAvailable = true;

    let backIsAvailable = true;

    this.getOperationsByType(COUPE_OVER_LENGTH).forEach(coupe => {
      if (coupe.side === FRONT) frontIsAvailable = false;
      if (coupe.side === BACK) backIsAvailable = false;
    });

    if (frontIsAvailable) availableSides.push(FRONT);
    if (backIsAvailable) availableSides.push(BACK);

    return availableSides;
  }

  getAvailableSidesForCoupe() {
    let availableSides = [];

    let leftIsAvailable = true;
    let rightIsAvailable = true;

    this.getOperationsByType(COUPE).forEach(coupe => {
      if (coupe.side === LEFT) leftIsAvailable = false;
      if (coupe.side === RIGHT) rightIsAvailable = false;
    });

    if (leftIsAvailable) availableSides.push(LEFT);
    if (rightIsAvailable) availableSides.push(RIGHT);

    return availableSides;
  }

  hasAnyOfOperationTypes(types) {
    for (let i = 0; i < this.operations.length; i++) {
      if (types.includes(this.operations[i].type)) return true;
    }

    return false;
  }

  eraseConnectionsWithOtherPieces() {
    this.operations = this.operations.map(operation => {
      if ([COUPE, HEIGHT_COUPE].includes(operation.type) && operation.connectedCoupe) {
        operation.connectedCoupe = null;
      }

      return operation;
    });

    this.connectedObjects = [];
  }

  isNotDeliverable() {
    return this.parts.some(part => part.isNotDeliverable(this.dimensions.height));
  }
}

export { Piece };
