import { getYPosForSide } from '../SurfaceMeasurementLineHelper';
import { BACK, FRONT } from '../../../../constants/ObjectSides';
import MeasurementPoints from '../../../models/MeasurementPoints';
import { Vector3 } from 'three';
import { DEFAULT_DIMENSION_RATIO } from '../../../../constants/Values';

export function getPointsForDrillHole(piece, drillHole) {
  const pieceDimensions = piece.dimensions;
  const lineYPos = getYPosForSide(pieceDimensions) + 0.05;

  const horizontalPoints = getHorizontalPoints(piece, drillHole, lineYPos);

  const verticalPoints = new MeasurementPoints(
    getVerticalPointA(pieceDimensions, drillHole, lineYPos),
    getVerticalPointB(pieceDimensions, drillHole, lineYPos),
  );

  const diameterPoints = new MeasurementPoints(
    getDiameterPointA(drillHole, lineYPos),
    getDiameterPointB(drillHole, lineYPos),
  );

  diameterPoints.lineLength = getDiameterLineLength(piece, drillHole);

  verticalPoints.textSide = getVerticalPointSide(drillHole.position.x);
  diameterPoints.textSide = getVerticalPointSide(drillHole.position.x);

  return {
    horizontal: horizontalPoints,
    vertical: [verticalPoints, diameterPoints],
  };
}

function getHorizontalPoints(piece, drillHole, lineYPos) {
  const horizontalPoints = new MeasurementPoints(
    getHorizontalPointA(piece.dimensions, drillHole, lineYPos),
    getHorizontalPointB(piece.dimensions, drillHole, lineYPos),
  );

  horizontalPoints.textSide = getHorizontalPointSide(drillHole.position.z);

  return horizontalPoints;
}

function getHorizontalPointA(pieceDimensions, drillHole, lineYPos) {
  let xPos;

  // Set the points on the side that is shortest
  if (drillHole.position.x > 0) {
    // Set on the right side
    xPos = drillHole.position.x;
  } else {
    // Set on the left side
    xPos = -pieceDimensions.length / 2;
  }

  return new Vector3(xPos, lineYPos, drillHole.position.z);
}

function getHorizontalPointB(pieceDimensions, drillHole, lineYPos) {
  let xPos;

  // Set the points on the side that is shortest
  if (drillHole.position.x > 0) {
    // Set on the right side
    xPos = pieceDimensions.length / 2;
  } else {
    // Set on the left side
    xPos = drillHole.position.x;
  }

  return new Vector3(xPos, lineYPos, drillHole.position.z);
}

function getVerticalPointA(pieceDimensions, drillHole, yLinePos) {
  let zPos;

  if (drillHole.position.z > 0) {
    zPos = pieceDimensions.width / 2;
  } else {
    zPos = drillHole.position.z;
  }

  return new Vector3(drillHole.position.x, yLinePos, zPos);
}

function getVerticalPointB(pieceDimensions, drillHole, yLinePos) {
  let zPos;

  if (drillHole.position.z > 0) {
    zPos = drillHole.position.z;
  } else {
    zPos = pieceDimensions.width / -2;
  }

  return new Vector3(drillHole.position.x, yLinePos, zPos);
}

function getDiameterPointA(drillHole, yLinePos) {
  let zPos;

  const length = drillHole.dimensions.length / 10;

  zPos = drillHole.position.z + length / 2;

  return new Vector3(drillHole.position.x, yLinePos, zPos);
}

function getDiameterPointB(drillHole, yLinePos) {
  let zPos;

  const length = drillHole.dimensions.length / 10;

  zPos = drillHole.position.z - length / 2;

  return new Vector3(drillHole.position.x, yLinePos, zPos);
}

function getHorizontalPointSide(zPos) {
  if (zPos > 0) {
    return BACK;
  } else {
    return FRONT;
  }
}

function getVerticalPointSide(xPos) {
  if (xPos > 0) {
    return BACK;
  } else {
    return FRONT;
  }
}

function getDiameterLineLength(piece, drillHole) {
  const isWidthBelowDefaultRatio = piece.dimensionRatio.width < DEFAULT_DIMENSION_RATIO;
  const isLengthBelowDefaultRatio = piece.dimensionRatio.length < DEFAULT_DIMENSION_RATIO;

  let lineLength = drillHole.dimensions.length / 10;

  if (isWidthBelowDefaultRatio || (isLengthBelowDefaultRatio && isWidthBelowDefaultRatio)) {
    lineLength *= 1 / piece.dimensionRatio.width;
  }

  return lineLength;
}
