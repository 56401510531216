import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useForm } from 'react-hook-form';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { ModalHolder } from './ModalHolder';

function SingleInputModal(props) {
  const { errors, register, handleSubmit } = useForm();

  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.singleInputModal.';

  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.isActive) {
      setValue(props.value);
    } else {
      setValue('');
    }
  }, [props.isActive]);

  const onCloseHandler = fields => {
    if (fields) {
      props.onClose(fields.value);
    } else {
      props.onClose();
    }
  };

  const onEnterPress = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      onCloseHandler();
    }
  };

  const renderFooterButtons = () => {
    return [
      <button
        key={'singleInputModalFooterButton1'}
        className="button button--primary"
        type="submit"
        form="singleInputForm"
      >
        {t(prefix + 'doneButton')}
      </button>,
      <button key={'singleInputModalFooterButton2'} className="button" type="button" onClick={() => onCloseHandler()}>
        {t(prefix + 'cancelButton')}
      </button>,
    ];
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={() => onCloseHandler()} animation={true} className="modal--prompt">
        <Modal.Header>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="singleInputForm" onSubmit={handleSubmit(onCloseHandler)}>
            <ValidatedInput
              register={register}
              name={'value'}
              label={props.content}
              value={value}
              minLength={1}
              maxLength={10}
              error={errors.value}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
}

SingleInputModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

SingleInputModal.defaultProps = {
  value: '',
};

export { SingleInputModal };
