import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import GalleryImage from '../../classes/models/GalleryImage';
import ReactTags from 'react-tag-autocomplete';
import { useEffect, useState } from 'react';
import GalleryImageService from '../../classes/services/GalleryImageService';
import { useDispatch } from 'react-redux';
import { addAlertMessage } from '../../actions/GeneralActions';
import { DANGER } from '../../constants/Variants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';

export const GalleryImageModal = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.galleryImageModal.';

  const [existingTags, setExistingTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fileToUpload, setFileToUpload] = useState();
  const [formIsValid, setFormIsValid] = useState(true);

  const galleryImageService = new GalleryImageService();

  const dispatch = useDispatch();

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    if (props.isActive && props.galleryImage) {
      setSelectedTags(props.galleryImage.tags);
    }

    if (!props.isActive) {
      setSelectedTags([]);
      setFileToUpload(null);
    }
  }, [props.isActive]);

  useEffect(() => {
    if (props.galleryImage == null) {
      setFormIsValid(fileToUpload != null);
    }

    if (props.galleryImage != null) {
      setFormIsValid(true);
    }
  }, [fileToUpload, props.galleryImage]);

  const getAllTags = () => {
    galleryImageService
      .getTags()
      .then(response => {
        if (response.success) {
          setExistingTags(response.data);
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, t(prefix + 'fetchTagsFailedMessage')));

        throw error;
      });
  };

  const onCloseHandler = submit => {
    if (submit) {
      props.onClose(fileToUpload, selectedTags);
    } else {
      props.onClose();
    }
  };

  const removeTag = index => {
    selectedTags.splice(index, 1);

    setSelectedTags(selectedTags);
  };

  const addTag = tag => {
    let tags = selectedTags;

    // Not searching on basis of the id because new tags do not have an id
    const existingTag = tags.find(selectedTag => selectedTag.name === tag.name);

    if (!existingTag) {
      tags.push(tag);
    }
  };

  const renderFileInput = () => {
    if (props.galleryImage == null) {
      return (
        <div className="form-group">
          <label>Choose a photo</label>
          <div class="form-group__file">
            <i class="material-icons">photo</i> Click to choose a file
            <input name="fileUploader" type="file" onChange={event => setFileToUpload(event.target.files[0])} />
          </div>
        </div>
      );
    }
  };

  return (
    <Modal className="modal--prompt" show={props.isActive} onHide={() => onCloseHandler()} animation={true}>
      <Modal.Header>
        <Modal.Title>{t(prefix + 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderFileInput()}
        <div className="form-group">
          <label>Photo subjects (tags)</label>
          <ReactTags
            onDelete={index => removeTag(index)}
            onAddition={tag => addTag(tag)}
            suggestions={existingTags}
            tags={selectedTags}
            allowNew={true}
            allowBackspace={false}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="button button--primary"
          disabled={!formIsValid}
          onClick={() => onCloseHandler(true)}
        >
          {props.galleryImage ? t(prefix + 'updateButton') : t(prefix + 'createButton')}
        </button>

        <button type="button" className="button button--outline" onClick={() => onCloseHandler()}>
          {t(prefix + 'cancelButton')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

GalleryImageModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  galleryImage: PropTypes.instanceOf(GalleryImage),
};
