import * as React from 'react';
import PropTypes from 'prop-types';

export const EmptySidebarListItem = props => {
  return (
    <div className="list__item">
      <div className="list__item__content">
        <span>{props.content}</span>
      </div>
    </div>
  );
};

EmptySidebarListItem.propTypes = {
  content: PropTypes.string.isRequired,
};
