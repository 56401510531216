class GalleryImage {
  constructor(id, name, tags = []) {
    this.id = id;
    this.name = name;
    this.tags = tags;
    this.imageUrl = '';
  }
}

export default GalleryImage;
