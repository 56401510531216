import { Vector3 } from 'three';
import {
  Address,
  Configuration,
  CoupeOperation,
  NotchOperation,
  Offer,
  Operation,
  Order,
  Part,
  Type1Piece,
  Type2Piece,
  Type3Piece,
  Type4Piece,
  Type5Piece,
  Type6Piece,
  Type7Piece,
  Type8Piece,
  VectorHelper,
} from '../../internal';
import i18next from 'i18next';
import { COLLECT, DELIVERY } from '../../constants/DeliveryOptions';
import { OrderStatus } from '../models/OrderStatus';
import { User } from '../models/User';
import Company from '../models/Company';
import Manufacturer from '../models/Manufacturer';
import Catalog from '../models/Catalog';
import ExternalLink from '../models/ExternalLink';
import GalleryImage from '../models/GalleryImage';
import Tag from '../models/Tag';
import Customer from '../models/Customer';
import ClientType from '../models/ClientType';
import { capitalizeString, parseToCommaSeparated } from './StringHelper';
import {
  ANCHOR_HOLE,
  CHISELED_SIDE,
  CORNER_CUTOUT,
  COUPE,
  DEBASING,
  DEBASING_ROUGH,
  DRILL_HOLE,
  FINISHED_SIDE,
  GLUED_CUSHION,
  HEIGHT_COUPE,
  NOTCH,
  RABAT,
  RECTANGULAR_CUT_OUT,
  ROUNDED_CORNER,
  WATERLIST,
} from '../../constants/OperationTypes';
import { RearHeel1_2 } from '../models/RearHeel1_2';
import { RearHeel5_2 } from '../models/RearHeel5_2';
import { RearHeelCustom } from '../models/RearHeelCustom';
import { CushionNotGlued } from '../models/CushionNotGlued';
import OutdoorConfiguration from '../models/OutdoorConfiguration';
import { OutdoorArticle } from '../models/OutdoorArticle';
import { DeliveryType } from '../models/DeliveryType';
import { DeliveryDate } from '../models/DeliveryDate';
import {
  CUSHION_NOT_GLUED,
  REAR_HEEL_1_2,
  REAR_HEEL_5_2,
  REAR_HEEL_CUSTOM,
  TYPE_1,
  TYPE_2,
  TYPE_3,
  TYPE_4,
  TYPE_5,
  TYPE_6,
  TYPE_7,
  TYPE_8,
} from '../../constants/ObjectTypes';
import { ConfigurationOptions } from '../models/ConfigurationOptions';
import { CompanyBranch } from '../models/CompanyBranch';
import { Setting } from '../models/Setting';
import moment from 'moment';
import { ArticleSubstitutionGroup } from '../models/ArticleSubstitutionGroup';
import { PriceVersion } from '../models/PriceVersion';
import { Log } from '../models/Log';
import { LogProperties } from '../models/LogProperties';
import { UserSettings } from '../services/UserSettings';
import { PaginationData } from '../models/PaginationData';

class Parser {
  static parseOffer(offerData, withRelations = false) {
    const timestamp = this.parseStringToTimestamp(offerData.updated_at);
    let parsedOffer = new Offer(offerData.id, offerData.name, offerData.creator, timestamp);

    if (withRelations) {
      parsedOffer.configurations = offerData.configurations.map(configuration => {
        return this.parseConfiguration(configuration, withRelations);
      });
    }
    parsedOffer.outdoorConfigurations = this.parseOutdoorConfigurations(offerData.outdoor_configurations);

    parsedOffer.price = parseToCommaSeparated(offerData.price, 2);
    parsedOffer.discount = parseToCommaSeparated(offerData.discount, 2);

    parsedOffer.companyBranch = offerData.company_branch
      ? this.parseCompanyBranch(offerData.company_branch)
      : new CompanyBranch();
    parsedOffer.companyBranch.id = offerData.company_branch_id;
    parsedOffer.margin = parseFloat(offerData.margin);
    parsedOffer.vat = parseFloat(offerData.vat);
    parsedOffer.customer = this.parseCustomer(offerData.customer);
    parsedOffer.expiryDate = moment(offerData.expiry_date);
    parsedOffer.isCreatedByAdmin = offerData.is_created_by_admin;
    parsedOffer.isVisible = offerData.is_visible;

    return parsedOffer;
  }

  static parseDimensions(piece, preset) {
    let dimensions;

    if (preset === CUSHION_NOT_GLUED) {
      dimensions = {
        length: parseFloat(piece.width),
        height: parseFloat(piece.height),
        width: parseFloat(piece.length),
      };
    } else {
      dimensions = {
        length: parseFloat(piece.length),
        height: parseFloat(piece.height),
        width: parseFloat(piece.width),
      };
    }

    switch (piece.type) {
      case TYPE_3:
      case TYPE_4:
        dimensions.barWidth = parseFloat(piece.bar_width);
        dimensions.extrusion = parseFloat(piece.extrusion);
        break;
      case TYPE_5:
      case TYPE_6:
        dimensions.frontHeight = parseFloat(piece.front_height);
        dimensions.extrusion = parseFloat(piece.extrusion);
        dimensions.barWidth = parseFloat(piece.bar_width);
        break;
      case TYPE_7:
      case TYPE_8:
        dimensions.frontHeight = parseFloat(piece.front_height);
        dimensions.extrusion = parseFloat(piece.extrusion);
        dimensions.barWidth = parseFloat(piece.bar_width);
        dimensions.cushionWidth = parseFloat(piece.cushion_width);
        dimensions.protrusion = parseFloat(piece.protrusion);
        break;
      default:
        break;
    }

    return dimensions;
  }

  static parseFinishedSides(finishedSides) {
    if (finishedSides == null) return [];

    return finishedSides.map(finishedSide => {
      return finishedSide.name;
    });
  }

  static instantiateObjectByType(type) {
    let piece;

    switch (type) {
      case TYPE_1:
        piece = new Type1Piece();
        break;
      case TYPE_2:
        piece = new Type2Piece();
        break;
      case TYPE_3:
        piece = new Type3Piece();
        break;
      case TYPE_4:
        piece = new Type4Piece();
        break;
      case TYPE_5:
        piece = new Type5Piece();
        break;
      case TYPE_6:
        piece = new Type6Piece();
        break;
      case TYPE_7:
        piece = new Type7Piece();
        break;
      case TYPE_8:
        piece = new Type8Piece();
        break;
      case REAR_HEEL_1_2:
        piece = new RearHeel1_2();
        break;
      case REAR_HEEL_5_2:
        piece = new RearHeel5_2();
        break;
      case REAR_HEEL_CUSTOM:
        piece = new RearHeelCustom();
        break;
      case CUSHION_NOT_GLUED:
        piece = new CushionNotGlued();
        break;
      default:
        piece = new Type1Piece();
        break;
    }

    return piece;
  }

  static parseConfiguration(configurationData, withRelations = false) {
    let configuration = new Configuration(configurationData.id);
    configuration.name = configurationData.name ? configurationData.name : '';
    configuration.amount = configurationData.amount;
    configuration.price = parseToCommaSeparated(configurationData.price, 2);
    configuration.options = this.parseConfigurationOptions(configurationData.options);
    configuration.operations = this.parseConfigurationOperations(
      configurationData.operations ?? configurationData.condensed_operations,
    );

    if (withRelations) {
      configuration.pieces = configurationData.pieces.map(pieceData => {
        return this.parsePiece(pieceData, configuration.options);
      });
      configuration.pieces = [...configuration.sortPieces()];
    }

    return configuration;
  }

  static parseOutdoorConfigurations(configurationsData) {
    if (!configurationsData) return [];

    return configurationsData.map(configurationData => OutdoorConfiguration.parse(configurationData));
  }

  static parseOutdoorArticles(articles) {
    Object.keys(articles).forEach(category1Name => {
      Object.keys(articles[category1Name]).forEach(category2Name => {
        Object.keys(articles[category1Name][category2Name]).forEach(category3Name => {
          articles[category1Name][category2Name][category3Name].forEach((data, index) => {
            articles[category1Name][category2Name][category3Name][index] = OutdoorArticle.parse(data);
          });
        });
      });
    });

    return articles;
  }

  static parseOperation(piece, operationData, pieceType) {
    let parsedOperation;

    if ([COUPE, HEIGHT_COUPE].includes(operationData.type)) {
      parsedOperation = this.parseCoupe(operationData);
    } else {
      parsedOperation = new Operation(operationData.id, operationData.type);
    }

    parsedOperation.id = operationData.id;

    parsedOperation.dimensions = {
      length: !isNaN(parseFloat(operationData.length)) ? parseFloat(operationData.length) : 0,
      height: !isNaN(parseFloat(operationData.height)) ? parseFloat(operationData.height) : 0,
      width: !isNaN(parseFloat(operationData.width)) ? parseFloat(operationData.width) : 0,
    };

    parsedOperation.type = operationData.type;
    parsedOperation.additionalDimension = {
      type: operationData.additional_type,
      value: parseFloat(operationData.additional_value),
    };

    parsedOperation.data = operationData.data;
    parsedOperation.side = operationData.side;
    parsedOperation.angle = operationData.angle_type;
    parsedOperation.price = [DEBASING_ROUGH, DEBASING].includes(parsedOperation.type)
      ? null
      : this.parsePriceString(operationData.price, 2);
    parsedOperation.isFinished = operationData.is_finished == true;
    parsedOperation.position = this.parseOperationPosition(piece, parsedOperation, pieceType, operationData.position);

    if (operationData.type === HEIGHT_COUPE) {
      parsedOperation.connectedCoupe = operationData.operation_id;
    }

    return parsedOperation;
  }

  static parseOperations(piece, operationData) {
    if (operationData == null) return [];

    let parsedOperations = operationData.map(operation => {
      return this.parseOperation(piece, operation, piece.type);
    });

    piece.operations = parsedOperations;

    parsedOperations = parsedOperations.map(operation => {
      operation.position = this.parseOperationPosition(piece, operation, piece.type);
      return operation;
    });

    return parsedOperations;
  }

  static parseNotch(operation) {
    const POSITION = this.parsePosition(operation.position);

    let dimensions = {
      length: parseFloat(operation.length),
      width: parseFloat(operation.width),
      distance: parseFloat(operation.additional_value),
    };

    return new NotchOperation(operation.id, operation.side, null, dimensions, POSITION, operation.is_finished);
  }

  static parseCoupe(operation) {
    const POSITION = this.parsePosition(operation.position);

    let dimensions = {
      length: parseFloat(operation.length),
      width: parseFloat(operation.width),
    };

    let coupe = new CoupeOperation(
      operation.id,
      operation.piece_id,
      operation.side,
      operation.angle_type,
      dimensions,
      POSITION,
      operation.is_finished,
    );
    coupe.additionalDimension = { value: operation.additional_value, type: operation.additional_type };
    coupe.connectedCoupe = operation.operation_id;

    return coupe;
  }

  static parsePiece(pieceData, { preset }) {
    let piece;
    const dimensions = this.parseDimensions(pieceData, preset);

    piece = this.instantiateObjectByType(pieceData.type);
    piece.id = parseInt(pieceData.id);
    piece.type = pieceData.type;
    piece.name = pieceData.name ? pieceData.name : '';
    piece.amount = parseInt(pieceData.amount);
    piece.dimensions = dimensions;
    piece.parts = this.parseParts(pieceData.parts);
    piece.position = this.parsePosition(pieceData.position);
    piece.rotation = this.parseRotation(pieceData.rotation);
    piece.generateBoundingBox();

    piece.connectedObjects = this.parseConnectedObjects(pieceData.connected_pieces);
    piece.isAnchor = VectorHelper.compareVectors(piece.position, new Vector3());
    piece.operations = this.parseOperations(piece, pieceData.operations);
    piece.price = this.parsePriceString(pieceData.price);
    piece.isManuallyDivided = pieceData.is_manually_divided;

    return piece;
  }

  static parseConnectedObjects(connectedPieces) {
    if (connectedPieces == null) return [];

    return connectedPieces.map(pieceData => {
      return {
        id: parseInt(pieceData.pivot.connected_piece_id),
        style: pieceData.pivot.connection_style,
        alignment: pieceData.pivot.alignment,
        alignmentDistance: pieceData.pivot.alignment_distance,
      };
    });
  }

  static parseOperationPosition(piece, operation, pieceType, positionData) {
    let position;

    if (positionData) {
      position = this.parsePosition(positionData);
    } else {
      switch (operation.type) {
        case NOTCH:
        case COUPE:
        case DRILL_HOLE:
        case RECTANGULAR_CUT_OUT:
        case ANCHOR_HOLE:
          // These have a position saved in the database
          position = operation.position;
          break;
        case FINISHED_SIDE:
        case CHISELED_SIDE:
        case WATERLIST:
          // These do not have a position
          break;
        case CORNER_CUTOUT:
          position = VectorHelper.getCornerCutoutPosition(pieceType, piece.dimensions, operation);
          break;
        case ROUNDED_CORNER:
          position = VectorHelper.getRoundedCornerPosition(piece.dimensions, operation);
          break;
        case GLUED_CUSHION:
          position = VectorHelper.getVectorForGluedCushion(piece.dimensions, operation, pieceType);
          break;
        case DEBASING_ROUGH:
          position = VectorHelper.getVectorForDebasingRough(piece.dimensions, operation);
          break;
        case RABAT:
          position = VectorHelper.getRabatPosition(piece.dimensions, operation);
          break;
        default:
          position = new Vector3();
          break;
      }
    }

    return position;
  }

  static parsePosition(positionData) {
    let position = new Vector3();

    if (positionData == null) return position;

    Object.keys(positionData).forEach(key => {
      let tempValue = parseFloat(positionData[key]);

      if (isNaN(tempValue)) positionData[key] = 0;

      positionData[key] = tempValue;
    });

    position.x = positionData.x;
    position.y = positionData.y;
    position.z = positionData.z;

    return position;
  }

  static parseRotation(rotationData) {
    let rotation = new Vector3();

    if (rotationData == null) return rotation;

    Object.keys(rotationData).forEach(key => {
      let tempValue = parseFloat(rotationData[key]);

      if (isNaN(tempValue)) rotationData[key] = 0;

      rotationData[key] = tempValue;
    });

    rotation.x = rotationData.x;
    rotation.y = rotationData.y;
    rotation.z = rotationData.z;

    return rotation;
  }

  static parseParts(parts) {
    if (parts == null) return [];

    return parts.map(part => {
      return new Part(part.id, part.length);
    });
  }

  static parseStringToTimestamp(date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    const month = date.toLocaleString(i18next.language, { month: 'long' });

    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

    const parsedDate = date.getDate() + ' ' + month + ' ' + date.getFullYear();
    const parsedTime = hours + ':' + minutes + ':' + seconds;

    return { date: parsedDate, time: parsedTime, milliseconds: date.getTime() };
  }

  static parseAddress(addressData) {
    return new Address(
      addressData.id,
      addressData.street,
      addressData.number,
      addressData.postal_code,
      addressData.city,
      addressData.country,
    );
  }

  static parseOrder(orderData) {
    let order = new Order(orderData.id, orderData.name);

    const configurations = orderData.configurations
      ? orderData.configurations.map(configuration => this.parseConfiguration(configuration, true))
      : [];

    const statuses = orderData.statuses
      ? orderData.statuses.map(statusData => {
          return this.parseOrderStatus(statusData);
        })
      : [];

    order.companyBranchId = orderData.company_branch_id;
    order.offerId = orderData.offer_id;
    order.comment = orderData.comment;
    order.contactPerson = Parser.parseUser(orderData.contact_person);
    order.creator = orderData.creator ? Parser.parseUser(orderData.creator) : null;
    order.handler = new User(orderData.handler_id);
    order.timestamp = this.parseStringToTimestamp(orderData.created_at);
    order.deliveryDate = this.parseDeliveryDate(orderData.delivery_date);
    order.deliveryType = orderData.delivery ? DELIVERY : COLLECT;
    order.paymentMethod = orderData.payment_method;
    order.address = orderData.address ? this.parseAddress(orderData.address) : null;
    order.configurations = configurations;
    order.outdoorConfigurations = this.parseOutdoorConfigurations(orderData.outdoor_configurations);
    order.statuses = statuses;
    order.price = parseToCommaSeparated(orderData.price, 2);
    order.margin = orderData.margin;
    order.vat = orderData.vat;
    order.customer = this.parseCustomer(orderData.customer);
    order.isModified = orderData.is_modified_in_adsolut;
    order.isCreatedByAdmin = orderData.is_created_by_admin;
    order.isVisible = orderData.is_visible;
    order.administrativeStatus = orderData.administrative_status;
    order.createdAt = moment(orderData.created_at);

    return order;
  }

  static parseOrderStatus(statusData) {
    const timeStamp = this.parseStringToTimestamp(statusData.created_at);

    return new OrderStatus(statusData.id, statusData.name, timeStamp);
  }

  static parseUser(userData) {
    if (!userData) return new User();

    let user = new User();
    user.id = userData.id;
    user.firstName = capitalizeString(userData.first_name);
    user.lastName = capitalizeString(userData.last_name);
    user.email = userData.email;
    user.phoneNumber = userData.phone_number;
    user.roles = userData.roles;
    user.company = userData.company ? this.parseCompany(userData.company) : null;
    user.companyBranch = userData.company_branch ? this.parseCompanyBranch(userData.company_branch) : null;
    user.isBanned = userData.is_banned;

    return user;
  }

  static parseCompany(companyData) {
    let company = new Company();

    company.id = companyData.id;
    company.name = companyData.name;
    company.vatNumber = companyData.vat_number;
    company.address = companyData.address;
    company.clientType = this.parseClientType(companyData.client_type);
    company.branches = companyData.branches
      ? companyData.branches.map(branchData => this.parseCompanyBranch(branchData))
      : [];
    company.defaultDeliveryOption = companyData.default_delivery_option;
    company.articleSubstitutionGroup = this.parseArticleSubstitutionGroup(companyData.article_substitution_group);
    company.logo = { name: '', file: '' };

    return company;
  }

  static parseCompanyBranch(branchData) {
    let branch = new CompanyBranch(branchData.id, branchData.name);
    branch.companyId = branchData.company_id;
    branch.relationCode = branchData.relation_code;
    branch.language = branchData.language;
    branch.canOrder = branchData.can_order;
    branch.canOrderStock = branchData.can_order_stock;

    branch.address = branchData.address ? this.parseAddress(branchData.address) : null;
    branch.users = branchData.users ? branchData.users.map(userData => this.parseUser(userData)) : [];
    branch.defaultDeliveryOption = branchData.default_delivery_option;
    branch.deliveryType = this.parseDeliveryType(branchData.delivery_type);
    branch.articleSubstitutionGroup = this.parseArticleSubstitutionGroup(branchData.article_substitution_group);

    return branch;
  }

  static parseClientType(clientTypeData) {
    const parsedCustomWork = parseFloat(clientTypeData.discount?.custom_work);
    const parsedSpecialOperations = parseFloat(clientTypeData.discount?.special_operations);

    const description = clientTypeData[i18next.language];

    const discount = {
      customWork: !isNaN(parsedCustomWork) ? parsedCustomWork : 0,
      specialOperations: !isNaN(parsedSpecialOperations) ? parsedSpecialOperations : 0,
    };

    return new ClientType(clientTypeData.id, clientTypeData.name, discount, description);
  }

  static parseArticleSubstitutionGroup(data) {
    if (!data) return new ArticleSubstitutionGroup();

    return new ArticleSubstitutionGroup(data.id, data.name);
  }

  static parseCustomer(customerData) {
    if (!customerData) return null;

    return new Customer(customerData.id, customerData.name);
  }

  static parseManufacturer(manufacturerData) {
    let manufacturer = new Manufacturer(manufacturerData.id, manufacturerData.name);

    if (manufacturerData.catalogs && manufacturerData.catalogs.length > 0) {
      manufacturer.catalogs = manufacturerData.catalogs.map(catalogData => {
        return this.parseCatalog(catalogData);
      });
    }

    if (manufacturerData.external_links && manufacturerData.external_links.length > 0) {
      manufacturer.links = manufacturerData.external_links.map(linkData => {
        return this.parseLink(linkData);
      });
    }

    return manufacturer;
  }

  static parseCatalog(catalogData) {
    return new Catalog(catalogData.id, catalogData.name, catalogData.file_name);
  }

  static parseLink(linkData) {
    let externalLink = new ExternalLink(linkData.id, linkData.name, linkData.link);

    if (!externalLink.link.includes('http://') || !externalLink.link.includes('https://')) {
      externalLink.link = 'http://' + externalLink.link;
    }

    return externalLink;
  }

  static parseGalleryImage(galleryImageData) {
    return new GalleryImage(galleryImageData.id, galleryImageData.name, this.parseTags(galleryImageData.tags));
  }

  static parseTags(tagsData) {
    if (!tagsData) return [];

    return tagsData.map(tagData => {
      return new Tag(tagData.id, tagData.name[Object.keys(tagData.name)[0]]);
    });
  }

  static parseDeliveryType(deliveryTypeData) {
    let deliveryType = new DeliveryType();

    if (!deliveryTypeData) return deliveryType;

    deliveryType.id = deliveryTypeData.id;
    deliveryType.name = deliveryTypeData.name;
    deliveryType.description = {
      nl: deliveryTypeData.description.nl,
      fr: deliveryTypeData.description.fr,
      en: deliveryTypeData.description.en,
    };
    deliveryType.dates = deliveryTypeData.delivery_dates
      ? deliveryTypeData.delivery_dates.map(dateData => this.parseDeliveryDate(dateData))
      : [];

    return deliveryType;
  }

  static parseDeliveryDate(deliveryDateData) {
    let deliveryDate = new DeliveryDate();

    if (!deliveryDateData) return deliveryDate;

    deliveryDate.id = deliveryDateData.id;
    deliveryDate.date = new Date(deliveryDateData.date);
    deliveryDate.status = deliveryDateData.status;
    deliveryDate.updatedAt = new Date(deliveryDateData.updated_at);

    return deliveryDate;
  }

  static parseConfigurationOptions(optionsData) {
    return new ConfigurationOptions(optionsData);
  }

  static parseConfigurationOperations(operationsData) {
    if (!operationsData || !operationsData.length) return [];

    return operationsData.map(operationData => {
      return {
        type: operationData.operationType ?? operationData.type,
        code: operationData.productCode ?? operationData.product_code,
        amount: operationData.amount,
        price: operationData.price,
      };
    });
  }

  static parseSetting(settingData) {
    return new Setting(settingData.id, settingData.key, settingData.value);
  }

  static parsePriceVersion(priceVersionData) {
    return new PriceVersion(
      priceVersionData.id,
      priceVersionData.version,
      new Date(priceVersionData.start),
      new Date(priceVersionData.end),
    );
  }

  static parsePriceString(price) {
    if (!price) return 0;

    if (typeof price === 'string') price = parseFloat(price);

    price = price.toFixed(2);

    return price.replace('.', ',');
  }

  static parseLog(logData) {
    const log = new Log();

    log.id = logData.id;
    log.name = logData.log_name;
    log.description = logData.description;
    log.subjectType = logData.subject_type;
    log.subjectId = logData.subject_id;
    log.causer = Parser.parseUser(logData.causer);
    log.properties = Parser.parseLogProperties(logData.properties);
    log.createdAt = moment(logData.created_at);

    return log;
  }

  static parseLogProperties(propertiesData) {
    const properties = new LogProperties();

    if (propertiesData.new) {
      properties.new = LogProperties.parse(propertiesData.new);
    }

    if (propertiesData.old) {
      properties.old = LogProperties.parse(propertiesData.old);
    }

    if (propertiesData.attributes) {
      properties.new = LogProperties.parse(propertiesData.attributes);
    }

    return properties;
  }

  static parseUserSettings(userSettingsData) {
    return new UserSettings(userSettingsData.id, userSettingsData.price_type);
  }
}

export { Parser };
