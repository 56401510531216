import {
  BufferAttribute,
  BufferGeometry,
  Color,
  DoubleSide,
  Line,
  LineBasicMaterial,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  ShapeBufferGeometry,
  SphereGeometry,
  Vector3,
} from 'three';
import * as ThreeColors from '../../../constants/ThreeConstants';
import { DISTANCE_TO_SHORTEN } from '../../../constants/MeasurementLineConstants';
import { BACK, FRONT, LEFT, RIGHT } from '../../../constants/ObjectSides';
import { getMiddlePointOfTwoPoints } from './SurfaceMeasurementLineHelper';
import { TOP } from '../../../constants/CameraAngles';
import { UPRIGHT_PRESETS } from '../../../constants/Presets';
import { round } from '../NumberHelper';
import { cloneDeep } from 'lodash';

export function createLineWithText(
  fontSettings,
  horizontalPoints,
  verticalPoints,
  { sideOfLine, preset, cameraAngle = TOP },
  linesAreShortened = true,
  rotate90Degrees = false,
) {
  let meshes = [];

  horizontalPoints.forEach(horizontalPoint => {
    if (horizontalPoint.visible) {
      const text = createLengthText(fontSettings, horizontalPoint, {
        side: sideOfLine ?? FRONT,
        cameraAngle: cameraAngle,
      });

      if (rotate90Degrees) {
        text.rotateX(MathUtils.degToRad(90));
        text.rotateY(MathUtils.degToRad(-45));
      }

      if (cameraAngle === LEFT) {
        if (UPRIGHT_PRESETS.includes(preset)) {
          text.rotateY(MathUtils.degToRad(-90));
        } else {
          text.rotateX(MathUtils.degToRad(90));
          text.rotateY(MathUtils.degToRad(-90));
        }
      } else if (cameraAngle === FRONT) {
        text.rotateX(MathUtils.degToRad(90));

        if (UPRIGHT_PRESETS.includes(preset)) {
          text.rotateX(MathUtils.degToRad(180));
        }
      }

      meshes.push(createLine(horizontalPoint), text);
    }
  });

  verticalPoints.forEach(verticalPoint => {
    if (verticalPoint.visible) {
      const text = createLengthText(fontSettings, verticalPoint, {
        side: sideOfLine ?? RIGHT,
        cameraAngle: cameraAngle,
      });

      if (cameraAngle === LEFT) {
        if (UPRIGHT_PRESETS.includes(preset)) {
          text.rotateY(MathUtils.degToRad(-90));
        } else {
          text.rotateX(MathUtils.degToRad(90));
          text.rotateY(MathUtils.degToRad(-90));
        }

        if (text.position.z > 0) {
          text.position.add(new Vector3(0, 0, 1));
        } else {
          text.position.sub(new Vector3(0, 0, 3));
        }
      } else if (cameraAngle === RIGHT) {
        text.rotateX(MathUtils.degToRad(90));
        text.rotateY(MathUtils.degToRad(90));
      } else if (cameraAngle === FRONT) {
        text.rotateX(MathUtils.degToRad(90));

        if (text.position.x < 0) {
          text.position.sub(new Vector3(1, 0, 0));
        } else {
          text.position.add(new Vector3(1, 0, 0));
        }

        if (UPRIGHT_PRESETS.includes(preset)) {
          text.rotateX(MathUtils.degToRad(180));
        }
      }

      meshes.push(createLine(verticalPoint), text);
    }
  });

  return meshes;
}

export function createLine(points) {
  if (!points.visible) return;

  const lineMaterial = new LineBasicMaterial({ color: ThreeColors.MEASUREMENT_LINE });

  let geometry = new BufferGeometry();
  let positions = new Float32Array(2 * 3);

  positions[0] = points.pointA.x;
  positions[1] = points.pointA.y;
  positions[2] = points.pointA.z;

  positions[3] = points.pointB.x;
  positions[4] = points.pointB.y;
  positions[5] = points.pointB.z;

  geometry.setAttribute('position', new BufferAttribute(positions, 3));
  geometry.setDrawRange(0, positions.length);

  const line = new Line(geometry, lineMaterial);

  return addDotsToLine(line, points);
}

function createLengthText(fontSettings, point, { side, cameraAngle }) {
  if (!point.visible) return;

  let lineLength = point.lineLength ?? getLineLength(point);
  const settings = cloneDeep(fontSettings);

  if (point.lineLength) settings.multiplier = { length: 1, width: 1 };

  let text = createTextMesh(settings, lineLength, side);

  return addPositionToText(text, point, cameraAngle);
}

function addDotsToLine(line, points) {
  const dotMaterial = new MeshBasicMaterial({ color: ThreeColors.MEASUREMENT_LINE });

  const startingDot = new Mesh(new SphereGeometry(0.3), dotMaterial);
  startingDot.position.add(points.pointA);

  const endingDot = new Mesh(new SphereGeometry(0.3), dotMaterial);
  endingDot.position.add(points.pointB);

  const lineWithDots = new Object3D();
  lineWithDots.add(line, startingDot, endingDot);

  return lineWithDots;
}

export function getLineLength(point) {
  let distanceToShorten = DISTANCE_TO_SHORTEN * 2;

  let lineLength = point.pointA.distanceTo(point.pointB);

  if (point.isShortened) {
    lineLength += distanceToShorten;
  }

  return lineLength;
}

function addPositionToText(text, point, cameraAngle) {
  let textPosition;
  const side = point.textSide;
  const textDimensions = getTextDimensions(text);

  const lineDirection = getLineDirection(point.pointA, point.pointB);
  const textDirection =
    cameraAngle === TOP
      ? getTextDirectionForTextOnSurface(lineDirection, side)
      : getTextDirectionForTextOnSide(lineDirection, side);

  const middlePoint = getMiddlePointOfTwoPoints(point.pointA, point.pointB);
  middlePoint.y += 0.05;
  const distanceFromLine = getDistanceFromLine(lineDirection, side, point, textDimensions);

  textPosition = new Vector3().addVectors(middlePoint, textDirection.multiplyScalar(distanceFromLine));

  text.position.add(centerText(textDimensions, textPosition));

  return text;
}

function getTextDimensions(text) {
  const textBoundingBox = text.geometry.boundingBox;

  return {
    length: Math.abs(textBoundingBox.min.x - textBoundingBox.max.x),
    width: Math.abs(textBoundingBox.min.y - textBoundingBox.max.y),
  };
}

function createTextMesh(fontSettings, lineLength, side) {
  if (fontSettings.multiplier) {
    if ([LEFT, RIGHT].includes(side)) {
      lineLength /= fontSettings.multiplier.width;
    } else {
      lineLength /= fontSettings.multiplier.length;
    }
  }

  lineLength = round(lineLength);

  // Parse lineLength to string, otherwise there will be no text shown
  let shapes = fontSettings.type.generateShapes(lineLength + '', fontSettings.size);

  let geometry = new ShapeBufferGeometry(shapes);
  geometry.computeBoundingBox();

  let textMaterial = new MeshStandardMaterial({
    color: new Color(ThreeColors.TEXT),
    transparent: false,
    opacity: 0.4,
    side: DoubleSide,
  });

  // make shape ( N.B. edge view not visible )
  let text = new Mesh(geometry, textMaterial);
  text.rotateX(MathUtils.degToRad(-90));

  return text;
}

function getLineDirection(pointA, pointB) {
  const x = pointB.x - pointA.x;
  const y = pointB.y - pointA.y;
  const z = pointB.z - pointA.z;

  return new Vector3(x, y, z).normalize();
}

function getTextDirectionForTextOnSurface({ x, y, z }, side) {
  let xDir, zDir;
  let yDir = 0;

  // ( 1, 0, 1 ) RIGHT DOWN
  if (x > 0 && z > 0) {
    if (side === FRONT) {
      xDir = x;
      zDir = -1;
    } else if (side === BACK) {
      xDir = -1;
      zDir = z;
    } else if (side === LEFT) {
      xDir = -1;
      zDir = -1;
    } else {
      // RIGHT
      xDir = x;
      zDir = z;
    }
  }

  // ( 1, 0, -1 ) RIGHT UP
  if (x > 0 && z < 0) {
    if (side === FRONT) {
      xDir = -1;
      zDir = z;
    } else if (side === BACK) {
      xDir = x;
      zDir = 1;
    } else if (side === LEFT) {
      xDir = -1;
      zDir = 1;
    } else {
      // RIGHT
      xDir = x;
      zDir = z;
    }
  }

  // ( -1, 0, -1 ) LEFT UP
  if (x < 0 && z < 0) {
    if (side === FRONT) {
      xDir = 1;
      zDir = z;
    } else if (side === BACK) {
      xDir = x;
      zDir = 1;
    } else if (side === RIGHT) {
      xDir = 1;
      zDir = 1;
    } else {
      // LEFT
      xDir = x;
      zDir = z;
    }
  }

  // ( -1, 0, 1 ) LEFT DOWN
  if (x < 0 && z > 0) {
    if (side === FRONT) {
      xDir = x;
      zDir = 1;
    } else if (side === BACK) {
      xDir = 1;
      zDir = z;
    } else if (side === RIGHT) {
      xDir = 1;
      zDir = -1;
    } else {
      // LEFT
      xDir = x;
      zDir = z;
    }
  }

  // (1, 0, 0) LEFT TO RIGHT
  if (x > 0 && z === 0) {
    if (side === FRONT) {
      xDir = 0;
      zDir = 1;
    } else if (side === BACK) {
      xDir = 0;
      zDir = -1;
    } else if (side === LEFT) {
      xDir = -1;
      zDir = 0;
    } else {
      // RIGHT
      xDir = x;
      zDir = z;
    }
  }

  // (-1, 0, 0) RIGHT TO LEFT
  if (x < 0 && z === 0) {
    if (side === FRONT) {
      xDir = 0;
      zDir = -1;
    } else if (side === BACK) {
      xDir = 0;
      zDir = 1;
    } else if (side === LEFT) {
      xDir = 1;
      zDir = 0;
    } else {
      // LEFT
      xDir = x;
      zDir = z;
    }
  }

  // (0, 0, 1) BACK TO FRONT
  if (x === 0 && z > 0) {
    if (side === FRONT) {
      xDir = -1;
      zDir = 0;
    } else if (side === BACK) {
      xDir = 1;
      zDir = 0;
    } else if (side === LEFT) {
      xDir = 0;
      zDir = -1;
    } else {
      // LEFT
      xDir = x;
      zDir = z;
    }
  }

  // (0, 0, -1) FRONT TO BACK
  if (x === 0 && z < 0) {
    if (side === FRONT) {
      xDir = 1;
      zDir = 0;
    } else if (side === BACK) {
      xDir = -1;
      zDir = 0;
    } else if (side === LEFT) {
      xDir = 0;
      zDir = 1;
    } else {
      // RIGHT
      xDir = x;
      zDir = z;
    }
  }

  // (0, -1, 0) TOP TO BOTTOM
  if (y < 0) {
    if (side === FRONT) {
      xDir = 1;
      zDir = 0;
    } else if (side === BACK) {
      xDir = -1;
      zDir = 0;
    } else if (side === RIGHT) {
      xDir = 0;
      yDir = 1;
      zDir = 0;
    } else {
      // RIGHT
      xDir = 0;
      yDir = y;
      zDir = 0;
    }
  }

  return new Vector3(xDir, yDir, zDir).normalize();
}

function getTextDirectionForTextOnSide({ x, y, z }, side) {
  let xDir = 0,
    zDir = 0;
  let yDir = 0;

  // ( 1, 0, 0 ) RIGHT
  if (x > 0) {
    if (side === FRONT) {
      yDir = -1;
    } else if (side === BACK) {
      yDir = 1;
    } else if (side === LEFT) {
      xDir = -1;
    } else {
      // RIGHT
      xDir = 1;
    }
  }

  // ( 0, 1, 0 ) UP
  if (y > 0) {
    if (side === FRONT) {
      xDir = 1;
    } else if (side === BACK) {
      xDir = -1;
    } else if (side === LEFT) {
      yDir = -1;
    } else {
      // RIGHT
      yDir = 1;
    }
  }

  // ( 0, -1, 0 ) DOWN
  if (y < 0) {
    if (side === FRONT) {
      zDir = -1;
    } else if (side === BACK) {
      zDir = 1;
    } else if (side === LEFT) {
      yDir = 1;
    } else {
      // RIGHT
      yDir = -1;
    }
  }

  // (0, 0, 1) BACK TO FRONT
  if (z > 0) {
    if (side === FRONT) {
      yDir = -1;
    } else if (side === BACK) {
      yDir = 1;
    } else if (side === LEFT) {
      zDir = -1;
    } else {
      // RIGHT
      zDir = 1;
    }
  }

  return new Vector3(xDir, yDir, zDir).normalize();
}

function getDistanceFromLine(direction, side, { pointA, pointB }, { length, width }) {
  let distanceFromLine;

  if (direction.x !== 0) {
    distanceFromLine = width;
  } else {
    distanceFromLine = length;
  }

  return distanceFromLine;
}

function centerText(textDimensions, textPosition) {
  return textPosition.sub(new Vector3(textDimensions.length / 2, 0, textDimensions.width / -2));
}
