import MeasurementPoints from '../../../models/MeasurementPoints';
import { BACK, FRONT, LEFT, RIGHT, TOP } from '../../../../constants/ObjectSides';
import { Vector3 } from 'three';
import { BASEBOARDS } from '../../../../constants/Presets';
import { distanceFromSide, getVerticalPointsTextSide } from '../SideMeasurementLineHelper';

export function getPointsForProfile(pieceDimensions, profile, preset) {
  const horizontalPoints = new MeasurementPoints(
    getHorizontalPointA(pieceDimensions, profile),
    getHorizontalPointB(pieceDimensions, profile),
  );

  horizontalPoints.textSide = BACK;

  const verticalPoints = new MeasurementPoints(
    getVerticalPointA(pieceDimensions, profile),
    getVerticalPointB(pieceDimensions, profile),
  );

  verticalPoints.textSide = getVerticalPointsTextSide(verticalPoints);
  verticalPoints.lineLength = profile.dimensions.height;

  const remainingPieceVerticalPoints = new MeasurementPoints(
    getRemainingVerticalPointA(pieceDimensions, profile),
    getRemainingVerticalPointB(pieceDimensions, profile),
  );

  remainingPieceVerticalPoints.textSide = getVerticalPointsTextSide(remainingPieceVerticalPoints);

  const pieceHeightVerticalPoints =
    preset === BASEBOARDS
      ? new MeasurementPoints(
          getPieceHeightVerticalPointA(pieceDimensions),
          getPieceHeightVerticalPointB(pieceDimensions),
        )
      : null;

  let horizontal = [horizontalPoints];
  // Remove when not needed anymore - difficult to render these dimensions if a profile is rendered top and bottom
  // - The remainingPieceVerticalPoints would be rendered AND the profile points
  // let vertical = [verticalPoints, remainingPieceVerticalPoints];
  let vertical = [verticalPoints];

  if (pieceHeightVerticalPoints) {
    pieceHeightVerticalPoints.textSide = getVerticalPointsTextSide(pieceHeightVerticalPoints);
    vertical.push(pieceHeightVerticalPoints);
  }

  return {
    horizontal: horizontal,
    vertical: vertical,
  };
}

function getVerticalPointA(pieceDimensions, profile) {
  const xPos = getXPosBySide(pieceDimensions, profile.side);
  const zPos = getZPosBySide(pieceDimensions, profile.side);
  let yPos;

  if (profile.data === TOP) {
    yPos = pieceDimensions.height / 2 - profile.dimensions.height;
  } else {
    yPos = pieceDimensions.height / -2 + profile.dimensions.height;
  }

  return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointB(pieceDimensions, profile) {
  const xPos = getXPosBySide(pieceDimensions, profile.side);
  const zPos = getZPosBySide(pieceDimensions, profile.side);
  let yPos;

  if (profile.data === TOP) {
    yPos = pieceDimensions.height / 2;
  } else {
    yPos = pieceDimensions.height / -2;
  }

  return new Vector3(xPos, yPos, zPos);
}

function getRemainingVerticalPointA(pieceDimensions, profile) {
  const xPos = getXPosBySide(pieceDimensions, profile.side);
  const yPos = getYPosForRemainingPiecePointA(pieceDimensions, profile);
  const zPos = getZPosBySide(pieceDimensions, profile.side);

  return new Vector3(xPos, yPos, zPos);
}

function getRemainingVerticalPointB(pieceDimensions, profile) {
  const xPos = getXPosBySide(pieceDimensions, profile.side);
  const yPos = getYPosForRemainingPiecePointB(pieceDimensions, profile);
  const zPos = getZPosBySide(pieceDimensions, profile.side);

  return new Vector3(xPos, yPos, zPos);
}

function getHorizontalPointA(pieceDimensions, profile) {
  const xPos = getXPosForHorizontalPointA(pieceDimensions, profile);
  const yPos = pieceDimensions.height / 2 + distanceFromSide;
  const zPos = getZPosForHorizontalPointA(pieceDimensions, profile);

  return new Vector3(xPos, yPos, zPos);
}

function getHorizontalPointB(pieceDimensions, profile) {
  const xPos = getXPosForHorizontalPointB(pieceDimensions, profile);
  const yPos = pieceDimensions.height / 2 + distanceFromSide;
  const zPos = getZPosForHorizontalPointB(pieceDimensions, profile);

  return new Vector3(xPos, yPos, zPos);
}

function getXPosBySide(pieceDimensions, side) {
  let xPos;

  switch (side) {
    case FRONT:
    case LEFT:
    case BACK:
      xPos = pieceDimensions.length / -2 - distanceFromSide;
      break;
    case RIGHT:
      xPos = pieceDimensions.length / 2 + distanceFromSide;
      break;
  }

  return xPos;
}

function getZPosBySide(pieceDimensions, side) {
  let zPos;

  switch (side) {
    case FRONT:
    case LEFT:
    case RIGHT:
      zPos = pieceDimensions.width / 2 + distanceFromSide;
      break;
    case BACK:
      zPos = pieceDimensions.width / -2 - distanceFromSide;
      break;
  }

  return zPos;
}

function getZPosForHorizontalPointA(pieceDimensions, profile) {
  let zPos;

  switch (profile.side) {
    case FRONT:
    case LEFT:
    case RIGHT:
      zPos = pieceDimensions.width / 2;
      break;
    case BACK:
      zPos = pieceDimensions.width / -2;
      break;
  }

  return zPos;
}

function getZPosForHorizontalPointB(pieceDimensions, profile) {
  let zPos;

  switch (profile.side) {
    case FRONT:
      zPos = pieceDimensions.width / 2 - profile.dimensions.width;
      break;
    case BACK:
      zPos = pieceDimensions.width / -2 + profile.dimensions.width;
      break;
    case LEFT:
    case RIGHT:
      // Same z as for point A
      zPos = pieceDimensions.width / 2;
      break;
  }

  return zPos;
}

function getXPosForHorizontalPointA(pieceDimensions, profile) {
  let xPos;

  switch (profile.side) {
    case FRONT:
    case BACK:
    case LEFT:
      xPos = pieceDimensions.length / -2;
      break;
    case RIGHT:
      // Same z as for point A
      xPos = pieceDimensions.length / 2 - profile.dimensions.width;
      break;
  }

  return xPos;
}

function getXPosForHorizontalPointB(pieceDimensions, profile) {
  let xPos;

  switch (profile.side) {
    case FRONT:
    case BACK:
      xPos = pieceDimensions.length / -2;
      break;
    case LEFT:
      xPos = pieceDimensions.length / -2 + profile.dimensions.width;
      break;
    case RIGHT:
      // Same z as for point A
      xPos = pieceDimensions.length / 2;
      break;
  }

  return xPos;
}

function getYPosForRemainingPiecePointA(pieceDimensions, profile) {
  return pieceDimensions.height / -2;
}

function getYPosForRemainingPiecePointB(pieceDimensions, profile) {
  return pieceDimensions.height / 2 - profile.dimensions.height;
}

function getPieceHeightVerticalPointA(pieceDimensions) {
  const xPos = pieceDimensions.length / -2;
  const yPos = pieceDimensions.height / -2;
  const zPos = pieceDimensions.width / 2 + distanceFromSide;

  return new Vector3(xPos, yPos, zPos);
}

function getPieceHeightVerticalPointB(pieceDimensions) {
  const xPos = pieceDimensions.length / -2;
  const yPos = pieceDimensions.height / 2;
  const zPos = pieceDimensions.width / 2 + distanceFromSide;

  return new Vector3(xPos, yPos, zPos);
}
