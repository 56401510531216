import {
  DEBASING_ROUGH,
  GLUED_CUSHION,
  HEIGHT_COUPE,
  NOTCH_OVER_LENGTH,
  PROFILE,
  RABAT,
} from '../../../constants/OperationTypes';
import { getPointsForDebasingRough } from './operations/DebasingRoughMeasurementLineHelper';
import { getPointsForProfile } from './operations/ProfileMeasurementLineHelper';
import { getPointsForHeightCoupe } from './operations/HeightCoupeMeasurementLineHelper';
import { getPointsForRabat } from './operations/RabatMeasurementLineHelper';
import { BACK, BOTTOM, FRONT, TOP } from '../../../constants/ObjectSides';
import { Vector3 } from 'three';
import MeasurementPoints from '../../models/MeasurementPoints';
import { getPointsForNotchOverLength } from './operations/NotchOverLengthMeasurementLineHelper';
import { getPointsForGluedCushionsOnSide } from './operations/GluedCushionSideMeasurementLineHelper';

export const distanceFromSide = 1;

export function getHorizontalPointsTextSide({ pointA }) {
  if (pointA.y > 0) return BACK;
  if (pointA.y < 0) return FRONT;
}

export function getVerticalPointsTextSide({ pointA }) {
  if (pointA.x > 0) return FRONT;
  if (pointA.x < 0) return BACK;
}

export function getSidePointsForOperation(piece, operation, preset) {
  let points;

  switch (operation.type) {
    case DEBASING_ROUGH:
      points = getPointsForDebasingRough(piece.dimensions, operation);
      break;
    case PROFILE:
      points = getPointsForProfile(piece.dimensions, operation, preset);
      break;
    case HEIGHT_COUPE:
      points = getPointsForHeightCoupe(piece, operation, preset);
      break;
    case RABAT:
      points = getPointsForRabat(piece.dimensions, operation);
      break;
    case NOTCH_OVER_LENGTH:
      points = getPointsForNotchOverLength(piece.dimensions, operation);
      break;
    case GLUED_CUSHION:
      points = getPointsForGluedCushionsOnSide(piece.dimensions, operation);
      break;
    default:
      points = {
        horizontal: null,
        vertical: null,
      };
  }

  return points;
}

export function getPointsForEmptySpacesBetweenOperationsForFrontSide(pieceDimensions, horizontalPoints) {
  let pointsBetweenOperations = [];

  const horizontalPointsTop = horizontalPoints.filter(points => determineSideOfLine(points) === TOP);
  const horizontalPointsBottom = horizontalPoints.filter(points => determineSideOfLine(points) === BOTTOM);

  pointsBetweenOperations.push(...getPointsForEmptySpaces(horizontalPointsTop, pieceDimensions, TOP));
  pointsBetweenOperations.push(...getPointsForEmptySpaces(horizontalPointsBottom, pieceDimensions, BOTTOM));

  // Add the text side for each new point, this is only for horizontal points for now
  pointsBetweenOperations = pointsBetweenOperations.map(points => {
    points.textSide = getHorizontalPointsTextSide(points);

    return points;
  });

  return pointsBetweenOperations;
}

function getPointsForEmptySpaces(horizontalPoints, pieceDimensions, sideOfLine) {
  let pointsBetweenOperations = [];
  horizontalPoints.sort((pointsA, pointsB) => {
    return pointsA.pointA.x - pointsB.pointA.x;
  });

  horizontalPoints.forEach((measurementPoint, index) => {
    if (index === 0) {
      const firstPointOfLine = getFirstPointOfLine(measurementPoint, pieceDimensions, sideOfLine);
      if (firstPointOfLine) pointsBetweenOperations.push(firstPointOfLine);
    }

    if (index === horizontalPoints.length - 1) {
      const lastPoint = getLastPointOfLine(measurementPoint, pieceDimensions, sideOfLine);
      if (lastPoint) pointsBetweenOperations.push(lastPoint);
    }
  });

  if (horizontalPoints.length > 1) {
    const middlePoint = getMiddlePoint(horizontalPoints[0], horizontalPoints[horizontalPoints.length - 1]);
    pointsBetweenOperations.push(middlePoint);
  }

  if (!horizontalPoints.length) {
    pointsBetweenOperations.push(getPointsForWholeFrontSide(pieceDimensions, sideOfLine));
  }

  return pointsBetweenOperations;
}

function getFirstPointOfLine(measurementPoints, pieceDimensions, side) {
  if (firstPointIsBeginningOfLine(measurementPoints, pieceDimensions)) return null;

  const xPos = pieceDimensions.length / -2;
  const yPos =
    side === TOP ? pieceDimensions.height / 2 + distanceFromSide : pieceDimensions.height / -2 - distanceFromSide;
  const zPos = pieceDimensions.width / 2;

  const firstPoint = new Vector3(xPos, yPos, zPos);

  return new MeasurementPoints(firstPoint, measurementPoints.pointA);
}

function getMiddlePoint(firstPoint, lastPoint) {
  return new MeasurementPoints(firstPoint.pointB, lastPoint.pointA);
}

function getLastPointOfLine(measurementPoints, pieceDimensions, side) {
  if (lastPointIsEndingOfLine(measurementPoints, pieceDimensions)) return null;

  const xPos = pieceDimensions.length / 2;
  const yPos =
    side === TOP ? pieceDimensions.height / 2 + distanceFromSide : pieceDimensions.height / -2 - distanceFromSide;
  const zPos = pieceDimensions.width / 2;

  const lastPoint = new Vector3(xPos, yPos, zPos);

  return new MeasurementPoints(measurementPoints.pointB, lastPoint);
}

function getPointsForWholeFrontSide({ length, width, height }, sideOfLine) {
  const xPosA = length / -2;
  const xPosB = length / 2;
  const yPos = sideOfLine === TOP ? height / 2 + distanceFromSide : height / -2 - distanceFromSide;
  const zPos = width / 2;

  return new MeasurementPoints(new Vector3(xPosA, yPos, zPos), new Vector3(xPosB, yPos, zPos));
}

function firstPointIsBeginningOfLine({ pointA }, { length }) {
  return pointA.x === length / -2;
}

function lastPointIsEndingOfLine({ pointB }, { length }) {
  return pointB.x === length / 2;
}

function determineSideOfLine({ pointA }) {
  return pointA.y < 0 ? BOTTOM : TOP;
}
