import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Company from '../../../../classes/models/Company';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../../constants/TranslationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { addAlertMessage, setWindowIsLoading } from '../../../../actions/GeneralActions';
import { DANGER, SUCCESS } from '../../../../constants/Variants';
import { LoadingMessage } from '../../../messages/LoadingMessage';
import { UserModal } from '../../../modals/UserModal';
import { User } from '../../../../classes/models/User';
import ClientTypeService from '../../../../classes/services/ClientTypeService';
import { SectionTitle } from '../../../headers/SectionTitle';
import { EmptyPage } from '../../../empty/EmptyPage';
import { ClientTypeItem } from './ClientTypeItem';
import { DisabledInput } from '../../../forms/inputs/DisabledInput';
import { CompanyBranch } from '../../../../classes/models/CompanyBranch';
import { CompanyBranchModal } from '../../../modals/CompanyBranchModal';
import { CompanyBranchService } from '../../../../classes/services/CompanyBranchService';
import { captureException } from '@sentry/react';
import { LocalStorageHelper } from '../../../../classes/helpers/LocalStorageHelper';

export const CompanyDetail = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.admin.companyList.';
  const deliveryOptionsConstantsPrefix = 'constants.deliveryOptions.';

  const [clientTypes, setClientTypes] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userModalIsActive, setUserModalIsActive] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState();
  const [companyBranchModalIsActive, setCompanyBranchModalIsActive] = useState(false);
  const [externalContactPersons, setExternalContactPersons] = useState([]);

  const dispatch = useDispatch();

  const { windowIsLoading, currentUser } = useSelector(state => state.generalReducer);

  const clientTypeService = new ClientTypeService();
  const companyBranchService = new CompanyBranchService();

  useEffect(() => {
    dispatch(setWindowIsLoading(true));

    // TODO: The getClientTypes call should not be executed every time a CompanyDetail is loaded
    // These values do not change that quick to be called every time

    getClientTypes();
  }, []);

  useEffect(() => {
    if (selectedBranch) {
      companyBranchService
        .getContactPersons(selectedBranch.id)
        .then(response => {
          if (response.success) {
            setExternalContactPersons(response.data);
          }
        })
        .catch(e => {
          captureException(e);
        });
    }
  }, [selectedBranch?.id]);

  const getClientTypes = () => {
    clientTypeService
      .getAll()
      .then(response => {
        if (response.success) {
          setClientTypes(response.data);
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const createCompanyBranch = data => {
    companyBranchService
      .create(props.company.id, data)
      .then(response => {
        if (response.success) {
          let company = props.company;
          company.branches.push(response.data);

          props.updateCompanyState(company);
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        captureException(error);
        dispatch(addAlertMessage(DANGER, t(prefix + 'createCompanyBranchFailed')));
      });
  };

  const updateCompanyBranch = data => {
    const currentUserBranch = currentUser?.companyBranch;

    companyBranchService
      .update(props.company.id, selectedBranch.id, data)
      .then(response => {
        if (response.success) {
          let company = props.company;
          company.branches = company.branches.map(branch => {
            if (branch.id === response.data.id) {
              return response.data;
            }

            return branch;
          });

          // Update the language for the current user - when admins change their own branch's language
          if (selectedBranch.id === currentUserBranch.id && selectedBranch.language !== data.language) {
            LocalStorageHelper.setLanguage(data.language);
          }

          props.updateCompanyState(company);
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        captureException(error);
        dispatch(addAlertMessage(DANGER, t(prefix + 'updateCompanyBranchFailed')));
      });
  };

  const deleteCompanyBranch = () => {
    companyBranchService
      .delete(props.company.id, selectedBranch.id)
      .then(response => {
        if (response.success) {
          props.deleteCompanyBranch(props.company, selectedBranch);

          dispatch(addAlertMessage(SUCCESS, t(prefix + 'deleteCompanyBranchSuccess')));
          closeCompanyBranchModal();
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        captureException(error);
        dispatch(addAlertMessage(DANGER, t(prefix + 'deleteCompanyBranchFailed')));
      });
  };

  const addContactPerson = (companyBranch, user) => {
    companyBranchService
      .addContactPerson(companyBranch.id, user.id)
      .then(response => {
        if (response.success) {
          dispatch(addAlertMessage(SUCCESS, t(prefix + 'addContactPersonSuccess')));

          setExternalContactPersons(response.data);
        }
      })
      .catch(e => {
        captureException(e);

        dispatch(addAlertMessage(DANGER, t(prefix + 'addContactPersonFailed')));
      });
  };

  const deleteExternalContact = (companyBranch, contact) => {
    companyBranchService
      .deleteContactPerson(companyBranch.id, contact.id)
      .then(response => {
        if (response.success) {
          dispatch(addAlertMessage(SUCCESS, t(prefix + 'deleteContactPersonSuccess')));

          setExternalContactPersons(response.data);
        }
      })
      .catch(e => {
        captureException(e);

        dispatch(addAlertMessage(DANGER, t(prefix + 'deleteContactPersonFailed')));
      });
  };

  const openBranchModal = branch => {
    if (!branch) {
      branch = new CompanyBranch(0, '');
    }

    setSelectedBranch(branch);
    setCompanyBranchModalIsActive(true);
  };

  const openUserModal = user => {
    if (!user) {
      user = new User();
    }

    setSelectedUser(user);
    setUserModalIsActive(true);
  };

  const closeUserModal = user => {
    if (user) {
      let company = props.company;
      props.updateCompanyState(company);

      company.branches = company.branches.map(b => {
        if (b.id === selectedBranch.id) {
          if (b.users.find(u => u.id === user.id)) {
            // Update
            b.users = b.users.map(u => {
              if (u.id === user.id) {
                u = Object.assign(u, user);
              }

              return u;
            });
          } else {
            // Add
            b.users = b.users.concat([user]);
          }
        }

        return b;
      });
    }

    setSelectedUser(null);
    setUserModalIsActive(false);
  };

  const closeCompanyBranchModal = branchData => {
    if (branchData) {
      if (selectedBranch.id) {
        updateCompanyBranch(branchData);
      } else {
        createCompanyBranch(branchData);
      }
    }

    setCompanyBranchModalIsActive(false);
  };

  const getOrderRightsIcon = hasRights => {
    if (hasRights) {
      return <i className="material-icons color--green">check</i>;
    } else {
      return <i className="material-icons color--red">close</i>;
    }
  };

  const renderBranchList = () => {
    return (
      <table className="table--bordered m-b-2">
        <tbody>
          <tr>
            <th>{t(prefix + 'nameTableHeader')}</th>
            <th>{t(prefix + 'canOrderTableHeader')}</th>
            <th>{t(prefix + 'canOrderStockTableHeader')}</th>
          </tr>
          {props.company.branches.map(branch => {
            return (
              <tr className="pointer" key={branch.id} onClick={() => openBranchModal(branch)}>
                <td data-cy={`admin-CompanyBranch-${branch.id}`}>{branch.name}</td>
                <td>{getOrderRightsIcon(branch.canOrder)}</td>
                <td>{getOrderRightsIcon(branch.canOrderStock)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderDiscountTable = () => {
    return (
      <table className="table--bordered m-b-5">
        <tbody>
          <tr>
            <th>{t(prefix + 'clientTypeTableHeader')}</th>
            <th>{t(prefix + 'discountPiecesTableHeader')}</th>
            <th>{t(prefix + 'discountOperationsTableHeader')}</th>
          </tr>

          {clientTypes?.map(type => {
            return (
              <ClientTypeItem
                key={type.id}
                clientType={type.description}
                discountPieces={type.discount?.customWork}
                discountOperations={type.discount?.specialOperations}
              />
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <UserModal
        isActive={userModalIsActive}
        onClose={fields => closeUserModal(fields)}
        user={selectedUser}
        companyId={props.company.id}
        companyBranchId={selectedBranch?.id}
      />

      <CompanyBranchModal
        isActive={companyBranchModalIsActive}
        onClose={fields => closeCompanyBranchModal(fields)}
        onDelete={() => deleteCompanyBranch()}
        companyBranch={selectedBranch}
        contactPersons={externalContactPersons}
        onBranchUpdate={branch => setSelectedBranch(branch)}
        openUserModal={user => openUserModal(user)}
        onContactPersonAdd={(companyBranch, user) => addContactPerson(companyBranch, user)}
        onContactPersonDelete={(companyBranch, user) => deleteExternalContact(companyBranch, user)}
      />

      {windowIsLoading ? (
        <LoadingMessage variant="overlay" />
      ) : (
        <div className="p-l-4 p-r-4">
          <SectionTitle content={t(prefix + 'companyInformationTitle')} />
          <>
            <DisabledInput label={t(prefix + 'companyNameLabel')} content={props.company.name} />
            <DisabledInput label={t(prefix + 'vatNumberLabel')} content={props.company.vatNumber} />
            <DisabledInput label={t(prefix + 'addressLabel')} content={props.company.address} />
            <DisabledInput label={t(prefix + 'clientTypeLabel')} content={props.company.clientType.description} />
            <DisabledInput
              label={t(prefix + 'defaultOrderOptionLabel')}
              content={t(deliveryOptionsConstantsPrefix + props.company.defaultDeliveryOption)}
            />
            <DisabledInput
              label={t(prefix + 'articleSubstitutionGroupLabel')}
              content={props.company.articleSubstitutionGroup?.name ?? t(prefix + 'emptyArticleSubstitutionGroupItem')}
            />
          </>

          <div className="m-b-5">
            {props.company.branches.length ? (
              <>
                <SectionTitle content={t(prefix + 'branchesTitle')} />
                <div className="m-b-5">
                  {renderBranchList()}
                  <button
                    type="button"
                    className="button button--primary button--icon-before"
                    onClick={() => openBranchModal()}
                  >
                    <i className="material-icons">add</i> {t(prefix + 'addBranchButton')}
                  </button>
                </div>
              </>
            ) : (
              <EmptyPage
                title={t(prefix + 'emptyBranchListTitle')}
                content={t(prefix + 'emptyBranchListMessage')}
                buttonText={t(prefix + 'addBranchButton')}
                onButtonClick={() => openBranchModal()}
              />
            )}
          </div>

          <SectionTitle content={t(prefix + 'discountTableTitle')} />

          {renderDiscountTable()}
        </div>
      )}
    </>
  );
};

CompanyDetail.propTypes = {
  company: PropTypes.instanceOf(Company),
  updateCompanyState: PropTypes.func.isRequired,
  deleteCompanyBranch: PropTypes.func.isRequired,
};
