import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { ModalHolder } from './ModalHolder';

export const DebasingModal = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.debasingModal.';

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={props.onClose} animation={true} className="modal--prompt">
        <Modal.Header>
          <Modal.Title>{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(prefix + 'content')}</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="button button-blue" onClick={() => props.onClose()}>
            {t(prefix + 'closeButton')}
          </div>
        </Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

DebasingModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
