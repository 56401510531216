import * as React from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from '../../navigation/SearchBar';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { ADMIN, SALES, SUPERVISOR, USER } from '../../../constants/RoleNames';
import { CompanyBranchSelect } from '../CompanyBranchSelect';
import { HANDELED, NEEDS_REVIEW, UNHANDELED } from '../../../constants/AdministrativeStatuses';
import { NONE } from '../../../constants/Filters';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const ListControls = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const filterPrefix = 'constants.filters.';
  const administrativeStatusPrefix = 'constants.administrativeStatuses.';

  const getSearchBarStyling = () => {
    if (hasOneOfRoles(USER)) {
      return 'w-100';
    } else {
      return 'col-8 p-0';
    }
  };

  const getCompanyBranchSelectStyling = () => {
    const defaultStyling = 'p-0 p-l-1';

    if (hasOneOfRoles(SALES)) {
      return `w-100 ${defaultStyling}`;
    }

    return `col-6 ${defaultStyling}`;
  };

  return (
    <div className="list__controls m-l-2 m-r-2">
      <div className="row">
        <div className={getSearchBarStyling()}>
          <SearchBar onInput={input => props.onUpdateSearchKeyword(input)} />
        </div>

        <div className="col-4 p-0 row">
          {!hasOneOfRoles(USER) && (
            <div className={getCompanyBranchSelectStyling()}>
              <CompanyBranchSelect dataCy="orders-companyBranchSelect" branches={props.branches} />
            </div>
          )}

          {hasOneOfRoles([ADMIN, SUPERVISOR]) && (
            <div className="col-6 p-0 p-l-1">
              <select
                data-cy="administrativeFilter"
                value={props.filterBy}
                onChange={event => props.onUpdateFilter(event.target.value)}
              >
                <option value={''} disabled hidden>
                  Filter
                </option>
                <option value={NONE}>{t(filterPrefix + NONE)}</option>
                <option value={UNHANDELED}>{t(administrativeStatusPrefix + UNHANDELED)}</option>
                <option value={NEEDS_REVIEW}>{t(administrativeStatusPrefix + NEEDS_REVIEW)}</option>
                <option value={HANDELED}>{t(administrativeStatusPrefix + HANDELED)}</option>
              </select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ListControls.propTypes = {
  sortBy: PropTypes.string.isRequired,
  filterBy: PropTypes.string.isRequired,
  branches: PropTypes.array,
  onUpdateFilter: PropTypes.func.isRequired,
  onUpdateSearchKeyword: PropTypes.func.isRequired,
};
