import * as React from 'react';
import PropTypes from 'prop-types';
import { ValidationMessage } from '../../shared/ValidationMessage';

export const CheckBox = props => {
  return (
    <div className="m-l-3">
      <label>
        <input
          type="checkbox"
          name="checkbox"
          checked={props.checked}
          onChange={() => props.onChange()}
          disabled={props.disabled}
          data-cy={props.content}
        />
        {props.image ? <img src={props.image} alt={props.content} /> : null}
        {props.content ? props.content : ''}

        {props.children}
      </label>

      {props.smallText ? <ValidationMessage content={props.smallText} /> : null}
    </div>
  );
};

CheckBox.propTypes = {
  content: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  smallText: PropTypes.string,
  disabled: PropTypes.bool,
};

CheckBox.defaultProps = {
  isSelected: false,
};
