import {
  LEFT_BACK_UP,
  LEFT_DOWN,
  LEFT_FRONT_DOWN,
  LEFT_HORIZONTAL,
  LEFT_UP,
  RIGHT_BACK_UP,
  RIGHT_DOWN,
  RIGHT_FRONT_DOWN,
  RIGHT_HORIZONTAL,
  RIGHT_UP,
} from '../../constants/ConnectObjectStyles';
import {
  applyOffsetToPoints,
  getPointsForEmptySpacesBetweenOperations,
  getPointsForOperation,
} from '../helpers/measurementLines/SurfaceMeasurementLineHelper';
import { BACK, FRONT, LEFT, RIGHT } from '../../constants/ObjectSides';
import { getPointsForWholeLengthOfSide } from '../helpers/measurementLines/PieceMeasurementLineHelper';
import { createLineWithText } from '../helpers/measurementLines/LineHelper';
import OperationsSorter from '../helpers/measurementLines/OperationsSorter';
import { calculateFontSizeByObjectLength } from '../helpers/ImageHelper2D';

class SurfaceMeasurementLineCreator {
  constructor() {
    this.__clearMemberVariables();
  }

  createMeasurementLines(object, operations, type, font, fontSize = null) {
    this.fontSettings = {
      type: font,
      size: fontSize ?? calculateFontSizeByObjectLength(object.dimensions.length),
      multiplier: object.dimensionRatio,
    };

    if (object.dimensions.length < 1 && object.dimensions.width < 1) return [];

    let objectConnectedStyles = object.connectedObjects.map(connectedObject => {
      return connectedObject.style;
    });

    const FRONT_CONNECTION_STYLES = [LEFT_FRONT_DOWN, RIGHT_FRONT_DOWN];
    const LEFT_CONNECTION_STYLES = [LEFT_UP, LEFT_DOWN, LEFT_HORIZONTAL];
    const BACK_CONNECTION_STYLES = [LEFT_BACK_UP, RIGHT_BACK_UP];
    const RIGHT_CONNECTION_STYLES = [RIGHT_UP, RIGHT_DOWN, RIGHT_HORIZONTAL];

    const hasObjectsConnectedToFrontSide = objectConnectedStyles.some(connectionStyle =>
      FRONT_CONNECTION_STYLES.includes(connectionStyle),
    );
    const hasObjectsConnectedToLeftSide = objectConnectedStyles.some(connectionStyle =>
      LEFT_CONNECTION_STYLES.includes(connectionStyle),
    );
    const hasObjectsConnectedToBackSide = objectConnectedStyles.some(connectionStyle =>
      BACK_CONNECTION_STYLES.includes(connectionStyle),
    );
    const hasObjectsConnectedToRightSide = objectConnectedStyles.some(connectionStyle =>
      RIGHT_CONNECTION_STYLES.includes(connectionStyle),
    );

    this.__sortOperations(operations);

    //region Set points for the lines
    if (!hasObjectsConnectedToFrontSide) {
      this.__calculatePointsForFrontSide(object, type);
    }

    if (!hasObjectsConnectedToLeftSide) {
      this.__calculatePointsForLeftSide(object, type);
    }

    if (!hasObjectsConnectedToBackSide) {
      this.__calculatePointsForBackSide(object, type);
    }

    if (!hasObjectsConnectedToRightSide) {
      this.__calculatePointsForRightSide(object, type);
    }

    this.__calculatePointsForMiscellaneous(object, type);

    //endregion

    let linesWithText = this.__createLinesWithText();

    this.__clearMemberVariables();

    return linesWithText?.filter(line => line !== undefined);
  }

  __getLinesForFrontSideOperations(piece, type) {
    let horizontalPoints = [];
    let verticalPoints = [];

    // Set points for the lines of operations
    for (let i = 0; i < this.frontSideOperations.length; i++) {
      const operationPoints = getPointsForOperation(piece, type, this.frontSideOperations[i], FRONT);

      if (operationPoints.horizontal) horizontalPoints.push(operationPoints.horizontal);
      if (operationPoints.vertical) verticalPoints.push(operationPoints.vertical);
    }

    // Set points for the lines where there are no operations
    const frontPointsInBetweenOperations = getPointsForEmptySpacesBetweenOperations(piece, horizontalPoints, FRONT);

    let pointMap = new Map();
    pointMap.set('horizontalPoints', horizontalPoints.concat(frontPointsInBetweenOperations));
    pointMap.set('verticalPoints', verticalPoints);

    return pointMap;
  }

  __getLinesForLeftSideOperations(piece, type) {
    let horizontalPoints = [];
    let verticalPoints = [];

    // Set points for the lines of operations
    for (let i = 0; i < this.leftSideOperations.length; i++) {
      const operationPoints = getPointsForOperation(piece, type, this.leftSideOperations[i], LEFT);

      if (operationPoints.horizontal) horizontalPoints.push(operationPoints.horizontal);
      if (operationPoints.vertical) verticalPoints.push(operationPoints.vertical);
    }

    // Set points for the lines where there are no operations
    const pointsInBetweenOperations = getPointsForEmptySpacesBetweenOperations(piece, horizontalPoints, LEFT);

    let pointMap = new Map();
    pointMap.set('horizontalPoints', horizontalPoints.concat(pointsInBetweenOperations));
    pointMap.set('verticalPoints', verticalPoints);

    return pointMap;
  }

  __getLinesForBackSideOperations(backSideOperations, piece, type) {
    let horizontalPoints = [];
    let verticalPoints = [];

    // Set points for the lines of operations
    for (let i = 0; i < backSideOperations.length; i++) {
      const operationPoints = getPointsForOperation(piece, type, backSideOperations[i], BACK);

      if (operationPoints.horizontal) horizontalPoints.push(operationPoints.horizontal);
      if (operationPoints.vertical) verticalPoints.push(operationPoints.vertical);
    }

    // Set points for the lines where there are no operations
    const pointsInBetweenOperations = getPointsForEmptySpacesBetweenOperations(piece, horizontalPoints, BACK);

    let pointMap = new Map();
    pointMap.set('horizontalPoints', horizontalPoints.concat(pointsInBetweenOperations));
    pointMap.set('verticalPoints', verticalPoints);

    return pointMap;
  }

  __getLinesForRightSideOperations(piece, type) {
    let horizontalPoints = [];
    let verticalPoints = [];

    // Set points for the lines of operations
    for (let i = 0; i < this.rightSideOperations.length; i++) {
      const operationPoints = getPointsForOperation(piece, type, this.rightSideOperations[i], RIGHT);

      if (operationPoints.horizontal) horizontalPoints.push(operationPoints.horizontal);
      if (operationPoints.vertical) verticalPoints.push(operationPoints.vertical);
    }

    // Set points for the lines where there are no operations
    const pointsInBetweenOperations = getPointsForEmptySpacesBetweenOperations(piece, horizontalPoints, RIGHT);

    let pointMap = new Map();
    pointMap.set('horizontalPoints', horizontalPoints.concat(pointsInBetweenOperations));
    pointMap.set('verticalPoints', verticalPoints);

    return pointMap;
  }

  __getLinesForMiscellaneousOperations(piece, type) {
    let horizontalPoints = [];
    let verticalPoints = [];
    let horizontalPointsShortened = [];
    let verticalPointsShortened = [];

    // Set points for the lines of operations
    for (let i = 0; i < this.miscellaneousOperations.length; i++) {
      const operationPoints = getPointsForOperation(piece, type, this.miscellaneousOperations[i]);

      if (operationPoints.shortened) {
        if (operationPoints.horizontal) horizontalPointsShortened.push(operationPoints.horizontal);
        if (operationPoints.vertical) verticalPointsShortened.push(operationPoints.vertical);
      } else {
        if (operationPoints.horizontal) {
          if (operationPoints.horizontal.length) {
            horizontalPoints.push(...operationPoints.horizontal);
          } else {
            horizontalPoints.push(operationPoints.horizontal);
          }
        }
        if (operationPoints.vertical) {
          if (operationPoints.vertical.length) {
            verticalPoints.push(...operationPoints.vertical);
          } else {
            verticalPoints.push(operationPoints.vertical);
          }
        }
      }
    }

    let pointMap = new Map();
    pointMap.set('horizontalPoints', horizontalPoints);
    pointMap.set('verticalPoints', verticalPoints);

    pointMap.set('horizontalPointsShortened', horizontalPointsShortened);
    pointMap.set('verticalPointsShortened', verticalPointsShortened);

    return pointMap;
  }

  __sortOperations(operations) {
    const operationsSorter = new OperationsSorter();
    const sortedOperations = operationsSorter.sortOperationsBySide(operations);

    // Sort the operations by position
    this.frontSideOperations = sortedOperations.front;
    this.leftSideOperations = sortedOperations.left;
    this.backSideOperations = sortedOperations.back;
    this.rightSideOperations = sortedOperations.right;
    this.miscellaneousOperations = sortedOperations.miscellaneous;
    // endregion
  }

  __clearMemberVariables() {
    this.frontSideOperations = [];
    this.leftSideOperations = [];
    this.backSideOperations = [];
    this.rightSideOperations = [];
    this.miscellaneousOperations = [];

    this.frontSideHorizontalPoints = [];
    this.leftSideHorizontalPoints = [];
    this.backSideHorizontalPoints = [];
    this.rightSideHorizontalPoints = [];
    this.miscellaneousHorizontalPoints = [];
    this.miscellaneousHorizontalPointsShortened = [];

    this.frontSideVerticalPoints = [];
    this.leftSideVerticalPoints = [];
    this.backSideVerticalPoints = [];
    this.rightSideVerticalPoints = [];
    this.miscellaneousVerticalPoints = [];
    this.miscellaneousVerticalPointsShortened = [];
    this.extraDimensionPoints = [];
  }

  __calculatePointsForFrontSide(piece) {
    if (this.frontSideOperations.length === 0) {
      this.frontSideHorizontalPoints.push(getPointsForWholeLengthOfSide(piece, FRONT));
    } else {
      let pointsMap = this.__getLinesForFrontSideOperations(piece);
      this.frontSideHorizontalPoints = pointsMap.get('horizontalPoints');
      this.frontSideVerticalPoints = pointsMap.get('verticalPoints');
    }

    const pointsWithOffset = applyOffsetToPoints(this.frontSideHorizontalPoints, this.frontSideVerticalPoints, FRONT);

    this.frontSideHorizontalPoints = pointsWithOffset.horizontal;
    this.frontSideVerticalPoints = pointsWithOffset.vertical;
  }

  __calculatePointsForLeftSide(piece, type) {
    if (this.leftSideOperations.length === 0) {
      this.leftSideHorizontalPoints.push(getPointsForWholeLengthOfSide(piece, LEFT));
    } else {
      let pointMap = this.__getLinesForLeftSideOperations(piece, type);

      this.leftSideHorizontalPoints = pointMap.get('horizontalPoints');
      this.leftSideVerticalPoints = pointMap.get('verticalPoints');
    }

    const pointsWithOffset = applyOffsetToPoints(this.leftSideHorizontalPoints, this.leftSideVerticalPoints, LEFT);

    this.leftSideHorizontalPoints = pointsWithOffset.horizontal;
    this.leftSideVerticalPoints = pointsWithOffset.vertical;
  }

  __calculatePointsForBackSide(piece, type) {
    if (this.backSideOperations.length === 0) {
      this.backSideHorizontalPoints.push(getPointsForWholeLengthOfSide(piece, BACK));
    } else {
      let pointMap = this.__getLinesForBackSideOperations(this.backSideOperations, piece, type);
      this.backSideHorizontalPoints = pointMap.get('horizontalPoints');
      this.backSideVerticalPoints = pointMap.get('verticalPoints');
    }

    const pointsWithOffset = applyOffsetToPoints(this.backSideHorizontalPoints, this.backSideVerticalPoints, BACK);

    this.backSideHorizontalPoints = pointsWithOffset.horizontal;
    this.backSideVerticalPoints = pointsWithOffset.vertical;
  }

  __calculatePointsForRightSide(piece, type) {
    if (this.rightSideOperations.length === 0) {
      this.rightSideHorizontalPoints.push(getPointsForWholeLengthOfSide(piece, RIGHT));
    } else {
      let pointMap = this.__getLinesForRightSideOperations(piece, type);
      this.rightSideHorizontalPoints = pointMap.get('horizontalPoints');
      this.rightSideVerticalPoints = pointMap.get('verticalPoints');
    }

    const pointsWithOffset = applyOffsetToPoints(this.rightSideHorizontalPoints, this.rightSideVerticalPoints, RIGHT);

    this.rightSideHorizontalPoints = pointsWithOffset.horizontal;
    this.rightSideVerticalPoints = pointsWithOffset.vertical;
  }

  __calculatePointsForMiscellaneous(dimensions, type) {
    if (this.miscellaneousOperations.length > 0) {
      const pointMap = this.__getLinesForMiscellaneousOperations(dimensions, type);

      this.miscellaneousHorizontalPoints = pointMap.get('horizontalPoints');
      this.miscellaneousVerticalPoints = pointMap.get('verticalPoints');

      this.miscellaneousHorizontalPointsShortened = pointMap.get('horizontalPointsShortened');
      this.miscellaneousVerticalPointsShortened = pointMap.get('verticalPointsShortened');
    }
  }

  __createLinesWithText() {
    let linesWithText = [];

    linesWithText.push(
      ...createLineWithText(this.fontSettings, this.frontSideHorizontalPoints, this.frontSideVerticalPoints, {
        sideOfLine: FRONT,
      }),
    );

    linesWithText.push(
      ...createLineWithText(this.fontSettings, this.leftSideHorizontalPoints, this.leftSideVerticalPoints, {
        sideOfLine: LEFT,
      }),
    );

    linesWithText.push(
      ...createLineWithText(this.fontSettings, this.backSideHorizontalPoints, this.backSideVerticalPoints, {
        sideOfLine: BACK,
      }),
    );

    linesWithText.push(
      ...createLineWithText(this.fontSettings, this.rightSideHorizontalPoints, this.rightSideVerticalPoints, {
        sideOfLine: RIGHT,
      }),
    );

    linesWithText.push(
      ...createLineWithText(
        this.fontSettings,
        this.miscellaneousHorizontalPoints,
        this.miscellaneousVerticalPoints,
        {},
        false,
      ),
    );

    linesWithText.push(
      ...createLineWithText(
        this.fontSettings,
        this.miscellaneousHorizontalPointsShortened,
        this.miscellaneousVerticalPointsShortened,
        {},
        true,
      ),
    );

    return linesWithText;
  }
}

export { SurfaceMeasurementLineCreator };
