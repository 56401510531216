import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { SectionTitle } from '../headers/SectionTitle';
import { RadioButton } from '../forms/inputs/RadioButton';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { CheckboxWithIcon } from '../forms/inputs/CheckboxWithIcon';
import { ModalHolder } from './ModalHolder';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useForm } from 'react-hook-form';
import { LEFT, RIGHT, SIDES, SURFACES } from '../../constants/ObjectSides';
import { useDispatch, useSelector } from 'react-redux';
import { Operation } from '../../classes/models/Operation';
import { NOTCH_OVER_LENGTH } from '../../constants/OperationTypes';
import { createOperation, deleteOperation, updateOperation } from './index';
import { setConfirmationModal } from '../../actions/GeneralActions';
import { parseNumber, parseToCommaSeparated } from '../../classes/helpers/StringHelper';
import { VectorHelper } from '../../classes/helpers/VectorHelper';

export const NotchOverLengthModal = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.notchOverLengthModal.';
  const sidePrefix = 'constants.objectSides.';

  const { handleSubmit, register, errors, setValue } = useForm();

  const [selectedSurface, setSelectedSurface] = useState();
  const [selectedSide, setSelectedSide] = useState();
  const [notchWidth, setNotchWidth] = useState();
  const [notchHeight, setNotchHeight] = useState();
  const [distanceFromSide, setDistanceFromSide] = useState();
  const [isFinished, setIsFinished] = useState(false);

  const { canEdit } = useSelector(state => state.generalReducer);
  const { currentPiece, currentOperation } = useSelector(state => state.offerReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isActive && currentOperation) {
      setNotchWidth(parseToCommaSeparated(currentOperation.dimensions.width));
      setNotchHeight(parseToCommaSeparated(currentOperation.dimensions.height));
      setDistanceFromSide(parseToCommaSeparated(currentOperation.additionalDimension.value));
      setSelectedSide(currentOperation.side);
      setSelectedSurface(currentOperation.additionalDimension.type);
      setIsFinished(currentOperation.isFinished);
    } else {
      setNotchHeight('');
      setNotchWidth('');
      setDistanceFromSide('');
      setSelectedSide(SIDES[0]);
      setSelectedSurface(SURFACES[0]);
      setIsFinished(false);
    }
  }, [props.isActive]);

  useEffect(() => {
    setValue('width', notchWidth);
  }, [notchWidth]);

  useEffect(() => {
    setValue('height', notchHeight);
  }, [notchHeight]);

  useEffect(() => {
    setValue('distance', distanceFromSide);
  }, [distanceFromSide]);

  const createNotchOverLength = ({ width, height, distance }) => {
    let operation = new Operation(0, NOTCH_OVER_LENGTH);
    operation.isFinished = isFinished;
    operation.additionalDimension = { type: selectedSurface, value: parseNumber(distance) };
    operation.side = selectedSide;
    operation.dimensions = { width, height };
    operation.position = VectorHelper.getNotchOverLengthPosition(currentPiece?.dimensions, operation);

    createOperation(operation, () => props.onClose(true), t(prefix + 'createFailedMessage'));
  };

  const updateNotchOverLength = ({ width, height, distance }) => {
    currentOperation.isFinished = isFinished;
    currentOperation.additionalDimension = { type: selectedSurface, value: parseNumber(distance) };
    currentOperation.side = selectedSide;
    currentOperation.dimensions = { width, height };
    currentOperation.position = VectorHelper.getNotchOverLengthPosition(currentPiece?.dimensions, currentOperation);

    updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'updateFailedMessage'));
  };

  const deleteNotchOverLength = () => {
    deleteOperation(
      currentOperation,
      () => props.onClose(true),
      t(prefix + 'deleteSuccessMessage'),
      t(prefix + 'deleteFailedMessage'),
    );
  };

  const onClose = fields => {
    if (fields) {
      fields.width = parseNumber(fields.width);
      fields.height = parseNumber(fields.height);

      if (currentOperation) {
        updateNotchOverLength(fields);
      } else {
        createNotchOverLength(fields);
      }
    } else {
      props.onClose();
    }
  };

  const getMaxDistance = () => {
    if (!currentPiece) return 0;

    let maxDistance;

    if ([LEFT, RIGHT].includes(selectedSide)) {
      maxDistance = currentPiece.dimensions.length - parseNumber(notchWidth);
    } else {
      maxDistance = currentPiece.dimensions.width - parseNumber(notchWidth);
    }

    return maxDistance;
  };

  const openConfirmationModal = () => {
    dispatch(
      setConfirmationModal({
        isActive: true,
        content: t(prefix + 'confirmDeleteMessage'),
        onAccept: () => deleteNotchOverLength(),
      }),
    );
  };

  const renderFooterButtons = () => {
    let secondaryButtonStyle = 'button button--outline';
    let secondaryButtonContent = t(prefix + 'cancelButton');
    let secondaryButtonOnClick = () => onClose();

    if (currentOperation != null && canEdit) {
      secondaryButtonStyle = 'button button--danger';
      secondaryButtonContent = t(prefix + 'deleteButton');
      secondaryButtonOnClick = () => openConfirmationModal();
    }

    return (
      <>
        <button
          className="button button--primary"
          type="submit"
          form="notchOverLengthForm"
          disabled={Object.keys(errors).length}
        >
          {t(prefix + 'submitButton')}
        </button>

        {canEdit ? (
          <button type="button" className={secondaryButtonStyle} onClick={secondaryButtonOnClick}>
            {secondaryButtonContent}
          </button>
        ) : null}
      </>
    );
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={() => onClose()} animation>
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(prefix + 'description')}</p>

          <form id="notchOverLengthForm" onSubmit={handleSubmit(onClose)}>
            <SectionTitle content={t(prefix + 'surfaceTitle')} />

            {SURFACES.map(surface => {
              return (
                <RadioButton
                  key={`notchOverLengthSurfaceRadioButton${surface}`}
                  name="surfaceRadioButton"
                  content={t(sidePrefix + surface)}
                  checked={selectedSurface === surface}
                  onChange={() => setSelectedSurface(surface)}
                  disabled={!canEdit}
                />
              );
            })}

            <SectionTitle content={t(prefix + 'sideTitle')} />

            {SIDES.map(side => {
              return (
                <RadioButton
                  key={`notchOverLengthSideRadioButton${side}`}
                  name="sideRadioButton"
                  content={t(sidePrefix + side)}
                  checked={selectedSide === side}
                  onChange={() => setSelectedSide(side)}
                  disabled={!canEdit}
                />
              );
            })}

            <SectionTitle content={t(prefix + 'dimensionsTitle')} />

            <div className="row">
              <div className="col-4">
                <ValidatedInput
                  register={register}
                  name={'width'}
                  label={t(prefix + 'widthLabel')}
                  placeholder={t(prefix + 'widthPlaceholder')}
                  value={currentOperation?.dimensions.width}
                  disabled={!canEdit}
                  required
                  min={1}
                  max={currentPiece?.dimensions.width / 2}
                  error={errors.width}
                />
              </div>
              <div className="col-4">
                <ValidatedInput
                  register={register}
                  name={'height'}
                  label={t(prefix + 'heightLabel')}
                  placeholder={t(prefix + 'heightPlaceholder')}
                  value={currentOperation?.dimensions.height}
                  disabled={!canEdit}
                  required
                  min={1}
                  max={currentPiece?.dimensions.height / 2}
                  error={errors.height}
                />
              </div>
              <div className="col-4">
                <ValidatedInput
                  register={register}
                  name={'distance'}
                  label={t(prefix + 'distanceLabel')}
                  placeholder={t(prefix + 'distancePlaceholder')}
                  disabled={!canEdit}
                  required
                  min={0.5}
                  max={getMaxDistance()}
                  error={errors.distance}
                />
              </div>
            </div>

            <SectionTitle content={t(prefix + 'finishTitle')} />

            <CheckboxWithIcon
              content={t(prefix + 'finishedCheckboxContent')}
              checked={isFinished}
              onChange={() => setIsFinished(!isFinished)}
              disabled={!canEdit}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

NotchOverLengthModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
