import { Service } from './Service';
import { Parser } from '../helpers/Parser';

class GalleryImageService extends Service {
  async getImages() {
    return await this.authenticatedRequest('get', 'galleryimages').then(response => {
      if (response.data.success) {
        response.data.data = response.data.data.map(imageData => {
          return Parser.parseGalleryImage(imageData);
        });
      }

      return response.data;
    });
  }

  async getImageFile(id) {
    return await this.fileRequest('get', 'galleryimages/' + id)
      .then(response => {
        return new Blob([response.data], { type: 'image/*' });
      })
      .catch(error => {
        throw error;
      });
  }

  async getTags() {
    return await this.anonymousRequest('get', 'tags').then(response => {
      if (response.data.success) {
        response.data.data = Parser.parseTags(response.data.data);

        return response.data;
      }
    });
  }

  async create(file, tags) {
    let data = new FormData();
    data.append('file', file);
    data.append('tags', JSON.stringify(tags));

    return await this.authenticatedRequest('post', 'galleryimages', data).then(response => {
      if (response.data.success) response.data.data = Parser.parseGalleryImage(response.data.data);

      return response.data;
    });
  }

  async update(id, tags) {
    const data = {
      tags: tags,
    };

    return await this.authenticatedRequest('put', 'galleryimages/' + id, data).then(response => {
      if (response.data.success) {
        response.data.data = Parser.parseGalleryImage(response.data.data);
      }

      return response.data;
    });
  }

  async delete(id) {
    return await this.authenticatedRequest('delete', 'galleryimages/' + id).then(response => {
      return response.data;
    });
  }
}

export default GalleryImageService;
