import { Service } from './Service';

class PdfService extends Service {
  async getPdf(id, type, files, priceType) {
    const data = {
      priceType: priceType,
      id: id,
      type: type,
    };

    return await this.uploadMultipleFilesRequest('pdf/overview', files, data).then(response => {
      if (response.status === 200) {
        response.data = {
          data: response.data,
          success: true,
        };

        return response.data;
      } else {
        response.data = {
          data: response.data,
          success: false,
        };

        return response.data;
      }
    });
  }

  async getSummary(id, priceType) {
    const data = {
      // priceType: priceType,
      orderId: id,
    };

    return await this.fileRequest('get', 'pdf/summary', data).then(response => {
      if (response.status === 200) {
        response.data = {
          data: response.data,
          success: true,
        };

        return response.data;
      } else {
        response.data = {
          data: response.data,
          success: false,
        };

        return response.data;
      }
    });
  }
}

export { PdfService };
