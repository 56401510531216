import {
  CORNER_CUTOUT,
  COUPE,
  COUPE_OVER_LENGTH,
  DEBASING_ROUGH,
  DRILL_HOLE,
  GLUED_CUSHION,
  HEIGHT_COUPE,
  NOTCH,
  PROFILE,
  RABAT,
  RECTANGULAR_CUT_OUT,
  ROUNDED_CORNER,
  WATERLIST,
} from '../../constants/OperationTypes';
import { getXFieldValueByPosition, getYFieldValueByPosition, VectorHelper } from './VectorHelper';
import { LENGTH, WIDTH } from '../../constants/Dimensions';
import { FRONT, LEFT, RIGHT } from '../../constants/ObjectSides';
import { TYPE_1, TYPE_3 } from '../../constants/ProfileOperationTypes';
import { DEFAULT_DIMENSION_RATIO } from '../../constants/Values';
import { getHeightCoupeWidth } from './OperationHelper';

const MAX_LENGTH = 100;
const MAX_WIDTH = 60;

export function normalizePiece(configuration, piece) {
  if (piece.dimensions.length <= MAX_LENGTH && piece.dimensions.width <= MAX_WIDTH) return piece;

  piece = normalizePieceDimensions(piece);

  piece = updateParts(piece);

  piece.operations.forEach(o => {
    o = updateOperation(configuration, piece, o);
  });

  return piece;
}

function updateParts(piece) {
  piece.parts = piece.parts.map(part => {
    part.length *= piece.dimensionRatio.length;

    return part;
  });

  return piece;
}

function updateOperation(configuration, piece, operation) {
  switch (operation.type) {
    case CORNER_CUTOUT:
      operation = updateCornerCutout(piece, operation);
      break;
    case ROUNDED_CORNER:
      operation = updateRoundedCorner(piece, operation);
      break;
    case DEBASING_ROUGH:
      operation = updateDebasingRough(piece, operation);
      break;
    case NOTCH:
      operation = updateNotch(piece, operation);
      break;
    case COUPE:
      operation = updateCoupe(piece, operation);
      break;
    case DRILL_HOLE:
      operation = updateDrillHole(piece, operation);
      break;
    case RECTANGULAR_CUT_OUT:
      operation = updateRectangularCutout(piece, operation);
      break;
    case PROFILE:
      operation = updateProfile(configuration, piece, operation);
      break;
    case COUPE_OVER_LENGTH:
      operation = updateCoupeOverLength(piece, operation);
      break;
    case GLUED_CUSHION:
      operation = updateGluedCushion(piece, operation);
      break;
    case HEIGHT_COUPE:
      operation = updateHeightCoupe(configuration, piece, operation);
      break;
    case RABAT:
      operation = updateRabat(piece, operation);
      break;
    case WATERLIST:
      operation = updateWaterlist(piece, operation);
      break;
  }

  return operation;
}

function updateCornerCutout(piece, cornerCutout) {
  return updateOperationDimensions(cornerCutout, piece);
}

function updateRoundedCorner(piece, roundedCorner) {
  return roundedCorner;
}

function updateWaterlist(piece, waterlist) {
  return waterlist;
}

function updateDebasingRough(piece, debasingRough) {
  debasingRough.position = VectorHelper.getVectorForDebasingRough(piece.dimensions, debasingRough);

  return debasingRough;
}

function updateNotch(piece, notch) {
  notch = updateOperationDimensions(notch, piece);
  notch.additionalDimension.value *= piece.dimensionRatio.length;
  notch.position = VectorHelper.getVectorForNotch(piece.dimensions, notch);

  return notch;
}

function updateCoupe(piece, coupe) {
  coupe.dimensions.length *= piece.dimensionRatio.length;
  coupe.dimensions.width *= piece.dimensionRatio.width;
  coupe.position = VectorHelper.getVectorForCoupe(piece.dimensions, coupe);

  return coupe;
}

function updateDrillHole(piece, drillHole) {
  let distanceFromLeft =
    getXFieldValueByPosition(drillHole, piece.originalDimensions, LEFT) * piece.dimensionRatio.length;
  let distanceFromFront =
    getYFieldValueByPosition(drillHole, piece.originalDimensions, FRONT) * piece.dimensionRatio.width;

  drillHole = updateOperationDimensions(drillHole, piece, WIDTH);

  if (distanceFromLeft < drillHole.dimensions.length / 10) {
    distanceFromLeft = drillHole.dimensions.length / 10;
  }

  if (distanceFromFront < drillHole.dimensions.length / 10) {
    distanceFromFront = drillHole.dimensions.length / 10;
  }

  drillHole.position = VectorHelper.getDrillHolePosition(piece.dimensions, distanceFromLeft, distanceFromFront);

  return drillHole;
}

function updateRectangularCutout(piece, cutOut) {
  let distanceFromLeft = getXFieldValueByPosition(cutOut, piece.originalDimensions, LEFT) * piece.dimensionRatio.length;

  let distanceFromFront =
    getYFieldValueByPosition(cutOut, piece.originalDimensions, FRONT) * piece.dimensionRatio.width;

  if (distanceFromLeft > piece.dimensions.length - cutOut.dimensions.length) {
    distanceFromLeft -= cutOut.dimensions.length + 5;
  }

  const resizedCutoutLength = cutOut.dimensions.length * piece.dimensionRatio.length;
  const differenceResizedAndOriginalLength = Math.abs(cutOut.dimensions.length - resizedCutoutLength);

  const resizedCutOutWidth = cutOut.dimensions.width * piece.dimensionRatio.width;
  const differenceResizedAndOriginalWidth = Math.abs(cutOut.dimensions.width - resizedCutOutWidth);

  // The cutOut itself is not recalculated, it stays the same size, so we have to subtract the difference to place
  // it exactly in the same place when scaling
  distanceFromLeft -= differenceResizedAndOriginalLength / 2;
  distanceFromFront -= differenceResizedAndOriginalWidth / 2;

  cutOut.originalPosition = Object.assign({}, cutOut.position);

  cutOut.position = VectorHelper.getVectorForRectangularCutOut(
    piece.dimensions,
    cutOut,
    {
      horizontalSide: LEFT,
      horizontalValue: distanceFromLeft,
    },
    { verticalSide: FRONT, verticalValue: distanceFromFront },
  );

  return cutOut;
}

function updateProfile(configuration, piece, profile) {
  const dimensionToScaleWith = [LEFT, RIGHT].includes(profile.side) ? WIDTH : LENGTH;
  profile.dimensions.length *= piece.dimensionRatio[dimensionToScaleWith];

  profile.position = [TYPE_1, TYPE_3].includes(profile.additionalDimension.value)
    ? VectorHelper.getProfile3Position(profile.side, profile.dimensions.length, piece)
    : VectorHelper.getProfile4Position(profile, piece);

  return profile;
}

function updateCoupeOverLength(piece, coupeOverLength) {
  coupeOverLength = updateOperationDimensions(coupeOverLength, piece, WIDTH);
  coupeOverLength.position = VectorHelper.getCoupeOverLengthPosition(piece.dimensions, coupeOverLength);

  return coupeOverLength;
}

function updateGluedCushion(piece, gluedCushion) {
  gluedCushion.position = VectorHelper.getVectorForGluedCushion(piece.dimensions, gluedCushion, piece.type);

  return gluedCushion;
}

function updateHeightCoupe(configuration, piece, coupe) {
  coupe.dimensions.width = getHeightCoupeWidth(coupe, piece.dimensions) * piece.dimensionRatio.length;
  coupe.position = VectorHelper.getHeightCoupePosition(piece.dimensions, coupe, configuration.options.preset);

  return coupe;
}

function updateRabat({ dimensions }, rabat) {
  rabat.position = VectorHelper.getRabatPosition(dimensions, rabat);

  return rabat;
}

function normalizePieceDimensions(piece) {
  piece.originalDimensions = Object.assign({}, piece.dimensions);

  const aspectRatio = calculateAspectRatio(piece.dimensions.length, piece.dimensions.width);

  piece.dimensions.length = aspectRatio.length;
  piece.dimensions.width = aspectRatio.width;
  // piece.dimensions.height *= Math.min(aspectRatio.lengthRatio, aspectRatio.widthRatio);

  piece.dimensionRatio = {
    length: aspectRatio.lengthRatio,
    width: aspectRatio.widthRatio,
  };

  return piece;
}

function calculateAspectRatio(length, width) {
  let lengthRatio = MAX_LENGTH / length;
  let widthRatio = MAX_WIDTH / width;

  if (length === width || Math.abs(length - width) <= 5) {
    // is square or almost square
    const lowestRatio = Math.min(lengthRatio, widthRatio);
    lengthRatio = lowestRatio;
    widthRatio = lowestRatio;
  }

  if (widthRatio > lengthRatio) widthRatio = DEFAULT_DIMENSION_RATIO;

  let newWidth = width * widthRatio;

  return {
    length: length * lengthRatio,
    width: newWidth,
    lengthRatio: lengthRatio,
    widthRatio: widthRatio,
  };
}

function updateOperationDimensions(operation, piece, dimensionToScaleWith = LENGTH) {
  Object.keys(operation.dimensions).forEach(key => {
    if (operation.dimensions[key]) {
      operation.dimensions[key] = operation.dimensions[key] * piece.dimensionRatio[dimensionToScaleWith];
    }
  });

  return operation;
}
