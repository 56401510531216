import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ValidatedInput } from '../../forms/inputs/ValidatedInput';
import { BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP } from '../../../constants/ObjectSides';
import { getXFieldValueByPosition, getYFieldValueByPosition } from '../../../classes/helpers/VectorHelper';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../../headers/SectionTitle';
import { OPERATION_SHAPES } from '../../../constants/OperationTypes';
import { getSideNameByPreset } from '../../../classes/helpers/ObjectHelper';
import { HORIZONTAL } from '../../../constants/ObjectOrientations';

export const LocationSelectorForm = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.shared.locationSelectorForm.';
  const sideConstantsPrefix = 'constants.objectSides.';

  const X_AXIS = 'X';
  const Y_AXIS = 'Y';

  const [selectedHorizontalSide, setSelectedHorizontalSide] = useState(LEFT);
  const [selectedVerticalSide, setSelectedVerticalSide] = useState(FRONT);

  const { currentConfiguration, currentPiece, currentOperation } = useSelector(state => state.offerReducer);
  const canEdit = useSelector(state => state.generalReducer.canEdit);

  const getHorizontalOptions = (side = TOP) => {
    let sides;

    switch (side) {
      case TOP:
        sides = [LEFT, RIGHT];
        break;
      case LEFT:
      case BACK:
      case RIGHT:
        sides = [FRONT, BACK];
        break;
      default:
        sides = [];
        break;
    }

    return sides;
  };

  const getVerticalOptions = (side = TOP) => {
    let sides;

    switch (side) {
      case TOP:
      case BACK:
        sides = [FRONT, BACK];
        break;
      case LEFT:
      case RIGHT:
        sides = [TOP, BOTTOM];
        break;
      default:
        sides = [];
        break;
    }

    return sides;
  };

  const getValue = axis => {
    let value;

    if (axis === X_AXIS) {
      value = getXFieldValueByPosition(currentOperation, currentPiece?.dimensions, selectedHorizontalSide);
    } else {
      value = getYFieldValueByPosition(currentOperation, currentPiece?.dimensions, selectedVerticalSide);
    }

    return value;
  };

  const getMinValue = axis => {
    let minValue;

    if (axis === X_AXIS) {
      minValue = props.variant === OPERATION_SHAPES.RECTANGULAR ? 1 : props.operationDimensions?.length / 2;
    } else {
      minValue = props.variant === OPERATION_SHAPES.RECTANGULAR ? 1 : props.operationDimensions?.length / 2;
    }

    return minValue;
  };

  const getMaxValue = axis => {
    let maxValue;

    if (axis === X_AXIS) {
      maxValue = currentPiece?.dimensions.length - props.operationDimensions?.length;
    } else {
      maxValue = currentPiece?.dimensions.width - props.operationDimensions?.width;
    }

    return maxValue;
  };

  return (
    <>
      <SectionTitle content={t(prefix + 'locationTitle')} />

      <p className="m-b-2">
        {props.variant === OPERATION_SHAPES.ROUND
          ? t(prefix + 'roundOperationPositionDescription')
          : t(prefix + 'rectangularOperationPositionDescription')}
      </p>

      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label>{t(prefix + 'horizontalSideSelectLabel')}</label>
            <select
              ref={props.register({ required: true })}
              name="horizontalSide"
              data-cy={'horizontalSide'}
              defaultValue={selectedHorizontalSide}
              onChange={event => setSelectedHorizontalSide(event.target.value)}
              disabled={!canEdit}
            >
              {getHorizontalOptions(props.side).map(side => {
                const name = getSideNameByPreset(currentConfiguration.options.preset, side);

                return (
                  <option key={`horizontalOption${side}`} value={side}>
                    {t(sideConstantsPrefix + name)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-8">
          <ValidatedInput
            register={props.register}
            name={'xField'}
            dataCy={'xField'}
            label={
              selectedHorizontalSide === LEFT ? t(prefix + 'xPositionLabelLeft') : t(prefix + 'xPositionLabelRight')
            }
            placeholder={t(prefix + 'xPositionPlaceholder')}
            value={getValue(X_AXIS)}
            disabled={!canEdit}
            required={true}
            min={getMinValue(X_AXIS)}
            max={getMaxValue(X_AXIS)}
            error={props.errors.xField}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label>{t(prefix + 'verticalSideSelectLabel')}</label>
            <select
              ref={props.register({ required: true })}
              name="verticalSide"
              data-cy={'verticalSide'}
              defaultValue={selectedVerticalSide}
              onChange={event => setSelectedVerticalSide(event.target.value)}
              disabled={!canEdit}
            >
              {getVerticalOptions(props.side).map(side => {
                const name = getSideNameByPreset(currentConfiguration.options.preset, side);

                return (
                  <option key={`verticalOption${side}`} value={side}>
                    {t(sideConstantsPrefix + name)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-8">
          <ValidatedInput
            register={props.register}
            name={'yField'}
            dataCy={'yField'}
            label={
              selectedVerticalSide === FRONT ? t(prefix + 'yPositionLabelFront') : t(prefix + 'yPositionLabelBack')
            }
            placeholder={t(prefix + 'yPositionPlaceholder')}
            value={getValue(Y_AXIS)}
            disabled={!canEdit}
            required={true}
            min={getMinValue(Y_AXIS)}
            max={getMaxValue(Y_AXIS)}
            error={props.errors.yField}
          />
        </div>
      </div>
    </>
  );
};

LocationSelectorForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  variant: PropTypes.string,
  pieceOrientation: PropTypes.string,
  operationDimensions: PropTypes.object,
  side: PropTypes.string,
};

LocationSelectorForm.defaultProps = {
  variant: OPERATION_SHAPES.ROUND,
  pieceOrientation: HORIZONTAL,
  operationDimensions: {
    length: 0,
    width: 0,
  },
  side: TOP,
};
