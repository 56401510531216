import * as React from 'react';
import PropTypes from 'prop-types';
import { SelectableImage } from '../forms/inputs/SelectableImage';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import icon100x100 from '../../assets/icons/100x100.png';

export const MaterialColorList = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'constants.materialColors.';

  const images = require.context('../../assets/img/configuration', true);

  const getImage = color => {
    try {
      return images('./' + color.toLowerCase() + '.jpg');
    } catch (e) {
      return icon100x100;
    }
  };

  return (
    <div className="form-group--selectable__holder">
      {props.colors.map((color, index) => {
        return (
          <SelectableImage
            dataCy={`materialColorSelect-${color.toLowerCase()}`}
            key={index}
            image={getImage(color)}
            onClick={() => props.onItemSelect(color)}
            name="presetSelect"
            content={t(prefix + color)}
            selected={props.selectedColor === color}
          />
        );
      })}
    </div>
  );
};

MaterialColorList.propTypes = {
  colors: PropTypes.array.isRequired,
  selectedColor: PropTypes.string,
  onItemSelect: PropTypes.func.isRequired,
};

MaterialColorList.defaultProps = {
  selectedColor: '',
};
