import * as React from 'react';
import PropTypes from 'prop-types';
import {
  LEFT_BACK_UP,
  LEFT_DOWN,
  LEFT_FRONT_DOWN,
  LEFT_HORIZONTAL,
  LEFT_STYLES,
  LEFT_UP,
  RIGHT_BACK_UP,
  RIGHT_DOWN,
  RIGHT_FRONT_DOWN,
  RIGHT_HORIZONTAL,
  RIGHT_STYLES,
  RIGHT_UP,
} from '../../../constants/ConnectObjectStyles';
import leftUp from '../../../assets/img/link-left-up.svg';
import leftDown from '../../../assets/img/link-left-down.svg';
import leftFrontDown from '../../../assets/img/link-left-front-down.svg';
import leftBackUp from '../../../assets/img/link-left-back-up.svg';
import leftHorizontal from '../../../assets/img/link-left-horizontal.svg';
import rightUp from '../../../assets/img/link-right-up.svg';
import rightDown from '../../../assets/img/link-right-down.svg';
import rightFrontDown from '../../../assets/img/link-right-front-down.svg';
import rightBackUp from '../../../assets/img/link-right-back-up.svg';
import rightHorizontal from '../../../assets/img/link-right-horizontal.svg';
import { LEFT } from '../../../constants/ObjectSides';
import { SectionTitle } from '../../headers/SectionTitle';
import { RadioButton } from '../../forms/inputs/RadioButton';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const NormalConnectionStyleSelect = props => {
  const prefix = 'modals.connectPieceModal.';
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const getIcon = style => {
    let icon;

    switch (style) {
      case LEFT_UP:
        icon = leftUp;
        break;
      case LEFT_DOWN:
        icon = leftDown;
        break;
      case LEFT_FRONT_DOWN:
        icon = leftFrontDown;
        break;
      case LEFT_BACK_UP:
        icon = leftBackUp;
        break;
      case LEFT_HORIZONTAL:
        icon = leftHorizontal;
        break;
      case RIGHT_UP:
        icon = rightUp;
        break;
      case RIGHT_DOWN:
        icon = rightDown;
        break;
      case RIGHT_FRONT_DOWN:
        icon = rightFrontDown;
        break;
      case RIGHT_BACK_UP:
        icon = rightBackUp;
        break;
      case RIGHT_HORIZONTAL:
        icon = rightHorizontal;
        break;
    }

    return icon;
  };

  const renderSelect = () => {
    if (props.selectedSide === LEFT) {
      return (
        <div>
          <SectionTitle content={t(prefix + 'normalConnectionsTitle')} />
          {LEFT_STYLES.map((style, index) => {
            return (
              <RadioButton
                key={index}
                image={getIcon(style)}
                dataCy={`normalConnectionStyleSelect-${style.toLowerCase()}`}
                onChange={() => props.setSelectedStyle(style)}
                name="normalConnectionStyleRadio"
                checked={props.selectedStyle === style}
                disabled={props.disabled}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <SectionTitle content={t(prefix + 'normalConnectionsTitle')} />
          {RIGHT_STYLES.map((style, index) => {
            return (
              <RadioButton
                dataCy={`normalConnectionStyleSelect-${style.toLowerCase()}`}
                key={index}
                image={getIcon(style)}
                onChange={() => props.setSelectedStyle(style)}
                name="normalConnectionStyleRadio"
                checked={props.selectedStyle === style}
                disabled={props.disabled}
              />
            );
          })}
        </div>
      );
    }
  };

  return <div>{renderSelect()}</div>;
};

NormalConnectionStyleSelect.propTypes = {
  selectedStyle: PropTypes.string,
  selectedSide: PropTypes.string,
  setSelectedStyle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
