import React, { useEffect, useState } from 'react';
import defaultLogo from '../../assets/img/logo.svg';
import history from '../../config/history';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { NavigationBarItem } from './NavigationBarItem';
import { ADMIN, SUPERVISOR } from '../../constants/RoleNames';
import { useDispatch, useSelector } from 'react-redux';
import { PriceTypeModal } from '../modals/PriceTypeModal';
import { setShouldUpdateLogo } from '../../actions/GeneralActions';
import { OFFER, ORDER } from '../../constants/ConfigurableTypes';
import { CompanyBranchService } from '../../classes/services/CompanyBranchService';
import { HelpModal } from '../modals/HelpModal';
import { useLocation } from 'react-router-dom';
import { ORDERS_PATH_NAME } from '../../constants/PathNames';
import { hasOneOfRoles } from '../../classes/helpers/UserHelper';

export const NavigationBar = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'navigation.navigationBar.';
  const constantsPrefix = 'constants.priceTypes.';

  const [logo, setLogo] = useState(null);
  const [priceTypeModalIsActive, setPriceTypeModalIsActive] = useState(false);
  const [renderPriceTypeButton, setRenderPriceTypeButton] = useState(false);
  const [helpModalIsActive, setHelpModalIsActive] = useState(false);

  const { priceType, shouldUpdateLogo } = useSelector(state => state.generalReducer);
  const { currentCompanyBranch } = useSelector(state => state.companyReducer);
  const { currentOrder } = useSelector(state => state.orderReducer);

  const dispatch = useDispatch();
  const location = useLocation();

  const companyBranchService = new CompanyBranchService();

  useEffect(() => {
    getLogo();
  }, []);

  useEffect(() => {
    if (shouldUpdateLogo) {
      getLogo();
      dispatch(setShouldUpdateLogo(false));
    }
  }, [shouldUpdateLogo]);

  useEffect(() => {
    let shouldRenderToggle = false;

    if (location.pathname.includes(ORDERS_PATH_NAME)) {
      if (currentOrder) {
        if (!currentOrder.isCreatedByAdmin) {
          shouldRenderToggle = true;
        }
      } else {
        shouldRenderToggle = true;
      }
    } else {
      shouldRenderToggle = true;
    }

    if (renderPriceTypeButton !== shouldRenderToggle) {
      setRenderPriceTypeButton(shouldRenderToggle);
    }
  }, [currentOrder]);

  const getCurrentLogo = async () => {
    return await companyBranchService.getCurrentLogo(currentCompanyBranch.id).then(response => {
      if (response instanceof Blob) {
        return response;
      } else {
        if (response.status === 400) {
          throw Error(response.message);

          //ELSE 404 No logo exists
        }
      }
    });
  };

  const getLogo = () => {
    let storedLogo = localStorage.getItem('logo');

    if (storedLogo) {
      setLogo(storedLogo);
    } else {
      if (!currentCompanyBranch) return;

      getCurrentLogo()
        .then(currentLogo => {
          if (currentLogo) {
            let reader = new FileReader();
            reader.readAsDataURL(currentLogo);
            reader.onloadend = function () {
              let base64Logo = reader.result;

              setLogo(base64Logo);
              localStorage.setItem('logo', base64Logo);
            };
          } else {
            setLogo(defaultLogo);
            localStorage.setItem('logo', defaultLogo);
          }
        })
        .catch(() => {
          setLogo(defaultLogo);
          localStorage.setItem('logo', defaultLogo);
        });
    }
  };

  const toOffers = () => {
    history.push('/offers');
  };

  const toOrders = () => {
    history.push('/orders');
  };

  const toArticles = () => {
    history.push('/articles');
  };

  const toAdmin = () => {
    history.push('/admin');
  };

  const toSettings = () => {
    history.push('/settings');
  };

  const isActive = routeNames => {
    let pathName = history.location.pathname;

    for (let i = 0; i < routeNames.length; i++) {
      if (pathName.includes(routeNames[i])) return true;
    }

    return false;
  };

  const renderRetailPriceToggle = () => {
    return (
      !!(renderPriceTypeButton && priceType) && (
        <div data-cy="priceTypeNavItem">
          <NavigationBarItem
            icon="local_offer"
            content={t(constantsPrefix + priceType)}
            action={() => setPriceTypeModalIsActive(true)}
          />
        </div>
      )
    );
  };

  return (
    <div className="app__navbar">
      <PriceTypeModal isActive={priceTypeModalIsActive} onClose={() => setPriceTypeModalIsActive(false)} />

      <HelpModal isActive={helpModalIsActive} onClose={() => setHelpModalIsActive(false)} />

      <nav>
        <span className="app__navbar__logo">{logo ? <img alt="logo" src={logo} /> : null}</span>

        <NavigationBarItem
          icon="description"
          content={t(prefix + 'offers')}
          action={() => toOffers()}
          isActive={isActive(['offers', `configurator/${OFFER}/`])}
        />

        <NavigationBarItem
          icon="local_shipping"
          content={t(prefix + 'orders')}
          action={() => toOrders()}
          isActive={isActive(['orders', `configurator/${ORDER}/`])}
          dataCy="ordersNavigationItem"
        />

        {/*<NavigationBarItem*/}
        {/*    icon="store"*/}
        {/*    content={t(prefix + 'articles')}*/}
        {/*    action={() => toArticles()}*/}
        {/*    isActive={isActive(['articles'])}/>*/}
      </nav>

      <nav>
        {renderRetailPriceToggle()}

        <span className="app__navbar__item" onClick={() => setHelpModalIsActive(true)}>
          <i className="material-icons">info</i> {t(prefix + 'info')}
        </span>

        {hasOneOfRoles([ADMIN, SUPERVISOR]) && (
          <NavigationBarItem
            icon="verified_user"
            content={t(prefix + 'admin')}
            action={() => toAdmin()}
            isActive={isActive(['admin'])}
          />
        )}

        <NavigationBarItem
          icon="settings"
          content={t(prefix + 'settings')}
          action={() => toSettings()}
          isActive={isActive(['settings'])}
        />
      </nav>
    </div>
  );
};
