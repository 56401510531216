import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppHeaderDropDown } from '../../headers/AppHeaderDropDown';
import { AppHeaderDropdownItem } from '../../headers/AppHeaderDropdownItem';
import { DANGER } from '../../../constants/Variants';
import history from '../../../config/history';
import { AppHeader } from '../../headers/AppHeader';
import { PricePopover } from './PricePopover';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { setConfiguratorUnsavedDimension, setConfirmationModal } from '../../../actions/GeneralActions';
import { StockInfo } from '../../shared/StockInfo';
import { MINIMUM_STOCK_AMOUNT } from '../../../constants/StandardMeasurements';
import { parseToCommaSeparated } from '../../../classes/helpers/StringHelper';
import { OFFER } from '../../../constants/ConfigurableTypes';
import { useParams } from 'react-router-dom';
import { isToday } from '../../../classes/helpers/DateHelper';
import moment from 'moment';
import { SaveButton } from './SaveButton';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { setConfiguratorStatus } from '../../../actions/ConfiguratorActions';
import { ADMIN, SUPERVISOR } from '../../../constants/RoleNames';

export const ConfiguratorAppHeader = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'headers.appHeader.';
  const actionsPrefix = 'headers.appHeader.actions.';
  const dimensionsPrefix = 'constants.dimensions.';
  const configuratorPrefix = 'pages.configurator.';

  const [stockSquareMeters, setStockSquareMeters] = useState(0);
  const [shouldRenderStockInfo, setShouldRenderStockInfo] = useState(false);
  const [configurableType, setConfigurableType] = useState(null);

  const { currentOffer, currentConfiguration } = useSelector(state => state.offerReducer);
  const { currentOrder } = useSelector(state => state.orderReducer);
  const { canEdit, priceIsLoading, configuratorUnsavedDimension, windowIsLoading } = useSelector(
    state => state.generalReducer,
  );
  const { company, selectedCompany } = useSelector(state => state.companyReducer);

  const dispatch = useDispatch();
  const urlParams = useParams();

  // TODO: Refactor useEffect hooks below: get triggered too often, add/update dependencies

  useEffect(() => {
    if (currentOffer && currentConfiguration && shouldRenderStockInfo) {
      setStockSquareMeters(currentOffer.getStockSquareMeter());
    }
  }, [currentConfiguration]);

  useEffect(() => {
    if (currentOffer) {
      const offerContainsStockConfiguration = currentOffer.containsStockConfiguration();
      setShouldRenderStockInfo(offerContainsStockConfiguration);
    }
  }, [currentOffer?.configurations]);

  useEffect(() => {
    if (configurableType !== urlParams.type) setConfigurableType(urlParams.type);
  }, [urlParams]);

  const getTitle = () => {
    let title = '';

    if (currentConfiguration) {
      let orderable;

      if (urlParams.type === OFFER) {
        orderable = currentOffer;
      } else {
        orderable = currentOrder;
      }

      if (currentConfiguration.name) {
        title = `${orderable.name} (#${orderable.id}) › ${currentConfiguration.name}`;
      } else {
        title = `${orderable.name} (#${orderable.id})`;
      }
    }

    return title;
  };

  const getOfferSaveButtonAction = () => {
    let action;
    const offerDetailsRoute = `/offers/${currentOffer.id}`;

    if (configuratorUnsavedDimension) {
      // Show message that there are unsaved dimensions
      action = dispatch(
        setConfirmationModal({
          isActive: true,
          content: t(prefix + 'unsavedValuesMessage', {
            dimensionName: t(dimensionsPrefix + configuratorUnsavedDimension).toLowerCase(),
          }),
          onAccept: () => {
            dispatch(setConfiguratorUnsavedDimension());
            history.push(offerDetailsRoute);
          },
        }),
      );
    } else if (currentConfiguration?.options.isConfiguredForStock() && stockSquareMeters < MINIMUM_STOCK_AMOUNT) {
      // Show an alert that tells the user that the stock amount is too low
      dispatch(
        setConfirmationModal({
          isActive: true,
          content: t(configuratorPrefix + 'insufficientStockAmount', {
            minimumAmount: MINIMUM_STOCK_AMOUNT,
            currentAmount: parseToCommaSeparated(stockSquareMeters, 2),
          }),
          onAccept: () => history.push(offerDetailsRoute),
        }),
      );
    } else if (
      urlParams.type === OFFER &&
      !isToday(currentOffer.getExpiryDateLastUpdatedDate()) &&
      !hasOneOfRoles(ADMIN)
    ) {
      // Show an alert that tells the user that the offer has expired and update the expiry date
      const newExpiryDate = moment().add(30, 'day').format('DD/MM/YYYY');

      dispatch(
        setConfirmationModal({
          isActive: true,
          content: t(configuratorPrefix + 'updateOfferExpiryDateModalContent', { newExpiryDate }),
          onAccept: () => props.updateExpiryDate(),
        }),
      );
    } else {
      // Return to offer details page
      action = history.push(offerDetailsRoute);
    }

    return action;
  };

  return (
    <>
      <AppHeader title={getTitle()}>
        {shouldRenderStockInfo && (
          <div className="m-r-1">
            <StockInfo amount={stockSquareMeters} />
          </div>
        )}

        <PricePopover
          isLoading={priceIsLoading}
          configuration={currentConfiguration}
          company={selectedCompany ?? company}
          vat={currentOffer ? currentOffer.vat : currentOrder?.vat}
        />

        {canEdit && (
          <>
            <AppHeaderDropDown content={t(prefix + 'mainButtonContent')}>
              <AppHeaderDropdownItem
                content={t(actionsPrefix + 'createConfiguration')}
                onClick={() => props.openConfigurationModal()}
              />

              <AppHeaderDropdownItem content={t(actionsPrefix + 'duplicate')} disabled={true} />

              <AppHeaderDropdownItem
                content={t(actionsPrefix + 'delete')}
                variant={DANGER}
                onClick={() => props.openDeleteOfferModal()}
              />
            </AppHeaderDropDown>
          </>
        )}

        {!!(configurableType === OFFER || hasOneOfRoles([ADMIN, SUPERVISOR])) && (
          <SaveButton
            dataCy="configuratorAppHeader-saveButton"
            configurableType={urlParams.type}
            onSave={getOfferSaveButtonAction}
            disabled={windowIsLoading || priceIsLoading}
            onUpdateButtonState={status => dispatch(setConfiguratorStatus(status))}
          />
        )}
      </AppHeader>
    </>
  );
};

ConfiguratorAppHeader.propTypes = {
  openConfigurationModal: PropTypes.func.isRequired,
  openDeleteOfferModal: PropTypes.func.isRequired,
  updateExpiryDate: PropTypes.func.isRequired,
};
