import React from 'react';
import { AppHeader } from '../../headers/AppHeader';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { SectionTitle } from '../../headers/SectionTitle';
import { RadioButton } from '../../forms/inputs/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_CAN_EDIT_OFFERS, VALUE_FALSE, VALUE_TRUE } from '../../../constants/AdminSettings';
import { AppContent } from '../../layout/AppContent';
import { SettingService } from '../../../classes/services/SettingService';
import { cloneDeep } from 'lodash';
import { addAlertMessage, setSettings } from '../../../actions/GeneralActions';
import { captureException } from '@sentry/react';
import { DANGER } from '../../../constants/Variants';

export function Settings() {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.admin.settings.';
  const settingsPrefix = 'constants.adminSettings.';
  const messagePrefix = 'messages.';

  const { settings } = useSelector(state => state.generalReducer);

  const dispatch = useDispatch();

  const updateSettingState = (key, newValue) => {
    const settingService = new SettingService();

    settingService
      .update(key, newValue)
      .then(response => {
        const newState = cloneDeep(settings);
        newState[key] = response.data.value;

        dispatch(setSettings(newState));
      })
      .catch(e => {
        captureException(e);
        dispatch(addAlertMessage(DANGER, t(messagePrefix + 'updateSettingFailed')));
      });
  };

  return (
    <AppContent>
      <div className="p-l-4 p-r-4">
        <AppHeader title={t(prefix + 'title')} />

        <SectionTitle content={t(prefix + 'generalTitle')} />

        <p>{t(prefix + 'canEditOffersDescription')}</p>

        <div className="m-t-2">
          {[true, false].map(canEdit => {
            return (
              <RadioButton
                key={`canEdit${canEdit}`}
                content={canEdit ? t(settingsPrefix + VALUE_TRUE) : t(settingsPrefix + VALUE_FALSE)}
                checked={canEdit === settings?.ADMIN_CAN_EDIT_OFFERS}
                onChange={() => updateSettingState(ADMIN_CAN_EDIT_OFFERS, canEdit)}
                name="canEditOffers"
              />
            );
          })}
        </div>
      </div>
    </AppContent>
  );
}
