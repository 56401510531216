import * as React from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from '../../headers/SectionTitle';
import { RadioButton } from '../../forms/inputs/RadioButton';
import normalConnectionStyleIcon from '../../../assets/img/coupe-none.svg';
import coupeConnectionStyleIcon from '../../../assets/img/coupe.svg';
import { COUPE, HEIGHT_COUPE } from '../../../constants/OperationTypes';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { NORMAL } from '../../../constants/ConnectObjectStyles';

export const ConnectionTypeSelect = props => {
  const prefix = 'modals.connectPieceModal.';
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const currentPiece = useSelector(state => state.offerReducer.currentPiece);

  const getAvailableSidesLength = () => {
    let availableSides = currentPiece?.getAvailableSidesToConnectPieces();
    availableSides = availableSides == null ? [] : availableSides;

    return availableSides.length;
  };

  return (
    <>
      <SectionTitle content={t(prefix + 'connectionStyleTitle')} />

      {props.variant === COUPE && (
        <RadioButton
          dataCy="connectionTypeSelect-withoutCoupe"
          image={normalConnectionStyleIcon}
          onChange={() => props.setSelectionType(NORMAL)}
          content={t(prefix + 'normalLabel')}
          name="styleRadio"
          disabled={getAvailableSidesLength() === 0}
          checked={props.currentConnectionType === NORMAL}
        />
      )}

      <RadioButton
        dataCy="connectionTypeSelect-coupe"
        image={coupeConnectionStyleIcon}
        onChange={() => props.setSelectionType(COUPE)}
        content={t(prefix + 'widthCoupeLabel')}
        name="styleRadio"
        disabled={getAvailableSidesLength() === 0}
        checked={props.currentConnectionType === COUPE}
      />
    </>
  );
};

ConnectionTypeSelect.propTypes = {
  currentConnectionType: PropTypes.string,
  setSelectionType: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

ConnectionTypeSelect.defaultProps = {
  variant: COUPE,
};
