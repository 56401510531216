import { Parser, Service } from '../../internal';
import { DEFAULT } from '../../constants/PriceTypes';
import { parseToCommaSeparated } from '../helpers/StringHelper';

class ConfigurationService extends Service {
  async create(configurableId, configurableType, options) {
    const data = {
      configurableId,
      configurableType,
      options,
    };

    return await this.authenticatedRequest('post', 'configurations', data)
      .then(response => {
        if (response.data.success) {
          response.data.data = Parser.parseConfiguration(response.data.data, true);
        }

        return response.data;
      })
      .catch(error => {
        if (error.response != null) {
          throw error.response.data.errors;
        }
      });
  }

  async getPrice(offerId, configurationId, priceType = DEFAULT) {
    let data = {
      priceType: priceType,
    };

    return await this.authenticatedRequest(
      'get',
      'offers/' + offerId + '/configurations/' + configurationId + '/price',
      data,
    )
      .then(response => {
        if (response.data.success) {
          response.data.data = parseToCommaSeparated(response.data.data, 2);
        }

        return response.data;
      })
      .catch(error => {
        throw Error(error);
      });
  }

  async updateAmount(configurationId, amount) {
    const data = {
      amount: amount,
    };

    return await this.authenticatedRequest('post', `configurations/${configurationId}`, data).then(response => {
      if (response.data.success) {
        response.data.data = Parser.parseConfiguration(response.data);
        return response.data;
      }

      throw Error('Something went wrong while updating the amount of the configuration');
    });
  }

  async updateName(configurationId, name) {
    return await this.authenticatedRequest('put', `configurations/${configurationId}/`, { name }).then(response => {
      if (response.success) {
        response.data.data = Parser.parseConfiguration(response.data.data);
      }

      return response.data;
    });
  }

  async duplicate(configuration) {
    return await this.authenticatedRequest('put', `configurations/${configuration.id}/duplicate`)
      .then(response => {
        if (response.data.success) {
          response.data.data = Parser.parseConfiguration(response.data.data, true);
        }

        return response.data;
      })
      .catch(error => {
        throw error.response;
      });
  }

  async updatePartsOfPieces(configurationId, pieces) {
    const data = {
      pieces: pieces,
    };

    return await this.authenticatedRequest('put', `configurations/${configurationId}/parts`, data).then(response => {
      if (response.data.success) {
        response.data.data = Parser.parseConfiguration(response.data.data, true);
      }

      return response.data;
    });
  }

  async delete(configurationId) {
    return await this.authenticatedRequest('delete', `configurations/${configurationId}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response;
      });
  }

  async updateOptions(configurableType, configurableId, configurationId, optionsData) {
    const data = {
      configurableId,
      configurableType,
      options: optionsData,
    };

    return await this.authenticatedRequest('put', `configurations/${configurationId}/options`, data)
      .then(response => {
        if (response.data.success) response.data.data = Parser.parseConfiguration(response.data.data, true);

        return response.data;
      })
      .catch(error => {
        throw error.response;
      });
  }
}

export { ConfigurationService };
