import { Box3, BoxGeometry, MathUtils, Mesh, MeshBasicMaterial, Vector3 } from 'three';

export function getBoundingBox(objects) {
  let boundingBox;

  if (objects instanceof Array) {
    boundingBox = getBoundingBoxOfPieceList(objects);
  } else {
    // Object3D
    boundingBox = new Box3().setFromObject(objects);
  }

  const length = Math.abs(boundingBox.min.x - boundingBox.max.x);
  const height = Math.abs(boundingBox.min.y - boundingBox.max.y);
  const width = Math.abs(boundingBox.min.z - boundingBox.max.z);

  const center = {
    x: boundingBox.min.x + length / 2,
    y: boundingBox.min.y + height / 2,
    z: boundingBox.min.z + width / 2,
  };

  return {
    min: boundingBox.min,
    max: boundingBox.max,
    length,
    height,
    width,
    center,
  };
}

export function createBoxMesh(position, rotation = new Vector3(), size = 1) {
  let mesh = new Mesh(new BoxGeometry(size, size, size), new MeshBasicMaterial({ color: 'red' }));
  mesh.position.add(position);
  mesh.rotateX(MathUtils.degToRad(rotation.x));
  mesh.rotateY(MathUtils.degToRad(rotation.y));
  mesh.rotateZ(MathUtils.degToRad(rotation.z));

  return mesh;
}

function getBoundingBoxOfPieceList(pieceList) {
  let negativeX = 0;
  let negativeY = 0;
  let negativeZ = 0;

  let positiveX = 0;
  let positiveY = 0;
  let positiveZ = 0;

  pieceList.forEach(piece => {
    piece.calculateSideVectors();

    Object.values(piece.sideVectors).forEach(sideVector => {
      if (sideVector.length > 0) {
        const sideVectorLowestX = sideVector[0].x < sideVector[1].x ? sideVector[0].x : sideVector[1].x;
        const sideVectorLowestY = sideVector[0].y < sideVector[1].y ? sideVector[0].y : sideVector[1].y;
        const sideVectorLowestZ = sideVector[0].z < sideVector[1].z ? sideVector[0].z : sideVector[1].z;

        const sideVectorHighestX = sideVector[0].x > sideVector[1].x ? sideVector[0].x : sideVector[1].x;
        const sideVectorHighestY = sideVector[0].y > sideVector[1].y ? sideVector[0].y : sideVector[1].y;
        const sideVectorHighestZ = sideVector[0].z > sideVector[1].z ? sideVector[0].z : sideVector[1].z;

        if (sideVectorLowestX < negativeX) negativeX = sideVectorLowestX;
        if (sideVectorLowestY < negativeY) negativeY = sideVectorLowestY;
        if (sideVectorLowestZ < negativeZ) negativeZ = sideVectorLowestZ;

        if (sideVectorHighestX > positiveX) positiveX = sideVectorHighestX;
        if (sideVectorHighestY > positiveY) positiveY = sideVectorHighestY;
        if (sideVectorLowestZ > positiveZ) positiveZ = sideVectorHighestZ;
      } else {
        if (sideVector.x < negativeX) negativeX = sideVector.x;
        if (sideVector.y < negativeY) negativeY = sideVector.y;
        if (sideVector.z < negativeZ) negativeZ = sideVector.z;

        if (sideVector.x > positiveX) positiveX = sideVector.x;
        if (sideVector.y > positiveY) positiveY = sideVector.y;
        if (sideVector.z > positiveZ) positiveZ = sideVector.z;
      }
    });
  });

  return { min: { x: negativeX, y: negativeY, z: negativeZ }, max: { x: positiveX, y: positiveY, z: positiveZ } };
}
