import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const TextArea = props => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.value);
  }, []);

  const blurHandler = () => {
    props.onBlur(value);
  };

  return (
    <div className="form-group">
      {props.label ? <label>{props.label}</label> : null}
      <textarea value={value} onChange={event => setValue(event.target.value)} onBlur={() => blurHandler()}>
        {props.content}
      </textarea>
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  content: PropTypes.string,
  onBlur: PropTypes.func,
};
