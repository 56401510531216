export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';
export const WARNING = 'WARNING';
export const DANGER = 'DANGER';
export const STRONG = 'STRONG';
export const LIGHT = 'LIGHT';

export const DATE_PICKER = {
  DATE: 'DATE',
  DATETIME: 'DATETIME',
};

export const LIST_ITEM = {
  CONFIG: 'config',
  OFFER: 'offer',
  ORDER: 'order',
  ADD: 'add',
};
