import * as React from 'react';
import PropTypes from 'prop-types';
import { ActionsDropdownItem } from '../../dropdowns/items/ActionsDropdownItem';
import { ActionsDropdown } from '../../dropdowns/ActionsDropdown';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const OrderItemActionsDropdown = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.orders.orderItemActionsDropdown.';

  return (
    <div className="m-l-2" onClick={event => event.stopPropagation()}>
      <ActionsDropdown dataCy="orderItemActionsDropdown">
        <ActionsDropdownItem
          dataCy="orderItemActionsDropdown-edit"
          content={t(prefix + 'editStatus')}
          dataTestTag="editStatus"
          onClick={event => props.onEditStatusClick(event)}
        />
      </ActionsDropdown>
    </div>
  );
};

OrderItemActionsDropdown.propTypes = {
  onEditStatusClick: PropTypes.func.isRequired,
};
