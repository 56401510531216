import { ActionsDropdown } from '../../dropdowns/ActionsDropdown';
import * as React from 'react';
import PropTypes from 'prop-types';
import { ActionsDropdownItem } from '../../dropdowns/items/ActionsDropdownItem';
import { DANGER } from '../../../constants/Variants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const ExternalLinkList = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.admin.manufacturerDetail.';

  return (
    <div className="list">
      {props.links.map(link => {
        return (
          <div key={link.id} className="list__item">
            <div className="list__item__content">
              <a href={link.link} target="_blank" rel="noopener noreferrer">
                {link.name}
              </a>
            </div>

            <ActionsDropdown>
              <ActionsDropdownItem content={t(prefix + 'dropdownEdit')} onClick={() => props.onEdit(link)} />
              <ActionsDropdownItem
                content={t(prefix + 'dropdownDelete')}
                variant={DANGER}
                onClick={() => props.onDelete(link)}
              />
            </ActionsDropdown>
          </div>
        );
      })}
    </div>
  );
};

ExternalLinkList.propTypes = {
  links: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
