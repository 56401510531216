import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { DeliveryDate } from '../../classes/models/DeliveryDate';
import { DatePicker } from '../forms/inputs/DatePicker';
import { ValidationMessage } from '../shared/ValidationMessage';
import { DANGER, DATE_PICKER } from '../../constants/Variants';
import { Select } from '../forms/selects/Select';
import { DELIVERY_DATE_STATUSES, OPEN } from '../../constants/Statuses';
import { SectionTitle } from '../headers/SectionTitle';
import { ModalHolder } from './ModalHolder';
import moment from 'moment';
import { CheckboxWithIcon } from '../forms/inputs/CheckboxWithIcon';

export const DeliveryDateModal = props => {
  const prefix = 'modals.deliveryDateModal.';
  const statusPrefix = 'constants.statuses.';
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const [newDate, setNewDate] = useState(moment());
  const [selectedStatus, setSelectedStatus] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(false);
  const [dateIsToBeDetermined, setDateIsToBeDetermined] = useState(false);

  useEffect(() => {
    if (props.isActive && props.deliveryDate) {
      setNewDate(moment(props.deliveryDate.date));
      setSelectedStatus(props.deliveryDate.status);
    } else {
      setNewDate(getCurrentDate());
      setSelectedStatus(OPEN);
    }

    if (dateIsToBeDetermined) setDateIsToBeDetermined(false);
  }, [props.isActive]);

  useEffect(() => {
    if (
      !props.allowDateInPast &&
      newDate.isBefore(getCurrentDate()) &&
      !moment(props.deliveryDate?.date).isSame(newDate)
    ) {
      setValidationMessage(t(prefix + 'validationMessage'));
      setSubmitButtonIsDisabled(true);
    } else {
      if (validationMessage !== '') setValidationMessage('');
      if (submitButtonIsDisabled) setSubmitButtonIsDisabled(false);
    }
  }, [newDate]);

  const getCurrentDate = () => {
    if (props.variant === DATE_PICKER.DATE) {
      return moment().startOf('day');
    } else {
      return moment();
    }
  };

  const getHeader = () => {
    let header;

    if (props.deliveryDate?.id) {
      header = t(prefix + 'updateHeader');
    } else {
      header = t(prefix + 'createHeader');
    }

    return header;
  };

  const getStatusOptions = () => {
    return DELIVERY_DATE_STATUSES.map(status => {
      return {
        id: status,
        value: t(statusPrefix + status),
      };
    });
  };

  const onClose = (submitted = false) => {
    if (submitted) {
      const date = dateIsToBeDetermined ? null : newDate;

      props.onClose(submitted, date, selectedStatus);
    } else {
      props.onClose();
    }
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={onClose} animation={true} className="modal--prompt">
        <Modal.Header>
          <Modal.Title>{getHeader()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(prefix + 'description')}</p>

          {!!(props.deliveryDate?.id && props.showStatus) && (
            <>
              <SectionTitle content={t(prefix + 'statusTitle')} />

              <Select
                name="deliveryDateSelect"
                label={t(prefix + 'statusSelectLabel')}
                options={getStatusOptions()}
                value={selectedStatus}
                onChange={value => setSelectedStatus(value)}
              />
            </>
          )}

          <SectionTitle content={t(prefix + 'dateTitle')} />

          <div className="form-group">
            <DatePicker
              dataCy="offerDeliveryDateModal-input"
              label={t(prefix + 'datePickerLabel')}
              date={props.deliveryDate?.date}
              onChange={date => setNewDate(date)}
              variant={props.variant}
              disabled={dateIsToBeDetermined}
            />
            {validationMessage && <ValidationMessage content={validationMessage} variant={DANGER} />}

            {props.showUnknownDateOption && (
              <div className="m-t-2">
                <CheckboxWithIcon
                  checked={dateIsToBeDetermined}
                  content={t(prefix + 'unknownDateCheckboxContent')}
                  onChange={() => setDateIsToBeDetermined(!dateIsToBeDetermined)}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            data-cy="offerDeliveryDateModal-submitButton"
            className="button button--primary"
            onClick={() => onClose(true)}
            type="button"
            disabled={submitButtonIsDisabled}
          >
            {props.deliveryDate?.id ? t(prefix + 'submitButtonUpdate') : t(prefix + 'submitButtonCreate')}
          </button>
          <div className="button button--outline" onClick={() => onClose()}>
            {t(prefix + 'closeButton')}
          </div>
        </Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

DeliveryDateModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showStatus: PropTypes.bool,
  deliveryDate: PropTypes.instanceOf(DeliveryDate),
  variant: PropTypes.string,
  allowDateInPast: PropTypes.bool,
  showUnknownDateOption: PropTypes.bool,
};

DeliveryDateModal.defaultProps = {
  deliveryDate: null,
  showStatus: true,
};
