import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { SectionTitle } from '../headers/SectionTitle';
import { RadioButton } from '../forms/inputs/RadioButton';
import { ALL, DEFAULT, DISCOUNTED, RETAIL } from '../../constants/PriceTypes';
import { useDispatch, useSelector } from 'react-redux';
import { setPriceType, setShouldUpdatePrice } from '../../actions/GeneralActions';
import history from '../../config/history';
import { useLocation } from 'react-router-dom';
import { ModalHolder } from './ModalHolder';

export const PriceTypeModal = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.priceTypeModal.';
  const constantsPrefix = 'constants.priceTypes.';

  const priceType = useSelector(state => state.generalReducer.priceType);
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.includes('order')) {
      dispatch(setPriceType(DISCOUNTED));
    }
  }, [location]);

  const onPriceTypeChange = newPriceType => {
    if (ALL.includes(newPriceType)) {
      dispatch(setPriceType(newPriceType));
    } else {
      dispatch(setPriceType(DEFAULT));
    }

    if (history.location.pathname.includes('configurator')) {
      dispatch(setShouldUpdatePrice(true));
    }
  };

  return (
    <ModalHolder isActive={props.isActive} datacy="priceTypeModal">
      <Modal show={props.isActive} onHide={props.onClose} animation={true} className="modal--prompt">
        <Modal.Header>
          <Modal.Title>{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(prefix + 'description')}</p>

          <SectionTitle content={t(prefix + 'sectionTitle')} dataCy="priceTypeSectionTitle" />

          <RadioButton
            onChange={() => onPriceTypeChange(DEFAULT)}
            name="defaultPriceType"
            checked={priceType === DEFAULT}
            content={t(constantsPrefix + DEFAULT)}
            smallText={t(prefix + 'defaultSmallText')}
            dataCy={'defaultPriceTypeRadioButton'}
          />

          <RadioButton
            onChange={() => onPriceTypeChange(DISCOUNTED)}
            name="discountedPriceType"
            checked={priceType === DISCOUNTED}
            content={t(constantsPrefix + DISCOUNTED)}
            smallText={t(prefix + 'discountedSmallText')}
            dataCy={'discountedPriceTypeRadioButton'}
          />

          <RadioButton
            onChange={() => onPriceTypeChange(RETAIL)}
            name="retailPriceType"
            checked={priceType === RETAIL}
            content={t(constantsPrefix + RETAIL)}
            smallText={t(prefix + 'retailSmallText')}
            dataCy={'retailPriceTypeRadioButton'}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button button-blue" data-cy="closeButton" onClick={() => props.onClose()}>
            {t(prefix + 'closeButton')}
          </div>
        </Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

PriceTypeModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
