import { Parser } from '../helpers/Parser';
import i18next from 'i18next';
import moment from 'moment';
import { parseNumber, parseToCommaSeparated } from '../helpers/StringHelper';
import { COLORS_REMOVED_FROM_PRODUCT_RANGE } from '../../constants/MaterialColors';
import { CompanyBranch } from './CompanyBranch';

class Offer {
  constructor(id, name = '', creator = null, timestamp = { date: null, time: null }) {
    this.id = id;
    this.name = name;
    this.creator = creator;
    this.timestamp = timestamp;
    this.expiryDate = null;
    this.companyBranch = new CompanyBranch(0, '');
    this.margin = 0;
    this.vat = 0;
    this.customer = null;
    this.isCreatedByAdmin = false;
    this.isVisible = false;
    this.configurations = [];
    this.outdoorConfigurations = [];
  }

  getExpiryDate() {
    if (this.expiryDate) {
      moment.locale = i18next.language;

      return Parser.parseStringToTimestamp(this.expiryDate.toDate().toDateString())?.date;
    }
  }

  getExpiryDateLastUpdatedDate() {
    return this.expiryDate.subtract(30, 'day');
  }

  getFormattedPrice(withVat = false) {
    let formattedPrice = this.price;

    if (withVat) {
      const price = parseNumber(this.price) * (this.vat / 100 + 1);

      formattedPrice = parseToCommaSeparated(price.toFixed(2));
    }

    return `€ ${formattedPrice}`;
  }

  getStockSquareMeter() {
    return this.configurations
      .filter(configuration => configuration.options.isConfiguredForStock())
      .map(configuration => configuration.getTotalSquareMeter())
      .reduce((a, b) => a + b, 0);
  }

  containsStockConfiguration() {
    return this.configurations.some(configuration => configuration.options.isConfiguredForStock());
  }

  containsProductsRemovedFromProductLine() {
    return this.configurations.some(configuration =>
      COLORS_REMOVED_FROM_PRODUCT_RANGE.includes(configuration.options.materialColor),
    );
  }

  isNotDeliverable() {
    return this.configurations.some(configuration => configuration.pieces.some(piece => piece.isNotDeliverable()));
  }
}

export { Offer };
