import * as React from 'react';
import PropTypes from 'prop-types';
import { LoadingMessage } from '../../messages/LoadingMessage';
import { Configuration } from '../../../classes/models/Configuration';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { ActionsDropdown } from '../../dropdowns/ActionsDropdown';
import { ActionsDropdownItem } from '../../dropdowns/items/ActionsDropdownItem';
import { STRONG } from '../../../constants/Variants';
import { DropdownDivider } from '../../headers/DropdownDivider';
import Company from '../../../classes/models/Company';

export const PricePopover = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.configurator.pricePopover.';

  return (
    <div className="m-r-1">
      {props.configuration && !props.isLoading ? (
        <ActionsDropdown buttonContent={`${t(prefix + 'price')}: € ${props.configuration.price}`}>
          <ActionsDropdownItem
            content={t(prefix + 'vatExclusive', { value: props.configuration.price })}
            variant={STRONG}
          />

          <DropdownDivider />

          {props.vat && <ActionsDropdownItem content={t(prefix + 'vatPercentage', { value: props.vat })} />}

          {props.company && (
            <>
              <ActionsDropdownItem
                content={t(prefix + 'discountCustomWork', { value: props.company.clientType.discount.customWork })}
              />
              <ActionsDropdownItem
                content={t(prefix + 'discountSpecialOperations', {
                  value: props.company.clientType.discount.specialOperations,
                })}
              />
            </>
          )}
        </ActionsDropdown>
      ) : (
        <button type="button" className="button button--outline">
          <LoadingMessage variant="inline" />
        </button>
      )}
    </div>
  );
};

PricePopover.propTypes = {
  isLoading: PropTypes.bool,
  configuration: PropTypes.instanceOf(Configuration),
  company: PropTypes.instanceOf(Company),
  vat: PropTypes.number,
};
