import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { COLLECT, DELIVERY, MAX_DELIVERY_LENGTH, MIN_DELIVERY_THICKNESS } from '../../../constants/DeliveryOptions';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { Title } from '../../headers/Title';
import { SidebarTextItem } from '../../lists/SidebarTextItem';
import { TextArea } from '../../forms/inputs/TextArea';
import { DeliveryChooser } from './DeliveryChooser';
import { SideBar } from '../../navigation/SideBar';
import { Offer } from '../../../classes/models/Offer';
import { InfoModal } from '../../modals/InfoModal';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { OrderService } from '../../../classes/services/OrderService';
import { addAlertMessage, setConfirmationModal, setWindowIsLoading } from '../../../actions/GeneralActions';
import ImageCreator from '../../../classes/services/ImageCreator';
import history from '../../../config/history';
import { DANGER, DATE_PICKER } from '../../../constants/Variants';
import { useDispatch, useSelector } from 'react-redux';
import { RETAIL } from '../../../constants/PriceTypes';
import { MINIMUM_STOCK_AMOUNT } from '../../../constants/StandardMeasurements';
import { parseToCommaSeparated } from '../../../classes/helpers/StringHelper';
import { INVOICE } from '../../../constants/PaymentMethods';
import { PaymentMethodPicker } from './PaymentMethodPicker';
import { UserSelect } from '../../shared/UserSelect';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { DeliveryDateModal } from '../../modals/DeliveryDateModal';
import { OfferService } from '../../../classes/services/OfferService';
import { ADMIN, SUPERVISOR } from '../../../constants/RoleNames';

export const OfferSideBar = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.offers.';
  const appHeaderPrefix = 'headers.appHeader.';
  const orderInfoModalPrefix = 'pages.offers.orderInfoModal.';
  const sidebarPrefix = 'partials.sidebar.';

  const [orderInfoModalMessage, setOrderInfoModalMessage] = useState('');
  const [orderModalIsActive, setOrderModalIsActive] = useState(false);
  const [deliveryDateModalIsActive, setDeliveryDateModalIsActive] = useState(false);
  const [comment, setComment] = useState('');
  const [address, setAddress] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState(COLLECT);
  const [paymentMethod, setPaymentMethod] = useState(INVOICE);
  const [selectedContactPerson, setSelectedContactPerson] = useState();
  const [weight, setWeight] = useState();

  const { priceType, currentUser } = useSelector(state => state.generalReducer);
  const { currentCompanyBranch } = useSelector(state => state.companyReducer);

  const dispatch = useDispatch();

  const orderService = new OrderService();
  const offerService = new OfferService();

  useEffect(() => {
    if (currentCompanyBranch) {
      setDeliveryOption(currentCompanyBranch.defaultDeliveryOption);
    } else {
      setDeliveryOption(COLLECT);
    }
  }, [currentCompanyBranch]);

  useEffect(() => {
    let isMounted = true;

    if (props.offer && props.offer.configurations.length > 0) {
      if (isMounted) {
        offerService.getWeight(props.offer.id).then(weight => setWeight(parseToCommaSeparated(weight)));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [props.offer]);

  const checkDeliveryOption = () => {
    if (deliveryOption === DELIVERY && props.offer.isNotDeliverable()) {
      setDeliveryOption(COLLECT);
    } else {
      return deliveryOption;
    }
  };

  const createOrder = (date = null) => {
    dispatch(setWindowIsLoading(true));

    let imagesCreator = new ImageCreator(props.offer.configurations);

    imagesCreator
      .getImages(true)
      .then(images => {
        const delivery = {
          type: deliveryOption,
          date: date,
        };

        return orderService.create(
          props.offer.id,
          images,
          delivery,
          comment,
          paymentMethod,
          address?.id,
          selectedContactPerson?.id,
        );
      })
      .then(response => {
        if (response.success) {
          history.push('/orders/' + response.data.id);
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, t(prefix + 'orderFailed')));

        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const closeOrderInfoModal = () => {
    setOrderInfoModalMessage('');
  };

  const closeConfirmationModal = () => {
    dispatch(setConfirmationModal({ isActive: false }));
  };

  const openOrderModal = () => {
    if (props.offer.isNotDeliverable()) {
      dispatch(
        setConfirmationModal({
          isActive: true,
          content: t(prefix + 'orderNotDeliverableModalMessage', {
            length: MAX_DELIVERY_LENGTH,
            height: MIN_DELIVERY_THICKNESS,
          }),
          onAccept: hasAccepted => {
            hasAccepted ? openDeliveryModal() : closeConfirmationModal();
          },
        }),
      );
    } else {
      openDeliveryModal();
    }
  };

  const closeDeliveryDateModal = (submitted, date) => {
    if (submitted) {
      if (date) {
        const dateFormat = deliveryOption === COLLECT ? 'DD-MM-YYYY HH:mm:ss' : 'DD-MM-YYYY';

        createOrder(date.format(dateFormat));
      } else {
        createOrder();
      }
    }

    setDeliveryDateModalIsActive(false);
  };

  const openOrderConfirmationModal = () => {
    if (props.offer.configurations > 0) {
      setOrderInfoModalMessage(t(orderInfoModalPrefix + 'noConfigurations'));
      return;
    }

    if (props.offer.containsStockConfiguration()) {
      const amount = props.offer.getStockSquareMeter();

      if (amount < MINIMUM_STOCK_AMOUNT) {
        setOrderInfoModalMessage(
          t(orderInfoModalPrefix + 'insufficientStockAmount', {
            currentAmount: parseToCommaSeparated(amount, 2),
            minimumAmount: MINIMUM_STOCK_AMOUNT,
          }),
        );

        return;
      }
    }

    const emptyConfigurationsPresent = props.offer.configurations.some(
      configuration => configuration.pieces.length === 0,
    );

    if (emptyConfigurationsPresent) {
      setOrderInfoModalMessage(t(orderInfoModalPrefix + 'emptyConfigurations'));
      return;
    }

    const offerHasEmptyPrices = props.offer.configurations.some(configuration => configuration.hasEmptyPrices());

    if (offerHasEmptyPrices) {
      setOrderInfoModalMessage(t(orderInfoModalPrefix + 'configurationsWithNoPrice'));
      return;
    }

    if (deliveryOption === DELIVERY && !address) {
      setOrderInfoModalMessage(t(orderInfoModalPrefix + 'noAddressSelected'));
      return;
    }

    if (props.offer.containsProductsRemovedFromProductLine()) {
      setOrderInfoModalMessage(t(orderInfoModalPrefix + 'configurationsWithProductsRemovedFromProductLine'));
      return;
    }

    setOrderModalIsActive(true);
  };

  const closeOrderConfirmationModal = accepted => {
    if (accepted) {
      createOrder();
    }

    setOrderModalIsActive(false);
  };

  const openDeliveryModal = () => {
    closeConfirmationModal();
    if (hasOneOfRoles([ADMIN, SUPERVISOR])) {
      setDeliveryDateModalIsActive(true);
    } else {
      openOrderConfirmationModal();
    }
  };

  return (
    <>
      <InfoModal
        isActive={orderInfoModalMessage !== ''}
        header={t(prefix + 'orderInfoModalHeader')}
        content={orderInfoModalMessage}
        onClose={() => closeOrderInfoModal()}
      />

      {hasOneOfRoles([ADMIN, SUPERVISOR]) ? (
        <DeliveryDateModal
          onClose={(submitted, date) => closeDeliveryDateModal(submitted, date)}
          isActive={deliveryDateModalIsActive}
          deliveryDate={currentCompanyBranch?.deliveryType.getNextDeliveryDate()}
          showStatus={false}
          variant={deliveryOption === COLLECT ? DATE_PICKER.DATETIME : DATE_PICKER.DATE}
          allowDateInPast
          showUnknownDateOption={true}
        />
      ) : (
        <ConfirmationModal
          isActive={orderModalIsActive}
          onClose={accepted => closeOrderConfirmationModal(accepted)}
          content={t(prefix + 'orderConfirmationModalMessage')}
        />
      )}

      <SideBar right>
        <Title title={t(sidebarPrefix + 'totalPriceTitle')} />
        <SidebarTextItem
          dataCy="offerSideBar-priceExcl"
          description={t(sidebarPrefix + 'priceExcludingVAT')}
          value={props.offer.getFormattedPrice()}
        />
        <SidebarTextItem
          dataCy="offerSideBar-priceIncl"
          description={t(sidebarPrefix + 'priceIncludingVAT')}
          value={props.offer.getFormattedPrice(true)}
        />

        <Title title={t(sidebarPrefix + 'offerDetailsTitle')} />
        <SidebarTextItem description={t(sidebarPrefix + 'offerId')} value={props.offer.id} />
        <SidebarTextItem description={t(sidebarPrefix + 'offerName')} value={props.offer.name} />
        <SidebarTextItem
          description={t(sidebarPrefix + 'customer')}
          value={props.offer.customer ? props.offer.customer.name : '/'}
        />
        <SidebarTextItem description={t(sidebarPrefix + 'date')} value={props.offer.timestamp.date} />
        <SidebarTextItem description={t(sidebarPrefix + 'validUntil')} value={props.offer.getExpiryDate()} />
        {priceType !== RETAIL ? (
          <SidebarTextItem description={t(sidebarPrefix + 'margin')} value={props.offer.margin + '%'} />
        ) : null}
        <SidebarTextItem description={t(sidebarPrefix + 'vat')} value={props.offer.vat + '%'} />
        <SidebarTextItem description={t(sidebarPrefix + 'weight')} value={weight ? `${weight} kg` : '/'} />
        <SidebarTextItem description={t(sidebarPrefix + 'createdBy')} value={props.offer.creator} />

        {hasOneOfRoles([ADMIN, SUPERVISOR]) && (
          <SidebarTextItem
            dataCy={'isVisibleSideBarItem'}
            description={t(sidebarPrefix + 'isVisible')}
            value={props.offer.isVisible ? t(sidebarPrefix + 'yes') : t(sidebarPrefix + 'no')}
          />
        )}

        {hasOneOfRoles([ADMIN, SUPERVISOR]) ||
        (currentCompanyBranch?.canOrder &&
          currentCompanyBranch.id === currentUser.companyBranch?.id &&
          (props.offer.configurations.length || props.offer.outdoorConfigurations.length)) ? (
          <div id="order">
            <Title dataCy="offerSideBar-comment" title={t(prefix + 'commentLabel')} />
            <div className="p-l-2 p-r-2">
              <TextArea value={comment} onBlur={value => setComment(value)} />
            </div>

            {
              // TODO: refactor to a setting so companies can be chosen
              [1, 2, 4, 34].includes(currentCompanyBranch?.id) ? (
                <PaymentMethodPicker
                  dataCy="offerSideBar-payment"
                  method={paymentMethod}
                  onChange={method => setPaymentMethod(method)}
                />
              ) : null
            }

            <DeliveryChooser
              dataCy="offerSideBar-delivery"
              deliveryOption={checkDeliveryOption()}
              setDeliveryOption={option => setDeliveryOption(option)}
              selectedAddress={address}
              setSelectedAddress={a => setAddress(a)}
              isNotDeliverable={props.offer.isNotDeliverable()}
            />

            {hasOneOfRoles([ADMIN, SUPERVISOR]) && (
              <>
                <Title dataCy="offerSideBar-userSelect-title">{t(prefix + 'userSelectLabel')}</Title>
                <div className="p-l-2 p-r-2 m-b-2">
                  <UserSelect users={props.users} onSelect={user => setSelectedContactPerson(user)} />
                </div>
              </>
            )}

            <div className="m-t-3 m-b-3 m-l-2">
              <button
                data-cy="offerSideBar-orderButton"
                id="orderButton"
                className="button button--danger"
                onClick={() => openOrderModal()}
              >
                {t(appHeaderPrefix + 'orderButton')}
              </button>
            </div>
          </div>
        ) : null}
      </SideBar>
    </>
  );
};

OfferSideBar.propTypes = {
  offer: PropTypes.instanceOf(Offer),
  openConfigurationModal: PropTypes.func.isRequired,
  users: PropTypes.array,
};

OfferSideBar.defaultProps = {
  offer: null,
  users: [],
};
