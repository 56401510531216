import MeasurementPoints from '../../../models/MeasurementPoints';
import { BOTTOM, LEFT, RIGHT, TOP } from '../../../../constants/ObjectSides';
import { distanceFromSide } from '../SideMeasurementLineHelper';
import { Vector3 } from 'three';

export function getPointsForRearHeel(dimensions) {
  let bottomMeasurementPoints = new MeasurementPoints(
    getHorizontalPointA(BOTTOM, dimensions),
    getHorizontalPointB(BOTTOM, dimensions),
  );

  let rightMeasurementPoints = new MeasurementPoints(
    getVerticalPointA(RIGHT, dimensions),
    getVerticalPointB(RIGHT, dimensions),
  );

  return {
    horizontal: bottomMeasurementPoints,
    vertical: rightMeasurementPoints,
  };
}

function getHorizontalPointA(side, dimensions) {
  const xPos = dimensions.length / -2;
  const yPos = side === TOP ? dimensions.height / 2 + distanceFromSide : dimensions.height / -2 - distanceFromSide;
  const zPos = dimensions.width / -2;

  return new Vector3(xPos, yPos, zPos);
}

function getHorizontalPointB(side, dimensions) {
  const xPos = dimensions.length / -2;
  const yPos = side === TOP ? dimensions.height / 2 + distanceFromSide : dimensions.height / -2 - distanceFromSide;
  const zPos = dimensions.width / 2;

  return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointA(side, dimensions) {
  const xPos = dimensions.length / -2;
  const yPos = dimensions.height / -2;
  const zPos = side === LEFT ? dimensions.width / -2 - distanceFromSide : dimensions.width / 2 + distanceFromSide;

  return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointB(side, dimensions) {
  const xPos = dimensions.length / -2;
  const yPos = dimensions.height / 2;
  const zPos = side === LEFT ? dimensions.width / -2 - distanceFromSide : dimensions.width / 2 + distanceFromSide;

  return new Vector3(xPos, yPos, zPos);
}
