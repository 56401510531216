import { Service } from './Service';
import { Parser } from '../helpers/Parser';
import moment from 'moment';
import { captureException } from '@sentry/react';

class OrderService extends Service {
  async getAll(priceType, companyBranch, searchKeyword, pageNumber = 1, administrativeStatus) {
    const data = {
      priceType: priceType,
      companyBranchId: companyBranch?.id,
      searchKeyword,
      page: pageNumber,
      administrativeStatus: administrativeStatus,
    };

    return await this.authenticatedRequest('get', 'orders', data)
      .then(response => {
        if (response.data.success)
          response.data.data.data = response.data.data.data.map(orderData => {
            const order = Parser.parseOrder(orderData);

            if (orderData.adsolut_data) {
              order.adsolutData = orderData.adsolut_data;
            }

            return order;
          });
        return response.data;
      })
      .catch(error => {
        captureException(error);
      });
  }

  async getById(id, priceType) {
    const data = {
      priceType: priceType,
    };

    return await this.authenticatedRequest('get', 'orders/' + id, data).then(response => {
      if (response.data.success) response.data.data = Parser.parseOrder(response.data.data);

      return response.data;
    });
  }

  async getFranco(companyBranchId, deliveryDateId) {
    const data = {
      companyBranchId: companyBranchId,
      deliveryDateId: deliveryDateId,
    };
    return await this.authenticatedRequest('get', 'orders/franco', data).then(response => {
      if (response.data.success) {
        return response.data;
      } else {
        throw Error(response.data.message);
      }
    });
  }

  async getWeight(orderId) {
    return await this.authenticatedRequest('get', `orders/${orderId}/weight`).then(response => {
      if (response.data.success) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    });
  }

  async create(offerId, images, delivery, comment, paymentMethod, addressId = null, contactPersonId = null) {
    let data = {
      deliveryType: delivery.type,
      comment,
      paymentMethod,
    };

    if (delivery.date) {
      data.deliveryDate = delivery.date;
    }

    if (contactPersonId) {
      data.contactPersonId = contactPersonId;
    }

    if (addressId) data.addressId = addressId;

    return await this.uploadMultipleFilesRequest('orders/' + offerId, images, data, 'json').then(response => {
      if (response.data.success) response.data.data = Parser.parseOrder(response.data.data);

      return response.data;
    });
  }

  async update(orderId, delivery) {
    return await this.authenticatedRequest('put', 'orders/' + orderId, { delivery })
      .then(response => {
        if (response.data.success) {
          response.data.data = Parser.parseOrder(response.data.data);
        } else {
          throw Error(response.data.message);
        }

        return response.data;
      })
      .catch(error => captureException(error));
  }

  async updateDeliveryDate(orderId, deliveryDate) {
    const data = {};

    if (deliveryDate?.date) {
      data.date = moment(deliveryDate.date).format('YYYY-MM-DD HH:mm:ss');
    }

    if (deliveryDate?.id) {
      data.deliveryDateId = deliveryDate.id;
    }

    return await this.authenticatedRequest('put', 'orders/' + orderId + '/date', data).then(response => {
      if (response.data.success) {
        return Parser.parseOrder(response.data.data);
      } else {
        throw Error(response.data.message);
      }
    });
  }

  async updateAdministrativeStatus(orderId) {
    return await this.authenticatedRequest('post', `orders/${orderId}/administrative-status`).then(response => {
      if (response.data.success) {
        return Parser.parseOrder(response.data.data);
      } else {
        throw Error(response.data.message);
      }
    });
  }

  async updateStatus(orderId, statuses) {
    const data = {
      statuses,
    };

    return await this.authenticatedRequest('put', `orders/${orderId}/statuses`, data).then(response => {
      if (response.data.success) {
        return Parser.parseOrder(response.data.data);
      } else {
        throw Error(response.data.message);
      }
    });
  }

  async recalculatePrices(orderId, images, priceType) {
    return await this.uploadMultipleFilesRequest(`orders/${orderId}/recalculate`, images, { priceType }, 'json').then(
      response => {
        if (response.data.success) {
          return Parser.parseOrder(response.data.data);
        } else {
          throw Error(response.data.message);
        }
      },
    );
  }
}

export { OrderService };
