import { Service } from './Service';
import { Parser } from '../helpers/Parser';

export class ArticleSubstitutionGroupService extends Service {
  async getAll() {
    return await this.authenticatedRequest('get', 'articlesubstitutiongroups').then(response => {
      if (response.data.success) {
        response.data.data = response.data.data.map(data => Parser.parseArticleSubstitutionGroup(data));

        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
  }
}
