import * as React from 'react';
import PropTypes from 'prop-types';
import { COUPE, HEIGHT_COUPE } from '../../../constants/OperationTypes';
import { SectionTitle } from '../../headers/SectionTitle';
import { RadioButton } from '../../forms/inputs/RadioButton';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { NORMAL } from '../../../constants/ConnectObjectStyles';
import { Message } from '../../messages/Message';
import { WARNING } from '../../../constants/Variants';
import { BASEBOARDS, WALL_SLABS } from '../../../constants/Presets';
import { getSideNameByPreset } from '../../../classes/helpers/ObjectHelper';

export const ConnectionSideSelect = props => {
  const prefix = 'modals.connectPieceModal.';
  const constantsPrefix = 'constants.objectSides.';
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const { currentConfiguration, currentPiece } = useSelector(state => state.offerReducer);

  const getCoupes = () => {
    return [BASEBOARDS, WALL_SLABS].includes(currentConfiguration.options.preset)
      ? currentPiece.getOperationsByType(HEIGHT_COUPE)
      : currentPiece.getOperationsByType(COUPE);
  };

  const getAvailableSidesForCoupes = () => {
    return getCoupes()
      .filter(coupe => coupe.connectedCoupe == null)
      .map(coupe => coupe.side);
  };

  const renderCoupeList = () => {
    const coupes = getCoupes();

    if (props.selectedType === COUPE && coupes.length > 0) {
      const availableSidesForCoupes = getAvailableSidesForCoupes();

      return (
        <>
          <SectionTitle content={t(prefix + 'coupeChoiceTitle')} />
          {coupes.map(coupe => {
            return (
              <RadioButton
                dataCy={`connectionSideSelect-side-${getSideNameByPreset(currentConfiguration?.options.preset, coupe.side).toLowerCase()}`}
                key={coupe.id}
                name="coupeRadio"
                onChange={() => props.selectCoupe(coupe)}
                content={t(constantsPrefix + getSideNameByPreset(currentConfiguration?.options.preset, coupe.side))}
                disabled={!availableSidesForCoupes.includes(coupe.side)}
                checked={props.selectedCoupe != null && props.selectedCoupe.id === coupe.id}
              />
            );
          })}
        </>
      );
    }
  };

  const renderEmptyCoupeListMessage = () => {
    const coupes = getCoupes();

    if (props.selectedType === COUPE && coupes.length < 1) {
      return (
        <>
          <SectionTitle content={t(prefix + 'noAvailableCoupesTitle')} />
          <Message
            dataCy="connectionSideSelect-noCoupesAvailableMessage"
            content={t(prefix + 'noAvailableCoupesHelpText')}
            variant={WARNING}
          />
        </>
      );
    }
  };

  const renderConnectionsWithoutCoupe = () => {
    const availableSides = currentPiece.getAvailableSidesToConnectPieces();

    if (props.selectedType === NORMAL && availableSides.length > 0) {
      return (
        <>
          <SectionTitle content={t(prefix + 'availableSideTitle')} />
          {availableSides.map((side, index) => {
            return (
              <RadioButton
                dataCy={`connectionSideSelect-side-${side.toLowerCase()}`}
                key={index}
                name="normalSideRadio"
                onChange={() => props.selectSide(side)}
                content={t(constantsPrefix + side)}
                checked={props.selectedSide === side}
              />
            );
          })}
        </>
      );
    }
  };

  return (
    <>
      {renderCoupeList()}
      {renderEmptyCoupeListMessage()}
      {renderConnectionsWithoutCoupe()}
    </>
  );
};

ConnectionSideSelect.propTypes = {
  selectedType: PropTypes.string,
  selectedSide: PropTypes.string,
  selectedCoupe: PropTypes.object,
  selectCoupe: PropTypes.func.isRequired,
  selectSide: PropTypes.func.isRequired,
};
