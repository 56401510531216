import { LEFT, RIGHT } from '../../constants/ObjectSides';

export function sortObjectsAlphabeticallyByName(objectA, objectB) {
  if (objectA.name < objectB.name) {
    return -1;
  }
  if (objectA.name > objectB.name) {
    return 1;
  }
  return 0;
}

export function sortObjectsAlphabeticallyByDescription(objectA, objectB) {
  if (objectA.getDescription() < objectB.getDescription()) {
    return -1;
  }
  if (objectA.getDescription() > objectB.getDescription()) {
    return 1;
  }
  return 0;
}

export function sortBySide(objectA, objectB) {
  if (objectA.side === LEFT) {
    return -1;
  }

  if (objectA.side === RIGHT) {
    return 1;
  }

  return 0;
}
