import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalHolder } from './ModalHolder';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { BACK, FRONT, LEFT, RIGHT } from '../../constants/ObjectSides';
import { SectionTitle } from '../headers/SectionTitle';
import { CheckboxWithIcon } from '../forms/inputs/CheckboxWithIcon';
import { updateSandedSides } from './index';
import { EXTRA_SANDING_5CM_BOTTOM } from '../../constants/OperationTypes';

export const ExtraSandingModal = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.extraSandingModal.';
  const sidesPrefix = 'constants.objectSides.';

  const [selectedSides, setSelectedSides] = useState([]);

  const { canEdit } = useSelector(state => state.generalReducer);
  const { currentPiece } = useSelector(state => state.offerReducer);

  const availableSides = [FRONT, LEFT, BACK, RIGHT];

  useEffect(() => {
    if (currentPiece) {
      setSelectedSides(currentPiece.getOperationsByType(EXTRA_SANDING_5CM_BOTTOM).map(operation => operation.side));
    }
  }, [props.isActive]);

  const onClose = submitted => {
    if (submitted) {
      updateSandedSides(selectedSides, t(prefix + 'updateFailedMessage'));
    }

    props.onClose();
  };

  const toggleAllSidesSelected = () => {
    if (allSidesAreSelected()) setSelectedSides([]);
    else setSelectedSides(availableSides);
  };

  const allSidesAreSelected = () => {
    let allSelected = true;

    availableSides.forEach(side => {
      if (allSelected && !selectedSides.includes(side)) allSelected = false;
    });

    return allSelected;
  };

  const updateSelectedSides = side => {
    let sides = [...selectedSides];

    const existingSideIndex = sides.indexOf(side);

    if (existingSideIndex > -1) {
      sides.splice(existingSideIndex, 1);
    } else {
      sides.push(side);
    }

    setSelectedSides(sides);
  };

  const renderFooterButtons = () => {
    const submitButton = (
      <button
        key={'extraSandingFooterButton1'}
        data-cy={'sandingextraModal-submitButton'}
        className="button button--primary"
        type="button"
        onClick={() => onClose(true)}
        form="singleInputForm"
      >
        {t(prefix + 'submitButton')}
      </button>
    );

    let buttons = [
      <button
        key={'extraSandingFooterButton2'}
        data-cy={'sandingextraModal-deleteButton'}
        className="button button--outline"
        type="button"
        onClick={() => onClose()}
      >
        {t(prefix + 'cancelButton')}
      </button>,
    ];

    if (canEdit) {
      buttons.unshift(submitButton);
    }

    return buttons;
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={() => onClose()} animation={true} className="modal">
        <Modal.Header>
          <Modal.Title>{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(prefix + 'description')}</p>

          <SectionTitle content={t(prefix + 'sidesTitle')} />

          <strong>
            <CheckboxWithIcon
              onChange={() => toggleAllSidesSelected()}
              content={t(prefix + 'selectAllSidesCheckbox')}
              checked={allSidesAreSelected()}
              disabled={!canEdit}
            />
          </strong>

          {availableSides.map(side => {
            return (
              <CheckboxWithIcon
                key={`sandingExtraSide-${side}`}
                onChange={() => updateSelectedSides(side)}
                content={t(sidesPrefix + side)}
                checked={selectedSides.includes(side)}
                disabled={!canEdit}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

ExtraSandingModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
