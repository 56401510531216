import React, { useEffect, useState } from 'react';
import { OffersAppHeader } from './OffersAppHeader';
import { EmptyOffer } from '../../empty/EmptyOffer';
import { Title } from '../../headers/Title';
import { ConfigurationList } from '../../lists/ConfigurationList';
import ArticleList from '../../lists/ArticleList';
import { AppContent } from '../../layout/AppContent';
import { Offer } from '../../../classes/models/Offer';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { ConfigurationModal } from '../../modals/configurationModal/ConfigurationModal';
import { useOffers } from '../../../classes/hooks/useOffers';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentOffer } from '../../../actions/OfferActions';
import history from '../../../config/history';
import { useParams } from 'react-router-dom';
import ConfigurableReducerHelper from '../../../classes/helpers/ConfigurableReducerHelper';
import {
  addAlertMessage,
  setActiveInfoModal,
  setConfirmationModal,
  setShouldUpdatePrice,
  setWindowIsLoading,
} from '../../../actions/GeneralActions';
import { captureException, captureMessage } from '@sentry/react';
import OutdoorShopService from '../../../classes/services/OutdoorShopService';
import { setActiveArticle, setArticleConfig } from '../../../actions/OutdoorShopActions';
import { OfferSideBar } from './OfferSideBar';
import { dateIsBetween } from '../../../classes/helpers/DateHelper';
import { InfoModal } from '../../modals/InfoModal';
import moment from 'moment';
import { OfferService } from '../../../classes/services/OfferService';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { USER } from '../../../constants/RoleNames';
import { DANGER, SUCCESS } from '../../../constants/Variants';
import { CompanyBranchService } from '../../../classes/services/CompanyBranchService';
import { AddOfferModal } from '../../modals/AddOfferModal';
import { useCustomers } from '../../../classes/hooks/useCustomers';
import { LoadingMessage } from '../../messages/LoadingMessage';
import { AppHolder } from '../../layout/AppHolder';
import { NavigationBar } from '../../navigation/NavigationBar';
import { usePrevious } from '../../../classes/hooks/usePrevious';
import { setCurrentCompanyBranch } from '../../../actions/CompanyActions';

export const OfferDetail = () => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.offers.';

  const [configurationModalIsActive, setConfigurationModalIsActive] = useState(false);
  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);
  const [isPriceChangeInfoModalViewed, setIsPriceChangeInfoModalViewed] = useState(false);
  const [companyBranchUsers, setCompanyBranchUsers] = useState([]);
  const [companyBranchContacts, setCompanyBranchContacts] = useState([]);
  const [offerModalIsActive, setOfferModalIsActive] = useState(false);
  const [companyBranches, setCompanyBranches] = useState([]);

  const { loadOffers, deleteOffer, setIsUpdatingArticleOffers, updateOffer } = useOffers();
  const dispatch = useDispatch();
  const urlParameters = useParams();
  const previousUrlParameters = usePrevious(urlParameters);

  const { currentOffer, currentConfiguration } = useSelector(state => state.offerReducer);
  const { currentPriceVersion, priceType, shouldUpdatePrice, windowIsLoading, confirmationModal, activeInfoModal } =
    useSelector(state => state.generalReducer);
  const { currentCompanyBranch, currentCompanyBranchId = currentCompanyBranch?.id } = useSelector(
    state => state.companyReducer,
  );

  const configurableReducerHelper = new ConfigurableReducerHelper();
  const offerService = new OfferService();
  const outdoorShopService = new OutdoorShopService();
  const companyBranchService = new CompanyBranchService();

  const { reloadCustomers, customers } = useCustomers();

  useEffect(() => {
    openPriceChangeInfoModal();
  }, [currentOffer, currentPriceVersion]);

  useEffect(() => {
    if (!urlParameters.id) return;

    let isMounted = true;

    if (previousUrlParameters?.id !== urlParameters.id || shouldUpdatePrice) {
      offerService
        .getByIdComplete(urlParameters.id, priceType)
        .then(response => {
          if (isMounted) {
            if (response.data) {
              dispatch(setCurrentOffer(response.data));
              if (shouldUpdatePrice) {
                dispatch(setShouldUpdatePrice(false));
              }

              if (!hasOneOfRoles(USER) && currentCompanyBranch?.id !== response.data.companyBranch.id) {
                // When the page is refreshed on an offer that does not belong to the current user
                dispatch(setCurrentCompanyBranch(response.data.companyBranch));
              }
            } else {
              showOfferList();
            }
          }
        })
        .catch(error => {
          if (isMounted) {
            captureException(error);

            dispatch(addAlertMessage(DANGER, t(prefix + 'fetchOfferFailedMessage')));
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [urlParameters.id, shouldUpdatePrice]);

  useEffect(() => {
    if (hasOneOfRoles(USER)) return;

    let isMounted = true;

    if (currentOffer) {
      companyBranchService
        .getUsers(currentOffer.companyBranch.id)
        .then(response => {
          if (isMounted) {
            setCompanyBranchUsers(response.data);
          }
        })
        .catch(error => {
          captureException(error);

          dispatch(addAlertMessage(DANGER, t(prefix + 'fetchUsersFailedMessage')));
        });
    }

    return () => {
      isMounted = false;
    };
  }, [currentOffer]);

  useEffect(() => {
    if (hasOneOfRoles(USER)) return;

    let isMounted = true;

    companyBranchService.getAll().then(response => {
      if (isMounted) {
        setCompanyBranches(response.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [currentCompanyBranchId, companyBranchUsers]);

  useEffect(() => {
    if (hasOneOfRoles(USER)) return;

    let isMounted = true;

    if (currentCompanyBranchId) {
      companyBranchService
        .getContactPersons(currentCompanyBranchId)
        .then(response => {
          if (isMounted) {
            setCompanyBranchContacts(response.data);
          }
        })
        .catch(error => {
          captureException(error);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [currentCompanyBranchId]);

  const duplicateOffer = () => {
    dispatch(setWindowIsLoading(true));

    offerService
      .duplicateOffer(currentOffer.id)
      .then(response => {
        if (response.success) {
          dispatch(addAlertMessage(SUCCESS, t(prefix + 'duplicateOfferSuccessMessage')));
        } else {
          throw Error(response.data.message);
        }
      })
      .catch(e => {
        captureException(e);
        dispatch(addAlertMessage(DANGER, t(prefix + 'duplicateOfferFailedMessage')));
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const onUpdateArticle = article => {
    dispatch(setActiveArticle(article.article));
    dispatch(setArticleConfig(article));
    // props.onEditArticleClick();
  };

  const showOfferList = () => {
    if (urlParameters.id) {
      history.push('/offers');
    } else {
      configurableReducerHelper.updateConfigurable(null);
    }
  };

  const openDeleteOutdoorConfigurationConfirmationModal = (event, outdoorConfiguration) => {
    // Added this to prevent the onClick of the higher order tag to fire (onUpdate())
    event.stopPropagation();

    dispatch(
      setConfirmationModal({
        isActive: true,
        content: t(prefix + 'deleteOutdoorConfigurationConfirmationModalContent'),
        onAccept: hasAccepted => onCloseDeleteOutdoorConfigurationConfirmationModal(hasAccepted, outdoorConfiguration),
      }),
    );
  };

  const onCloseDeleteOutdoorConfigurationConfirmationModal = (hasAccepted, outdoorConfiguration) => {
    if (hasAccepted) {
      outdoorShopService
        .delete(currentOffer.id, outdoorConfiguration.id)
        .then(response => {
          if (response.success) {
            setIsUpdatingArticleOffers(true);
            dispatch(setShouldUpdatePrice(true));
          } else {
            throw Error(response.message);
          }
        })
        .catch(error => {
          captureMessage(error.message);
        });
    }
  };

  const closeConfigurationModal = shouldRefreshScreen => {
    if (shouldRefreshScreen) {
      loadOffers().then(offerList => {
        if (!offerList) return;

        offerList.forEach(offer => {
          if (offer.id === currentOffer.id) dispatch(setCurrentOffer(offer));
        });
      });
    }

    setConfigurationModalIsActive(false);
  };

  const closeDeleteModal = confirmed => {
    if (!currentOffer) return;

    if (confirmed) {
      deleteOffer().then(() => {
        showOfferList();
      });
    }

    setDeleteModalIsActive(false);
  };

  const openPriceChangeInfoModal = () => {
    if (hasOneOfRoles(USER) && currentPriceVersion && currentOffer && !isPriceChangeInfoModalViewed) {
      if (
        !dateIsBetween(
          moment(currentOffer.timestamp.milliseconds),
          currentPriceVersion.start,
          currentPriceVersion.end,
        ) &&
        !isPriceChangeInfoModalViewed
      ) {
        dispatch(setActiveInfoModal(true, t('messages.priceChangeInfoContent')));
        setIsPriceChangeInfoModalViewed(true);

        offerService.updateExpiryDate(currentOffer.id).catch(error => {
          captureException(error);
        });
      }
    }
  };

  const closeOfferModal = offer => {
    setOfferModalIsActive(false);

    if (!offer) return;

    const { name, customer, margin, vat, isVisible } = offer;

    if (
      currentOffer.name === name &&
      currentOffer.margin === margin &&
      currentOffer.vat === vat &&
      currentOffer.customer?.name === customer?.name &&
      currentOffer.isVisible === isVisible
    ) {
      return;
    }

    updateOffer(name, customer, margin, vat, currentCompanyBranchId, isVisible).then(() => {
      if (customer && customer.id === 0) {
        reloadCustomers();
      }
    });
  };

  if (!currentOffer)
    return (
      <AppHolder>
        <LoadingMessage variant="overlay" />
      </AppHolder>
    );

  return (
    <AppHolder>
      {windowIsLoading && <LoadingMessage variant="overlay" />}

      <NavigationBar />

      <AddOfferModal
        isActive={offerModalIsActive}
        onClose={(offer, companyBranchId) => closeOfferModal(offer, companyBranchId)}
        offer={currentOffer ?? new Offer()}
        isEditing={true}
        customers={customers}
        companyBranches={companyBranches}
      />

      <AppContent>
        <InfoModal
          isActive={activeInfoModal.isActive}
          onClose={() => dispatch(setActiveInfoModal(false, ''))}
          content={activeInfoModal.content}
        />

        <ConfirmationModal
          isActive={deleteModalIsActive}
          onClose={confirmed => closeDeleteModal(confirmed)}
          content={t(prefix + 'deleteConfirmation')}
        />

        <ConfirmationModal
          isActive={confirmationModal.isActive}
          onClose={hasAccepted => confirmationModal.onAccept(hasAccepted)}
          content={confirmationModal.content}
        />

        <ConfigurationModal
          isActive={configurationModalIsActive}
          onClose={shouldRefreshOffers => closeConfigurationModal(shouldRefreshOffers)}
          configuration={currentConfiguration}
        />

        <OffersAppHeader
          offer={currentOffer}
          onBackClick={() => showOfferList()}
          openConfigurationModal={() => setConfigurationModalIsActive(true)}
          openOfferModal={() => setOfferModalIsActive(true)}
          openDeleteOfferModal={() => setDeleteModalIsActive(true)}
          onDuplicateClick={() => duplicateOffer()}
        />

        {!currentOffer.configurations.length && !currentOffer.outdoorConfigurations.length ? (
          <EmptyOffer
            offerName={currentOffer.name}
            onAddConfigurationClick={() => setConfigurationModalIsActive(true)}
          />
        ) : null}

        {currentOffer.configurations.length ? (
          <>
            <div className="m-l-2">
              <Title>{t(prefix + 'configurationsListTitle')}</Title>
            </div>

            <ConfigurationList
              configurations={currentOffer.configurations}
              openConfigurationModal={() => setConfigurationModalIsActive(true)}
            />
          </>
        ) : null}

        {currentOffer.outdoorConfigurations.length > 0 ? (
          <>
            <div className="m-l-2">
              <Title>{t(prefix + 'outdoorConfigurationsListTitle')}</Title>
            </div>
            <ArticleList
              outdoorConfigurations={currentOffer.outdoorConfigurations}
              updateArticle={onUpdateArticle}
              deleteArticle={(event, configurationToDelete) =>
                openDeleteOutdoorConfigurationConfirmationModal(event, configurationToDelete)
              }
            />
          </>
        ) : null}
      </AppContent>

      <OfferSideBar
        offer={currentOffer}
        users={companyBranchUsers.concat(companyBranchContacts)}
        openConfigurationModal={() => setConfigurationModalIsActive(true)}
      />
    </AppHolder>
  );
};
