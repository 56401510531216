import { ChiseledPiece } from '../../internal';
import { Vector3 } from 'three';

class Type2Piece extends ChiseledPiece {
  constructor(id, dimensions = { length: 0, height: 0, width: 0 }, operations = [], position = new Vector3()) {
    super(id, dimensions, operations, position);
  }
}

export { Type2Piece };
