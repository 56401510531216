import * as React from 'react';
import PropTypes from 'prop-types';
import { DANGER, INFO, SUCCESS, WARNING } from '../../constants/Variants';

export const Message = props => {
  const getClassName = variant => {
    let styling = 'message message--';

    switch (variant) {
      case INFO:
        styling += 'info';
        break;
      case SUCCESS:
        styling += 'success';
        break;
      case DANGER:
        styling += 'alert';
        break;
      case WARNING:
        styling += 'warning';
        break;
      default:
        styling += 'default';
        break;
    }

    return styling;
  };

  const close = () => {
    if (props.onClick != null) {
      props.onClick();
    }
  };

  return (
    <div data-cy={props.dataCy} className={getClassName(props.variant)} onClick={() => close()}>
      {props.content}
    </div>
  );
};

Message.propTypes = {
  content: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  dataCy: PropTypes.string,
};
