import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from '../../headers/SectionTitle';
import { Message } from '../../messages/Message';
import { WARNING } from '../../../constants/Variants';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { EXTERIOR, INTERIOR } from '../../../constants/Values';
import { BLUESTONE, GRANITE, MARBLE } from '../../../constants/Materials';
import { TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8 } from '../../../constants/ObjectTypes';
import { ConfigOption } from '../../forms/selects/ConfigOption';
import { parseNumber } from '../../../classes/helpers/StringHelper';
import { ValidatedInput } from '../../forms/inputs/ValidatedInput';
import { REAR_HEEL_STANDARD_HEIGHTS, STANDARD_HEIGHTS } from '../../../constants/StandardMeasurements';
import { CUSHIONS, REAR_HEELS, SILLS_STOCK } from '../../../constants/Presets';
import DimensionValidator from '../../../classes/helpers/DimensionValidator';
import { HEIGHT } from '../../../constants/Dimensions';

export const HeightSelect = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.configurationModal.';

  const [warningMessage, setWarningMessage] = useState('');

  const dimensionValidator = new DimensionValidator();

  useEffect(() => {
    handleWarningMessage();
  }, [
    props.selectedOptions.height,
    props.selectedOptions.type,
    props.selectedOptions.preset,
    props.selectedOptions.subPreset,
  ]);

  const handleWarningMessage = () => {
    if ([REAR_HEELS, CUSHIONS].includes(props.selectedOptions.preset)) return;

    let newWarningMessage = '';
    if (
      props.selectedOptions.height &&
      props.selectedOptions.material === BLUESTONE &&
      props.selectedOptions.decor === EXTERIOR
    ) {
      if (props.selectedOptions.preset === REAR_HEELS) {
        if (!REAR_HEEL_STANDARD_HEIGHTS.includes(+props.selectedOptions.height)) {
          newWarningMessage = t('messages.rearHeelExtraCostMessage');
        }
      } else {
        if (+props.selectedOptions.height > dimensionValidator.getMaxValue(HEIGHT, props.selectedOptions)) {
          newWarningMessage = `${t('messages.heightToHighMessage')} ${dimensionValidator.getMaxValue(HEIGHT, props.selectedOptions)} cm`;
        } else if (!STANDARD_HEIGHTS.includes(+props.selectedOptions.height)) {
          if ([TYPE_5, TYPE_6, TYPE_7, TYPE_8].includes(props.selectedOptions.type)) {
            newWarningMessage = t('messages.extraCostsHeightType5Through8');
          } else if ([TYPE_3, TYPE_4].includes(props.selectedOptions.type)) {
            newWarningMessage = t('messages.changeTypeMessage');
          } else {
            newWarningMessage = t('messages.extraCostsHeight');
          }
        }
      }
    }

    if (props.selectedOptions.subPreset === SILLS_STOCK) {
      newWarningMessage = t('messages.fixedHeightStockSills');
    }

    if (warningMessage !== newWarningMessage) setWarningMessage(newWarningMessage);
  };

  const getHeightElements = () => {
    let heightElements;

    if (props.selectedOptions.material === BLUESTONE && props.selectedOptions.decor === EXTERIOR) {
      heightElements = renderBlueStoneHeightInput();
    } else if (props.selectedOptions.material === GRANITE) {
      heightElements = renderGraniteHeightOptions();
    } else {
      heightElements = renderHeightOptions2And3();
    }

    return heightElements;
  };

  const renderBlueStoneHeightInput = () => {
    let heightLabel;
    const minValue = dimensionValidator.getMinValue(HEIGHT, props.selectedOptions);
    const maxValue = dimensionValidator.getMaxValue(HEIGHT, props.selectedOptions);

    switch (props.selectedOptions.type) {
      case TYPE_1:
      case TYPE_2:
        heightLabel = t(prefix + 'heightLabelType1And2');
        break;
      case TYPE_3:
      case TYPE_4:
        heightLabel = t(prefix + 'heightLabelType3And4');
        break;
      case TYPE_5:
      case TYPE_6:
      case TYPE_7:
      case TYPE_8:
        heightLabel = t(prefix + 'heightLabelType5To8');
        break;
      default:
        heightLabel = t(prefix + 'heightLabelType1And2');
    }

    return (
      <>
        <ValidatedInput
          dataCy="heightSelect-input"
          label={heightLabel}
          name="height"
          placeholder={t(prefix + 'heightPlaceholder')}
          value={props.selectedOptions.height}
          register={props.inputRegister}
          required={true}
          min={minValue}
          max={maxValue}
          error={props.inputErrors.height}
          onChange={value => props.setHeight(value)}
          disabled={props.selectedOptions.subPreset === SILLS_STOCK}
        />
        <small>{t(prefix + 'heightSubText', { minValue: minValue, maxValue: maxValue })}</small>
      </>
    );
  };

  const renderGraniteHeightOptions = () => {
    if (props.selectedOptions.decor === INTERIOR) {
      return renderHeightOptions2And3();
    } else {
      return (
        <div className="configuration__options">
          {[3, 5].map(height => {
            return (
              <ConfigOption
                dataCy={`heightSelect-graniteOption-${height}`}
                key={height}
                name="height"
                content={`${height} cm`}
                onClick={() => props.setHeight(height)}
                selected={parseNumber(props.selectedOptions.height) === height}
              />
            );
          })}
        </div>
      );
    }
  };

  const renderHeightOptions2And3 = () => {
    let heightList = [2, 3];

    return (
      <div className="configuration__options">
        {heightList.map(height => {
          return (
            <ConfigOption
              dataCy={`heightSelect-graniteOption-${height}`}
              key={`heightSelect${height}`}
              name="height"
              content={height + ' cm'}
              onClick={() => props.setHeight(height)}
              selected={parseNumber(props.selectedOptions.height) === height}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      {props.isVisible && (
        <>
          <SectionTitle content={t(prefix + 'heightTitle')} />
          {getHeightElements()}
          {warningMessage !== '' && (
            <Message dataCy="heightSelect-warningMessage" content={warningMessage} variant={WARNING} />
          )}
        </>
      )}
    </>
  );
};

HeightSelect.propTypes = {
  isVisible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  selectedOptions: PropTypes.object,
  setHeight: PropTypes.func.isRequired,
  inputRegister: PropTypes.func.isRequired,
  inputErrors: PropTypes.object,
};
