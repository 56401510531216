import i18n from '../../i18n/index';
import { parseNumber, parseToCommaSeparated } from './StringHelper';

class SummaryHelper {
  static getConfigurationSummary(options) {
    let prefix = 'common:constants.';
    let summary = '';

    Object.keys(options).forEach(key => {
      if (options[key] != null) {
        if (typeof options[key] != 'string') {
          options[key] = options[key].toString();
        }

        switch (key) {
          case 'decor':
            summary += i18n.t(prefix + 'values.' + options[key]);
            break;
          case 'preset':
            summary += ' - ' + i18n.t(prefix + 'presets.' + options[key]);
            break;
          case 'material':
            summary += ' - ' + i18n.t(prefix + 'materials.' + options[key]);
            break;
          case 'materialColor':
            summary += ' - ' + i18n.t(prefix + 'materialColors.' + options[key]);
            break;
          case 'type':
            summary += ' - ' + i18n.t(prefix + 'types.' + options[key]);
            break;
          case 'height':
            summary += ' - ' + options[key] + ' cm';
            break;
          default:
            break;
        }
      }
    });

    return summary;
  }

  static getDebasingSummary(operation) {
    let squareMeter = ((operation.dimensions.length * operation.dimensions.width) / 10000).toFixed(2);
    squareMeter = parseToCommaSeparated(squareMeter);
    const height = parseNumber(operation.dimensions.height).toFixed(0);

    return `${height} cm - ${squareMeter} m²`;
  }
}

export { SummaryHelper };
