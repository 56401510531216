import i18n from '../../i18n';
import { HANDELED, NEEDS_REVIEW, UNHANDELED } from '../../constants/AdministrativeStatuses';
import { TYPES } from '../../constants/ObjectTypes';
import { ALL, WATERLIST } from '../../constants/OperationTypes';
import { ALL_SIDES } from '../../constants/ObjectSides';
import { COMPLETELY, NONE, PARTIALLY } from '../../constants/FinishedSideStates';
import { EXTERIOR, INTERIOR } from '../../constants/Values';
import { ALL_PRESETS, SILLS_CUSTOM, SILLS_STOCK } from '../../constants/Presets';
import { ALL_MATERIAL_QUALITIES } from '../../constants/MaterialQualities';
import { MATERIALS } from '../../constants/Materials';
import { ALL_MATERIAL_COLORS } from '../../constants/MaterialColors';

export class TranslateLogService {
  static SPLIT_OPERATOR_COLON = ': ';
  static SPLIT_OPERATOR_ARROW = ' -> ';

  getModelTranslationKey(model) {
    let key;

    switch (model) {
      case 'App\\Order':
        key = 'order';
        break;
      case 'App\\ConfigurationOptions':
        key = 'configurationOptions';
        break;
      case 'App\\Piece':
        key = 'piece';
        break;
      case 'App\\Operation':
        key = 'operation';
        break;
      case 'App\\DeliveryDate':
        key = 'deliveryDate';
        break;
    }

    return i18n.t('app:modals.logsModal.models.' + key);
  }

  getPropertyTranslation(model, property, values = []) {
    if (!property) {
      return;
    }

    let propertyTranslation;
    let valuesTranslation;

    switch (model) {
      case 'App\\Order':
        propertyTranslation = this.getOrderPropertyTranslation(property);
        valuesTranslation = this.getOrderPropertyValuesTranslation(values);
        break;
      case 'App\\Piece':
        propertyTranslation = this.getPiecePropertyTranslation(property);
        valuesTranslation = this.getPieceValuesTranslation(values).join(' -> ');
        break;
      case 'App\\Operation':
        propertyTranslation = this.getOperationPropertyTranslation(property);
        valuesTranslation = this.getOperationValuesTranslation(values);
        break;
      case 'App\\ConfigurationOptions':
        propertyTranslation = this.getConfigurationOptionsPropertyTranslation(property);
        valuesTranslation = this.getConfigurationOptionsValuesTranslation(values);
        break;
      case 'App\\DeliveryDate':
        propertyTranslation = this.getDeliveryDatePropertyTranslation(property);
        valuesTranslation = values;
        break;
    }

    valuesTranslation = Array.isArray(valuesTranslation)
      ? valuesTranslation.join(TranslateLogService.SPLIT_OPERATOR_ARROW)
      : valuesTranslation;

    return propertyTranslation + TranslateLogService.SPLIT_OPERATOR_COLON + valuesTranslation;
  }

  getOrderPropertyTranslation(property) {
    const prefix = 'app:modals.logsModal.properties.order.';

    return i18n.t(prefix + property.trim());
  }

  getOrderPropertyValuesTranslation(values) {
    return values
      .map(value => {
        value = value.trim();

        if ([HANDELED, UNHANDELED, NEEDS_REVIEW].includes(value)) {
          return i18n.t('app:constants.administrativeStatuses.' + value);
        }

        return value;
      })
      .join(' -> ');
  }

  getPiecePropertyTranslation(property) {
    const prefix = 'app:modals.logsModal.properties.piece.';

    return i18n.t(prefix + property);
  }

  getPieceValuesTranslation(values) {
    return values.map(v => {
      if (TYPES.includes(v)) {
        return i18n.t('app:constants.types.' + v);
      }

      return v;
    });
  }

  getOperationPropertyTranslation(property) {
    const prefix = 'app:modals.logsModal.properties.operation.';

    return i18n.t(prefix + property);
  }

  getOperationValuesTranslation(values) {
    return values.map(v => {
      if (ALL.includes(v) || v === WATERLIST) {
        return i18n.t('app:constants.operationTypes.' + v);
      }

      if (ALL_SIDES.includes(v)) {
        return i18n.t('app:constants.objectSides.' + v);
      }

      if ([PARTIALLY, COMPLETELY, NONE].includes(v)) {
        return i18n.t('app:constants.finishedSideStates.' + v);
      }

      return v;
    });
  }

  getConfigurationOptionsPropertyTranslation(property) {
    const prefix = 'app:modals.logsModal.properties.configurationOptions.';

    return i18n.t(prefix + property);
  }

  getConfigurationOptionsValuesTranslation(values) {
    return values.map(v => {
      if (TYPES.includes(v)) {
        return i18n.t('app:constants.types.' + v);
      }

      if ([INTERIOR, EXTERIOR].includes(v)) {
        return i18n.t('app:constants.values.' + v);
      }

      if (ALL_PRESETS.includes(v)) {
        return i18n.t('app:constants.presets.' + v);
      }

      if ([SILLS_CUSTOM, SILLS_STOCK].includes(v)) {
        return i18n.t('app:constants.presets.' + v);
      }

      if (ALL_MATERIAL_QUALITIES.includes(v)) {
        return i18n.t('app:constants.materialQualities.' + v);
      }

      if (MATERIALS.includes(v)) {
        return i18n.t('app:constants.materials.' + v);
      }

      if (ALL_MATERIAL_COLORS.includes(v)) {
        return i18n.t('app:constants.materialColors.' + v);
      }

      return v;
    });
  }

  getDeliveryDatePropertyTranslation(property) {
    const prefix = 'app:modals.logsModal.properties.deliveryDate.';

    return i18n.t(prefix + property);
  }
}
