import { getYPosForSide } from '../SurfaceMeasurementLineHelper';
import MeasurementPoints from '../../../models/MeasurementPoints';
import { VectorHelper } from '../../VectorHelper';
import { Vector3 } from 'three';
import { BACK, FRONT, LEFT } from '../../../../constants/ObjectSides';

export function getPointsForRoundedCorner(pieceDimensions, roundedCorner) {
  let horizontalPoints;

  const lineYPos = getYPosForSide(pieceDimensions) + 0.05;

  roundedCorner.position = VectorHelper.getRoundedCornerPosition(pieceDimensions, roundedCorner);

  const horizontalPointA = getHorizontalPointA(roundedCorner.position, lineYPos);

  horizontalPoints = new MeasurementPoints(horizontalPointA, getHorizontalPointB(roundedCorner, horizontalPointA));

  horizontalPoints.textSide = getTextSide(roundedCorner.additionalDimension.type);
  horizontalPoints.lineLength = roundedCorner.dimensions.length;

  return {
    horizontal: horizontalPoints,
    vertical: null,
  };
}

function getHorizontalPointA(position, lineYPos) {
  return new Vector3(position.x, lineYPos, position.z);
}

function getHorizontalPointB(roundedCorner, horizontalPointA) {
  const direction = getDirection(roundedCorner.side, roundedCorner.additionalDimension.type);
  const distance = roundedCorner.dimensions.length;

  return new Vector3().addVectors(horizontalPointA, direction.multiplyScalar(distance));
}

function getDirection(side, additionalType) {
  let direction;

  if (side === LEFT) {
    if (additionalType === FRONT) {
      direction = new Vector3(-1, 0, 1);
    } else {
      // BACK
      direction = new Vector3(-1, 0, -1);
    }
  } else {
    // RIGHT
    if (additionalType === FRONT) {
      direction = new Vector3(1, 0, 1);
    } else {
      // BACK
      direction = new Vector3(1, 0, -1);
    }
  }

  return direction.normalize();
}

function getTextSide(additionalType) {
  let textSide;

  if (additionalType === FRONT) {
    textSide = BACK;
  } else {
    // BACK
    textSide = FRONT;
  }

  return textSide;
}
