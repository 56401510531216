import { Service } from './Service';
import { Parser } from '../helpers/Parser';

export class UserSettingsService extends Service {
  async get(userId) {
    return await this.authenticatedRequest('get', `usersettings/${userId}`).then(response => {
      response.data.data = Parser.parseUserSettings(response.data.data);

      return response;
    });
  }

  async update(userSettings) {
    const body = { priceType: userSettings.priceType };

    return await this.authenticatedRequest('put', 'usersettings', body).then(response => {
      response.data.data = Parser.parseUserSettings(response.data.data);

      return response;
    });
  }
}
