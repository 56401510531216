import * as ActionTypes from '../constants/ActionTypes';

export function setCurrentOffer(payload) {
  return {
    type: ActionTypes.SET_CURRENT_OFFER,
    payload: payload,
  };
}

export function setCurrentConfiguration(configuration) {
  return {
    type: ActionTypes.SET_CURRENT_CONFIGURATION,
    payload: configuration,
  };
}

export function setCurrentPiece(piece) {
  return {
    type: ActionTypes.SET_CURRENT_PIECE,
    payload: piece,
  };
}

export function setCurrentOperation(operation) {
  return {
    type: ActionTypes.SET_CURRENT_OPERATION,
    payload: operation,
  };
}
