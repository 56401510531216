import { Parser } from '../helpers/Parser';
import { Service } from './Service';

export class LogService extends Service {
  async getForOrder(orderId) {
    return await this.authenticatedRequest('get', 'logs/order/' + orderId).then(response => {
      if (response.data.success) {
        response.data.data = response.data.data.map(logData => {
          return Parser.parseLog(logData);
        });

        return response.data;
      }
    });
  }
}
