export const FRONT = 'FRONT';
export const LEFT = 'LEFT';
export const BACK = 'BACK';
export const RIGHT = 'RIGHT';
export const TOP = 'TOP';
export const BOTTOM = 'BOTTOM';
export const OTHER = 'OTHER';

export const ALL_SIDES = [FRONT, BACK, LEFT, RIGHT, TOP, BOTTOM];

export const SIDES = [FRONT, BACK, LEFT, RIGHT];

export const SURFACES = [TOP, BOTTOM];

export const PROFILE_SIDES_TYPE_1 = [FRONT, LEFT, BACK, RIGHT];

export const PROFILE_SIDES_TYPE_3 = [FRONT, LEFT, RIGHT];

export const PROFILE_SIDES_TYPE_5 = [FRONT, LEFT, RIGHT];

export const PROFILE_SIDES_TYPE_7 = [FRONT];

export const PROFILE_SIDES_TYPE_9 = [BACK, LEFT, RIGHT];
