import * as React from 'react';
import PropTypes from 'prop-types';
import { ModalHolder } from './ModalHolder';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { SectionTitle } from '../headers/SectionTitle';

export const HelpModal = props => {
  const prefix = 'modals.helpModal.';
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={props.onClose} animation={true} className="modal">
        <Modal.Header>
          <Modal.Title>{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SectionTitle content={t(prefix + 'contactInformationTitle')} />
          <div>
            <strong>Tilly Vrancken</strong>
          </div>
          <div className="m-t-2">
            <span className="d-flex align-items-center">
              <i className="material-icons color--blue m-r-1">phone</i> 012 25 04 33
              <br />
            </span>
            <span className="d-flex align-items-center m-t-1">
              <i className="material-icons color--blue m-r-1">email</i> tilly@lambrechts-nicolaers.be
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button button-blue" onClick={() => props.onClose(true)}>
            {t(prefix + 'closeButton')}
          </div>
        </Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

HelpModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
