import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfigurationList } from '../../lists/ConfigurationList';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { Title } from '../../headers/Title';
import ArticleList from '../../lists/ArticleList';
import { AppContent } from '../../layout/AppContent';
import { Order } from '../../../classes/models/Order';
import { OrderDetailAppHeader } from './OrderDetailAppHeader';
import { LogService } from '../../../classes/services/LogService';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { LogsModal } from '../../modals/LogsModal';
import { ADMIN, SUPERVISOR } from '../../../constants/RoleNames';

export const OrderDetail = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.orders.';

  const [logsModalIsActive, setLogsModalIsActive] = useState(false);
  const [logs, setLogs] = useState([]);

  const logService = new LogService();

  useEffect(() => {
    if (hasOneOfRoles([ADMIN, SUPERVISOR])) {
      logService.getForOrder(props.order?.id).then(response => {
        // Only get the logs created after the order was created
        setLogs(response.data.filter(l => l.createdAt.isAfter(props.order.createdAt)));
      });
    }
  }, []);

  return (
    <AppContent>
      {hasOneOfRoles([ADMIN, SUPERVISOR]) && (
        <LogsModal isActive={logsModalIsActive} closeModal={() => setLogsModalIsActive(false)} logs={logs} />
      )}

      <OrderDetailAppHeader
        order={props.order}
        onEditClick={() => props.onEditOrderClick()}
        onDeliveryDateUpdateClick={() => props.onDeliveryDateUpdateClick()}
        onOpenLogsClick={() => setLogsModalIsActive(true)}
      />

      {!!props.order?.configurations.length && (
        <>
          <div className="m-l-4">
            <Title>{t(prefix + 'configurationsListTitle')}</Title>
          </div>

          <ConfigurationList configurations={props.order.configurations} />
        </>
      )}

      {!!(props.order && props.order.outdoorConfigurations.length) && (
        <>
          <div className="m-l-4">
            <Title>{t(prefix + 'outdoorConfigurationsListTitle')}</Title>
          </div>
          <ArticleList outdoorConfigurations={props.order.outdoorConfigurations} isInOrder />
        </>
      )}
    </AppContent>
  );
};

OrderDetail.propTypes = {
  order: PropTypes.instanceOf(Order),
  onEditOrderClick: PropTypes.func.isRequired,
  onDeliveryDateUpdateClick: PropTypes.func.isRequired,
};
