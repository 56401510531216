import { Part } from '../models/Part';
import { REAR_HEEL_1_2, REAR_HEEL_5_2, REAR_HEEL_CUSTOM, REAR_HEEL_TYPES } from '../../constants/ObjectTypes';
import DimensionValidator from './DimensionValidator';
import { LENGTH } from '../../constants/Dimensions';
import { store } from '../../store';

export function getMinPartAmount(length, width, height, type = '') {
  let maxPieceLength;

  if (REAR_HEEL_TYPES.includes(type)) {
    maxPieceLength = getRearHeelMaxLength({ length, width, height }, type);
  } else {
    maxPieceLength = getMaxLengthForStandardTypes(width, height);
  }

  return Math.ceil(length / maxPieceLength);
}

export function getMaxPartAmount(length) {
  let minLength = getMinLength();

  // Min length could be less than 10, but for calculating the maximum amount of parts it should be at least 10
  // Otherwise if the min length is 1, a piece of 100 cm could be divided in 100 parts which is way too much
  if (minLength < 10) minLength = 10;

  return Math.floor(length / minLength);
}

export function dividePieceInParts(length, width, height, type, desiredAmountOfParts = null) {
  length = parseFloat(length);
  width = parseFloat(width);
  height = parseFloat(height);

  let amountOfParts;
  let lengths = [];

  const maxAmountOfParts = getMaxPartAmount(length);
  const minAmountOfParts = getMinPartAmount(length, width, height, type);

  if (desiredAmountOfParts && desiredAmountOfParts <= maxAmountOfParts && desiredAmountOfParts >= minAmountOfParts) {
    amountOfParts = desiredAmountOfParts;
  } else {
    amountOfParts = getParts(length, width, height, type);
  }

  for (let i = 0; i < amountOfParts; i++) {
    let partLength = roundLengthForDivision(length / amountOfParts);
    if (i === amountOfParts - 1) {
      partLength = length - (amountOfParts - 1) * partLength;
    }
    lengths.push(partLength);
  }

  return lengths.map(l => new Part(0, l));
}

export function getMaxLengthForStandardTypes(width, height) {
  let maxLength;

  if (height === 1) {
    if (width < 5) maxLength = 120;
    else if (width >= 5 && width < 10) maxLength = 150;
    else if (width >= 10 && width < 30) maxLength = 170;
    else maxLength = 200;
  } else if (height === 2 || height === 3) {
    if (width < 5) maxLength = 120;
    else if (width >= 5 && width < 10) maxLength = 150;
    else if (width >= 10 && width < 30) maxLength = 200;
    else maxLength = 250;
  } else {
    // height >= 4
    if (width < 5) maxLength = 150;
    else if (width >= 5 && width < 10) maxLength = 170;
    else if (width >= 10 && width < 30) maxLength = 220;
    else maxLength = 250;
  }

  return maxLength;
}

export function getRearHeelMaxLength(dimensions, type) {
  let maxLength;

  switch (type) {
    case REAR_HEEL_1_2:
      maxLength = 120;
      break;
    case REAR_HEEL_5_2:
      maxLength = 170;
      break;
    case REAR_HEEL_CUSTOM:
      if (dimensions.height < 2 || dimensions.width < 2) {
        maxLength = 120;
      } else {
        maxLength = 170;
      }
      break;
  }

  return maxLength;
}

export function getMaxLengthForType(length, width, height, type) {
  let maxPieceLength;

  if (REAR_HEEL_TYPES.includes(type)) {
    maxPieceLength = getRearHeelMaxLength({ length, width, height }, type);
  } else {
    maxPieceLength = getMaxLengthForStandardTypes(width, height);
  }

  return maxPieceLength;
}

export function getMinLength() {
  const dimensionValidator = new DimensionValidator();
  const { currentConfiguration } = store.getState().offerReducer;

  return dimensionValidator.getMinValue(LENGTH, currentConfiguration?.options);
}

/**
 * Rounds the given length conforming the rules given by the client
 */
export function roundLengthForDivision(length) {
  let afterDecimal = length % 1;
  length -= afterDecimal;

  afterDecimal = Math.round((afterDecimal + Number.EPSILON) * 100) / 100;

  return length + afterDecimal;
}

function getParts(length, width, height, type) {
  const maxLength = getMaxLengthForType(length, width, height, type);

  return getIdealPartAmount(length, maxLength);
}

function getIdealPartAmount(length, maxLength) {
  let amountOfParts;
  const idealLength = maxLength === 150 ? 150 : 170;

  amountOfParts = Math.ceil(length / idealLength);

  while (length / amountOfParts > maxLength) {
    amountOfParts += 1;
  }

  return amountOfParts;
}
