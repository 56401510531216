import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../headers/SectionTitle';
import { RadioButton } from '../forms/inputs/RadioButton';
import { ConfirmationModal } from './ConfirmationModal';
import { useForm } from 'react-hook-form';
import { WARNING } from '../../constants/Variants';
import { Operation } from '../../classes/models/Operation';
import * as OperationTypes from '../../constants/OperationTypes';
import { Message } from '../messages/Message';
import { createOperation, deleteOperation, updateOperation } from './index';
import { ModalHolder } from './ModalHolder';
import {
  ALL_GROOVE_TYPES,
  BUSH_HAMMERED_TYPES,
  CHISELED,
  CHISELED_TYPES,
  LUPATO_TYPES,
} from '../../constants/GrooveTypes';

function GroovesModal(props) {
  const prefix = 'modals.groovesModal.';

  const operationType = OperationTypes.GROOVES;

  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const { handleSubmit } = useForm();

  const [selectedType, setSelectedType] = useState('');
  const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
  const [canCreateGrooves, setCanCreateGrooves] = useState(false);

  const currentOperation = useSelector(state => state.offerReducer.currentOperation);
  const currentPiece = useSelector(state => state.offerReducer.currentPiece);
  const canEdit = useSelector(state => state.generalReducer.canEdit);

  useEffect(() => {
    if (currentOperation == null) {
      const groovesArePresent =
        currentPiece?.operations.filter(operation => operation.type === OperationTypes.GROOVES).length > 0;
      setCanCreateGrooves(groovesArePresent);

      if (!groovesArePresent) {
        setSelectedType(CHISELED);
      }
    } else {
      if (currentPiece) {
        setCanCreateGrooves(false);
      }
      setSelectedType(currentOperation.additionalDimension.type);
    }
  }, [currentPiece, currentOperation]);

  const createGrooves = () => {
    let grooves = new Operation(0, operationType);
    grooves.additionalType = selectedType;

    createOperation(grooves, () => props.onClose(true), t(prefix + 'groovesCreateFailed'));
  };

  const updateGrooves = () => {
    currentOperation.type = operationType;
    currentOperation.additionalDimension.type = selectedType;

    updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'groovesUpdateFailed'));
  };

  const deleteGrooves = () => {
    deleteOperation(
      currentOperation,
      () => props.onClose(true),
      t(prefix + 'groovesDeleteSuccess'),
      t(prefix + 'groovesDeleteFailed'),
    );
  };

  const closeModal = submit => {
    if (!submit) {
      props.onClose();
      return;
    }

    if (currentOperation == null) {
      createGrooves();
    } else {
      updateGrooves();
    }
  };

  const renderTypeSelect = () => {
    return (
      <>
        <SectionTitle content={t(prefix + 'chiseledTitle')} />

        {CHISELED_TYPES.map(grooveType => {
          return (
            <RadioButton
              key={`${grooveType}RadioButton`}
              name="groovesType"
              content={t('constants.grooveTypes.' + grooveType)}
              checked={selectedType === grooveType}
              disabled={canCreateGrooves || !canEdit}
              onChange={() => onTypeChange(grooveType)}
            />
          );
        })}

        <SectionTitle content={t(prefix + 'bushTitle')} />

        {BUSH_HAMMERED_TYPES.map(grooveType => {
          return (
            <RadioButton
              name="groovesType"
              key={`${grooveType}RadioButton`}
              content={t('constants.grooveTypes.' + grooveType)}
              checked={selectedType === grooveType}
              disabled={canCreateGrooves || !canEdit}
              onChange={() => onTypeChange(grooveType)}
            />
          );
        })}

        <SectionTitle content={t(prefix + 'lupatoTitle')} />

        {LUPATO_TYPES.map(grooveType => {
          return (
            <RadioButton
              key={`${grooveType}RadioButton`}
              name="groovesType"
              content={t('constants.grooveTypes.' + grooveType)}
              checked={selectedType === grooveType}
              disabled={canCreateGrooves || !canEdit}
              onChange={() => onTypeChange(grooveType)}
            />
          );
        })}
      </>
    );
  };

  const onTypeChange = value => {
    if (!ALL_GROOVE_TYPES.includes(value)) return;

    // Set the new value for the radio buttons, so the correct one is shown as selected
    setSelectedType(value);
  };

  const renderFooterButtons = () => {
    let secondaryButtonStyle = 'button button--outline';
    let secondaryButtonContent = t(prefix + 'cancelButton');
    let secondaryButtonOnClick = () => closeModal();

    if (currentOperation != null && canEdit) {
      secondaryButtonStyle = 'button button--danger';
      secondaryButtonContent = t(prefix + 'deleteButton');
      secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
    }

    const submitButton = (
      <button
        key={'groovesModalSubmitButton'}
        className="button button--primary"
        form="GroovesForm"
        data-cy={'groovesModal-submitButton'}
        disabled={canCreateGrooves}
        type="submit"
      >
        {t(prefix + 'doneButton')}
      </button>
    );

    let buttons = [
      <button
        key={'groovesModalCancelButton'}
        data-cy={'groovesModal-deleteButton'}
        type="button"
        className={secondaryButtonStyle}
        onClick={secondaryButtonOnClick}
      >
        {secondaryButtonContent}
      </button>,
    ];

    if (canEdit) {
      buttons.unshift(submitButton);
    }

    return buttons;
  };

  const toggleConfirmationModal = userHasAccepted => {
    if (userHasAccepted) {
      deleteGrooves();
    }

    setConfirmationModalIsActive(!confirmationModalIsActive);
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <ConfirmationModal
        isActive={confirmationModalIsActive}
        onClose={userHasAccepted => toggleConfirmationModal(userHasAccepted)}
        content={t('modals.groovesModal.deleteGrooves')}
      />

      <Modal show={props.isActive} onHide={() => closeModal()} animation={true}>
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="GroovesForm" onSubmit={handleSubmit(() => closeModal(true))}>
            <p>{t(prefix + 'description')}</p>

            {renderTypeSelect()}
            {canCreateGrooves ? <Message variant={WARNING} content={t(prefix + 'cannotCreateGroovesMessage')} /> : null}
          </form>
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
}

GroovesModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { GroovesModal };
