import { store } from '../../../store';
import ConfigurableReducerHelper from '../../../classes/helpers/ConfigurableReducerHelper';
import { setShouldUpdatePrice } from '../../../actions/GeneralActions';
import { dividePieceInParts } from '../../../classes/helpers/PieceDivisionHelper';
import { ConfigurationService } from '../../../classes/services/ConfigurationService';

function dispatch(callback) {
  store.dispatch(callback);
}

export function updatePartsOfPieces(configuration) {
  const configurationService = new ConfigurationService();
  const { currentOffer, currentConfiguration } = store.getState().offerReducer;

  const pieces = configuration.pieces.map(piece => {
    piece.parts = dividePieceInParts(
      piece.dimensions.length,
      piece.dimensions.width,
      piece.dimensions.height,
      configuration.options.type,
      piece.parts.length,
    );
    return piece;
  });

  return configurationService.updatePartsOfPieces(currentConfiguration.id, pieces);
}

export function handleConfigurationUpdate(updatedConfiguration, callback) {
  const configurableReducerHelper = new ConfigurableReducerHelper();

  dispatch(setShouldUpdatePrice(true));
  configurableReducerHelper.updateConfiguration(updatedConfiguration);

  if (callback) callback();
}
