import OutdoorConfiguration from '../models/OutdoorConfiguration';
import { Service } from './Service';
import { Parser } from '../helpers/Parser';

export default class OutdoorShopService extends Service {
  async getArticles() {
    try {
      const { data } = await this.authenticatedRequest('get', 'outdoorarticles');
      if (data.success) {
        return Parser.parseOutdoorArticles(data.data);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  getArticleByCategory2(articles = [], category) {
    return articles.filter(article => article.category2 === category);
  }

  getArticlesByCategory3(articles = [], category) {
    return articles.filter(article => article.category3 === category);
  }

  async getArticleConfigurations() {
    try {
      const { data } = await this.authenticatedRequest('GET', 'offers', {
        priceType: 'DEFAULT',
      });
      return data.data;
    } catch (e) {
      throw new Error(e);
    }
  }

  async create(configuration, articleId) {
    const data = {
      offerId: configuration.offerId,
      title: configuration.title,
      amount: configuration.amount,
      margin: configuration.margin,
    };

    return await this.authenticatedRequest('POST', `outdoorconfigurations/${articleId}`, data).then(response => {
      response.data.data = OutdoorConfiguration.parse(response.data.data);
      return response.data;
    });
  }

  async update(outdoorConfigurationId, data) {
    try {
      const response = await this.authenticatedRequest('PUT', `outdoorconfigurations/${outdoorConfigurationId}`, data);

      return response;
    } catch (e) {
      throw new Error(e);
    }
  }

  async delete(offerId, outdoorConfigurationId) {
    try {
      const data = await this.authenticatedRequest('DELETE', `outdoorconfigurations/${outdoorConfigurationId}`, {
        offerId,
      });

      return data.data;
    } catch (e) {
      throw new Error(e);
    }
  }
}
