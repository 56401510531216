import { OutdoorArticle } from './OutdoorArticle';

class OutdoorConfiguration {
  constructor(id, offerId, title, price, amount, margin = 0) {
    this.id = id;
    this.offerId = offerId;
    this.title = title;
    this.price = price;
    this.amount = amount;
    this.margin = margin;
    this.article = null;
  }

  static parse(data) {
    let configuration = new OutdoorConfiguration(
      data.id,
      data.offer_id,
      data.title,
      data.price,
      data.amount,
      data.margin,
    );

    if (data.article) configuration.article = OutdoorArticle.parse(data.article);

    return configuration;
  }
}

export default OutdoorConfiguration;
