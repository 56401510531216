import ClientType from './ClientType';
import { COLLECT } from '../../constants/DeliveryOptions';

class Company {
  constructor() {
    this.id = 0;
    this.name = '';
    this.vatNumber = '';
    this.address = '';
    this.clientType = new ClientType(0, '', { customWork: 0, specialOperations: 0 });
    this.branches = [];
    this.defaultDeliveryOption = COLLECT;
    this.articleSubstitutionGroup = null;
    this.logo = { name: '', file: '' };
  }

  hasBranchThatCanOrder() {
    return this.branches.some(branch => branch.canOrder);
  }
}

export default Company;
