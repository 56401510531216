import * as React from 'react';
import PropTypes from 'prop-types';
import { CENTIMETERS, DEGREES } from '../../../constants/Values';
import { SectionTitle } from '../../headers/SectionTitle';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useEffect, useState } from 'react';
import { parseNumber } from '../../../classes/helpers/StringHelper';

export const WidthCoupeInput = props => {
  const prefix = 'modals.connectPieceModal.';
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const [inputType, setInputType] = useState(DEGREES);

  const changeInputType = type => {
    props.onInputTypeChange(type);
    setInputType(type);
  };

  const getTitle = () => {
    let title;

    if (inputType === DEGREES) {
      title = t(prefix + 'degreesTitle');
    } else {
      title = t(prefix + 'centimetersTitle');
    }

    return title;
  };

  const renderButtons = () => {
    let degreesButtonClass = 'button button--active';
    let centimetersButtonClass = 'button button--outline';

    if (inputType === CENTIMETERS) {
      degreesButtonClass = 'button button--outline';
      centimetersButtonClass = 'button button--active';
    }

    return (
      <div className="button-group w-100 m-l-1">
        <button
          data-cy="widthCoupeInput-degreesButton"
          className={`${degreesButtonClass} w-100`}
          type="button"
          onClick={() => changeInputType(DEGREES)}
          disabled={props.disabled}
        >
          {t(prefix + 'degreesButton')}
        </button>
        <button
          data-cy="widthCoupeInput-centimetersButton"
          className={`${centimetersButtonClass} w-100`}
          type="button"
          onClick={() => changeInputType(CENTIMETERS)}
          disabled={props.disabled}
        >
          {t(prefix + 'centimeterButton')}
        </button>
      </div>
    );
  };

  return (
    <div>
      <SectionTitle content={getTitle()} />
      <div className="d-flex align-items-bottom">
        <div className="form-group m-0-i ">
          <label className="m-0-i">
            <input
              data-cy="widthCoupeInput-input"
              className="w-100"
              name="coupeInput"
              type="text"
              placeholder={t(prefix + 'degreesPlaceholder')}
              onChange={event => props.onInputChange(parseNumber(event.target.value))}
              disabled={props.disabled}
            />
          </label>
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};

WidthCoupeInput.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onInputTypeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
