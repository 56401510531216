import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { getOperationIcon } from '../../../classes/helpers/OperationHelper';
import * as PieceActionTypes from '../../../constants/PieceActionTypes';
import { FINISHED_SIDE, PROFILE } from '../../../constants/OperationTypes';
import { EXTERIOR_PRESETS_WITHOUT_WATERLISTS } from '../../../constants/Presets';
import { useSelector } from 'react-redux';
import { EXTERIOR } from '../../../constants/Values';
import { TYPE_10, TYPE_9 } from '../../../constants/ObjectTypes';

export const OperationListItem = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const typePrefix = 'constants.operationTypes.';
  const pieceActionsPrefix = 'constants.pieceActionTypes.';

  const { currentConfiguration } = useSelector(state => state.offerReducer);

  const getContent = () => {
    let content;
    let infoExtra = props.infoExtra;

    if (PieceActionTypes.ALL.includes(props.type)) {
      content = t(pieceActionsPrefix + props.type);
    } else if (props.type === FINISHED_SIDE) {
      if (
        EXTERIOR_PRESETS_WITHOUT_WATERLISTS.includes(currentConfiguration?.options.preset) ||
        currentConfiguration?.options.decor !== EXTERIOR
      ) {
        content = t(typePrefix + props.type);
      } else {
        content = t(typePrefix + 'FINISHED_SIDE_AND_WATERLIST');
      }
    } else if (props.type === PROFILE && [TYPE_9, TYPE_10].includes(currentConfiguration?.options.type)) {
      content = t(typePrefix + 'PROFILE_TYPE_9_AND_10');
      infoExtra = '';
    } else {
      content = t(typePrefix + props.type);
    }

    if (infoExtra) content += ' - ' + infoExtra;

    return content;
  };

  return (
    <div data-cy={props.dataCy} className="list__item list__item--operation" onClick={() => props.onClick()}>
      <div className="list__item__icon">
        <img
          src={getOperationIcon(props.type, props.side, props.additionalType)}
          alt={'Icon of a ' + props.type.toLowerCase()}
        />
      </div>
      <span className="d-flex flex-column ">
        <span>{getContent()}</span>
        <small className="color--red">{props.subText}</small>
      </span>
      <small>{props.info}</small>
    </div>
  );
};

OperationListItem.propTypes = {
  type: PropTypes.string.isRequired,
  additionalType: PropTypes.string,
  side: PropTypes.string,
  info: PropTypes.string,
  infoExtra: PropTypes.string,
  subText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
};
