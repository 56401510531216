import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { parseNumber, parseToCommaSeparated } from '../../../classes/helpers/StringHelper';

export const DimensionInput = props => {
  const [value, setValue] = useState('');

  let editableSpan = useRef();

  useEffect(() => {
    props.value === 0 ? setValue('-') : setValue(props.value);
  }, []);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onEnterPress = event => {
    if (event.keyCode === 13) {
      window.getSelection().removeAllRanges();
      onBlur(event);
    }
  };

  const onFocus = () => {
    if (typeof value === 'string' && value.includes('-')) setValue('');
  };

  const onBlur = event => {
    const dimension = event.target.textContent.replace('<div><br></div>', '<br>');

    if (dimension === 0 || dimension === '') setValue('-');

    if (dimension && !isNaN(parseNumber(dimension))) {
      props.onBlur(dimension);
    }
  };

  return (
    <div className={props.emphasize ? 'configuration__objects__spec color--red' : 'configuration__objects__spec'}>
      <label>{props.label}</label>
      <span
        data-cy={`${props.dataCy}Input`}
        ref={span => (editableSpan = span)}
        onKeyDown={event => onEnterPress(event)}
        suppressContentEditableWarning={true}
        contentEditable={!props.disabled}
        onBlur={event => onBlur(event)}
        onFocus={() => onFocus()}
      >
        {parseToCommaSeparated(value)}
      </span>
    </div>
  );
};

DimensionInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  emphasize: PropTypes.bool,
  onBlur: PropTypes.func,
  dataCy: PropTypes.string,
};

DimensionInput.defaultProps = {
  disabled: false,
  emphasize: false,
  value: 0,
};
