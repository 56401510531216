import * as React from 'react';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { useTranslation } from 'react-i18next';
import { ALL } from '../../../constants/PriceTypes';
import PropTypes from 'prop-types';

export const PriceTypeSelect = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.settings.priceTypeSelect.';
  const constantsPrefix = 'constants.priceTypes.';

  return (
    <div className="m-b-2">
      <div className="form-group">
        <label>{t(prefix + 'label')}</label>
        <select onChange={event => props.onChange(event.target.value)} value={props.value}>
          {ALL.map(priceType => {
            return (
              <option key={priceType} value={priceType}>
                {t(constantsPrefix + priceType)}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

PriceTypeSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
