import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { SectionTitle } from '../headers/SectionTitle';
import { ModalHolder } from './ModalHolder';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP } from '../../constants/ObjectSides';
import { CheckboxWithIcon } from '../forms/inputs/CheckboxWithIcon';
import { RadioButton } from '../forms/inputs/RadioButton';
import { RABAT } from '../../constants/OperationTypes';
import { createOperation, deleteOperation, updateOperation } from './index';
import { Operation } from '../../classes/models/Operation';
import { useForm } from 'react-hook-form';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { setConfirmationModal } from '../../actions/GeneralActions';
import { getSideNameByPreset } from '../../classes/helpers/ObjectHelper';

export const RabatModal = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.rabatModal.';
  const sidePrefix = 'constants.objectSides.';

  const eligibleSides = [FRONT, LEFT, BACK, RIGHT];
  const eligibleSurfaces = [TOP, BOTTOM];

  const [isFinished, setIsFinished] = useState(false);
  const [selectedSide, setSelectedSide] = useState();
  const [selectedSurface, setSelectedSurface] = useState();
  const [availableSides, setAvailableSides] = useState([]);
  const [formIsValid, setFormIsValid] = useState();

  const { register, errors, handleSubmit } = useForm();

  const { canEdit } = useSelector(state => state.generalReducer);
  const { currentConfiguration, currentPiece, currentOperation } = useSelector(state => state.offerReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isActive) {
      updateAvailableSides();

      setSelectedSide(currentOperation?.side);
      setSelectedSurface(currentOperation?.additionalDimension.type);
      setIsFinished(currentOperation?.isFinished);
    }
  }, [props.isActive]);

  useEffect(() => {
    if (selectedSide && selectedSurface) {
      if (!formIsValid) setFormIsValid(true);
    } else {
      if (formIsValid) setFormIsValid(false);
    }
  }, [selectedSide, selectedSurface]);

  const onClose = fields => {
    if (!fields) {
      props.onClose();
      return;
    }

    if (currentOperation) {
      // Update
      updateRabat(fields);
    } else {
      // Create
      createRabat(fields);
    }
  };

  const createRabat = ({ width, height }) => {
    let operation = new Operation(0, RABAT);
    operation.isFinished = isFinished;
    operation.additionalDimension.type = selectedSurface;
    operation.side = selectedSide;
    operation.dimensions = { width, height };

    createOperation(operation, () => props.onClose(true), t(prefix + 'createFailedMessage'));
  };

  const updateRabat = ({ width, height }) => {
    currentOperation.isFinished = isFinished;
    currentOperation.additionalDimension.type = selectedSurface;
    currentOperation.side = selectedSide;
    currentOperation.dimensions = { width, height };

    updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'updateFailedMessage'));
  };

  const deleteRabat = () => {
    deleteOperation(
      currentOperation,
      () => props.onClose(true),
      t(prefix + 'deleteSuccessMessage'),
      t(prefix + 'deleteFailedMessage'),
    );
  };

  const toggleRabatIsFinished = () => {
    setIsFinished(!isFinished);
  };

  const updateAvailableSides = () => {
    const sidesTakenByRabat = currentPiece.getOperationsByType(RABAT).map(operation => operation.side);

    let sides = [...eligibleSides.filter(side => !sidesTakenByRabat.includes(side))];
    if (currentOperation) sides.push(currentOperation.side);

    setAvailableSides(sides);
  };

  const openConfirmationModal = () => {
    dispatch(
      setConfirmationModal({
        isActive: true,
        content: t(prefix + 'confirmDeleteMessage'),
        onAccept: () => deleteRabat(),
      }),
    );
  };

  const renderFooterButtons = () => {
    let secondaryButtonStyle = 'button button--outline';
    let secondaryButtonContent = t(prefix + 'cancelButton');
    let secondaryButtonOnClick = () => onClose();

    if (currentOperation != null && canEdit) {
      secondaryButtonStyle = 'button button--danger';
      secondaryButtonContent = t(prefix + 'deleteButton');
      secondaryButtonOnClick = () => openConfirmationModal();
    }

    const submitButton = (
      <button
        key={'roundedCornerModalSubmitButton'}
        data-cy={'rabatModal-submitButton'}
        className="button button--primary"
        type="submit"
        form="rabatForm"
        disabled={!formIsValid}
      >
        {t(prefix + 'doneButton')}
      </button>
    );

    let buttons = [
      <button
        key={'roundedCornerModalCancelButton'}
        data-cy={'rabatModal-deleteButton'}
        type="button"
        className={secondaryButtonStyle}
        onClick={secondaryButtonOnClick}
      >
        {secondaryButtonContent}
      </button>,
    ];

    if (canEdit) {
      buttons.unshift(submitButton);
    }

    return buttons;
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={() => onClose()} animation={true}>
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(prefix + 'description')}</p>

          <form id="rabatForm" onSubmit={handleSubmit(onClose)}>
            <SectionTitle content={t(prefix + 'surfaceSelectionTitle')} />

            {eligibleSurfaces.map(surface => {
              return (
                <RadioButton
                  key={`rabat${surface}`}
                  name="rabatSide"
                  content={t(sidePrefix + getSideNameByPreset(currentConfiguration?.options.preset, surface))}
                  checked={selectedSurface === surface}
                  disabled={!canEdit}
                  onChange={() => setSelectedSurface(surface)}
                />
              );
            })}

            <SectionTitle content={t(prefix + 'sideSelectionTitle')} />

            {eligibleSides.map(side => {
              return (
                <RadioButton
                  key={`rabat${side}`}
                  name="rabatSide"
                  dataCy={''}
                  content={t(sidePrefix + getSideNameByPreset(currentConfiguration?.options.preset, side))}
                  checked={selectedSide === side}
                  disabled={!availableSides.includes(side) || !canEdit}
                  onChange={() => setSelectedSide(side)}
                />
              );
            })}

            <SectionTitle content={t(prefix + 'dimensionTitle')} />

            <div className="row">
              <div className="col-6">
                <ValidatedInput
                  register={register}
                  name="width"
                  dataCy={'width'}
                  label={t(prefix + 'widthLabel')}
                  placeholder={t(prefix + 'widthPlaceholder')}
                  value={currentOperation?.dimensions.width}
                  required
                  min={0.4}
                  max={currentPiece?.dimensions.width / 2}
                  error={errors?.width}
                  disabled={!canEdit}
                />
              </div>

              <div className="col-6">
                <ValidatedInput
                  register={register}
                  name="height"
                  dataCy={'height'}
                  label={t(prefix + 'heightLabel')}
                  placeholder={t(prefix + 'heightPlaceholder')}
                  value={currentOperation?.dimensions.height}
                  required
                  min={0.4}
                  max={currentPiece?.dimensions.height - 0.5}
                  error={errors?.height}
                  disabled={!canEdit}
                />
              </div>
            </div>

            <SectionTitle content={t(prefix + 'finishedTitle')} />

            <CheckboxWithIcon
              content={t(prefix + 'finishedCheckboxContent')}
              checked={isFinished}
              disabled={!canEdit}
              onChange={() => toggleRabatIsFinished()}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

RabatModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
