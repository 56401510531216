import * as React from 'react';
import PropTypes from 'prop-types';
import * as Countries from '../../../constants/Countries';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { Address } from '../../../classes/models/Address';

export const CountrySelect = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'forms.selects.countrySelect.';
  const constantsPrefix = 'constants.countries.';
  const validationPrefix = 'validation.';

  return (
    <div className="form-group">
      <label>{t(prefix + 'countryLabel')}</label>
      <select ref={props.register({ required: true })} name="country" defaultValue={'default'}>
        <option hidden disabled defaultValue={props.address?.country}>
          {t(prefix + 'countryPlaceholder')}
        </option>
        {Countries.ALL.map((countryName, index) => (
          <option key={index} value={countryName}>
            {t(constantsPrefix + countryName)}
          </option>
        ))}
      </select>
      {props.error?.type === 'required' ? (
        <small className="form-errortext">{t(validationPrefix + 'required')}</small>
      ) : null}
    </div>
  );
};

CountrySelect.props = {
  register: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  address: PropTypes.instanceOf(Address),
};
