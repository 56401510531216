import * as React from 'react';
import PropTypes from 'prop-types';
import { Message } from './Message';

export function MessageHolder(props) {
  const renderMessage = (index, variant, content) => {
    return <Message key={index} content={content} variant={variant} onClick={() => props.onClose(index)} />;
  };

  const renderMessages = () => {
    return (
      <div className="message--holder pointer">
        {props.messages &&
          props.messages.map((message, index) => {
            return renderMessage(index, message.variant, message.content);
          })}
      </div>
    );
  };

  return renderMessages();
}

MessageHolder.propTypes = {
  messages: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
