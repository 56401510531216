import {
  BASEBOARDS,
  CHIMNEY_COVERS,
  CUSHIONS,
  CUSTOM_TILE,
  ENTREPORTS,
  LINTELS,
  PILLARS,
  REAR_HEELS,
  SILLS,
  SILLS_STOCK,
  STAIR_RISERS,
  STAIR_STEPS,
  WALL_COVERS,
  WALL_SLABS,
  WINDOW_SLABS,
} from './Presets';
import { INTERIOR } from './Values';
import { GRANITE } from './Materials';

export const TYPE_1 = 'TYPE_1';
export const TYPE_2 = 'TYPE_2';
export const TYPE_3 = 'TYPE_3';
export const TYPE_4 = 'TYPE_4';
export const TYPE_5 = 'TYPE_5';
export const TYPE_6 = 'TYPE_6';
export const TYPE_7 = 'TYPE_7';
export const TYPE_8 = 'TYPE_8';
export const TYPE_9 = 'TYPE_9';
export const TYPE_10 = 'TYPE_10';
export const REAR_HEEL_1_2 = 'REAR_HEEL_1_2';
export const REAR_HEEL_5_2 = 'REAR_HEEL_5_2';
export const REAR_HEEL_CUSTOM = 'REAR_HEEL_CUSTOM';
export const CUSHION_NOT_GLUED = 'CUSHION_NOT_GLUED';

export const TYPES = [TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8, TYPE_9, TYPE_10];

export const CHISELED_OBJECT_TYPES = [TYPE_2, TYPE_4, TYPE_6, TYPE_8, TYPE_10];

export const INVERTED_DIMENSION_TYPES = [CUSHION_NOT_GLUED];

//region Exterior
export const WINDOW_SLAB_TYPES = [TYPE_1];

export const ENTREPORT_TYPES = [TYPE_1];

export const WALL_SLAB_TYPES = [TYPE_1];
//endregion

//region Exterior
// Dorpels
export const SILL_TYPES = [TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8];

export const SILL_STOCK_TYPES = [TYPE_1, TYPE_2];

export const WALL_COVER_TYPES = {
  INTERIOR: [TYPE_1],
  EXTERIOR: [TYPE_1, TYPE_2],
};

export const CHIMNEY_COVER_TYPES = [TYPE_1, TYPE_2];

export const BASEBOARD_TYPES = [TYPE_1, TYPE_9, TYPE_10];

export const STAIR_STEP_TYPES = [TYPE_1];

export const STAIR_RISER_TYPES = [TYPE_1];

export const LINTEL_TYPES = [TYPE_1];

export const PILLAR_TYPES = [TYPE_1];

export const CUSTOM_TILE_TYPES = [TYPE_1];

export const REAR_HEEL_TYPES = [REAR_HEEL_1_2, REAR_HEEL_5_2, REAR_HEEL_CUSTOM];

export const CUSHION_TYPES = [CUSHION_NOT_GLUED];
//endregion

export const INTERIOR_TYPES = [TYPE_1, TYPE_2, TYPE_3, TYPE_4];

export const EXTERIOR_TYPES = [TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8, TYPE_9];

// Types which are created from massive pieces of stone
export const MASSIVE_TYPES = [TYPE_5, TYPE_6, TYPE_7, TYPE_8];

export function getStandardMeasurementsByType(type, height) {
  let measurements;

  const standardExtrusion = 1;

  switch (type) {
    case TYPE_1:
    case TYPE_2:
      measurements = {};
      break;
    case TYPE_3:
    case TYPE_4:
      measurements = {
        extrusion: standardExtrusion,
        barWidth: 2,
      };
      break;
    case TYPE_5:
    case TYPE_6:
      measurements = {
        frontHeight: height - standardExtrusion,
        extrusion: standardExtrusion,
        barWidth: 2,
      };
      break;
    case TYPE_7:
    case TYPE_8:
      measurements = {
        frontHeight: height - standardExtrusion - 1,
        extrusion: standardExtrusion,
        barWidth: 2,
        cushionWidth: 3,
        protrusion: 0,
      };
      break;
    case REAR_HEEL_1_2:
      measurements = {
        height: 1,
        width: 2,
      };
      break;
    case REAR_HEEL_5_2:
      measurements = {
        height: 5,
        width: 2,
      };
      break;
    case REAR_HEEL_CUSTOM: {
      measurements = {};
      break;
    }
    case CUSHION_NOT_GLUED: {
      measurements = {
        height: 2,
        width: 3,
      };
      break;
    }
  }

  return measurements;
}

export function getTypesByPreset(decor, material, preset, subPreset = '') {
  let types;
  switch (preset) {
    case WINDOW_SLABS:
      types = WINDOW_SLAB_TYPES;
      break;
    case ENTREPORTS:
      types = ENTREPORT_TYPES;
      break;
    case WALL_SLABS:
      types = WALL_SLAB_TYPES;
      break;
    case SILLS:
      if (subPreset === SILLS_STOCK) {
        types = SILL_STOCK_TYPES;
      } else {
        types = SILL_TYPES;
      }
      break;
    case WALL_COVERS:
      types = decor === INTERIOR ? WALL_COVER_TYPES.INTERIOR : WALL_COVER_TYPES.EXTERIOR;
      break;
    case CHIMNEY_COVERS:
      types = CHIMNEY_COVER_TYPES;
      break;
    case BASEBOARDS:
      types = BASEBOARD_TYPES;
      break;
    case STAIR_STEPS:
      types = STAIR_STEP_TYPES;
      break;
    case STAIR_RISERS:
      types = STAIR_RISER_TYPES;
      break;
    case LINTELS:
      types = LINTEL_TYPES;
      break;
    case PILLARS:
      types = PILLAR_TYPES;
      break;
    case CUSTOM_TILE:
      types = CUSTOM_TILE_TYPES;
      break;
    case REAR_HEELS:
      types = REAR_HEEL_TYPES;
      break;
    case CUSHIONS:
      types = CUSHION_TYPES;
      break;
    default:
      types = [];
      break;
  }

  // TODO: Temporary rule, remove when new articles are made in Adsolut
  if (material === GRANITE) {
    types = types.filter(t => t === TYPE_1);
  }

  return types;
}
