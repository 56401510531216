import * as React from 'react';
import { useEffect, useState } from 'react';
import { Title } from '../../headers/Title';
import { SidebarTextItem } from '../../lists/SidebarTextItem';
import { RETAIL } from '../../../constants/PriceTypes';
import { COLLECT, DELIVERY } from '../../../constants/DeliveryOptions';
import { SideBar } from '../../navigation/SideBar';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import PropTypes from 'prop-types';
import { Order } from '../../../classes/models/Order';
import { useSelector } from 'react-redux';
import { OrderService } from '../../../classes/services/OrderService';
import { parseToCommaSeparated } from '../../../classes/helpers/StringHelper';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';
import { ADMIN, SUPERVISOR } from '../../../constants/RoleNames';

export const OrderSideBar = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const sidebarPrefix = 'partials.sidebar.';
  const deliveryOptionPrefix = 'constants.deliveryOptions.';
  const paymentMethodPrefix = 'constants.paymentMethods.';

  const [weight, setWeight] = useState(0);

  const { priceType } = useSelector(state => state.generalReducer);

  const orderService = new OrderService();

  useEffect(() => {
    let isMounted = true;

    if (props.order) {
      orderService.getWeight(props.order.id).then(weight => {
        if (isMounted) {
          setWeight(parseToCommaSeparated(weight, 2));
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [props.order]);

  const getDeliveryTypeDescription = () => {
    if (props.order.deliveryType === DELIVERY) {
      return t(sidebarPrefix + 'deliveryDate');
    }

    return t(sidebarPrefix + 'collectionDate');
  };

  return (
    <SideBar right>
      <Title title={t(sidebarPrefix + 'totalPriceTitle')} />

      <SidebarTextItem
        dataCy="orderSideBar-priceExcl"
        description={t(sidebarPrefix + 'priceExcludingVAT')}
        value={props.order.getFormattedPrice()}
      />

      <SidebarTextItem
        dataCy="orderSideBar-priceIncl"
        description={t(sidebarPrefix + 'priceIncludingVAT')}
        value={props.order.getFormattedPrice(true)}
      />

      <Title title={t(sidebarPrefix + 'orderDetailsTitle')} />

      <SidebarTextItem description={t(sidebarPrefix + 'orderId')} value={props.order.id} />

      <SidebarTextItem description={t(sidebarPrefix + 'orderName')} value={props.order.name} />

      {!props.order.isCreatedByAdmin && (
        <SidebarTextItem description={t(sidebarPrefix + 'offerId')} value={props.order.offerId} />
      )}

      <SidebarTextItem
        description={t(sidebarPrefix + 'customer')}
        value={props.order.customer ? props.order.customer.name : '/'}
      />

      {priceType !== RETAIL && (
        <SidebarTextItem description={t(sidebarPrefix + 'margin')} value={props.order.margin + '%'} />
      )}

      <SidebarTextItem description={t(sidebarPrefix + 'vat')} value={props.order.vat + '%'} />

      <SidebarTextItem
        dataCy="orderSideBar-paymentMethod"
        description={t(sidebarPrefix + 'paymentMethod')}
        value={t(paymentMethodPrefix + props.order.paymentMethod)}
      />

      <SidebarTextItem description={t(sidebarPrefix + 'createdBy')} value={props.order.creator?.getFullName()} />

      {hasOneOfRoles(ADMIN, SUPERVISOR) && (
        <SidebarTextItem
          description={t(sidebarPrefix + 'isVisible')}
          value={props.order.isVisible ? t(sidebarPrefix + 'yes') : t(sidebarPrefix + 'no')}
        />
      )}

      {props.order.creator.id !== props.order.contactPerson.id && (
        <SidebarTextItem
          dataCy={'orderSideBar-contactPerson'}
          description={t(sidebarPrefix + 'contactPerson')}
          value={props.order.contactPerson.getFullName()}
        />
      )}

      <SidebarTextItem description={t(sidebarPrefix + 'date')} value={props.order.timestamp.date} />

      <Title title={t(sidebarPrefix + 'deliveryDetailsTitle')} />

      <SidebarTextItem
        dataCy="orderSideBar-deliveryType"
        description={t(sidebarPrefix + 'deliveryOption')}
        value={
          props.order.deliveryType === DELIVERY ? t(deliveryOptionPrefix + DELIVERY) : t(deliveryOptionPrefix + COLLECT)
        }
      />

      <SidebarTextItem
        description={getDeliveryTypeDescription()}
        value={props.order.deliveryDate.getReadableDate(props.order.deliveryType === COLLECT)}
        dataCy="offerSideBar-deliveryDate"
      />

      {props.order.comment && (
        <>
          <Title title={t(sidebarPrefix + 'comment')} />
          <SidebarTextItem value={props.order.comment} />
        </>
      )}

      <SidebarTextItem
        description={t(sidebarPrefix + 'weight')}
        value={weight ? `${weight} kg` : '/'}
        dataCy="offerSideBar-weight"
      />
    </SideBar>
  );
};

OrderSideBar.propTypes = {
  order: PropTypes.instanceOf(Order).isRequired,
};
