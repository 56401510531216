import * as React from 'react';
import logo from '../../../assets/img/logo.svg';
import imageMaatopname from '../../../assets/img/landingpage/maatopname.png';
import imageMateriaalkeuze from '../../../assets/img/landingpage/materiaalkeuze.png';
import imageOffertes from '../../../assets/img/landingpage/offertes.png';
import imageBestelling from '../../../assets/img/landingpage/bestelling.png';
import imageFacturatie from '../../../assets/img/landingpage/facturatie.png';

import propertyAannemer from '../../../assets/img/landingpage/property-aannemer.jpg';
import propertyPleister from '../../../assets/img/landingpage/property-pleister.jpg';
import propertyTuin from '../../../assets/img/landingpage/property-tuin.jpg';
import propertyVloerder from '../../../assets/img/landingpage/property-vloerder.jpg';
import heroImage from '../../../assets/img/landingpage/hero-image.png';
import history from '../../../config/history';

export const LandingPage = props => {
  const toLogin = () => {
    history.push('/login');
  };

  const toRegister = () => {
    history.push('/register');
  };

  return (
    <div className="landingpage">
      <header>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header__holder">
                <div data-cy="logo" className="header__logo">
                  <img data-cy="logo-img" alt="logo" src={logo} />
                  Stone App
                </div>
                <div className="header__nav">
                  <a data-cy="contact-button" href="mailto:info@stoneapp.be" className="button button--danger">
                    Contacteer ons
                  </a>
                  {/*<button onClick={() => toRegister()} className="button button--danger">Account maken</button>*/}
                  <button data-cy="login-button" onClick={() => toLogin()} className="button button--outline">
                    Aanmelden
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="hero__title">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 data-cy="landing-page-app-title">Stone App</h1>
              <p data-cy="landing-page-app-subtitle">Maatwerk in natuursteen wordt 'pap'-makkelijk</p>
            </div>
          </div>
        </div>
      </div>

      <div className="hero">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="hero__video" data-cy="landing-page-video">
                <img src={heroImage} alt="configurator-preview" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h2 data-cy="container-title">Stone App vergemakkelijkt het bestelproces</h2>
              <p data-cy="container-subtitle">
                Ontdek hoe Stone App je door elke fase van de bouw van dienst kan zijn.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <ul className="landingpage__steps" data-cy="landingpage-steps">
                <li>
                  <a href="#maatopname">Maatopname</a>
                </li>
                <li>
                  <a href="#materiaalkeuze">Materiaalkeuze</a>
                </li>
                <li>
                  <a href="#offertes">Offertes</a>
                </li>
                <li>
                  <a href="#bestelling">Bestellen</a>
                </li>
                <li>
                  <a href="#facturatie">Opvolging</a>
                </li>
                <li>
                  <a href="#facturatie">Facturatie</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="featured__item">
        <div className="container">
          <div data-cy="measurement-survey" className="row align-items-center featured" id="maatopname">
            <div data-cy="measurement-survey-text" className="col-lg-6 featured__content">
              <h3 data-cy="measurement-survey-title">Maatopname</h3>
              <p>
                Maatwerk bestellen in dorpels, muurkappen, tabletten of trappen? Dat begint met uitmeten, op plan of op
                de werf.
              </p>
              <p>
                Voer al je gewenste maten gemakkelijk in Stone App. Met onze 3D configurator krijg je een realistische
                voorstelling van je bestelling. Ook tegels, boordstenen en pallisades kan je terugvinden in het webshop
                gedeelte.
              </p>
            </div>
            <div className="col-lg-6 featured__image">
              <img data-cy="measurement-survey-img" src={imageMaatopname} />
            </div>
          </div>
        </div>
      </section>
      <section className="featured__item">
        <div className="container">
          <div data-cy="choice-of-material" className="row align-items-center featured" id="materiaalkeuze">
            <div className="col-lg-6 featured__image">
              <img data-cy="choice-of-material-img" src={imageMateriaalkeuze} />
            </div>
            <div data-cy="choice-of-material-text" className="col-lg-6 featured__content">
              <h3 data-cy="choice-of-material-title">Materiaalkeuze</h3>
              <p>
                In de uitgebreide catalogus, beschikbaar in de Stone App, zijn tal van natuursteensoorten verkrijgbaar.
              </p>
              <p>
                Van marmers tot granieten, van wit tot zwart, de keuze is aan u. De afwerkingsmogelijkheden in Belgische
                hardsteen zijn ongeëvenaard!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="featured__item">
        <div className="container">
          <div data-cy="offers" className="row align-items-center featured" id="offertes">
            <div data-cy="offers-text" className="col-lg-6 featured__content">
              <h3 data-cy="offers-title">Offertes</h3>
              <p>
                Eens materiaal en afmetingen zijn ingegeven, heb je onmiddellijk een goed zicht van het kostenplaatje.
                Dit laat een snelle feedback toe naar je klanten: één klik en de offerte is verstuurd.
              </p>
              <p>
                Je kan steeds een aanpassing doen, of een variant uitwerken. Stone App houdt het voor u bij! Stuk per
                stuk kan je de afmetingen en bewerkingen in 3D controleren.
              </p>
            </div>
            <div className="col-lg-6 featured__image">
              <img data-cy="offers-img" src={imageOffertes} />
            </div>
          </div>
        </div>
      </section>
      <section className="featured__item">
        <div className="container">
          <div data-cy="orders" className="row align-items-center featured" id="bestelling">
            <div className="col-lg-6 featured__image">
              <img data-cy="orders-img" src={imageBestelling} />
            </div>
            <div data-cy="orders-text" className="col-lg-6 featured__content">
              <h3 data-cy="orders-title">Bestellingen plaatsen</h3>
              <p>
                Alles juist? Plaats je bestelling online, in een handomdraai. Ook hier geeft Stone App een overzicht van
                uw lopende bestellingen.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="featured__item">
        <div className="container">
          <div data-cy="state-order" className="row align-items-center featured" id="facturatie">
            <div data-cy="state-order-text" className="col-lg-6 featured__content">
              <h3 data-cy="state-order-title">Statusopvolging bestelling & facturatie</h3>
              <p>
                Stone App houd je per bestelling op de hoogte van de verwerkingsfaze: verzaagd, gepoleerd, afgewerkt...
                en ingepakt, klaar voor afhaling of levering.
              </p>
              <p>En facturatie… ... goede afspraken, goede vrienden.</p>
            </div>
            <div className="col-lg-6 featured__image">
              <img data-cy="state-order-img" src={imageFacturatie} />
            </div>
          </div>
        </div>
      </section>

      <section className="property">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h2>Van ruwbouw tot eindafwerking</h2>
              <p>Ontdek voor wie Stone App een niet te missen tool is</p>
              <br />
              <br />
              <br />
            </div>
          </div>

          <div className="row">
            <div data-cy="contractor-text" className="col-lg-6 property__item">
              <img data-cy="contractor-img" src={propertyAannemer} className="w-100" />
              <h3 data-cy="contractor-title">Aannemers & bouwhandelaars</h3>
              <p>
                Alle 'natuursteenposten' van uw ruwbouw kan je vooraf calculeren en vervolgens verder verfijnen tijdens
                uitvoering van de werf:{' '}
              </p>
              <ul>
                <li>Deur- en vensterdorpel : op maat, al dan niet met opkant of afschuining</li>
                <li>
                  Muurkappen in verstek of met uitsparing? Geen probleem: de Stone App geeft je een 3D voorstelling.
                </li>
                <li>Muurplinten : massief of voorzet, met of zonder afschuining</li>
              </ul>
            </div>

            <div data-cy="plastering-works" className="col-lg-6 property__item">
              <img data-cy="plastering-works-img" src={propertyPleister} className="w-100" />
              <h3 data-cy="plastering-works-title">Pleisterwerken en -handelaars: </h3>
              <p data-cy="plastering-works-text">
                Venstertabletten ineens kunnen mee plaatsen, betekent tijdswinst voor iedereen. Via Stone App heeft u
                snel een overzicht van de mogelijkheden, kostprijs en timing. Onze lokale, moderne productie-ateliers
                staan in voor snelle levering.{' '}
              </p>
            </div>

            <div data-cy="flooring-contractors" className="col-lg-6 property__item">
              <img data-cy="flooring-contractors-img" src={propertyVloerder} className="w-100" />
              <h3 data-cy="flooring-contractors-title">Vloerders en tegelhandelaars:</h3>
              <p data-cy="flooring-contractors-text">
                Een niveauverschil wegwerken kan gemakkelijk met een deurdorpel (entreporte). Trappen: één trede of een
                hele set? Natuursteentegels vind je terug in de webshop van Stone App.
              </p>
            </div>

            <div data-cy="gardeners" className="col-lg-6 property__item">
              <img data-cy="gardeners-img" src={propertyTuin} className="w-100" />
              <h3 data-cy="gardeners-title">Tuinaanleggers:</h3>
              <p data-cy="gardeners-text">
                Niet enkel de terrastegels in echte belgische blauwe steen, maar ook boordstenen en pallisades in
                verschillende afmetingen. Kies je afwerking van oppervlak : bruut gezaagd, geschuurd of verouderd. We
                hebben het op voorraad. Een offerte via de webshop in Stone App is snel opgemaakt, en doorgestuurd naar
                je klant. Met onze installaties kunnen we u op maat verder helpen voor bloktredes of andere
                toepassingen. Speciale afwerkingen (oude frijnslag, gevlamd...) kunnen aangevraagd worden. En wat is er
                mooier dan pure natuur uitstraling in je tuin?
              </p>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div data-cy="footer" className="col text-center">
              <strong>Stone App</strong> - www.stoneapp.be
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
