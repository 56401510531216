import { BACK, FRONT, LEFT, RIGHT } from '../../../constants/ObjectSides';
import { Vector3 } from 'three';
import MeasurementPoints from '../../models/MeasurementPoints';
import { getOriginalSideLengthOfPiece, getXPosForSide, getZPosForSide } from './SurfaceMeasurementLineHelper';
import { DEFAULT_DIMENSION_RATIO } from '../../../constants/Values';

// Gets the starting and ending points of the whole length of the given side
export function getPointsForWholeLengthOfSide(piece, sideOfLine) {
  const pieceDimensions = piece.dimensions;
  let xPosA, xPosB;
  let zPosA, zPosB;

  const xPos = getXPosForSide(pieceDimensions, sideOfLine);
  const yPos = pieceDimensions.height / 2;
  const zPos = getZPosForSide(pieceDimensions, sideOfLine);

  switch (sideOfLine) {
    case FRONT:
      xPosA = pieceDimensions.length / -2;
      xPosB = pieceDimensions.length / 2;
      break;
    case BACK:
      xPosA = pieceDimensions.length / 2;
      xPosB = pieceDimensions.length / -2;
      break;
    case LEFT:
      zPosA = pieceDimensions.width / -2;
      zPosB = pieceDimensions.width / 2;
      break;
    case RIGHT:
      zPosA = pieceDimensions.width / 2;
      zPosB = pieceDimensions.width / -2;
      break;
  }

  const pointA = new Vector3(xPosA ?? xPos, yPos, zPosA ?? zPos);

  const pointB = new Vector3(xPosB ?? xPos, yPos, zPosB ?? zPos);

  return new MeasurementPoints(pointA, pointB);
}
