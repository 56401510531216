import * as React from 'react';
import PropTypes from 'prop-types';
import { SingleInputModal } from '../../modals/SingleInputModal';
import { useState } from 'react';
import ManufacturerService from '../../../classes/services/ManufacturerService';
import { useDispatch, useSelector } from 'react-redux';
import { addAlertMessage, setWindowIsLoading } from '../../../actions/GeneralActions';
import { DANGER, SUCCESS } from '../../../constants/Variants';
import { CatalogModal } from '../../modals/CatalogModal';
import { setCurrentManufacturer } from '../../../actions/ManufacturerActions';
import { AppHeader } from '../../headers/AppHeader';
import { ActionsDropdown } from '../../dropdowns/ActionsDropdown';
import { ActionsDropdownItem } from '../../dropdowns/items/ActionsDropdownItem';
import { SectionTitle } from '../../headers/SectionTitle';
import { ExternalLinkModal } from '../../modals/ExternalLinkModal';
import { ExternalLinkList } from './ExternalLinkList';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { CatalogList } from './CatalogList';
import CatalogService from '../../../classes/services/CatalogService';
import ManufacturerReducerHelper from '../../../classes/helpers/ManufacturerReducerHelper';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';

export const ManufacturerDetail = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.admin.manufacturerDetail.';
  const constantsPrefix = 'constants.adminPages.';

  const [editManufacturerNameModalIsActive, setEditManufacturerNameModalIsActive] = useState(false);
  const [externalLinkModalIsActive, setExternalLinkModalIsActive] = useState(false);
  const [deleteExternalLinkModalIsActive, setDeleteExternalLinkModalIsActive] = useState(false);
  const [deleteCatalogModalIsActive, setDeleteCatalogModalIsActive] = useState(false);
  const [catalogModalIsActive, setCatalogModalIsActive] = useState(false);
  const [currentCatalog, setCurrentCatalog] = useState();
  const [currentExternalLink, setCurrentExternalLink] = useState();

  const currentManufacturer = useSelector(state => state.manufacturerReducer.currentManufacturer);

  const manufacturerService = new ManufacturerService();
  const catalogService = new CatalogService();

  const dispatch = useDispatch();

  const addExternalLink = (name, link) => {
    dispatch(setWindowIsLoading(true));

    manufacturerService
      .addExternalLink(currentManufacturer.id, name, link)
      .then(response => {
        if (response.success) {
          currentManufacturer.links.push(response.data);
          dispatch(setCurrentManufacturer(currentManufacturer));
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, t(prefix + 'addLinkFailedMessage')));

        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const updateExternalLink = (name, linkContent) => {
    currentExternalLink.name = name;
    currentExternalLink.link = linkContent;

    manufacturerService
      .updateExternalLink(currentManufacturer.id, currentExternalLink)
      .then(response => {
        currentManufacturer.links = currentManufacturer.links.map(link => {
          if (link === response.data.id) return response.data;

          return link;
        });

        setCurrentManufacturer(currentManufacturer);
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, t(prefix + 'updateLinkFailedMessage')));

        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const createCatalog = (name, file) => {
    dispatch(setWindowIsLoading(true));

    catalogService
      .create(currentManufacturer.id, name, file)
      .then(response => {
        if (response.success) {
          currentManufacturer.catalogs.push(response.data);

          setCurrentManufacturer(currentManufacturer);
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, t(prefix + 'addCatalogFailedMessage')));

        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const editCatalog = (name, file) => {
    dispatch(setWindowIsLoading(true));

    let promises = [];

    if (name !== currentCatalog.name) {
      promises.push(catalogService.updateName(currentCatalog.id, name));
    }

    if (file) {
      promises.push(catalogService.updateFile(currentCatalog.id, file));
    }

    Promise.all(promises)
      .then(responses => {
        responses.forEach(response => {
          if (response.success) {
            ManufacturerReducerHelper.updateCatalog(currentCatalog.id, response.data);
          } else {
            throw Error(response.message);
          }
        });
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, t(prefix + 'updateCatalogFailedMessage')));

        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));
  };

  const closeEditManufacturerNameModal = name => {
    if (name && name !== currentManufacturer?.name) {
      dispatch(setWindowIsLoading(true));

      manufacturerService
        .updateName(currentManufacturer.id, name)
        .then(response => {
          if (response.success) {
            currentManufacturer.name = response.data.name;
            dispatch(setCurrentManufacturer(currentManufacturer));

            dispatch(addAlertMessage(SUCCESS, t(prefix + 'updateManufacturerSuccessMessage')));
          } else {
            throw Error(response.message);
          }
        })
        .catch(error => {
          dispatch(addAlertMessage(DANGER, error.message));
        })
        .finally(() => dispatch(setWindowIsLoading(false)));
    }

    setEditManufacturerNameModalIsActive(false);
  };

  const closeExternalLinkLModal = (name, linkContent) => {
    if (name && name.length > 0 && linkContent && linkContent.length > 0) {
      if (currentExternalLink) {
        updateExternalLink(name, linkContent);
      } else {
        addExternalLink(name, linkContent);
      }
    }

    setCurrentExternalLink(null);
    setExternalLinkModalIsActive(false);
  };

  const closeCatalogModal = (name, file) => {
    if (name && name.length > 0 && file) {
      if (currentCatalog) {
        editCatalog(name, file);
      } else {
        createCatalog(name, file);
      }
    }

    setCatalogModalIsActive(false);
    setCurrentCatalog(null);
  };

  const closeDeleteExternalLinkModal = accepted => {
    if (accepted) {
      dispatch(setWindowIsLoading(true));

      manufacturerService
        .deleteExternalLink(currentManufacturer.id, currentExternalLink.id)
        .then(response => {
          if (response.success) {
            dispatch(addAlertMessage(SUCCESS, t(prefix + 'deleteLinkSuccessMessage')));

            currentManufacturer.links = currentManufacturer.links.filter(link => link.id !== currentExternalLink.id);
          } else {
            throw Error(response.message);
          }
        })
        .catch(error => {
          dispatch(addAlertMessage(DANGER, t(prefix + 'deleteLinkFailedMessage')));

          throw error;
        })
        .finally(() => {
          dispatch(setWindowIsLoading(false));
          setCurrentExternalLink(null);
        });
    }

    setDeleteExternalLinkModalIsActive(false);
  };

  const openDeleteExternalLinkModal = externalLink => {
    if (externalLink) {
      setCurrentExternalLink(externalLink);
      setDeleteExternalLinkModalIsActive(true);
    }
  };

  const closeDeleteCatalogModal = accepted => {
    if (accepted) {
      dispatch(setWindowIsLoading(true));

      catalogService
        .delete(currentCatalog.id)
        .then(response => {
          if (response.success) {
            ManufacturerReducerHelper.deleteCatalog(currentCatalog.id);

            dispatch(addAlertMessage(SUCCESS, t(prefix + 'deleteCatalogSuccessMessage')));
          } else {
            throw Error(response.message);
          }
        })
        .catch(error => {
          dispatch(addAlertMessage(DANGER, t(prefix + 'deleteCatalogFailedMessage')));

          throw error;
        })
        .finally(() => dispatch(setWindowIsLoading(false)));
    }

    setCurrentCatalog(null);
    setDeleteCatalogModalIsActive(false);
  };

  const openDeleteCatalogModal = catalog => {
    if (catalog) {
      setCurrentCatalog(catalog);
      setDeleteCatalogModalIsActive(true);
    }
  };

  const openEditExternalLinkModal = externalLink => {
    if (externalLink) {
      setCurrentExternalLink(externalLink);
      setExternalLinkModalIsActive(true);
    }
  };

  const openCatalogModal = catalog => {
    if (catalog) setCurrentCatalog(catalog);

    setCatalogModalIsActive(true);
  };

  return (
    <div>
      <SingleInputModal
        isActive={editManufacturerNameModalIsActive}
        onClose={name => closeEditManufacturerNameModal(name)}
        header={t(prefix + 'editManufacturerModalHeader')}
        content={t(prefix + 'editManufacturerModalContent')}
        value={currentManufacturer?.name}
      />

      <CatalogModal
        catalog={currentCatalog}
        isActive={catalogModalIsActive}
        onClose={(name, file) => closeCatalogModal(name, file)}
      />

      <ExternalLinkModal
        externalLink={currentExternalLink}
        isActive={externalLinkModalIsActive}
        onClose={(name, linkContent) => closeExternalLinkLModal(name, linkContent)}
      />

      <ConfirmationModal
        isActive={deleteExternalLinkModalIsActive}
        onClose={accepted => closeDeleteExternalLinkModal(accepted)}
        content={t(prefix + 'deleteExternalLinkModalContent')}
      />

      <ConfirmationModal
        isActive={deleteCatalogModalIsActive}
        onClose={accepted => closeDeleteCatalogModal(accepted)}
        content={t(prefix + 'deleteCatalogModalContent')}
      />

      <AppHeader title={currentManufacturer?.name} onBackClick={() => props.onNavigateBack()}>
        <ActionsDropdown>
          <ActionsDropdownItem
            content={t(prefix + 'dropdownEdit')}
            onClick={() => setEditManufacturerNameModalIsActive(true)}
          />
        </ActionsDropdown>
      </AppHeader>

      <SectionTitle content={t(prefix + 'externalLinksTitle')} />
      <button
        type="button"
        className="button button--light button--icon-before"
        onClick={() => setExternalLinkModalIsActive(true)}
      >
        <i className="material-icons">add</i>
      </button>

      <ExternalLinkList
        links={currentManufacturer.links}
        onEdit={externalLink => openEditExternalLinkModal(externalLink)}
        onDelete={externalLink => openDeleteExternalLinkModal(externalLink)}
      />

      <SectionTitle content={t(prefix + 'catalogsTitle')} />

      <button type="button" className="button button--light button--icon-before" onClick={() => openCatalogModal()}>
        <i className="material-icons">add</i>
      </button>

      <CatalogList
        catalogs={currentManufacturer.catalogs}
        onEdit={catalog => openCatalogModal(catalog)}
        onDelete={catalog => openDeleteCatalogModal(catalog)}
      />
    </div>
  );
};

ManufacturerDetail.propTypes = {
  onNavigateBack: PropTypes.func.isRequired,
};
