import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.svg';
import { AuthService } from '../../internal';
import history from '../../config/history';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlertMessage, setCurrentUser, setIsAuthenticated } from '../../actions/GeneralActions';
import { useForm } from 'react-hook-form';
import { ValidationMessage } from '../shared/ValidationMessage';
import { DANGER } from '../../constants/Variants';
import { SystemService } from '../../classes/services/SystemService';

export function Login(props) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.login.';
  const validationPrefix = 'validation.';

  const [isLoading, setIsLoading] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState('');

  const { alertMessages, isAuthenticated } = useSelector(state => state.generalReducer);

  const dispatch = useDispatch();

  const authService = new AuthService();
  const systemService = new SystemService();

  const { errors, register, handleSubmit, reset } = useForm();

  useEffect(() => {
    authService.isAuthenticated().then(user => {
      if (user) {
        dispatch(setIsAuthenticated(true));
        dispatch(setCurrentUser(user));
      }
    });

    systemService.checkupOnQueue();
  }, []);

  useEffect(() => {
    // Remove all alert messages when returning to this screen
    for (let i = 0; i < alertMessages.length; i++) {
      dispatch(removeAlertMessage(0));
    }
  }, [alertMessages]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/offers');
    }
  }, [isAuthenticated]);

  const login = fields => {
    setIsLoading(true);

    let formData = new FormData();
    formData.append('email', fields.email.trim());
    formData.append('password', fields.password.trim());

    setLoginError('');

    authService
      .login(formData)
      .then(response => {
        if (response.success) {
          dispatch(setIsAuthenticated(true));
        } else {
          if (isAuthenticated) dispatch(setIsAuthenticated(false));

          if (response.status === 401) {
            setLoginError(t(prefix + 'accessDenied'));
            reset();
          } else {
            setLoginError(t(prefix + 'invalidCredentials'));
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <div className="sessionpage">
        <div className="sessionpage__login">
          <div className="sessionpage__login__inner">
            <h1 data-cy="login-logo-text">
              <img data-cy="login-logo" alt="logo" src={logo} />
              Login
            </h1>

            <form id="loginForm" onSubmit={handleSubmit(login)}>
              <div className="form-group">
                <label data-cy="login-email-label" htmlFor="email">
                  {t(prefix + 'emailLabel')}
                </label>
                <input
                  data-cy="login-email-input"
                  ref={register({
                    required: true,
                    maxLength: 100,
                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}\s*$/i,
                  })}
                  name="email"
                  type="text"
                  className={'form-control'}
                  disabled={isLoading}
                  placeholder={t(prefix + 'emailPlaceholder')}
                />
                {errors.email?.type === 'required' ? (
                  <ValidationMessage
                    dataCy="login-email-error"
                    content={t(validationPrefix + 'required')}
                    variant={DANGER}
                  />
                ) : null}
                {errors.email?.type === 'maxLength' ? (
                  <ValidationMessage
                    dataCy="login-email-error"
                    content={t(validationPrefix + 'maxLength', { maxLength: 100 })}
                    variant={DANGER}
                  />
                ) : null}
                {errors.email?.type === 'pattern' ? (
                  <ValidationMessage
                    dataCy="login-email-error"
                    content={t(validationPrefix + 'email')}
                    variant={DANGER}
                  />
                ) : null}
              </div>

              <div className="form-group">
                <label data-cy="login-password-label" htmlFor="password">
                  {t(prefix + 'passwordLabel')}
                </label>
                <input
                  data-cy="login-password-input"
                  ref={register({ required: true, maxLength: 100 })}
                  name="password"
                  type="password"
                  className={'form-control'}
                  disabled={isLoading}
                  placeholder={t(prefix + 'passwordPlaceholder')}
                />
                {errors.password?.type === 'required' ? (
                  <ValidationMessage
                    dataCy="login-password-error"
                    content={t(validationPrefix + 'required')}
                    variant={DANGER}
                  />
                ) : null}
                {errors.password?.type === 'maxLength' ? (
                  <ValidationMessage
                    dataCy="login-password-error"
                    content={t(validationPrefix + 'maxLength', { maxLength: 100 })}
                    variant={DANGER}
                  />
                ) : null}
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  form="loginForm"
                  className="button button--primary"
                  disabled={isLoading}
                  data-cy="login-button"
                >
                  {isLoading ? t(prefix + 'loginButtonLoading') : t(prefix + 'loginButton')}
                </button>
              </div>

              {loginError ? (
                <small data-cy="login-form-error" className="form-error">
                  {loginError}
                </small>
              ) : null}

              <div data-cy="login-forgot-password" className="sessionpage__login__footer">
                {/*<Link to="/register" className="link">{t(prefix + 'register')}</Link>*/}
                {/*-*/}
                <Link to={'/forgotpassword'} className="link">
                  {t(prefix + 'forgotPassword')}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
