import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

export const SearchBar = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'navigation.sidebar.';

  const { register, setValue } = useForm();

  const { searchKeyword } = useSelector(state => state.generalReducer);

  useEffect(() => {
    setValue('searchBar', searchKeyword);
  }, [searchKeyword]);

  return (
    <div className={`search ${props.className}`}>
      <input
        ref={register}
        type="search"
        name="searchBar"
        defaultValue={searchKeyword}
        placeholder={t(prefix + 'searchBarPlaceholder')}
        disabled={props.disabled}
        data-cy="search-bar"
        onChange={event => props.onInput(event.target.value)}
      />
    </div>
  );
};

SearchBar.propTypes = {
  onInput: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

SearchBar.defaultProps = {
  disabled: false,
  className: '',
};
