import * as ActionTypes from '../constants/ActionTypes';

export function setCompany(company) {
  return {
    type: ActionTypes.SET_COMPANY,
    company: company,
  };
}

export function setSelectedCompany(company) {
  return {
    type: ActionTypes.SET_SELECTED_COMPANY,
    company: company,
  };
}

export function setCurrentCompanyBranch(companyBranch) {
  return {
    type: ActionTypes.SET_CURRENT_COMPANY_BRANCH,
    companyBranch: companyBranch,
  };
}
