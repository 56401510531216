import MeasurementPoints from '../../../models/MeasurementPoints';
import { BACK, FRONT, LEFT, RIGHT, TOP } from '../../../../constants/ObjectSides';
import { Vector3 } from 'three';
import { distanceFromSide } from '../SideMeasurementLineHelper';
import { VectorHelper } from '../../VectorHelper';

export function getPointsForNotchOverLength(pieceDimensions, notchOverLength) {
  notchOverLength.position = VectorHelper.getNotchOverLengthPosition(pieceDimensions, notchOverLength);

  const horizontalPoints = new MeasurementPoints(
    getHorizontalPointA(pieceDimensions, notchOverLength),
    getHorizontalPointB(pieceDimensions, notchOverLength),
  );

  if (notchOverLength.additionalDimension.type === TOP) horizontalPoints.textSide = BACK;

  const verticalPoints = new MeasurementPoints(
    getVerticalPointA(pieceDimensions, notchOverLength),
    getVerticalPointB(pieceDimensions, notchOverLength),
  );

  if ([FRONT, BACK].includes(notchOverLength.side)) {
    if (notchOverLength.position.z > 0) verticalPoints.textSide = FRONT;
    else verticalPoints.textSide = BACK;
  }

  return {
    horizontal: horizontalPoints,
    vertical: verticalPoints,
  };
}

function getHorizontalPointA(pieceDimensions, notchOverLength) {
  const xPos = getXPosHorizontalPointA(pieceDimensions, notchOverLength);
  const yPos = getYPosHorizontalPoint(pieceDimensions, notchOverLength);
  const zPos = getZPosHorizontalPointA(pieceDimensions, notchOverLength);

  return new Vector3(xPos, yPos, zPos);
}

function getHorizontalPointB(pieceDimensions, notchOverLength) {
  const xPos = getXPosHorizontalPointB(pieceDimensions, notchOverLength);
  const yPos = getYPosHorizontalPoint(pieceDimensions, notchOverLength);
  const zPos = getZPosHorizontalPointB(pieceDimensions, notchOverLength);

  return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointA(pieceDimensions, notchOverLength) {
  const xPos = getXPosVerticalPoint(pieceDimensions, notchOverLength);
  const yPos = getYPosVerticalPointA(notchOverLength);
  const zPos = getZPosVerticalPoint(pieceDimensions, notchOverLength);

  return new Vector3(xPos, yPos, zPos);
}

function getVerticalPointB(pieceDimensions, notchOverLength) {
  const xPos = getXPosVerticalPoint(pieceDimensions, notchOverLength);
  const yPos = getYPosVerticalPointB(notchOverLength);
  const zPos = getZPosVerticalPoint(pieceDimensions, notchOverLength);

  return new Vector3(xPos, yPos, zPos);
}

function getXPosHorizontalPointA({ length }, notchOverLength) {
  let xPos;

  if ([FRONT, BACK].includes(notchOverLength.side)) {
    xPos = length / -2;
  } else {
    xPos = notchOverLength.position.x - notchOverLength.dimensions.width / 2;
  }

  return xPos;
}

function getXPosHorizontalPointB({ length }, notchOverLength) {
  let xPos;

  if ([FRONT, BACK].includes(notchOverLength.side)) {
    xPos = length / -2;
  } else {
    xPos = notchOverLength.position.x + notchOverLength.dimensions.width / 2;
  }

  return xPos;
}

function getYPosHorizontalPoint({ height }, notchOverLength) {
  let yPos;

  if (notchOverLength.additionalDimension.type === TOP) {
    yPos = height / 2 + distanceFromSide;
  } else {
    yPos = height / -2 - distanceFromSide;
  }

  return yPos;
}

function getZPosHorizontalPointA({ width }, notchOverLength) {
  let zPos;

  if ([FRONT, BACK].includes(notchOverLength.side)) {
    zPos = notchOverLength.position.z - notchOverLength.dimensions.width / 2;
  } else {
    zPos = width / 2;
  }

  return zPos;
}

function getZPosHorizontalPointB({ width }, notchOverLength) {
  let zPos;

  if ([FRONT, BACK].includes(notchOverLength.side)) {
    zPos = notchOverLength.position.z + notchOverLength.dimensions.width / 2;
  } else {
    zPos = width / 2;
  }

  return zPos;
}

function getXPosVerticalPoint({ length }, notchOverLength) {
  let xPos;

  if ([FRONT, BACK].includes(notchOverLength.side)) {
    xPos = length / -2;
  } else {
    xPos = notchOverLength.position.x - notchOverLength.dimensions.width / 2 - distanceFromSide;
  }

  return xPos;
}

function getYPosVerticalPointA(notchOverLength) {
  return notchOverLength.position.y + notchOverLength.dimensions.height / 2;
}

function getYPosVerticalPointB(notchOverLength) {
  return notchOverLength.position.y - notchOverLength.dimensions.height / 2;
}

function getZPosVerticalPoint({ length, width }, notchOverLength) {
  let zPos;

  switch (notchOverLength.side) {
    case FRONT:
      zPos = notchOverLength.position.z - notchOverLength.dimensions.width / 2 - distanceFromSide;
      break;
    case BACK:
      zPos = notchOverLength.position.z + notchOverLength.dimensions.width / 2 + distanceFromSide;
      break;
    case LEFT:
    case RIGHT:
      zPos = length / 2;
      break;
  }

  return zPos;
}
