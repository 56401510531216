import * as React from 'react';
import PropTypes from 'prop-types';
import { Select } from '../forms/selects/Select';
import { sortObjectsAlphabeticallyByName } from '../../classes/helpers/SortingHelper';
import { useDispatch, useSelector } from 'react-redux';
import { addAlertMessage, setSearchKeyword } from '../../actions/GeneralActions';
import { DANGER } from '../../constants/Variants';
import { setCurrentCompanyBranch } from '../../actions/CompanyActions';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { NONE } from '../../constants/Filters';
import { useLocation } from 'react-router-dom';
import { ORDERS_PATH_NAME } from '../../constants/PathNames';

export const CompanyBranchSelect = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'shared.companyBranchSelect.';
  const filterPrefix = 'constants.filters.';

  const { currentCompanyBranch } = useSelector(state => state.companyReducer);
  const { searchKeyword } = useSelector(state => state.generalReducer);

  const dispatch = useDispatch();
  const urlParams = useLocation();

  const updateCurrentCompanyBranch = id => {
    if (id === NONE) {
      dispatch(setCurrentCompanyBranch(null));
    } else {
      if (searchKeyword) dispatch(setSearchKeyword());

      const newBranch = props.branches.find(c => c.id === parseInt(id));

      if (!newBranch) {
        dispatch(addAlertMessage(DANGER, t(prefix + 'companyBranchDoesNotExist')));
        return;
      }

      dispatch(setCurrentCompanyBranch(newBranch));
    }
  };

  const getOptions = () => {
    const options = props.branches.sort(sortObjectsAlphabeticallyByName).map(branch => {
      return { id: branch.id, value: branch.name, emphasize: branch?.canOrder };
    });

    if (urlParams.pathname.includes(ORDERS_PATH_NAME)) {
      return [
        {
          id: NONE,
          value: t(filterPrefix + NONE),
        },
      ].concat(options);
    }

    return options;
  };

  return (
    <div className={props.className}>
      <Select
        name="companySelect"
        value={currentCompanyBranch?.id}
        options={getOptions()}
        onChange={branchId => updateCurrentCompanyBranch(branchId)}
        dataTest="companyBranchSelect"
        dataCy={props.dataCy}
      />
    </div>
  );
};

CompanyBranchSelect.propTypes = {
  branches: PropTypes.array,
  className: PropTypes.string,
  dataCy: PropTypes.string,
};

CompanyBranchSelect.defaultProps = {
  branches: [],
  className: '',
};
