import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ModalHolder } from './ModalHolder';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { SectionTitle } from '../headers/SectionTitle';
import { CheckboxWithIcon } from '../forms/inputs/CheckboxWithIcon';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { useForm } from 'react-hook-form';
import { CompanyBranch } from '../../classes/models/CompanyBranch';
import { CountrySelect } from '../forms/selects/CountrySelect';
import { EmptyPage } from '../empty/EmptyPage';
import { ONLY_NUMBERS } from '../../constants/RegEx';
import { LanguageSelect } from '../forms/selects/LanguageSelect';
import { addAlertMessage, setWindowIsLoading } from '../../actions/GeneralActions';
import { DANGER } from '../../constants/Variants';
import { DeliveryTypeService } from '../../classes/services/DeliveryTypeService';
import { useDispatch } from 'react-redux';
import { Select } from '../forms/selects/Select';
import { FRENCH_SHORT } from '../../constants/Languages';
import { ExternalContactPersonModal } from './ExternalContactPersonModal';

export const CompanyBranchModal = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.companyBranchModal.';

  const [canOrder, setCanOrder] = useState(false);
  const [canOrderStock, setCanOrderStock] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [deliveryType, setDeliveryType] = useState({});
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [externalContactsModalIsActive, setExternalContactsModalIsActive] = useState(false);

  const { register, handleSubmit, errors, setValue } = useForm();

  const dispatch = useDispatch();

  const deliveryTypeService = new DeliveryTypeService();

  useEffect(() => {
    console.log(deliveryType);
  }, [deliveryType]);

  useEffect(() => {
    let isMounted = true;
    dispatch(setWindowIsLoading(true));

    deliveryTypeService
      .getAll()
      .then(types => {
        if (isMounted) setDeliveryTypes(types);
      })
      .catch(error => {
        dispatch(addAlertMessage(DANGER, t(prefix + 'getDeliveryTypesFailed')));

        throw error;
      })
      .finally(() => dispatch(setWindowIsLoading(false)));

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (props.isActive) {
      if (props.companyBranch?.id) {
        setDeliveryType(props.companyBranch.deliveryType);
        setCanOrder(props.companyBranch.canOrder);
        setCanOrderStock(props.companyBranch.canOrderStock);
        setSelectedLanguage(props.companyBranch.language);

        if (props.companyBranch.address?.country) {
          setValue('country', props.companyBranch.address?.country);
        }
      } else {
        setDeliveryType(deliveryTypes[0]);
        setCanOrder(false);
        setCanOrderStock(false);
        setSelectedLanguage(FRENCH_SHORT);
      }
    } else {
      if (deliveryTypes.length) {
        setDeliveryType(deliveryTypes[0]);
      }
    }
  }, [props.isActive, props.companyBranch]);

  const onDeliveryTypeChange = deliveryTypeId => {
    setDeliveryType(deliveryTypes.find(t => t.id === parseInt(deliveryTypeId)));
  };

  const onClose = fields => {
    if (fields) {
      const data = {
        name: fields.name,
        relationCode: fields.relationCode,
        deliveryTypeId: deliveryType.id ?? deliveryTypes[0],
        canOrder: canOrder,
        canOrderStock: canOrderStock,
        language: selectedLanguage,
        address: {
          street: fields.street,
          number: fields.number,
          postalCode: fields.postalCode,
          city: fields.city,
          country: fields.country,
        },
      };

      props.onClose(data);
    } else {
      props.onClose();
    }
  };

  const closeExternalContactPersonModal = user => {
    if (user) {
      props.onContactPersonAdd(props.companyBranch, user);
    }

    setExternalContactsModalIsActive(false);
  };

  const renderUserList = (users, canDelete = false) => {
    let onListItemClick = user => props.openUserModal(user);

    if (canDelete) {
      onListItemClick = () => {};
    }

    return (
      <table data-cy={'companyDetail-userList'} className="table--bordered m-b-2">
        <tbody>
          <tr>
            <th>{t(prefix + 'nameTableHeader')}</th>
            <th>{t(prefix + 'userEmailTableHeader')}</th>
            {canDelete && <th></th>}
          </tr>
          {users.map(user => {
            return (
              <tr
                className="pointer"
                key={user.id}
                onClick={() => onListItemClick(user)}
                data-cy={'companyDetail-userList-item'}
              >
                <td>{user.getFullName()}</td>
                <td>{user.email}</td>
                {canDelete && (
                  <td>
                    <button
                      data-cy={'companyDetail-userList-item-deleteButton'}
                      type="button"
                      className="button button--icon color--red"
                      onClick={() => props.onContactPersonDelete(props.companyBranch, user)}
                    >
                      <i className="material-icons">delete</i>
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <ExternalContactPersonModal
        isActive={externalContactsModalIsActive}
        companyBranch={props.companyBranch}
        contactPersons={props.contactPersons}
        onClose={user => {
          closeExternalContactPersonModal(user);
        }}
      />

      <Modal show={props.isActive} onHide={() => onClose(false)} animation={true} className="modal">
        <Modal.Header>
          <Modal.Title>{props.companyBranch?.id > 0 ? t(prefix + 'editHeader') : t(prefix + 'addHeader')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="branchForm" data-cy={'companyBranchModalForm'} onSubmit={handleSubmit(onClose)}>
            <SectionTitle content={t(prefix + 'generalInfoTitle')} />

            <ValidatedInput
              register={register}
              error={errors.name}
              name="name"
              label={t(prefix + 'nameLabel')}
              placeholder={t(prefix + 'namePlaceholder')}
              value={props.companyBranch?.name}
              required={true}
              minLength={1}
              maxLength={100}
            />

            <ValidatedInput
              register={register}
              error={errors.relationCode}
              name="relationCode"
              label={t(prefix + 'relationCodeLabel')}
              placeholder={t(prefix + 'relationCodePlaceholder')}
              value={props.companyBranch?.relationCode}
              required
              minLength={1}
              maxLength={6}
              pattern={ONLY_NUMBERS}
            />

            <LanguageSelect
              label={t(prefix + 'languageSelectLabel')}
              value={selectedLanguage}
              onChange={language => setSelectedLanguage(language)}
            />

            <Select
              name="deliveryType"
              value={deliveryType?.id}
              label={t(prefix + 'deliveryTypeLabel')}
              options={deliveryTypes.map(type => {
                return { id: type.id, value: type.getDescription() };
              })}
              onChange={id => onDeliveryTypeChange(id)}
            />

            <SectionTitle content={t(prefix + 'addressTitle')} />

            <div className="row">
              <div className="col-6">
                <ValidatedInput
                  register={register}
                  error={errors.street}
                  name="street"
                  label={t(prefix + 'streetLabel')}
                  placeholder={t(prefix + 'streetPlaceholder')}
                  value={props.companyBranch?.address?.street}
                  required={true}
                  minLength={1}
                  maxLength={100}
                />
              </div>
              <div className="col-6">
                <ValidatedInput
                  register={register}
                  error={errors.number}
                  name="number"
                  label={t(prefix + 'numberLabel')}
                  placeholder={t(prefix + 'numberPlaceholder')}
                  value={props.companyBranch?.address?.number}
                  required={true}
                  minLength={1}
                  maxLength={100}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <ValidatedInput
                  register={register}
                  error={errors.postalCode}
                  name="postalCode"
                  label={t(prefix + 'postalCodeLabel')}
                  placeholder={t(prefix + 'postalCodePlaceholder')}
                  value={props.companyBranch?.address?.postalCode}
                  required={true}
                  minLength={1}
                  maxLength={100}
                />
              </div>
              <div className="col-6">
                <ValidatedInput
                  register={register}
                  error={errors.city}
                  name="city"
                  label={t(prefix + 'cityLabel')}
                  placeholder={t(prefix + 'cityPlaceholder')}
                  value={props.companyBranch?.address?.city}
                  required={true}
                  minLength={1}
                  maxLength={100}
                />
              </div>
            </div>

            <CountrySelect register={register} error={errors.country} />

            <SectionTitle content={t(prefix + 'rightsTitle')} />

            <CheckboxWithIcon
              checked={canOrder}
              content={t(prefix + 'canOrderCheckbox')}
              onChange={() => setCanOrder(!canOrder)}
            />

            <div className="m-t-2">
              <CheckboxWithIcon
                checked={canOrderStock}
                content={t(prefix + 'canOrderStockCheckbox')}
                onChange={() => setCanOrderStock(!canOrderStock)}
              />
            </div>
          </form>

          {!!(props.companyBranch && props.companyBranch.id) && (
            <div className="m-b-5">
              {props.companyBranch.users.length ? (
                <>
                  <SectionTitle content={t(prefix + 'usersTitle')} />
                  <div className="m-b-5">
                    {renderUserList(props.companyBranch.users)}
                    <button
                      type="button"
                      className="button button--primary button--icon-before"
                      onClick={() => props.openUserModal()}
                    >
                      <i className="material-icons">add</i> {t(prefix + 'addUserButton')}
                    </button>
                  </div>
                </>
              ) : (
                <EmptyPage
                  title={t(prefix + 'emptyUserListTitle')}
                  content={t(prefix + 'emptyUserListMessage')}
                  buttonText={t(prefix + 'addUserButton')}
                  onButtonClick={() => props.openUserModal()}
                />
              )}
            </div>
          )}

          <SectionTitle
            content={t(prefix + 'externalContactPersonsTitle')}
            dataCy={'companyDetail-externalContactsTitle'}
          />

          {props.contactPersons.length ? (
            <>
              {renderUserList(props.contactPersons, true)}
              <button
                data-cy={'companyDetail-externalContactsButton'}
                type="button"
                className="button button--primary button--icon-before"
                onClick={() => setExternalContactsModalIsActive(true)}
              >
                <i className="material-icons">add</i> {t(prefix + 'addContactButton')}
              </button>
            </>
          ) : (
            <EmptyPage
              title={t(prefix + 'emptyContactListTitle')}
              content={t(prefix + 'emptyContactListMessage')}
              buttonText={t(prefix + 'addContactButton')}
              onButtonClick={() => setExternalContactsModalIsActive(true)}
              buttonDataCy={'companyDetail-emptyContacts-addButton'}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="button button--primary" type="submit" form="branchForm">
            {props.companyBranch?.id ? t(prefix + 'editButton') : t(prefix + 'addButton')}
          </button>
          <button className="button button--outline" type="button" onClick={() => onClose(false)}>
            {t(prefix + 'cancelButton')}
          </button>
        </Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

CompanyBranchModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  companyBranch: PropTypes.instanceOf(CompanyBranch),
  contactPersons: PropTypes.array,
  openUserModal: PropTypes.func.isRequired,
  onContactPersonAdd: PropTypes.func.isRequired,
  onContactPersonDelete: PropTypes.func.isRequired,
};
