import { Service } from './Service';
import { Parser } from '../helpers/Parser';

class ManufacturerService extends Service {
  async getAll() {
    const data = {
      withCatalogs: true,
      withLinks: true,
    };

    return await this.authenticatedRequest('get', 'manufacturers', data).then(response => {
      if (response.data.success) {
        response.data.data = response.data.data.map(manufacturerData => {
          return Parser.parseManufacturer(manufacturerData);
        });
      }

      return response.data;
    });
  }

  async create(name) {
    const data = {
      name: name,
    };

    return await this.authenticatedRequest('post', 'manufacturers', data).then(response => {
      if (response.data.success) {
        response.data.data = Parser.parseManufacturer(response.data.data);
      }

      return response.data;
    });
  }

  async addExternalLink(manufacturerId, name, link) {
    const data = {
      name: name,
      link: link,
    };

    return await this.authenticatedRequest('post', 'manufacturers/' + manufacturerId, data).then(response => {
      if (response.data.success) {
        response.data.data = Parser.parseLink(response.data.data);
      }

      return response.data;
    });
  }

  async updateName(id, name) {
    const data = {
      name: name,
    };

    return await this.authenticatedRequest('put', 'manufacturers/' + id, data).then(response => {
      if (response.data.success) {
        response.data.data = Parser.parseManufacturer(response.data.data);
      }

      return response.data;
    });
  }

  async updateExternalLink(manufacturerId, externalLink) {
    const data = {
      name: externalLink.name,
      link: externalLink.link,
    };

    return await this.authenticatedRequest(
      'put',
      'manufacturers/' + manufacturerId + '/links/' + externalLink.id,
      data,
    ).then(response => {
      if (response.data.success) {
        response.data.data = Parser.parseLink(response.data.data);
      }

      return response.data;
    });
  }

  async delete(id) {
    return await this.authenticatedRequest('delete', 'manufacturers/' + id).then(response => {
      return response.data;
    });
  }

  async deleteExternalLink(manufacturerId, externalLinkId) {
    return await this.authenticatedRequest(
      'delete',
      'manufacturers/' + manufacturerId + '/links/' + externalLinkId,
    ).then(response => {
      return response.data;
    });
  }
}

export default ManufacturerService;
