import { Parser } from '../helpers/Parser';
import { OPEN } from '../../constants/Statuses';
import i18n from 'i18next';
import { DELIVERY_DATE_TO_BE_DETERMINED } from '../../constants/Values';

export class DeliveryDate {
  constructor() {
    this.id = 0;
    this.date = null;
    this.status = OPEN;
    this.updatedAt = null;
  }

  getReadableDate(withTimeStamp = false) {
    if (!this.date) return i18n.t('app:constants.values.' + DELIVERY_DATE_TO_BE_DETERMINED);

    const parsedTimestamp = Parser.parseStringToTimestamp(this.date);

    if (withTimeStamp) {
      return `${parsedTimestamp.date} ${parsedTimestamp.time}`;
    }

    return parsedTimestamp.date;
  }
}
