import { createStore } from 'redux';
import rootReducer from '../reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

// const persistConfig = {
//     // Root
//     key: 'root',
//     storage: storage,
//     // blacklist: ['generalReducer'],
//     transforms: [ReferenceTransform]
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// Added composeWithDevTools for using the ReduxDevTools
// ----Remove composeWithDevTools() when not needed anymore----
// export const store = createStore(persistedReducer, composeWithDevTools());
// export const persistor = persistStore(store);

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export const store = createStore(rootReducer, composeEnhancers());
