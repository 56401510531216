import produce from 'immer';
import * as ActionTypes from '../constants/ActionTypes';
import { CHECKING } from '../constants/OrderPhases';

const initialState = {
  currentOrder: null,
  currentPhase: CHECKING,
  comment: '',
  orders: [],
  paginationSettings: {},
};

function orderReducer(state = initialState, action) {
  // Always return a state when not using the producer
  // The producer automatically returns the state when no action matches
  return produce(state, draftState => {
    switch (action.type) {
      case ActionTypes.SET_CURRENT_ORDER:
        draftState.currentOrder = action.payload;
        break;
      case ActionTypes.SET_CURRENT_PHASE:
        draftState.currentPhase = action.payload;
        break;
      case ActionTypes.SET_ORDER_COMMENT:
        draftState.comment = action.payload;
        break;
      case ActionTypes.SET_ORDERS:
        draftState.orders = action.orders ? action.orders.concat([]) : [];
        break;
      case ActionTypes.SET_ORDER_PAGINATION_SETTINGS:
        draftState.paginationSettings = action.payload;
        break;
      default:
        break;
    }
  });
}

export default orderReducer;
