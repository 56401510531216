import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../constants/TranslationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { RadioButton } from '../forms/inputs/RadioButton';
import { DEBASE_ROUGH_TYPES, DEBASING_ROUGH, PROHIBITED_OPERATION_TYPES } from '../../constants/OperationTypes';
import frontToBackIcon from '../../assets/img/config-ravaleren-voor-achter.png';
import backToFrontIcon from '../../assets/img/config-ravaleren-achter-voor.png';
import middleToSidesIcon from '../../assets/img/config-ravaleren-midden.png';
import { SectionTitle } from '../headers/SectionTitle';
import { ValidatedInput } from '../forms/inputs/ValidatedInput';
import { Operation } from '../../classes/models/Operation';
import { parseNumber } from '../../classes/helpers/StringHelper';
import { setConfirmationModal } from '../../actions/GeneralActions';
import { WARNING } from '../../constants/Variants';
import { Message } from '../messages/Message';
import { createOperation, deleteOperation, updateOperation } from './index';
import { BOTTOM, TOP } from '../../constants/ObjectSides';
import { getDebasingRoughTypeByPreset, getSideNameByPreset } from '../../classes/helpers/ObjectHelper';
import { ModalHolder } from './ModalHolder';
import { EXCEPTIONAL } from '../../constants/MaterialQualities';
import { MASSIVE_TYPES } from '../../constants/ObjectTypes';
import { shouldHaveExceptionalQuality } from '../../classes/helpers/OperationHelper';

export const DebaseRoughModal = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'modals.debaseRoughModal.';
  const constantsPrefix = 'constants.operationTypes.DEBASE_ROUGH_TYPES.';
  const sidesPrefix = 'constants.objectSides.';

  const formRef = useRef();

  const { errors, register, handleSubmit } = useForm();

  const [selectedType, setSelectedType] = useState('');
  const [selectedSide, setSelectedSide] = useState('');
  const [canAddDebasing, setCanAddDebasing] = useState(false);
  const [availableSurfaces, setAvailableSurfaces] = useState([]);

  const { currentConfiguration, currentPiece, currentOperation } = useSelector(state => state.offerReducer);
  const canEdit = useSelector(state => state.generalReducer.canEdit);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isActive) {
      let surfaces = [];

      if (MASSIVE_TYPES.includes(currentPiece?.type)) {
        surfaces = [BOTTOM];
      } else {
        surfaces = [TOP, BOTTOM];
      }

      if (currentOperation) {
        setSelectedType(currentOperation.additionalDimension.type);
        setSelectedSide(currentOperation.side);
      } else {
        setSelectedType(DEBASE_ROUGH_TYPES.BACK_TO_FRONT);
        setSelectedSide(surfaces.length ? surfaces[0] : '');
      }

      setAvailableSurfaces(surfaces);
    }
  }, [props.isActive, currentOperation]);

  useEffect(() => {
    let canAdd = true;

    if (currentPiece?.getOperationsByType(DEBASING_ROUGH).length >= 1 && !currentOperation) canAdd = false;
    if (currentPiece?.hasAnyOfOperationTypes(PROHIBITED_OPERATION_TYPES.DEBASING_ROUGH)) canAdd = false;
    setCanAddDebasing(canAdd);
  }, [props.isActive]);

  useEffect(() => {
    if (selectedSide === BOTTOM && selectedType === DEBASE_ROUGH_TYPES.MIDDLE_TO_SIDES) {
      setSelectedType(DEBASE_ROUGH_TYPES.BACK_TO_FRONT);
    }
  }, [selectedSide]);

  const getSelectableDebaseRoughTypes = () => {
    let selectableTypes = [DEBASE_ROUGH_TYPES.BACK_TO_FRONT, DEBASE_ROUGH_TYPES.FRONT_TO_BACK];

    if (selectedSide === TOP) selectableTypes.push(DEBASE_ROUGH_TYPES.MIDDLE_TO_SIDES);

    return selectableTypes;
  };

  const getTypeIcon = type => {
    let icon;

    switch (type) {
      case DEBASE_ROUGH_TYPES.BACK_TO_FRONT:
        icon = backToFrontIcon;
        break;
      case DEBASE_ROUGH_TYPES.FRONT_TO_BACK:
        icon = frontToBackIcon;
        break;
      case DEBASE_ROUGH_TYPES.MIDDLE_TO_SIDES:
        icon = middleToSidesIcon;
        break;
    }

    return icon;
  };

  const createDebasingRough = fields => {
    let debasingRough = new Operation(0, DEBASING_ROUGH);
    debasingRough.dimensions.height = parseNumber(fields.height);
    debasingRough.additionalDimension.type = selectedType;
    debasingRough.side = selectedSide;

    createOperation(debasingRough, () => props.onClose(true), t(prefix + 'createDebasingRoughFailed'));
  };

  const updateDebasingRough = fields => {
    if (
      currentOperation.dimensions.height === parseNumber(fields.height) &&
      currentOperation.additionalDimension.type === selectedType &&
      currentOperation.side === selectedSide
    ) {
      props.onClose();
      return;
    }

    currentOperation.dimensions.height = parseNumber(fields.height);
    currentOperation.additionalDimension.type = selectedType;
    currentOperation.side = selectedSide;

    updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'debaseRoughUpdateFailed'));
  };

  const deleteDebasingRough = () => {
    deleteOperation(
      currentOperation,
      () => props.onClose(true),
      t(prefix + 'debasingRoughDeleteSuccess'),
      t(prefix + 'debasingRoughDeleteFailed'),
    );
  };

  const openConfirmationModal = () => {
    dispatch(
      setConfirmationModal({
        isActive: true,
        content: t(prefix + 'confirmDebasingDeletion'),
        onAccept: () => deleteDebasingRough(),
      }),
    );
  };

  const closeModal = fields => {
    if (!fields) {
      props.onClose();
      return;
    }

    if (currentOperation) {
      updateDebasingRough(fields);
    } else {
      createDebasingRough(fields);
    }
  };

  const getOnClickForPrimaryButton = () => {
    let onSubmitClick;
    const submit = () => formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));

    if (
      shouldHaveExceptionalQuality(currentPiece?.type, currentConfiguration?.options.height) &&
      currentConfiguration?.options.quality !== EXCEPTIONAL
    ) {
      onSubmitClick = dispatch(
        setConfirmationModal({
          isActive: true,
          content: t(prefix + 'confirmDebasingCreation'),
          onAccept: () => submit(),
        }),
      );
    } else {
      onSubmitClick = submit();
    }

    return onSubmitClick;
  };

  const renderFooterButtons = () => {
    let secondaryButtonStyle = 'button button--outline';
    let secondaryButtonContent = t(prefix + 'cancelButton');
    let secondaryButtonOnClick = () => closeModal();

    if (currentOperation != null && canEdit) {
      secondaryButtonStyle = 'button button--danger';
      secondaryButtonContent = t(prefix + 'deleteButton');
      secondaryButtonOnClick = () => openConfirmationModal();
    }

    const submitButton = (
      <button
        key={'cornerCutoutModalSubmitButton'}
        data-cy={'debaseRoughModal-submitButton'}
        className="button button--primary"
        form="debaseRoughModal"
        type="button"
        onClick={() => getOnClickForPrimaryButton()}
      >
        {t(prefix + 'doneButton')}
      </button>
    );

    let buttons = [
      <button
        key={'cornerCutoutModalCancelButton'}
        data-cy={'debaseRoughModal-deleteButton'}
        type="button"
        className={secondaryButtonStyle}
        onClick={secondaryButtonOnClick}
      >
        {secondaryButtonContent}
      </button>,
    ];

    if (canEdit) {
      buttons.unshift(submitButton);
    }

    return buttons;
  };

  return (
    <ModalHolder isActive={props.isActive}>
      <Modal show={props.isActive} onHide={() => closeModal()} animation={true}>
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(prefix + 'description')}</p>

          <form id="debaseRoughModal" ref={formRef} onSubmit={handleSubmit(closeModal)}>
            <SectionTitle content={t(prefix + 'sideTitle')} />

            {availableSurfaces.map(side => {
              const sideName = getSideNameByPreset(currentConfiguration?.options.preset, side);

              return (
                <RadioButton
                  key={`debaseRoughSideRadioButton${side}`}
                  name="debaseRoughSide"
                  content={t(sidesPrefix + sideName)}
                  onChange={() => setSelectedSide(side)}
                  checked={selectedSide === side}
                  disabled={!canEdit || !canAddDebasing}
                />
              );
            })}

            <SectionTitle content={t(prefix + 'typeTitle')} />

            {getSelectableDebaseRoughTypes().map(type => {
              const typeName = getDebasingRoughTypeByPreset(currentConfiguration?.options.preset, type);

              return (
                <RadioButton
                  key={`debaseRoughTypeRadioButton${type}`}
                  name="debaseRoughType"
                  image={getTypeIcon()}
                  content={t(constantsPrefix + typeName)}
                  checked={selectedType === type}
                  onChange={() => setSelectedType(type)}
                  disabled={!canEdit || !canAddDebasing}
                />
              );
            })}

            <SectionTitle content={t(prefix + 'heightSelectTitle')} />

            <ValidatedInput
              register={register}
              name="height"
              dataCy={'height'}
              label={t(prefix + 'heightInputLabel')}
              placeholder={t(prefix + 'heightInputPlaceholder')}
              value={currentOperation?.dimensions.height}
              error={errors.height}
              required={true}
              min={0.5}
              max={currentPiece?.dimensions.height - 1}
              disabled={!canEdit || !canAddDebasing}
            />
          </form>

          {!canAddDebasing && <Message content={t(prefix + 'cannotCreateDebasingRoughMessage')} variant={WARNING} />}
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </Modal>
    </ModalHolder>
  );
};

DebaseRoughModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
