import React, { useEffect, useState } from 'react';
import Configurator from './components/pages/configurator/Configurator';
import { AuthService, ForgotPassword, LocalStorageHelper, Login, Offers, Orders, ResetPassword } from './internal';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import './style/screen.scss';
import { TRANSLATION_NAMESPACE } from './constants/TranslationConstants';
import { Settings } from './components/pages/Settings';
import { Admin } from './components/pages/admin/Admin';
import { LandingPage } from './components/pages/landing/LandingPage';
import { Maintenance } from './components/pages/Maintenance';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAlertMessage,
  setCurrentUser,
  setDomain,
  setIsAuthenticated,
  setPriceType,
  setPriceVersion,
  setSearchKeyword,
  setSettings,
  setShouldUpdateLogo,
  setUserSettings,
} from './actions/GeneralActions';
import { ErrorPage } from './components/pages/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { setCompany, setCurrentCompanyBranch } from './actions/CompanyActions';
import { DANGER } from './constants/Variants';
import { CompanyBranchService } from './classes/services/CompanyBranchService';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import { SettingService } from './classes/services/SettingService';
import { OFFERS_PATH_NAME, ORDERS_PATH_NAME } from './constants/PathNames';
import { hasOneOfRoles } from './classes/helpers/UserHelper';
import CompanyService from './classes/services/CompanyService';
import { ENGLISH_SHORT } from './constants/Languages';
import { PriceVersionService } from './classes/services/PriceVersionService';
import { RefreshModal } from './components/modals/RefreshModal';
import { OfferDetail } from './components/pages/offers/OfferDetail';
import { UserSettingsService } from './classes/services/UserSettingsService';
import { DISCOUNTED } from './constants/PriceTypes';
import { USER } from './constants/RoleNames';

function App() {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const messagePrefix = 'messages.';
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [domainIsSet, setDomainIsSet] = useState(false);

  const { company, currentCompanyBranch } = useSelector(state => state.companyReducer);
  const { isAuthenticated, refreshModalIsActive, priceType, userSettings } = useSelector(state => state.generalReducer);

  const ignoredUrls = ['/register', '/forgotpassword', '/maintenance', '/resetpassword'];

  const companyService = new CompanyService();
  const companyBranchService = new CompanyBranchService();
  const authService = new AuthService();
  const userSettingsService = new UserSettingsService();

  useEffect(() => {
    dispatch(setDomain(window.location.hostname));

    setDomainIsSet(true);
  }, []);

  useEffect(() => {
    let removeListen;

    if (userSettings.priceType) {
      setPriceTypeByPathname(history.location.pathname);

      removeListen = history.listen((location, action) => {
        setPriceTypeByPathname(location.pathname);
      });
    }

    return () => {
      if (removeListen) {
        removeListen();
      }
    };
  }, [userSettings]);

  useEffect(() => {
    if (!domainIsSet) return;

    let isMounted = true;

    authService
      .isAuthenticated()
      .then(user => {
        if (user && isMounted) {
          dispatch(setIsAuthenticated(true));
          dispatch(setCurrentUser(user));

          Sentry.setUser({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            companyBranch: user.companyBranch,
            roles: user.roles,
          });

          getUserSettings(user.id);
        }
      })
      .catch(() => {
        if (isMounted) dispatch(addAlertMessage(DANGER, t(messagePrefix + 'getUserFailed')));
      });

    return () => {
      isMounted = false;
    };
  }, [domainIsSet, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated || hasOneOfRoles(USER)) return;

    let isMounted = true;

    const settingService = new SettingService();

    settingService
      .getAll()
      .then(data => {
        if (data.data && isMounted) {
          let newSettingsData = {};

          data.data.forEach(settingData => {
            newSettingsData[settingData.key] = settingData.value;
          });

          dispatch(setSettings(newSettingsData));
        }
      })
      .catch(e => {
        captureException(e);
        dispatch(addAlertMessage(DANGER, t(messagePrefix + 'fetchingSettingsFailedMessage')));
      });

    return () => {
      isMounted = false;
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) return;

    let isMounted = true;

    const priceVersionService = new PriceVersionService();

    priceVersionService
      .getCurrent()
      .then(priceVersion => {
        if (priceVersion && isMounted) {
          dispatch(setPriceVersion(priceVersion));
        }
      })
      .catch(e => {
        captureException(e);
      });

    return () => {
      isMounted = false;
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (!domainIsSet || !isAuthenticated) return;
    if (company) return;

    let isMounted = true;

    companyService
      .getCompanyOfCurrentUser()
      .then(response => {
        if (response.success) {
          if (isMounted) dispatch(setCompany(response.data));
        }
      })
      .catch(() => {
        if (isMounted) dispatch(addAlertMessage(DANGER, t(messagePrefix + 'getCompanyFailed')));
      });

    return () => {
      isMounted = false;
    };
  }, [domainIsSet, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (ignoredUrls.some(ignoredUrl => location.pathname.includes(ignoredUrl))) return;

    if (location.pathname === '/login') {
      dispatch(setCompany(null));
    } else if (!currentCompanyBranch && domainIsSet) {
      getCurrentCompanyBranch();
    }

    dispatch(setSearchKeyword());
  }, [location, domainIsSet, isAuthenticated]);

  const getCurrentCompanyBranch = () => {
    companyBranchService
      .getCurrent()
      .then(response => {
        if (response.success) {
          dispatch(setCurrentCompanyBranch(response.data));
          dispatch(setShouldUpdateLogo(true));
          LocalStorageHelper.setLanguage(response.data.language ?? ENGLISH_SHORT);
        } else {
          throw Error(response.message);
        }
      })
      .catch(error => {
        captureException(error);
        dispatch(addAlertMessage(DANGER, t(messagePrefix + 'fetchCurrentCompanyFailed')));
      });
  };

  const getUserSettings = userId => {
    userSettingsService
      .get(userId)
      .then(response => {
        if (response.data.success) {
          dispatch(setUserSettings(response.data.data));
        }
      })
      .catch(error => {
        captureException(error);
      });
  };

  const setPriceTypeByPathname = path => {
    if (path.includes(ORDERS_PATH_NAME)) {
      if (priceType !== DISCOUNTED) {
        dispatch(setPriceType(DISCOUNTED));
      }
    } else {
      if (priceType !== userSettings.priceType) {
        dispatch(setPriceType(userSettings.priceType));
      }
    }
  };

  const onErrorReset = () => {
    history.push('/offers');
  };

  return (
    <>
      <RefreshModal isActive={refreshModalIsActive} />

      {domainIsSet ? (
        <ErrorBoundary FallbackComponent={ErrorPage} onReset={() => onErrorReset()} onError={e => captureException(e)}>
          <div className="App">
            <link rel="stylesheet" href="https://unicons.iconscout.com/release/v2.0.1/css/unicons.css" />
            <Route exact path="/" component={LandingPage} />
            <Route path="/configurator/:type/:id/:configurationId" component={Configurator} />
            <Route path={`/${OFFERS_PATH_NAME}`} exact component={Offers} />
            <Route path={`/${OFFERS_PATH_NAME}/:id`} exact component={OfferDetail} />
            {/*<Route path="/articles" component={Articles}/>*/}
            <Route path="/login" component={Login} />
            {/*<Route path="/register" component={Register}/>*/}
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/resetpassword/:token" exact component={ResetPassword} />
            <Route path={`/${ORDERS_PATH_NAME}/:id?`} component={Orders} />
            <Route path="/admin/:page?/:id?" component={Admin} />
            <Route path="/settings" component={Settings} />
            <Route path="/maintenance" component={Maintenance} />
          </div>
        </ErrorBoundary>
      ) : null}
    </>
  );
}

export default Sentry.withProfiler(withTranslation(TRANSLATION_NAMESPACE)(App));
