import * as React from 'react';
import { ManufacturersList } from './ManufacturersList';
import { ManufacturerDetail } from './ManufacturerDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentManufacturer } from '../../../actions/ManufacturerActions';

export const ManufacturerManagement = props => {
  const currentManufacturer = useSelector(state => state.manufacturerReducer.currentManufacturer);
  const dispatch = useDispatch();

  const renderContent = () => {
    if (currentManufacturer) {
      return <ManufacturerDetail onNavigateBack={() => dispatch(setCurrentManufacturer(null))} />;
    } else {
      return <ManufacturersList />;
    }
  };

  return <div>{renderContent()}</div>;
};
