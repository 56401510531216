import * as React from 'react';
import PropTypes from 'prop-types';
import { Operation } from '../../../classes/models/Operation';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { CHISELED_SIDE, FINISHED_SIDE } from '../../../constants/OperationTypes';

export const OperationItem = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const prefix = 'pages.offers.configurationList.constants.';
  const operationTypePrefix = 'constants.operationTypes.';
  const messagePrefix = 'messages.';

  const getSummary = () => {
    let summary;

    if (props.operation.count !== undefined) {
      summary = props.operation.count;
    } else {
      summary = props.operation.getSummary();
    }

    return summary;
  };

  const getPrice = () => {
    let price;

    if (props.operation.price !== null) {
      price = `€ ${props.operation.price}`;
    } else {
      price = t(messagePrefix + 'cumulatedOperations');
    }

    return price;
  };

  const getOperationTypeTranslation = type => {
    let translation;

    if ([FINISHED_SIDE, CHISELED_SIDE].includes(type) && props.configurationOperations.find(o => o.type === type)) {
      translation = t(prefix + FINISHED_SIDE);
    } else {
      translation = t(operationTypePrefix + type);
    }

    return translation;
  };

  return (
    <tr className="offer__table__level2">
      <td>
        <i className="material-icons">subdirectory_arrow_right</i> {getOperationTypeTranslation(props.operation.type)}
      </td>
      <td colSpan="2">
        <small>{getSummary()}</small>
      </td>
      <td>{getPrice()}</td>
      <td colSpan={2} />
    </tr>
  );
};

OperationItem.propTypes = {
  operation: PropTypes.instanceOf(Operation),
  configurationOperations: PropTypes.array,
};
