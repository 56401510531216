import * as React from 'react';
import PropTypes from 'prop-types';
import { DimensionInput } from '../../forms/inputs/DimensionInput';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import * as Dimensions from '../../../constants/Dimensions';
import { useSelector } from 'react-redux';
import {
  INVERTED_DIMENSION_TYPES,
  MASSIVE_TYPES,
  REAR_HEEL_1_2,
  REAR_HEEL_5_2,
  TYPE_3,
  TYPE_4,
  TYPE_5,
  TYPE_6,
} from '../../../constants/ObjectTypes';
import { dimensionIsStandard, getDimensionsByPieceType } from '../../../classes/helpers/ObjectHelper';
import { UPRIGHT_PRESETS } from '../../../constants/Presets';
import { ADMIN, SUPERVISOR } from '../../../constants/RoleNames';
import { hasOneOfRoles } from '../../../classes/helpers/UserHelper';

export const PieceListItemDimensions = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const constantsPrefix = 'constants.dimensions.';

  const { currentConfiguration } = useSelector(state => state.offerReducer);

  const getLabel = dimension => {
    let label = t(constantsPrefix + dimension);

    if (INVERTED_DIMENSION_TYPES.includes(props.pieceType)) {
      if (dimension === Dimensions.LENGTH) label = t(constantsPrefix + Dimensions.WIDTH);
      if (dimension === Dimensions.WIDTH) label = t(constantsPrefix + Dimensions.LENGTH);
    }

    if (UPRIGHT_PRESETS.includes(currentConfiguration?.options.preset)) {
      if (dimension === Dimensions.WIDTH) label = t(constantsPrefix + Dimensions.HEIGHT_UPRIGHT);
    }

    if ([TYPE_3, TYPE_4].includes(props.pieceType)) {
      if (dimension === Dimensions.HEIGHT) label = t(constantsPrefix + Dimensions.HEIGHT_TYPE_3_TO_4);
    }

    if (MASSIVE_TYPES.includes(props.pieceType)) {
      if (dimension === Dimensions.HEIGHT) label = t(constantsPrefix + Dimensions.HEIGHT_TYPE_5_TO_8);
    }

    return label;
  };

  const getValue = dimension => {
    let value;

    if (dimension === Dimensions.HEIGHT_BACK_SIDE) {
      value = props.dimensions.height + props.dimensions.extrusion;
    } else {
      value = props.dimensions[dimension];
    }

    return value;
  };

  return (
    <div className="configuration__objects__content">
      <div className="configuration__objects__specs">
        {getDimensionsByPieceType(props.pieceType).map((dimension, index) => {
          let disabled = props.disabled;

          if (dimension === Dimensions.HEIGHT) disabled = true;
          if (
            dimension === Dimensions.WIDTH &&
            [REAR_HEEL_1_2, REAR_HEEL_5_2].includes(currentConfiguration?.options.type)
          )
            disabled = true;

          const dimensionValue = getValue(dimension);

          const shouldEmphasize =
            hasOneOfRoles([ADMIN, SUPERVISOR]) &&
            [TYPE_3, TYPE_4, TYPE_5, TYPE_6].includes(props.pieceType) &&
            !dimensionIsStandard(dimension, dimensionValue, currentConfiguration?.options.height, props.pieceType);

          return (
            <DimensionInput
              dataCy={`${props.dataCy}-${dimension}`}
              key={index}
              label={getLabel(dimension)}
              disabled={disabled}
              emphasize={shouldEmphasize}
              value={dimensionValue}
              onBlur={value => props.onDimensionUpdate(dimension, value)}
            />
          );
        })}
      </div>
    </div>
  );
};

PieceListItemDimensions.propTypes = {
  dimensions: PropTypes.object.isRequired,
  pieceType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onDimensionUpdate: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
};
