import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import app_nl from '../translations/nl/app';
import app_fr from '../translations/fr/app';
import app_en from '../translations/en/app';
import { LANGUAGE_KEY } from '../constants/Values';

i18n.use(detector).init({
  detection: {
    order: ['localStorage'],
    lookupLocalStorage: LANGUAGE_KEY,
  },
  interpolation: { escapeValue: false }, // React already does escaping
  react: {
    useSuspense: false,
  },
  fallbackLng: 'en',
  resources: {
    nl: {
      app: app_nl,
    },
    fr: {
      app: app_fr,
    },
    en: {
      app: app_en,
    },
  },
});

export default i18n;
