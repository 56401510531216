import * as React from 'react';
import PropTypes from 'prop-types';
import { OperationListItem } from './OperationListItem';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../../../constants/TranslationConstants';
import { Piece } from '../../../classes/models/Piece';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveOperationModal } from '../../../actions/GeneralActions';
import { setCurrentOperation, setCurrentPiece } from '../../../actions/OfferActions';
import { summarizeOperations } from '../../../classes/helpers/OperationHelper';
import { translateProfileTypeToName } from '../../../classes/helpers/operations/ProfileOperationHelper';
import { DIVIDE_PIECE } from '../../../constants/PieceActionTypes';
import {
  CHISELED_SIDE,
  COUPE,
  FINISHED_SIDE,
  HEIGHT_COUPE,
  PROFILE,
  SUMMARIZED_OPERATIONS,
  WATERLIST,
} from '../../../constants/OperationTypes';
import { CUSHIONS } from '../../../constants/Presets';
import { BOTTOM } from '../../../constants/ObjectSides';
import * as ProfileOperationTypes from '../../../constants/ProfileOperationTypes';

export const OperationList = props => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const profileTypePrefix = 'constants.profileOperationTypes.';
  const objectSidesPrefix = 'constants.objectSides.';
  const prefix = 'pages.configurator.configurationPieceList.';

  const { currentConfiguration } = useSelector(state => state.offerReducer);

  const dispatch = useDispatch();

  const addDividePieceOption = operations => {
    if (currentConfiguration?.options.preset !== CUSHIONS) {
      operations = operations.concat({
        type: DIVIDE_PIECE,
        count: props.piece.parts.length,
      });
    }

    return operations;
  };

  const getSubText = summarizedOperation => {
    let subText = '';

    if (summarizedOperation.type === FINISHED_SIDE) {
      const finishedSideBottom = props.piece.operations.find(
        operation => operation.type === FINISHED_SIDE && operation.side === BOTTOM,
      );

      if (finishedSideBottom) subText = t(prefix + 'bottomSideIsFinished');
    }

    return subText;
  };

  const getSummarizedOperations = () => {
    let summarizedOperations = summarizeOperations(props.piece.operations);
    summarizedOperations = addDividePieceOption(summarizedOperations);

    const finishedSidesPresent = !!summarizedOperations.find(o => o.type === FINISHED_SIDE || o.type === CHISELED_SIDE);

    if (finishedSidesPresent) {
      summarizedOperations = summarizedOperations.filter(summarizedOperation => summarizedOperation.type !== WATERLIST);
    }

    return summarizedOperations.map((summarizedOperation, index) => {
      const additionalType = summarizedOperation.type === PROFILE ? summarizedOperation.additionalType : '';

      let extraInfo;

      if (summarizedOperation.type === PROFILE) {
        extraInfo = t(profileTypePrefix + translateProfileTypeToName(summarizedOperation.additionalType));

        if (summarizedOperation.additionalType !== ProfileOperationTypes.TYPE_1 && summarizedOperation.data) {
          extraInfo += ' - ' + t(objectSidesPrefix + summarizedOperation.data);
        }
      }

      return (
        <OperationListItem
          dataCy={`${props.dataCy}-${summarizedOperation.type.toLowerCase()}`}
          key={`summarizedOperationListItem${index}`}
          type={summarizedOperation.type}
          additionalType={additionalType}
          info={'x' + summarizedOperation.count}
          infoExtra={extraInfo}
          subText={getSubText(summarizedOperation)}
          onClick={() =>
            openSummarizedOperationModal(
              summarizedOperation.type,
              summarizedOperation.additionalType,
              summarizedOperation.data,
            )
          }
        />
      );
    });
  };

  const openIndividualOperationModal = operation => {
    dispatch(setCurrentPiece(props.piece));
    dispatch(setCurrentOperation(operation));
    dispatch(setActiveOperationModal(operation.type));
  };

  const openSummarizedOperationModal = (type, additionalType, data) => {
    dispatch(setCurrentPiece(props.piece));
    dispatch(setActiveOperationModal(type, additionalType, data));
  };

  const renderOperationList = () => {
    let individualRenderedOperations = props.piece.operations.filter(
      operation => !SUMMARIZED_OPERATIONS.includes(operation.type),
    );

    let operationElements = individualRenderedOperations.map((operation, index) => {
      let additionalType;

      if ([COUPE, HEIGHT_COUPE].includes(operation.type)) {
        additionalType = operation.angle;
      } else {
        additionalType = operation.additionalDimension.type;
      }

      return (
        <OperationListItem
          dataCy={`${props.dataCy}-${operation.type.toLowerCase()}`}
          key={`individualRenderedOperation${index}`}
          type={operation.type}
          side={operation.side}
          additionalType={additionalType}
          info={operation.getSummary(currentConfiguration?.options.preset)}
          onClick={() => openIndividualOperationModal(operation)}
        />
      );
    });

    operationElements = operationElements.concat(getSummarizedOperations());

    return operationElements.map(element => element);
  };

  return <div className="configuration__objects__operations">{renderOperationList()}</div>;
};

OperationList.propTypes = {
  piece: PropTypes.instanceOf(Piece).isRequired,
  dataCy: PropTypes.string,
};
