import { CUSHION_NOT_GLUED, REAR_HEEL_TYPES } from '../../constants/ObjectTypes';
import {
  BAR_WIDTH,
  CUSHION_WIDTH,
  EXTRUSION,
  FRONT_HEIGHT,
  HEIGHT,
  HEIGHT_BACK_SIDE,
  LENGTH,
  PROTRUSION,
  WIDTH,
} from '../../constants/Dimensions';
import { INVALID_VALUE, TOO_HIGH, TOO_LOW } from '../../constants/ValidationErrors';
import { STANDARD_WIDTHS } from '../../constants/StandardMeasurements';
import { BASEBOARDS } from '../../constants/Presets';

export const MIN_LENGTH = 1;
const MIN_LENGTH_STOCK = 60;
const MAX_LENGTH = 5000;
const MAX_LENGTH_STOCK = 200;

const MIN_WIDTH = 5;
const MIN_WIDTH_STOCK = 18;
const MAX_WIDTH = 170;
const MAX_WIDTH_STOCK = 30;

const MIN_HEIGHT = 1;
const MAX_HEIGHT = 20;

const MIN_FRONT_HEIGHT = 1;
const MAX_FRONT_HEIGHT = 20;

const MIN_HEIGHT_BACK_SIDE = 2;
const MAX_HEIGHT_BACK_SIDE = 25;

const MIN_EXTRUSION = 0;
const MAX_EXTRUSION = 10;

const MIN_BAR_WIDTH = 0;
const MAX_BAR_WIDTH = MAX_WIDTH;

const MIN_CUSHION_WIDTH = 1;
const MAX_CUSHION_WIDTH = 20;

const MIN_PROTRUSION = 0;
const MAX_PROTRUSION = MAX_WIDTH;

class DimensionValidator {
  validateDimension(name, value, configurationOptions, pieceDimensions = null) {
    if (value > this.getMaxValue(name, configurationOptions, pieceDimensions)) {
      return TOO_HIGH;
    }
    if (value < this.getMinValue(name, configurationOptions)) {
      return TOO_LOW;
    }

    return '';
  }

  validateStockDimension(name, value) {
    let validationError;

    if (name === LENGTH) {
      validationError = this.__validateStockLength(value);
    } else if (name === WIDTH) {
      validationError = STANDARD_WIDTHS.includes(parseFloat(value)) ? undefined : INVALID_VALUE;
    }

    return validationError;
  }

  getMinValue(name, configurationOptions) {
    if (!configurationOptions) return;
    let minValue;

    if ([...REAR_HEEL_TYPES].includes(configurationOptions.type)) {
      minValue = this.__getMinValueForRearHeels(name);
    } else if (configurationOptions.type === CUSHION_NOT_GLUED) {
      minValue = this.__getMinValueForCushionsNotGlued(name);
    } else {
      minValue = this.__getMinValueForStandardTypes(
        name,
        configurationOptions.isConfiguredForStock(),
        configurationOptions.preset,
      );
    }

    return minValue;
  }

  getMaxValue(name, configurationOptions, pieceDimensions = null) {
    if (!configurationOptions) return;
    let maxValue;

    if ([...REAR_HEEL_TYPES].includes(configurationOptions.type)) {
      maxValue = this.__getMaxValueForRearHeels(name);
    } else if (configurationOptions.type === CUSHION_NOT_GLUED) {
      maxValue = this.__getMaxValueForCushionsNotGlued(name);
    } else {
      maxValue = this.__getMaxValueForStandardTypes(
        name,
        pieceDimensions,
        configurationOptions.isConfiguredForStock(),
        configurationOptions,
      );
    }

    return maxValue;
  }

  __getMinValueForRearHeels(name) {
    let minValue;

    switch (name) {
      case LENGTH:
        minValue = 50;
        break;
      case WIDTH:
        minValue = 1;
        break;
      case HEIGHT:
        minValue = 0.8;
        break;
      default:
        minValue = 10;
        break;
    }

    return minValue;
  }

  __getMinValueForCushionsNotGlued(name) {
    let minValue;

    switch (name) {
      case LENGTH:
        minValue = 0.1;
        break;
      case WIDTH:
        minValue = 1;
        break;
      case HEIGHT:
        minValue = 0.8;
        break;
      default:
        minValue = 1;
        break;
    }

    return minValue;
  }

  __getMinValueForStandardTypes(name, isStock, preset) {
    let minValue;

    switch (name) {
      case LENGTH:
        if (isStock) {
          minValue = MIN_LENGTH_STOCK;
        } else {
          minValue = MIN_LENGTH;
        }
        break;
      case WIDTH:
        if (isStock) {
          minValue = MIN_WIDTH_STOCK;
        } else {
          minValue = MIN_WIDTH;
        }
        break;
      case HEIGHT:
        if (preset === BASEBOARDS) {
          minValue = 2;
        } else {
          minValue = MIN_HEIGHT;
        }
        break;
      case FRONT_HEIGHT:
        minValue = MIN_FRONT_HEIGHT;
        break;
      case HEIGHT_BACK_SIDE:
        minValue = MIN_HEIGHT_BACK_SIDE;
        break;
      case EXTRUSION:
        minValue = MIN_EXTRUSION;
        break;
      case BAR_WIDTH:
        minValue = MIN_BAR_WIDTH;
        break;
      case CUSHION_WIDTH:
        minValue = MIN_CUSHION_WIDTH;
        break;
      case PROTRUSION:
        minValue = MIN_PROTRUSION;
        break;
      default:
        minValue = 10;
        break;
    }

    return minValue;
  }

  __getMaxValueForRearHeels(name) {
    let minValue;

    switch (name) {
      case LENGTH:
        minValue = 2000;
        break;
      case WIDTH:
        minValue = 5;
        break;
      case HEIGHT:
        minValue = 20;
        break;
      default:
        minValue = 2000;
        break;
    }

    return minValue;
  }

  __getMaxValueForCushionsNotGlued(name) {
    let maxValue;

    switch (name) {
      case LENGTH:
        maxValue = 20;
        break;
      case WIDTH:
        maxValue = 50;
        break;
      case HEIGHT:
        maxValue = 5;
        break;
      default:
        maxValue = 50;
        break;
    }

    return maxValue;
  }

  __getMaxValueForStandardTypes(name, pieceDimensions, isStock, { preset }) {
    let maxValue;

    switch (name) {
      case LENGTH:
        if (isStock) {
          maxValue = MAX_LENGTH_STOCK;
        } else {
          maxValue = MAX_LENGTH;
        }
        break;
      case WIDTH:
        if (isStock) {
          maxValue = MAX_WIDTH_STOCK;
        } else {
          maxValue = MAX_WIDTH;
        }
        break;
      case HEIGHT:
        if (preset === BASEBOARDS) {
          maxValue = 10;
        } else {
          maxValue = MAX_HEIGHT;
        }
        break;
      case FRONT_HEIGHT:
        maxValue = MAX_FRONT_HEIGHT;
        break;
      case HEIGHT_BACK_SIDE:
        maxValue = MAX_HEIGHT_BACK_SIDE;
        break;
      case EXTRUSION:
        maxValue = MAX_EXTRUSION;

        if (pieceDimensions) {
          maxValue = pieceDimensions.height - MIN_FRONT_HEIGHT;
        }
        break;
      case BAR_WIDTH:
        maxValue = MAX_BAR_WIDTH;
        break;
      case CUSHION_WIDTH:
        maxValue = MAX_CUSHION_WIDTH;
        break;
      case PROTRUSION:
        maxValue = MAX_PROTRUSION;
        break;
      default:
        maxValue = 10;
        break;
    }

    return maxValue;
  }

  __validateStockLength(value) {
    if (value % 5 > 0) return INVALID_VALUE;
  }
}

export default DimensionValidator;
